import * as VideoConference from '../State/VideoConference'
import * as Eff from 'redux-saga/effects'
import * as Api from '../Util/Api'

export function* fetchVideoconferencingRoomLink({ payload: {teleExpertiseType, teleExpertiseId, patientCode, staffVideoconferenceId} }) {
  try {
    const response = yield Eff.call(Api.fetchVideoconferencingRoomLink, {teleExpertiseType: teleExpertiseType === 'rcps' ? 'staffs' : teleExpertiseType, teleExpertiseId, patientCode, staffVideoconferenceId})

    if (response.success) {
      yield Eff.put(VideoConference.receivedVideoconferencingRoomLink({zoomRoomLink: response.zoomRoomLink, zoomRoomPassword: response.zoomRoomPassword}))
    } else {
      yield Eff.put(VideoConference.receivedApiErrorMessage(response.errorMessage))
    }
  } catch (error) {
    yield Eff.put(VideoConference.apiError(error.message))
  }
}

const VideoConferenceSagas = function* () {
  yield Eff.takeLatest(`${VideoConference.fetchVideoconferencingRoomLink}`, fetchVideoconferencingRoomLink)
}

export default VideoConferenceSagas;
