import * as React from "react";

export const SkemeetIcon = ({
    color = '#3BC3A8',
    ...props
}) => (
    <svg
        id="SKEMEET"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 358.47 358.03"
        {...props}
    >
        <defs>
        <style>
            {
            "\n      .cls-1 {\n        fill: #3bc3a8;\n      }\n\n      .cls-1, .cls-2, .cls-3 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #fff;\n      }\n\n      .cls-3 {\n        fill: #214152;\n      }\n    "
            }
        </style>
        </defs>
        <path
        className="cls-3"
        d="m202.47,84.42h-99.99c-6.44,0-11.36,1.46-14.75,4.37-3.4,2.91-5.09,6.88-5.09,11.91s1.63,8.76,4.9,11.71c3.26,2.96,8.25,4.43,14.95,4.43h42.87v140.93c0,8.03,1.83,14.03,5.49,18,3.66,3.97,8.4,5.96,14.23,5.96s10.41-1.96,14.03-5.89c3.62-3.92,5.43-9.95,5.43-18.06V116.84h17.95v-32.42Z"
        />
        <g>
        <path
            className="cls-1"
            d="m250.76,79.3c1.02-10.86,10.55-13.57,15.84-13.32l22.47.12c8.13,0,15.32,5.34,15.32,13.2l-.09,25.01c0,7.89-7,12.85-15.17,12.85h0l-3.38.15s0-.02,0,0v2.22s-.13,9.75-.13,9.75c0,.04.03.03,0,0l-8.17-8.14-3.7-3.95s.01,0,0,0l-1.65-.03-6.64.22c-3.31.11-6.64-.81-9.19-2.85-4.2-3.36-5.94-8.91-5.76-15.11l.24-20.12Z"
        />
        <path
            className="cls-3"
            d="m263.61,117.34c-.67,0-1.27-.11-1.8-.31-.61-.09-1.2-.28-1.73-.56-.88-.35-1.57-.94-2.05-1.66-.28-.35-.52-.74-.69-1.16-1.35-1.8-1.36-4.54-.02-6.35-.25-4.27.67-8.33.87-12.67.14-2.94.54-6.09.34-9.02-.02-.36-.07-.73-.13-1.08-.74-.4-1.41-1.05-1.91-2.01-.11-.21-.21-.43-.32-.65.03-2.16.11-4.32.32-6.47.03-.27.07-.52.13-.77-.46-.1-.92-.17-1.37-.24.03-.06.04-.12.07-.17-1.15-.01-2.36.01-3.59.04l-5.32.03s0,0,0,.01l-19.14.11c-8.13,0-15.32,5.34-15.32,13.2l.09,25.01c0,7.89,7,12.85,15.17,12.85l3.38.15v2.22l.13,9.75,8.17-8.14,3.7-3.95,1.65-.03,9.33-.09c5.28-1.09,8.66-4.02,10.51-8.03-.03,0-.05-.02-.08-.03-.13,0-.25.02-.39.02Z"
        />
        <path
            className="cls-2"
            d="m269.91,89.39h-8.97v-9.12c0-3.3-2.63-5.97-5.87-5.97s-5.87,2.67-5.87,5.97v9.12h-8.97c-3.24,0-5.87,2.67-5.87,5.97s2.63,5.97,5.87,5.97h8.97v9.12c0,3.3,2.63,5.97,5.87,5.97s5.87-2.67,5.87-5.97v-9.12h8.97c3.24,0,5.87-2.67,5.87-5.97s-2.63-5.97-5.87-5.97Z"
        />
        </g>
    </svg>
);
