import styled from 'styled-components/macro'
import { Link } from "react-router-dom";
import * as Ui from '../Component/Ui'

export const MessageContainer = styled.div`
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Search.FiltersAndSorting} {
    justify-content: flex-end;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Button.Back} {
    flex: initial;
  }
  ${Ui.Layout.Content} {
    padding-bottom: 2rem;
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Form.TextArea} {
      margin: 1rem 0;
    }
    ${Ui.Message.Body} {
      ${Ui.BasicElement.P} {
        margin: 0;
      }
    }
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  a {
    flex: 0;
    text-decoration: none;
  }
`

export const ButtonLargeLink = styled(props => <Link {...props} />)`
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    width: 100%;
  }
`

export const MessageSection = styled.div`
  margin-top: 2rem;
`

export const MessageSubject = styled.div`
  margin: 1rem 0;
`

