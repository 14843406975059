import React from "react";
import { useDispatch } from 'react-redux';
import { apiUrl } from '../../Util/Config'
import styled from "styled-components";
import * as Ui from '../Ui'
import Toggle from '../Ui/Toggle'
import * as Profile from '../../State/Profile'

const QuestionnaireImagePreview = styled.div`
  margin-top: .5rem;
  background: url(${props => props.backgroundSrc}) no-repeat white;
  background-size: auto 90%;
  width: 40rem;
  position: relative;
  height: 6.25rem;
  background-position: center;
`

const QuestionnaireImagePreviewStretched = styled.div`
  margin-top: .5rem;
  background: url(${props => props.backgroundSrc}) no-repeat;
  background-position: ${props =>
    props.alignment === 1
      ? 'top'
      : props.alignment === 2
        ? 'center'
        : 'bottom'
  };
  background-size: cover;
  width: 40rem;
  position: relative;
  height: 6.25rem;
`

const Choice = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.darkBlue};
  font-size: .875rem;
  margin-right: 1.1rem;
  label {
    margin-right: .4rem;
  }
  label > span {
    margin-left: .4rem;
    font-weight: normal;
  };
`

const ImageToolComponent = ({
  stretchKey = '',
  alignKey = '',
  isStretched = false,
  isEditMode = false,
  slice = {},
  alignment = 2,
  image = '',
  teleExpertiseName,
  teleExpertiseId,
  endpointPath = 'patientStepImage',
  canEdit,
}) => {

  const dispatch = useDispatch()
  const setImageSize = (e) => {
    dispatch(slice.updateData({ name: stretchKey, value: !isStretched }))
    dispatch(slice.saveField({ cohortId: teleExpertiseId, fieldName: stretchKey, scope: Profile.SCOPE.portalPro }))
  }

  const setAlignment = (e, value) => {
    dispatch(slice.updateData({ name: alignKey, value }))
    dispatch(slice.saveField({ cohortId: teleExpertiseId, fieldName: alignKey, scope: Profile.SCOPE.portalPro }))
  }

  const backgroundSrc = teleExpertiseName
    ? `${apiUrl}/${teleExpertiseName}s/${endpointPath}/${image}`
    : `${apiUrl}/portal/picture/${image}`

return <ImageToolContainer>
  <Ui.BasicElement.H3>Disposition de l'image</Ui.BasicElement.H3>
  {isStretched
    ? <QuestionnaireImagePreviewStretched
      backgroundSrc={backgroundSrc}
      alignment={alignment}
    />
    : <QuestionnaireImagePreview
      backgroundSrc={backgroundSrc}
      alignment={alignment}
    />
  }
  {image && isEditMode &&
    <>
      <ImageSettings flow="row nowrap">
        <Ui.Layout.BlockContainer>
          <Ui.Layout.FlexBox>
            <ImageSettingsHeader>
              Pleine largeur ?
            </ImageSettingsHeader>
            <Toggle
              id="header-image"
              name="header-image"
              checked={isStretched}
              onChange={setImageSize}
              disabled={!canEdit}
            />
          </Ui.Layout.FlexBox>
        </Ui.Layout.BlockContainer>
        {isStretched &&
          <Ui.Layout.FlexBox flow="row nowrap">
            <ImageSettingsHeader>
              Alignement :
            </ImageSettingsHeader>
            <Ui.Layout.RadioGroupContainer>
              {[
                { label: 'Centre', value: 2 },
                { label: 'Haut', value: 1 },
                { label: 'Bas', value: 3 },
              ]
                .map((position, idx) =>
                  <Choice key={idx}>
                    <Ui.Form.Radio
                      name="image_layout"
                      defaultChecked={alignment === position.value}
                      value={idx + 1}
                      label={position.label}
                      colorized
                      onClick={e => setAlignment(e, position.value)}
                      disabled={!canEdit}
                    />
                    {position.label}
                  </Choice>
                )}
            </Ui.Layout.RadioGroupContainer>
          </Ui.Layout.FlexBox>
        }
      </ImageSettings>
    </>
  }
</ImageToolContainer>
}

const ImageToolContainer = styled.div`
  margin-left: 1.5rem;
`

const ImageSettings = styled(props => <Ui.Layout.FlexBox {...props} />)`
  margin-top: .8rem;
  ${Ui.Layout.FlexBox} {
    align-items: center;
  }
  ${Ui.BasicElement.P} {
    white-space: nowrap !important;
  }
  ${Ui.Layout.RadioGroupContainer} {
    margin-top: 0;
  }
  ${Ui.Layout.BlockContainer}:first-child {
    padding-right: 1rem;
    margin-bottom: 0;
  }
`

const ImageSettingsHeader = styled.div`
  font-weight: 700;
  white-space: nowrap;
  font-size: .875rem;
  padding-right: .6rem;
`

export default ImageToolComponent;
