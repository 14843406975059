import * as React from "react";

export const DocMp4 = ({
  ...props
}) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
        }}
        {...props}
    >
        <path
        d="M128,0C110.4,0 96,14.4 96,32L96,480C96,497.6 110.4,512 128,512L448,512C465.6,512 480,497.6 480,480L480,128L352,0L128,0Z"
        style={{
            fill: "rgb(226,229,231)",
            fillRule: "nonzero",
        }}
        />
        <path
        d="M384,128L480,128L352,0L352,96C352,113.6 366.4,128 384,128Z"
        style={{
            fill: "rgb(176,183,189)",
            fillRule: "nonzero",
        }}
        />
        <path
        d="M480,224L384,128L480,128L480,224Z"
        style={{
            fill: "rgb(202,209,216)",
            fillRule: "nonzero",
        }}
        />
        <path
        d="M416,416C416,424.8 408.8,432 400,432L48,432C39.2,432 32,424.8 32,416L32,256C32,247.2 39.2,240 48,240L400,240C408.8,240 416,247.2 416,256L416,416Z"
        style={{
            fill: "rgb(247,184,78)",
            fillRule: "nonzero",
        }}
        />
        <g transform="matrix(10.3789,0,0,10.3789,-2540.86,-2306.54)">
        <text
            x="271.264px"
            y="259.276px"
            style={{
            fontFamily:
                "'ArialRoundedMTBold', 'Arial Rounded MT Bold', sans-serif",
            fontSize: 12,
            fill: "white",
            }}
        >
            {"4"}
        </text>
        </g>
        <g>
        <path
            d="M103.408,327.84L103.408,375.168C103.408,380.816 98.816,384 94.192,384C90.096,384 86.528,380.816 86.528,375.168L86.528,303.152C86.528,296.496 92.16,294.304 94.192,294.304C97.904,294.304 100.08,296.496 102.256,298.928L130.416,336.912L159.568,297.504C163.808,292.272 174.16,294.304 174.16,303.152L174.16,375.168C174.16,380.816 170.56,384 166.464,384C161.872,384 158.272,380.816 158.272,375.168L158.272,327.84L137.04,354.704C132.448,360.352 126.688,360.352 122.464,354.704L103.408,327.84Z"
            style={{
            fill: "white",
            fillRule: "nonzero",
            }}
        />
        <path
            d="M196.496,303.152C196.496,298.928 199.824,294.304 205.2,294.304L234.752,294.304C251.392,294.304 266.368,305.44 266.368,326.8C266.368,347.024 251.392,358.272 234.752,358.272L213.392,358.272L213.392,375.168C213.392,380.816 209.808,384 205.2,384C200.976,384 196.496,380.816 196.496,375.168L196.496,303.152ZM213.392,310.432L213.392,342.288L234.752,342.288C243.328,342.288 250.112,334.736 250.112,326.8C250.112,317.84 243.328,310.432 234.752,310.432L213.392,310.432Z"
            style={{
            fill: "white",
            fillRule: "nonzero",
            }}
        />
        </g>
        <path
        d="M400,432L96,432L96,448L400,448C408.8,448 416,440.8 416,432L416,416C416,424.8 408.8,432 400,432Z"
        style={{
            fill: "rgb(202,209,216)",
            fillRule: "nonzero",
        }}
        />
    </svg>
);
