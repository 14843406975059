import * as React from "react";

export const DocMpg = ({
  ...props
}) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{
        enableBackground: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <path
        style={{
            fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
        />
        <path
        style={{
            fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
        />
        <polygon
        style={{
            fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
        />
        <path
        style={{
            fill: "#F7B84E",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
        />
        <g>
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M103.408,327.84v47.328c0,5.648-4.592,8.832-9.216,8.832c-4.096,0-7.664-3.184-7.664-8.832v-72.016 c0-6.656,5.632-8.848,7.664-8.848c3.712,0,5.888,2.192,8.064,4.624l28.16,37.984l29.152-39.408c4.24-5.232,14.592-3.2,14.592,5.648 v72.016c0,5.648-3.6,8.832-7.696,8.832c-4.592,0-8.192-3.184-8.192-8.832V327.84l-21.232,26.864 c-4.592,5.648-10.352,5.648-14.576,0L103.408,327.84z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M196.496,303.152c0-4.224,3.328-8.848,8.704-8.848h29.552c16.64,0,31.616,11.136,31.616,32.496 c0,20.224-14.976,31.472-31.616,31.472h-21.36v16.896c0,5.648-3.584,8.832-8.192,8.832c-4.224,0-8.704-3.184-8.704-8.832V303.152z  M213.392,310.432v31.856h21.36c8.576,0,15.36-7.552,15.36-15.488c0-8.96-6.784-16.368-15.36-16.368L213.392,310.432 L213.392,310.432z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M357.728,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808 c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.792,7.664-3.456,19.056-11.12,12.288 c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448 c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248 C360.928,369.552,359.904,371.712,357.728,374.16z"
        />
        </g>
        <path
        style={{
            fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
        />
    </svg>
);
