export const en = {
  'rcp': 'staff',
  'rcpSession': 'staffSession',
  'expertise': 'opinion',
  'cohorte': 'cohort',
}

export const fr = {
  'period': {
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
    year: 'Année',
    days: 'Jours',
    weeks: 'Semaines',
    months: 'Mois',
    years: 'Années',
  },
  'recurrence': {
    none: 'aucune',
    daily: 'quotidienne',
    weekly: 'hebdomadaire',
    monthly: 'mensuelle',
    quarterly: 'trimestrielle',
    annual: 'annuelle'
  },
  'colors': {
    gray: 'gris',
    black: 'noir',
    green: 'vert',
    blue: 'bleu',
    purple: 'violet',
    orange: 'orange',
    red: 'rouge',
    yellow: 'jaune',
  },
  'staff': {
    a: 'un',
    new: 'nouveau',
    myExpertise: 'ma RCP',
    newExpertise: 'Nouvelle RCP',
    expertise: 'RCP',
    expertises: 'RCPs',
    ofExpertises: 'de RCP',
    allExpertises: 'Toutes les RCPs',
    theExpertise: 'la RCP',
    thisExpertise: 'cette RCP',
    createdExpertises: 'RCPs créées',
    joinedExpertises: 'RCPs rejointes',
    archivedExpertises: 'RCPs archivées',
  },
  'cohort': {
    a: 'une',
    new: 'nouvelle',
    myExpertise: 'ma cohorte',
    newExpertise: 'Nouvelle cohorte',
    expertise: 'cohorte',
    expertises: 'cohortes',
    ofExpertises: 'de cohorte',
    allExpertises: 'Toutes les cohortes',
    theExpertise: 'la cohorte',
    thisExpertise: 'cette cohorte',
    createdExpertises: 'Cohortes créées',
    joinedExpertises: 'Cohortes rejointes',
    archivedExpertises: 'Cohortes archivées',
  },
  'opinion': {
    a: 'une',
    new: 'nouvelle',
    myExpertise: 'mon expertise',
    newExpertise: 'Ajouter une nouvelle expertise',
    expertise: 'expertise',
    expertises: 'expertises',
    ofExpertises: 'd\'expertise',
    allExpertises: 'Toutes les expertises',
    theExpertise: 'l\'expertise',
    thisExpertise: 'cette expertise',
    createdExpertises: 'Expertises créées',
    joinedExpertises: 'Expertises rejointes',
    archivedExpertises: 'Expertises archivées',
  },
  male: 'Homme',
  female: 'Femme',
  undetermined: 'Indéterminé',
}
