import * as Profile from '../State/Profile'
import * as Organization from '../State/Organization'
import * as Login from '../State/Login'
import * as Resources from '../State/Resources'
import * as UploadFile from '../State/UploadFile'
import * as Form from '../Util/Form'
import * as Api from '../Util/Api'
import { takeLatest, takeEvery, call, put, select, delay } from 'redux-saga/effects'
import { uniqById } from '../Util/Object'
import { debounceTime } from '../Util/Date'

export function* updateAndSaveField({ payload: { name, value, scope } }) {
  yield put(Profile.updateField({
    name,
    value,
  }))

  yield put(Profile.saveField({ fieldName: name, scope }))
}

export function* saveField({ payload: { fieldName, scope } }) {
  try {
    const data = yield select(Profile.selectData)
    const fieldData = data[fieldName]
    const validatedData = yield call(Form.validate, { [fieldName]: fieldData })
    const apiFields = {
      portalName: 'name',
      portalHeaderImage: 'portalProHeaderImageName',
      portalAbout: 'portalProDescription',
      portalProPictureAlign: 'pictureAlign',
      portalProPictureStretch: 'pictureStretch',
    }

    const currentUser = yield select(Login.selectUser)
    if (currentUser.hasOwnProperty(fieldName)) {
      yield put(Login.updateCurrentUserField({ name: fieldName, value: fieldData.value }))
    }

    if (!validatedData[fieldName].errors.length > 0) {
      const apiData = {
        [scope]:
          fieldName === 'rpps'
            ? { rppsUser: { rpps: validatedData[fieldName].value } }
            : { [apiFields[fieldName] || fieldName]: validatedData[fieldName].value }
      }

      yield call(scope === Profile.SCOPE.portalPro
        ? Api.savePortalPro
        : Api.saveProfile
        , apiData)

      yield put(Profile.setEditField({ field: fieldName, value: false }))
      yield put(Profile.updateInitialValue({ field: fieldName, value: fieldData.value }))
    }
    yield put(Profile.success())
  } catch (error) {
    console.log(error)
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Profile.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Profile.invalidate(
        `Une information obligatoire est manquante pour valider la mise à jour de vos données.`
      ))
    } else {
      yield put(Profile.apiError(error.message))
    }
  }
}

export function* fetchMedicineWordDisciplines() {
  try {
    const medicineWordDisciplines = yield call(Api.fetchMedicineWordDisciplines)

    yield put(Profile.receivedMedicineWordDisciplines(medicineWordDisciplines))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* fetchAllRppsRegistration() {
  try {
    const allRppsRegistration = yield call(Api.fetchAllRppsRegistration)

    yield put(Resources.received({
      name: [Resources.RESOURCE_REGISTERED_RPPS],
      data: allRppsRegistration,
    }))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* getUserData() {
  try {
    const {
      title,
      firstname,
      lastname,
      city,
      rppsUser: {
        rpps
      } = '',
      email,
      cellPhone,
      expert,
      profilePicture,
      speciality,
      status,
      uploadPractitionerProof
    } = yield select(Login.selectUser)

    yield put(Profile.received({
      title,
      firstname,
      lastname,
      city,
      rpps: rpps ? rpps : '',
      rppsInitial: rpps ? rpps : '',
      email,
      cellPhone,
      profilePicture,
      expert,
      speciality,
      status,
      uploadPractitionerProof
    }))

    yield put(Profile.success())

  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* fetchPortalPro({ payload: id }) {
  try {
    const {
      url,
      name,
      description,
      picture: portalProPicture = '',
      pictureAlign: portalProPictureAlign,
      pictureStretch: portalProPictureStretch,
      logo: portalProLogo = '',
      staffs = [],
      opinions = [],
      cohorts = [],
    } = yield call(Api.fetchPortalPro, id)

    yield put(Profile.received({
      url,
      description,
      name,
      portalProPicture,
      portalProPictureAlign,
      portalProPictureStretch,
      portalProLogo,
      expertises: [
        ...staffs.map(e => ({ type: 'staffs', id: e.id })),
        ...opinions.map(e => ({ type: 'opinions', id: e.id })),
        ...cohorts.map(e => ({ type: 'cohorts', id: e.id }))
      ],
    }))

    yield put(Profile.receivedAvailableExpertises({
      teleExpertises: [
        ...staffs.map(e => ({ ...e, type: 'staffs' })),
        ...opinions.map(e => ({ ...e, type: 'opinions' })),
        ...cohorts.map(e => ({ ...e, type: 'cohorts' })),
      ],
    }))

    yield put(Profile.success())
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* fetchExpertises(type, query = '', onlyLocked = 0) {
  try {
    yield put(Profile.fetchAvailableExpertises(type))
    const types = type === 'subscriptionExpertises' || type === 'expertises'
      ? 'staffs,opinions,cohorts'
      : type

    const { teleExpertises, keywordsNotFound } = yield call(Api.getTeleExpertisesAllNoCf, types, query, onlyLocked)

    yield put(Profile.receivedAvailableExpertises({ teleExpertises, keywordsNotFound }))
    yield put(Profile.success())

  } catch (error) {

    yield put(Profile.apiError(error.message))
  }
}

export function* fetchExpertise({ payload: { type, id } }) {
  try {
    const expertise = yield call(Api.getTeleExpertise, type, id)

    const availableExpertises = yield select(Profile.selectAvailableExpertises)
    const keywordsNotFound = yield select(Profile.selectKeywordsNotFound)
    const exists = 0 !== availableExpertises
      .filter(e => e.type === expertise.type && Number(e.id) === Number(expertise.id))
      .length

    if (!exists) {
      yield put(Profile.receivedAvailableExpertises({
        teleExpertises: [
          ...availableExpertises,
          expertise,
        ],
        keywordsNotFound,
      }))
    }
    yield updateLocked({ payload: { value: { type, id }, toggled: true } })
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* fetchUserExpertises({ payload: { unlockStaffs, unlockCohorts, unlockOpinions } }) {
  try {
    yield put(Profile.fetchSubscriptionExpertises())
    const { teleExpertises, keywordsNotFound } = yield call(Api.getTeleExpertisesAllNoCf, 'staffs,opinions,cohorts', '', 0, '')
    yield put(Profile.receivedAvailableExpertises({ teleExpertises: [...teleExpertises, ...unlockStaffs, ...unlockCohorts, ...unlockOpinions], keywordsNotFound }))
    yield put(Profile.receivedSubscriptionExpertises([...unlockStaffs, ...unlockCohorts, ...unlockOpinions]))
    yield put(Profile.receivedUserExpertises(teleExpertises))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* fetchSubscriptionExpertises() {
  try {
    const { teleExpertises, keywordsNotFound } = yield call(Api.getTeleExpertisesAllNoCf, 'staffs,opinions,cohorts', '')
    yield put(Profile.receivedAvailableExpertises({ teleExpertises, keywordsNotFound }))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* fetchOrganizations({ payload }) {
  try {
    const filters = {
      search: '',
      onlyManagedOrMember: 1
    }
    const organizations = yield call(Api.fetchOrganizations, filters)

    yield put(Profile.received({ organizations: organizations.map(organization => organization.id) }))

    yield put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: organizations,
    }))

    if (organizations.length > 0) {
      const foundOrganization = organizations.find(organization => Number(organization.id) === Number(payload))
      const organizationId = foundOrganization
        ? foundOrganization.id
        : organizations[0].id

      yield put(Profile.fetchOrganization(organizationId))
    }
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* fetchOrganization({ payload }) {
  try {
    const organization = yield call(Api.fetchOrganization, payload)
    yield put(Resources.received({
      name: [Resources.RESOURCE_ORGANIZATIONS],
      data: [organization],
    }))

    const {
      id: organizationId,
      name,
      logo,
      members = [],
      managers = [],
      staffs = [],
      opinions = [],
      cohorts = [],
      _canBe,
      _canBeModified,
    } = organization

    yield put(Resources.received({
      name: [Resources.RESOURCE_MEMBERS],
      data: [...members, ...managers].filter(uniqById),
    }))

    yield put(Organization.received({
      organizationId,
      name,
      logo,
      members: members.map(e => e.id),
      managers: managers.map(e => e.id),
      expertises: [
        ...staffs.map(e => ({ type: 'staffs', id: e.id })),
        ...opinions.map(e => ({ type: 'opinions', id: e.id })),
        ...cohorts.map(e => ({ type: 'cohorts', id: e.id })),
      ],
      permissions: { _canBe, _canBeModified }
    }))
    yield put(Organization.receivedAvailableExpertises({
      teleExpertises: [
        ...staffs.map(e => ({ ...e, type: 'staffs' })),
        ...opinions.map(e => ({ ...e, type: 'opinions' })),
        ...cohorts.map(e => ({ ...e, type: 'cohorts' })),
      ]
    }))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* sendMessage({ payload }) {
  try {
    yield call(Api.urlUpdateRequest, { message: payload })
    yield put(Profile.setUrlMessageBox(false))
    yield put(Profile.setUpdateUrlMessageSent(true))
    yield put(Profile.setEditMode({ target: 'message', value: false }))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* sendEstimateRequest({ payload: { offerName, message } }) {
  try {
    yield call(Api.sendEstimateRequest, offerName, message)
    yield put(Profile.setEstimateRequestMessageBox(false))
    yield put(Profile.setEstimateRequestMessageSent(true))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* savePortalPro() {
  try {
    const currentUser = yield select(Login.selectUser)
    const {
      portalProTitle,
      description,
    } = yield select(Profile.selectData)
    const fileData = yield select(UploadFile.selectData)
    const portalProPicture = fileData['portalProPicture']
    const portalProPictureFileName = Object
      .values(portalProPicture.value)
      .map(doc => (doc.fileName))
      .shift()
    const portalProLogo = fileData['portalProLogo']
    const portalProLogoFileName = Object
      .values(portalProLogo.value)
      .map(doc => (doc.fileName))
      .shift()

    const validatedData = yield call(Form.validate, {
      portalProTitle,
      description,
    })

    yield call(Api.savePortalPro, validatedData.portalProTitle.value, validatedData.description.value)

    if (portalProPictureFileName) {
      yield put(Profile.updateImage({ type: 'portalProPicture', fileName: portalProPictureFileName }))
    }
    if (portalProLogoFileName) {
      yield put(Profile.updateImage({ type: 'portalProLogo', fileName: portalProLogoFileName }))
    }
    yield put(Profile.setEditMode({ target: 'portalPro', value: false }))
    yield put(Login.receivedUser({
      ...currentUser,
      portalPro:
      {
        ...currentUser.portal,
        title: portalProTitle,
        description
      }
    }))

    yield put(Profile.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Profile.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Profile.invalidate(
        `Une information obligatoire est manquante pour valider la mise à jour de vos données.`
      ))
    } else {
      yield put(Profile.apiError(error.message))
    }
  }
}

export function* fetchDisciplines() {
  try {
    yield put(Resources.load({
      filter: [Resources.RESOURCE_DISCIPLINES],
    }))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* validateDiscipline() {
  try {
    const { disciplines } = yield select(Resources.selectResources)
    const { speciality } = yield select(Profile.selectData)
    if (speciality.value && !disciplines.includes(speciality.value)) {
      throw new Form.SpecialityValidationError({
        speciality: {
          value: speciality.value,
          errors: ['Aucune correspondance trouvée pour cette discipline'],
        }
      })
    }
    yield put(Profile.saveField({ fieldName: 'speciality', scope: Profile.SCOPE.profile }))
    yield put(Profile.success())
  } catch (error) {
    if (error instanceof Form.ValidationError || error instanceof Form.SpecialityValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Profile.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
    }
    if (error instanceof Form.ValidationError) {
      yield put(Profile.invalidate(
        `Une information obligatoire est manquante pour valider la mise à jour de vos données.`
      ))
    } else if (error instanceof Form.SpecialityValidationError) {
      yield put(Profile.invalidate())
    }
  }
}

export function* validateRpps() {
  try {
    const { rpps: rppsList } = yield select(Resources.selectResources)
    const { rpps, rppsInitial } = yield select(Profile.selectData)
    if (rpps.value && rpps.value !== rppsInitial.value && rppsList.includes(rpps.value)) {
      throw new Form.SpecialityValidationError({
        rpps: {
          value: rpps.value,
          errors: ['Ce numéro RPPS est déjà enregistré'],
        }
      })
    }
    if (rpps.isFieldEdit)
      yield put(Profile.saveField({ fieldName: 'rpps', scope: Profile.SCOPE.profile }))
    yield put(Profile.success())
  } catch (error) {
    // eslint-disable-next-line no-unused-vars
    for (let [_, field] of Object.entries(error.data)) {
      yield put(Profile.updateField({
        name: 'rpps',
        value: field.value,
        errors: field.errors,
      }))
    }
  }
}

export function* saveProfile() {
  try {
    yield validateDiscipline()
    yield validateRpps()
    const currentUser = yield select(Login.selectUser)
    const { disciplines } = yield select(Resources.selectResources)
    const {
      title,
      firstname,
      lastname,
      email,
      city,
      rpps,
      cellPhone,
      speciality
    } = yield select(Profile.selectData)

    const fileData = yield select(UploadFile.selectData)

    const profilePicture = fileData['profilePicture']
    const profilePictureFileName = profilePicture?.value
      ? Object
        .values(profilePicture.value)
        .map(doc => (doc.fileName))
        .shift()
      : null

    const validatedData = yield call(Form.validate, {
      title,
      firstname,
      lastname,
      email,
      city,
      rpps,
      cellPhone,
      speciality
    })

    if (speciality.value && !disciplines.includes(speciality.value)) {
      throw new Form.ValidationError({
        speciality: {
          value: speciality.value,
          errors: ['Aucune correspondance trouvée pour cette spécialité'],
        }
      })
    }

    const apiData = {
      my_account: {
        title: validatedData.title.value,
        city: validatedData.city.value,
        email: validatedData.email.value,
        firstname: validatedData.firstname.value,
        lastname: validatedData.lastname.value,
        speciality: validatedData.speciality.value,
        cellPhone: validatedData.cellPhone.value,
        ...(validatedData.rpps.value !== '' && {
          rppsUser: { rpps: validatedData.rpps.value }
        })
      },
    }

    yield call(Api.saveProfile, apiData)

    if (profilePictureFileName) {
      yield put(Profile.updateImage({ type: 'profilePicture', fileName: profilePictureFileName }))
    }
    yield put(Profile.setEditMode({ target: 'profile', value: false }))
    yield put(Login.receivedUser({ ...currentUser, ...apiData.my_account }))

    yield put(Profile.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Profile.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
      yield put(Profile.invalidate(
        `Une information obligatoire est manquante pour valider la mise à jour de vos données.`
      ))
    } else {
      yield put(Profile.apiError(error.message))
    }
  }
}

export function* savePassword() {
  try {
    const { oldPassword, newPassword, newPasswordConfirmation } = yield select(Profile.selectData)
    const validatedData = yield call(Form.validate, { oldPassword, newPassword, newPasswordConfirmation })
    yield call(Form.validatedPasswordConfirmation, { oldPassword, newPassword, newPasswordConfirmation }, 'newPassword', 'newPasswordConfirmation')
    const apiData = {
      my_account_password: {
        plainPassword: validatedData.newPassword.value,
        previousPassword: validatedData.oldPassword.value,
      },
    }

    yield call(Api.savePassword, apiData)
    yield put(Profile.setEditMode({ target: 'password', value: false }))
    yield put(Profile.success())
  } catch (error) {
    if (error instanceof Form.ValidationError) {
      for (let [name, field] of Object.entries(error.data)) {
        yield put(Profile.updateField({
          name,
          value: field.value,
          errors: field.errors,
        }))
      }
    } else {
      yield put(Profile.apiError(error.message))
    }
  }
}

export function* addExpertise({ payload: { id: teleExpertiseId, type } }) {
  try {
    yield call(Api.addPortalProExpertise, teleExpertiseId, type)
    yield put(Profile.setEditMode({ target: 'expertises', value: false }))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* removeExpertise({ payload: { id: teleExpertiseId, type } }) {
  try {
    yield call(Api.removePortalProExpertise, teleExpertiseId, type)
    yield put(Profile.setEditMode({ target: 'expertises', value: false }))
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* removeOrganization({ payload: organizationId }) {
  try {
    yield call(Api.removeOrganization, organizationId)
    yield put(Organization.clean())

  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* updateSubscribed({ payload: { teleExpertiseId, teleExpertiseType, toggled } }) {
  try {
    yield call(Api.updateSubscribed, teleExpertiseId, teleExpertiseType)
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* updateLocked({ payload: { value, toggled = true, slice = null } }) {
  try {
    const { type, id } = value
    const {
      unlockTeleExpertise,
      unlockTeleExpertiseRemaining
    } = yield call(Api.updateLocked, type, id, toggled)

    yield put(Login.updateActiveSubscription({
      unlockTeleExpertise,
      unlockTeleExpertiseRemaining
    }))

    yield put(Profile.setSuccessUnlockTeleExpertise({
      successUnlockTeleExpertise: true
    }))
  } catch (error) {
    if (slice) {
      yield put(slice.apiError(error.message))
    } else {
      yield put(Profile.apiError(error.message))
    }
  }
}

export function* selectExpertise({ payload: { value, toggled = true } }) {
  try {
    // TODO : not used ???
    // const { type, id } = value
    // const availableExpertises = yield select(Profile.selectAvailableExpertises)
    // const expertise = availableExpertises
    //   .find(expertise => type === expertise.type && String(id) === String(expertise.id))

  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* setQuery({ payload: { name, value: query, onlyLocked = 0 } }) {
  try {
    const delayMs = debounceTime(query)
    yield delay(delayMs)
    yield fetchExpertises(name, query, onlyLocked)

  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* receivedPaymentCardData({ payload: { quantityTeleExpertiseSubscrib, paymentCardToken, paymentCardData } }) {
  try {
    yield call(Api.payTeleExpertise, quantityTeleExpertiseSubscrib, paymentCardToken, paymentCardData)
    yield put(Profile.successSubscription({ teleExpertiseDowngradeNames: null }))

    try {
      const currentUser = yield call(Api.fetchCurrentUser)
      yield put(Login.receivedUser(currentUser))
    } catch (error) {
      console.log(error)
      yield put(Login.failure())
    }
  } catch (error) {
    yield put(Profile.apiError(error.message))
    yield put(Profile.errorPayment())
  }
}

export function* saveSubscription({ payload: { quantityTeleExpertiseSubscrib } }) {
  try {
    const { teleExpertiseDowngradeNames } = yield call(Api.updateSubscription, quantityTeleExpertiseSubscrib)
    yield put(Profile.successSubscription({ teleExpertiseDowngradeNames: teleExpertiseDowngradeNames }))

    try {
      const currentUser = yield call(Api.fetchCurrentUser)
      yield put(Login.receivedUser(currentUser))
    } catch (error) {
      yield put(Login.failure())
    }
  } catch (error) {
    yield put(Profile.apiError(error.message))
    yield put(Profile.errorPayment())
  }
}

export function* removeProfilePicture() {
  try {
    yield call(Api.removeProfilePicture)
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* removePortalProPicture() {
  try {
    yield call(Api.removePortalProPicture)
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

export function* removePortalProLogo() {
  try {
    yield call(Api.removePortalProLogo)
  } catch (error) {
    yield put(Profile.apiError(error.message))
  }
}

const profileSagas = function* () {
  yield takeLatest(`${Profile.fetchPortalPro}`, fetchPortalPro);
  yield takeLatest(`${Profile.getUserData}`, getUserData);
  yield takeLatest(`${Profile.selectExpertise}`, selectExpertise)
  yield takeLatest(`${Profile.toggle}`, updateLocked)
  yield takeLatest(`${Profile.fetchUserExpertises}`, fetchUserExpertises)
  yield takeLatest(`${Profile.fetchOrganizations}`, fetchOrganizations)
  yield takeLatest(`${Profile.fetchDisciplines}`, fetchDisciplines)
  yield takeLatest(`${Profile.addExpertise}`, addExpertise)
  yield takeLatest(`${Profile.removeExpertise}`, removeExpertise)
  yield takeLatest(`${Profile.sendMessage}`, sendMessage)
  yield takeLatest(`${Profile.sendEstimateRequest}`, sendEstimateRequest)
  yield takeLatest(`${Profile.saveProfile}`, saveProfile)
  yield takeLatest(`${Profile.savePassword}`, savePassword)
  yield takeLatest(`${Profile.savePortalPro}`, savePortalPro)
  yield takeLatest(`${Profile.setQuery}`, setQuery)
  yield takeLatest(`${Profile.updateSubscribed}`, updateSubscribed)
  yield takeLatest(`${Profile.fetchOrganization}`, fetchOrganization)
  yield takeLatest(`${Profile.fetchExpertise}`, fetchExpertise)
  yield takeLatest(`${Profile.receivedPaymentCardData}`, receivedPaymentCardData)
  yield takeLatest(`${Profile.saveSubscription}`, saveSubscription)
  yield takeLatest(`${Profile.removeOrganization}`, removeOrganization)
  yield takeLatest(`${Profile.validateDiscipline}`, validateDiscipline)
  yield takeLatest(`${Profile.validateRpps}`, validateRpps)
  yield takeLatest(`${Profile.fetchMedicineWordDisciplines}`, fetchMedicineWordDisciplines)
  yield takeLatest(`${Profile.fetchAllRppsRegistration}`, fetchAllRppsRegistration)
  yield takeLatest(`${Profile.removeProfilePicture}`, removeProfilePicture)
  yield takeLatest(`${Profile.removePortalProPicture}`, removePortalProPicture)
  yield takeLatest(`${Profile.removePortalProLogo}`, removePortalProLogo)
  yield takeEvery(`${Profile.saveField}`, saveField)
  yield takeEvery(`${Profile.updateAndSaveField}`, updateAndSaveField)
};

export default profileSagas;
