import React, { useEffect, useState } from 'react';
import { privateSitePrefix } from '../Util/Config'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from "react-router-dom";
import { useAlert } from 'react-alert'
import * as Message from '../State/Message'
import * as Resources from '../State/Resources'
import * as UploadFile from '../State/UploadFile'
import * as Ui from './Ui'
import { useHistory } from "react-router-dom";
import { uniqById } from '../Util/Object'
import styled from 'styled-components'
import LoadingBox from './Ui/LoadingBox'

const CreateMessageComponent = (props) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const currentUser = useSelector(state => state.login.currentUser)
  const success = useSelector(Message.selectSuccess)
  const errorMessage = useSelector(state => state.message.errorMessage)
  const resourceLoading = useSelector(Resources.selectLoading)
  const history = useHistory();

  const {
    search,
    data: {
      content,
      subject,
      patients,
      recipients,
    },
  } = useSelector(state => state.message)
  const { match: { params } } = props

  const {
    [Resources.RESOURCE_PATIENTS]: patientsResource,
    [Resources.RESOURCE_MEMBERS]: membersResource,
  } = useSelector(Resources.selectResources)

  useEffect(() => {
    if (!params.id) {
      return
    }
    dispatch(Message.setRecipients({ id: params.id }))
  }, [dispatch, params.id])

  useEffect(() => {

    return () => {
      dispatch(Message.clean())
      dispatch(UploadFile.cleanAll())
    }
  }, [dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const updateField = name => e => {
    dispatch(Message.updateField({ name, value: e.target.value }))
  }

  useEffect(() => {

    return () => dispatch(Message.clean())
  }, [dispatch])

  const send = (e) => {
    e.preventDefault()
    dispatch(Message.send({ messageId: params.id }))
  }

  const [attachmentBoxOpen, setAttachmentBoxOpen] = useState(false)

  if (success) {
    alert.success("Votre message a bien été envoyé.", { timeout: 5000 })
    return params.id
      ? <Redirect to={`${privateSitePrefix}/medecins/${params.id}`} />
      : <Redirect to={`${privateSitePrefix}/messages`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <CreateMessageContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.PanelContainer>
              <Ui.Layout.Panel>
                <Ui.Button.BackButton onClick={history.goBack} />
                <Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.Layout.TelexpertiseLogo>
                    <Ui.Icon.IconChat />
                  </Ui.Layout.TelexpertiseLogo>
                  <Ui.BasicElement.H3 noMargin>Nouveau message</Ui.BasicElement.H3>
                </Ui.Layout.TelexpertiseThumbnailContainer>
              </Ui.Layout.Panel>
            </Ui.Layout.PanelContainer>
            <Ui.Layout.Content hasPanel>
              <Ui.Layout.Form onSubmit={send} noValidate>
                <Ui.BasicElement.H2>
                  Nouveau message
                </Ui.BasicElement.H2>
                <Ui.Message.Container>
                    <Ui.Form.Label htmlFor="recipients">
                      Envoyer à :
                      <Ui.SearchBar.SearchAndCheck
                        isPurple
                        name="recipients"
                        query={search.recipients.value}
                        TeleExpertise={Message}
                        resultSet={membersResource.filter(uniqById)}
                        list={recipients.value}
                        loading={resourceLoading.managers}
                        placeholder="Recherchez un médecin sur Skemeet..."
                        onToggle={(id, toggled, name = 'recipients') =>
                          dispatch(Message.toggle({ id, toggled, name }))}
                        onQueryChange={(e) => dispatch(
                          Message.setQuery({
                            name: 'recipients',
                            value: e.target.value
                          }))}
                      />
                    </Ui.Form.Label>
                    {recipients.errors[0] &&
                      <Ui.Form.FieldErrorMessage>{recipients.errors[0]}</Ui.Form.FieldErrorMessage>
                    }
                  <Ui.Layout.Row>
                    <Ui.Form.TextInput
                      htmlFor="messageObject"
                      label="Objet du message *"
                      value={subject.value}
                      onChange={updateField('subject')}
                      error={subject.errors[0]}
                      noSubmit
                    />
                  </Ui.Layout.Row>
                  <Ui.Layout.Row>
                    <Ui.Form.TextAreaInputResizable
                      htmlFor="messageBody"
                      label="Votre message *"
                      value={content.value}
                      onChange={updateField('content')}
                      error={content.errors[0]}
                      noSubmit
                    />
                  </Ui.Layout.Row>
                  <Ui.Layout.BlockContainer>
                    <Ui.Form.Label htmlFor="patients">
                      Lier un ou plusieurs patients :
                      <Ui.SearchBar.SearchAndCheck
                        isPurple
                        name="patients"
                        query={search.patients.value}
                        TeleExpertise={Message}
                        resultSet={patientsResource}
                        list={patients.value}
                        loading={resourceLoading.patients}
                        onToggle={(id, toggled, name = 'patients') =>
                          dispatch(Message.toggle({ id, toggled, name }))}
                      />
                    </Ui.Form.Label>
                    <Ui.BasicElement.CollapsibleLink onClick={(e) => setAttachmentBoxOpen(!attachmentBoxOpen)}>
                      <span>Ajouter des pièces jointes </span>
                      <span>
                        {attachmentBoxOpen
                          ? <Ui.Icon.IconUp />
                          : <Ui.Icon.IconDown />
                        }
                      </span>
                    </Ui.BasicElement.CollapsibleLink>
                    <Ui.Layout.AttachmentsBox open={attachmentBoxOpen}>
                      <Ui.UploadFile.DocumentsUpload
                        name="message-upload"
                        id="message-upload"
                        validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
                        label="Documents"
                        endpoint="/messages/new/assets"
                        apiPath={`/messages/assets/`}
                      >
                        Poids max : 30 Mo. <br />
                        Formats acceptés : Txt, Jpeg, Png, Gif, Tiff, Zip, Pdf, Word, Excel, PowerPoint
                      </Ui.UploadFile.DocumentsUpload>
                    </Ui.Layout.AttachmentsBox>
                  </Ui.Layout.BlockContainer>
                  <Ui.Button.BasicButton
                    isFilled
                    isGreen
                    type="submit"
                  >
                    Envoyer le message
                  </Ui.Button.BasicButton>
                </Ui.Message.Container>
              </Ui.Layout.Form>
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
        </CreateMessageContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const CreateMessageContainer = styled.div`
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Search.FiltersAndSorting} {
    justify-content: flex-end;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    padding-bottom: 2rem;
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Message.Body} {
      ${Ui.BasicElement.P} {
        margin: 0;
      }
    }
  }

  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  ${Ui.Form.InputContainer} {
    margin-top: 1rem;
  }
  a {
    flex: 0;
    text-decoration: none;
  }
`

export default CreateMessageComponent;
