import * as Icon from '../icons';
import { apiUrl } from '../../Util/Config'

export const StaffPicture = ({
    className,
    pictureFileName = null
}) => {
    return pictureFileName
        ? <img
            className={`${className} rounded-full h-[2.75rem] w-[2.75rem]`}
            src={`${apiUrl}/staffs/picture/${pictureFileName}`}
            alt=""
        />
        : <Icon.Staff className={className} />
}
