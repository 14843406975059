import React, { useState, useEffect } from 'react'
import { privateSitePrefix } from '../Util/Config'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";
import { useAlert } from 'react-alert'
import * as Menu from '../State/Menu'
import * as Patient from '../State/Patient';
import * as Login from '../State/Login';
import * as CustomField from '../State/CustomField';
import * as UploadFile from '../State/UploadFile';
import * as Ui from './Ui'
import styled from 'styled-components'
import { uniqByIdAndType } from '../Util/Object'
import pluralize from '../Util/Pluralize'
import qs from 'qs'
import StyledDatePicker from './Ui/DatePicker';
import LoadingBox from './Ui/LoadingBox'

const CreatePatientComponent = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const alert = useAlert()
  const currentUser = useSelector(Login.selectUser)
  const data = useSelector(Patient.selectData)
  const search = useSelector(Patient.selectSearch)
  const creationSuccess = useSelector(Patient.selectCreationSuccess)
  const pending = useSelector(Patient.selectPending)
  const expertises = useSelector(Patient.selectAvailableExpertises)
  const patientId = useSelector(Patient.selectPatientId)
  const keywordsNotFound = useSelector(Patient.selectKeywordsNotFound)
  const errorMessage = useSelector(Patient.selectErrorMessage)
  const patientExists = useSelector(Patient.selectPatientExists)
  const existingOwnedPatient = useSelector(Patient.selectExistingOwnedPatient)
  const coOwnershipRequestSent = useSelector(Patient.selectCoOwnershipRequestsent)
  const requestedDoctor = useSelector(Patient.selectRequesteDoctor)
  const availableExpertises = useSelector(Patient.selectAvailableExpertises)
  const selectExpertise = props.location.search
  const uploading = useSelector(UploadFile.selectUploading)
  const queryParams = qs.parse(selectExpertise, { ignoreQueryPrefix: true })
  const expertiseTypes = {'rcps':'staffs', 'expertises':'opinions', 'cohorts':'cohorts'}
  const fromTeleExpertise = expertiseTypes[Object.keys(queryParams)[0]] ? {id: queryParams[Object.keys(queryParams)[0]], type: expertiseTypes[Object.keys(queryParams)[0]]} : null
  const updateField = name => e => {
    dispatch(Patient.updateField({ name, value: e.target.value }))
  }

  const submitPatient = e => {
    e.preventDefault()
    dispatch(Patient.send())
  }

  useEffect(() => {
    if (data.noLinkedTeleExpertise.value) {
      dispatch(Patient.updateField({ name: 'noLinkedTeleExpertise', value: false }))
      alert.show(`Êtes vous sûr de vouloir créer ce patient sans le partager à une téléexpertise ?`, {
        onConfirm: (e) => {
          dispatch(Patient.send({ confirmSave: true }))
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (patientExists) {
      alert.show(`Ce patient existe déjà. Souhaitez-vous demander au ${requestedDoctor?.title} ${requestedDoctor?.fullName} à être ajouté en tant que responsable de ce patient ou préférez-vous créer une nouvelle fiche patient ?`, {
        onAskCoOwnership: (e) => {
          dispatch(Patient.askPatientCoOwnership(patientId))
        },
        onForceCreation: (e) => {
          dispatch(Patient.send({ forceCreation: true }))
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientExists])

  // Make sure patient slice is clean (patients.data.expertises has not always same structure)
  useEffect(() => {
    if (!patientExists && !existingOwnedPatient) {
      dispatch(Patient.clean())
      dispatch(CustomField.clean())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.PATIENTS))

    return () => {
      dispatch(Patient.clean())
      dispatch(CustomField.clean())
      dispatch(UploadFile.cleanAll())
    }
  }, [dispatch])

  useEffect(() => {
    if (!fromTeleExpertise) {
      return;
    }

    dispatch(Patient.selectExpertise({
      name: 'expertises',
      value: fromTeleExpertise,
      preselected: true,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectExpertise])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const selectedExpertises = expertises
    .filter(uniqByIdAndType)
    .filter(expertise => Object.values(data.expertises.value)
      .find(exp => Number(expertise.id) === Number(exp.id) && expertise.type === exp.type))

  const teleExpertisesCount = `${selectedExpertises.length} ${pluralize('téléexpertise', selectedExpertises.length)}`

  if (creationSuccess || existingOwnedPatient) {
    return <Redirect to={`${privateSitePrefix}/patients/${patientId}`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <PatientContainer>
          <Ui.Layout.Form onSubmit={submitPatient}>
            <Ui.Layout.PageContentWrapper>
              <Panel
                selectedExpertises={selectedExpertises}
                teleExpertisesCount={teleExpertisesCount}
                history={history}
              />
              <Ui.Layout.Content hasPanel noPadding>
                {coOwnershipRequestSent
                  ? <RequestSentMessage>
                    <Ui.BasicElement.H2>Demande de co-responsabilité d'un patient</Ui.BasicElement.H2>
                    <Ui.BasicElement.P>Votre demande a été envoyée.</Ui.BasicElement.P>
                    <Link to={`${privateSitePrefix}/patients`}>Retourner à la liste des patients</Link>
                  </RequestSentMessage>
                  : <><Ui.Layout.NavContainer>
                    <nav>
                      <Ui.Layout.NavLink on={true}>
                        <Ui.Icon.IconInfos />
                        Créer votre patient
                      </Ui.Layout.NavLink>
                    </nav>
                    <Ui.Button.NavbarButton
                      isFilled
                      isGreen
                      type="button"
                      onClick={submitPatient}
                      disabled={pending || uploading}>
                      {pending && <Ui.Global.Loader />}
                      Valider
                    </Ui.Button.NavbarButton>
                  </Ui.Layout.NavContainer>
                    <Ui.Layout.PaddedContent>
                      <Summary
                        updateField={updateField}
                        data={data}
                        dispatch={dispatch}
                        selectedExpertises={selectedExpertises}
                        search={search}
                        expertises={expertises}
                        keywordsNotFound={keywordsNotFound}
                        fromTeleExpertise={fromTeleExpertise}
                        availableExpertises={availableExpertises}
                      />
                    </Ui.Layout.PaddedContent>
                  </>
                }
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
          </Ui.Layout.Form>
        </PatientContainer>
      </Ui.Layout.PageLayout>

    </>
  )
}

const Panel = ({
  selectedExpertises,
  teleExpertisesCount,
  history,
}) =>
  <Ui.Layout.PanelContainer>
    <Ui.Layout.Panel>
      <Ui.Button.BackButton onClick={history.goBack} />
      <Ui.BasicElement.H1Container>
        <Ui.Icon.IconH1>
          <Ui.Icon.IconPatientSup />
        </Ui.Icon.IconH1>
        <Ui.BasicElement.H1>Nouveau patient</Ui.BasicElement.H1>
      </Ui.BasicElement.H1Container>
      <Ui.BasicElement.P>
        Ajoutez un nouveau patient et partagez-le avec vos médecins en
        le liant à une ou plusieurs téléexpertises.
      </Ui.BasicElement.P>
      {selectedExpertises.length > 0 &&
        <TeleExpertiseSectionContainer>
          <Ui.Layout.FlexBox flow="row wrap" alignItems="center">
            <Ui.SearchBar.PlainRoundIcon>
              <Ui.Icon.IconShare />
            </Ui.SearchBar.PlainRoundIcon>
            <Ui.Layout.FlexBox flow="column">
              <Ui.BasicElement.P bold medium light>Ce patient sera partagé avec :</Ui.BasicElement.P>
              <Ui.BasicElement.P bold dark>
                {teleExpertisesCount}
              </Ui.BasicElement.P>
            </Ui.Layout.FlexBox>
          </Ui.Layout.FlexBox>
          <Ui.Layout.FlexBox flow="column wrap" alignContent="inherit">
            {selectedExpertises.map((expertise, i) =>
              <Ui.Form.ButtonLarge
                key={i}
                teleExpertiseType={expertise.type}
                value={expertise.name}
                subLabel={expertise.thePersonal
                  ? [expertise.ownerSpeciality, expertise.ownerCity].filter(e => e).join(', ')
                  : `${expertise.ownerTitle} ${expertise.ownerFullname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}`}
                label={expertise.name}
              >
                {expertise.pictureFileName
                  ? <Ui.BasicElement.TeleExpertisePicture
                    value={{
                      teleExpertiseId: expertise.id,
                      type: expertise.type.slice(-1) === 's' ? expertise.type.slice(0, -1) : expertise.type,
                      pictureFileName: expertise.pictureFileName,
                    }}
                    isBig={false}
                  />
                  : < Ui.SearchBar.ExpertiseCheckboxIcon
                    value={expertise}
                  />
                }
              </Ui.Form.ButtonLarge>
            )}
          </Ui.Layout.FlexBox>
        </TeleExpertiseSectionContainer>
      }
    </Ui.Layout.Panel>
  </Ui.Layout.PanelContainer>

const Summary = ({
  updateField,
  data,
  dispatch,
  selectedExpertises,
  search,
  expertises,
  fromTeleExpertise,
  keywordsNotFound,
  availableExpertises,
}) => {
  const [viewMode, setViewMode] = useState(true)

  const keyPressFunc = (e) => {
    if (e.which === 8) {
      let val = data.birthdate.value;

      if (val.length === 3 || val.length === 6) {
        val = val.slice(0, val.length - 1);
        dispatch(Patient.updateField({ name: 'birthdate', value: val }))
      }
    }
  }

  const updateDateField = name => e => {
    let val = e.target.value;

    if (val.length === 2) {
      val += '/';
    } else if (val.length === 5) {
      val += '/';
    }
    dispatch(Patient.updateField({ name, value: val }))
  }

  const genderSelectList = [['male', 'Homme'], ['female', 'Femme'], ['undetermined', 'Indéterminé']]
    .map(([value, label]) => ({
      label,
      value,
      selected: value === data.gender.value,
    }))

  return (
    <>
      <SectionContainer>
        <Ui.Layout.Row>
          <ExplanationText>Veuillez renseigner les données de votre patient et partager le avec une ou plusieurs téléexpertises.</ExplanationText>
        </Ui.Layout.Row>
        <Ui.Layout.Row>
          <Ui.Form.TextInput
            htmlFor="lastname"
            label="Nom *"
            value={data.lastname.value}
            onChange={updateField('lastname')}
            autoFocus
            error={data.lastname.errors[0]}
            noSubmit
          />
          <Ui.Form.TextInput
            htmlFor="firstname"
            label="Prénom *"
            value={data.firstname.value}
            onChange={updateField('firstname')}
            error={data.firstname.errors[0]}
            noSubmit
          />
        </Ui.Layout.Row>
        <Ui.Layout.Row>
          <Ui.Layout.InputBoxContainer>
            <Ui.Form.Label>Date de naissance *</Ui.Form.Label>
            <Ui.Form.Input
              htmlFor="birthdate"
              label="Date de naissance"
              value={data.birthdate.value}
              onChange={updateDateField('birthdate')}
              onKeyDown={keyPressFunc}
              maxLength="10"
              placeholder="JJ/MM/AAAA"
            />
            {data.birthdate.errors[0] &&
              <Ui.Form.FieldErrorMessage>{data.birthdate.errors[0]}</Ui.Form.FieldErrorMessage>
            }
          </Ui.Layout.InputBoxContainer>
          <Ui.Layout.InputBoxContainer>
            <Ui.Form.Label>Sexe *</Ui.Form.Label>
            <Ui.Select.Select
              htmlFor="sex"
              items={genderSelectList}
              onChange={updateField('gender')}
            />
          </Ui.Layout.InputBoxContainer>
        </Ui.Layout.Row>
      </SectionContainer>
      <SectionContainer>
        <Ui.BasicElement.H2>
          Partager votre patient avec des téléexpertises
        </Ui.BasicElement.H2>
        {viewMode && fromTeleExpertise
          ? <>
            <Ui.Layout.FlexBox flow="row wrap" alignContent="inherit">
              {selectedExpertises.map((expertise, i) =>
                <Ui.Form.ButtonLarge
                  key={i}
                  teleExpertiseType={expertise.type}
                  value={expertise.name}
                  subLabel={`${expertise.ownerTitle} ${expertise.ownerFullname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}`}
                  label={expertise.name}
                >
                  <Ui.SearchBar.ExpertiseCheckboxIcon
                    value={expertise}
                  />
                </Ui.Form.ButtonLarge>
              )}
              {availableExpertises.length > 0 &&
                <PlusButton
                  type="button"
                  isGreen
                  title="Associer des expertises supplémentaires"
                  onClick={() => setViewMode(false)}>
                  <Ui.Icon.IconPlus />
                </PlusButton>
              }
            </Ui.Layout.FlexBox>
          </>
          : <Ui.SearchBar.SearchAndCheck
            name="expertises"
            query={search.expertises.value}
            placeholder={'Rechercher une expertise, RCP ou cohorte. Ex: "RCP rachis", "neurochirurgie", "Dr Gilles Laurent"'}
            TeleExpertise={Patient}
            resultSet={expertises.filter(uniqByIdAndType)}
            list={data.expertises.value}
            loading={search.expertises.loading}
            // keywordsNotFound={keywordsNotFound}
            CheckboxIconComponent={Ui.SearchBar.ExpertiseCheckboxIcon}
          />
        }
      </SectionContainer>
    </>
  )
}

const RequestSentMessage = styled(props => <Ui.Layout.PaddedContent {...props} />)`
  margin-top: 2rem;
  a {
    color: ${props => props.theme.patientGreen};
    font-size: .875rem;
  }
`

const PatientContainer = styled.div`
  ${Ui.BasicElement.H1Container} {
    margin-bottom: 1rem;
    h1 {
      color: ${props => props.theme.patientGreen};
    }
  }
  ${Ui.Layout.NavLinkItem} {
    cursor: auto;
    &:hover {
      color: ${props => props.theme.patientGreen};
    }
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding: 0;
    }
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Form.Input} {
      &:read-only {
        border-color: transparent;
        opacity: 1;
      }
    }
  }
  ${Ui.Button.Back} {
    flex: none;
  }
`

const SectionContainer = styled.div`
  margin-bottom: 2.5rem;
  ${Ui.Form.InputContainer} {
    margin-top: 1.8rem;
    margin-bottom: .15rem;
  };
  ${Ui.Button.Button} {
    &:disabled {
      background-color: grey;
    };
  };
  ${StyledDatePicker} {
    border-bottom: 1px solid ${props => props.theme.patientGreen};
    min-height: 1.96rem;
    padding-bottom: .3rem;
    padding-top: .5rem;
  };
  h2 + h3 {
    margin-top: 1.5rem;
  }
  ${Ui.Layout.Row} {
    align-items: flex-end;
  }
`
const ExplanationText = styled(props => <Ui.BasicElement.P {...props} />)`
  margin-bottom: 0;
`

const TeleExpertiseSectionContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    line-height: 1.2rem;
  }
  ${Ui.SearchBar.PlainRoundIcon} {
    margin-right: 1rem;
  }
  ${Ui.SearchBar.CheckboxIcon} {
    margin-right: 0;
  }
  ${Ui.Card.TelexpertiseContainer} {
    margin: .5rem 0;
  }
  ${Ui.Layout.FlexBox} {
    width: auto;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    ${Ui.Form.CheckboxLargeContainer} {
      max-width: none;
    }
  }
  ${Ui.Form.CheckboxLargeContainer}:first-child {
    margin-top: 1rem;
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

const PlusButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-bottom: .5rem;
  margin-top: .5rem;
  box-shadow: ${props => props.theme.boxShadow.medium};
  color: ${props => props.theme.mediumGrey};
  i {
    font-size: 1.2rem;
  }
  &:hover {
    box-shadow: ${props => props.theme.boxShadow.medium};
    color: ${props => props.theme.grey2};
  }
`

export default CreatePatientComponent;
