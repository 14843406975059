import { apiUrl, apiSymfony6Url, authUrl, privateSitePrefix } from './Config';

export default (() => {
  const fetching = async ({ path, params, errorMessage, launchIsLoggedIf404 = true }) => {
    params.credentials = 'include';

    try {
      const response = await fetch(path, params);

      if (launchIsLoggedIf404 && 403 === response.status) {
        isLogged();
      }

      if (404 === response.status) {
        throw Error('Not found');
      }

      if (!response.ok) {
        throw Error('');
      }

      const json = await response.json();
      return json;
    } catch (e) {
      if (e.message === 'Not found') throw new Error('Not found');
      throw new Error(errorMessage);
    }
  };

  return {
    post({ path, errorMessage, launchIsLoggedIf404 = true, body = {}, signal = null, isNewSymfony = false }) {
      return fetching({
        path: `${isNewSymfony ? apiSymfony6Url : apiUrl}${path}`,
        params: {
          method: 'POST',
          signal,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body,
        },
        errorMessage,
        launchIsLoggedIf404
      });
    },
    get({ path, errorMessage, launchIsLoggedIf404 = true, signal = null, isNewSymfony = false }) {
      const params = {
        method: 'GET',
        signal,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
      const response = fetching({
        path: `${isNewSymfony ? apiSymfony6Url : apiUrl}${path}`,
        params: Object.entries(params)
          .filter(([key, value]) => value)
          .reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: value,
            }),
            {}
          ),
        errorMessage,
        launchIsLoggedIf404
      });
      return response;
    },
    patch({ path, errorMessage, launchIsLoggedIf404 = true, body = null, isNewSymfony = false }) {
      return fetching({
        path: `${isNewSymfony ? apiSymfony6Url : apiUrl}${path}`,
        params: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body,
        },
        errorMessage,
        launchIsLoggedIf404
      });
    },
    put({ path, errorMessage, launchIsLoggedIf404 = true, body = null, isNewSymfony = false }) {
      return fetching({
        path: `${isNewSymfony ? apiSymfony6Url : apiUrl}${path}`,
        params: {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body,
        },
        errorMessage,
        launchIsLoggedIf404
      });
    },
    delete({ path, errorMessage, launchIsLoggedIf404 = true, body = null, isNewSymfony = false }) {
      return fetching({
        path: `${isNewSymfony ? apiSymfony6Url : apiUrl}${path}`,
        params: {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body,
        },
        errorMessage,
        launchIsLoggedIf404
      });
    },
  };
})();

const isLogged = async function () {
  if (window.location.pathname.includes(`${privateSitePrefix}/`)) {
    try {
      const response = await fetch(`${apiUrl}/users/is-logged`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (403 === response.status) {
        window.location = `${process.env.REACT_APP_SKEZI_AUTH_FRONT_URL}/login?redirectUrl=${encodeURIComponent(window.location.href)}`
      }
    } catch {
      window.location = authUrl;
    }
  }
};
