import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import * as Ui from '../Ui'

export const FaqPicture = styled.img`
  width: ${props => props.width || '90%'};
  margin: 0 auto;
`

const faqContent = [
  {
    title:
      'Puis-je m\'inscrire si je ne suis pas médecin ou que je n\'ai pas de numéro RPPS ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Vous pouvez vous inscrire sur Skemeet si vous n'êtes pas médecin ou que vous n'avez pas de numéro RPPS en cliquant sur le bouton "s'inscrire manuellement" (seuls les champs avec une astérisque (*) sont obligatoires).
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Une fois inscrit, vous pouvez modifier votre titre (Dr/Pr, Mr/Mme) dans "Mon Profil".
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-faq-publique-01.png" />
    </Ui.Layout.BlockContainer>
  },
  {
    title:
      'Pourquoi dois-je renseigner mon numéro de téléphone ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Afin de renforcer la sécurité des données de santé, une double authentification est nécessaire pour vous connecter sur Skemeet.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        En pratique, lors de votre 1ère connexion, un code à usage unique est envoyé par SMS au numéro de téléphone fourni lors de votre inscription.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Lors d'une future connexion sur le même poste de travail, vous pourrez aussi cocher la case "Ne plus demander de code".
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-faq-publique-02.png" />
    </Ui.Layout.BlockContainer>
  },
  {
    title:
      'Je ne reçois pas le code de confirmation par SMS',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Si vous ne recevez pas le code il est possible que vous ayez fait une erreur en saisissant votre numéro de téléphone ou que celui-ci ne soit pas un numéro français.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Dans ce cas, il suffit de cliquer sur le bouton "Email" sous "Recevoir un nouveau code" pour que celui-ci vous soit envoyé sur l'adresse email renseignée lors de votre inscription.
      </Ui.BasicElement.P>
      <FaqPicture width="50%" src="/screen-faq-publique-03.png" />
    </Ui.Layout.BlockContainer>
  },
  {
    title:
      'J\'ai oublié mon identifiant et/ou j\'ai fait trois erreurs consécutives en saisissant mon mot de passe',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Par mesure de sécurité, après 3 erreurs de saisie de votre mot de passe, vous devrez attendre 15 minutes avant de pouvoir réessayer de vous connecter.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser en cliquant sur "Mot de passe oublié"
      </Ui.BasicElement.P>
      <FaqPicture width="50%" src="/screen-faq-publique-04.png" />
    </Ui.Layout.BlockContainer>
  },
  {
    title:
      'Doit-on payer pour utiliser Skemeet ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        L'inscription sur Skemeet est gratuite et vous donne accès à toutes les fonctionnalités de la plateforme. Vous pouvez gratuitement et sans limitation adresser des patients à vos médecins via des expertises, des RCPs ou des cohortes.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Vous pouvez aussi créer votre propre téléexpertise gratuitement mais seuls les dix premiers patients pourront être partagés par vos correspondants. Au-delà, vous pourrez souscrire une offre Skemeet PRO.
      </Ui.BasicElement.P>
    </Ui.Layout.BlockContainer>
  },
  {
    title:
      'Les données sont-elles sécurisées ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        L'hébergement des données de santé déposées via Skemeet est assuré par un hébergeur agréé pour les données de santé (OVH Cloud).
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        A ce titre, les mesures mises en œuvre par OVH pour assurer la sécurité de vos données de santé est conforme aux exigences de l'<a href="https://esante.gouv.fr/offres-services/hds/liste-des-herbergeurs-certifies"rel="noreferrer"  target="_blank">Agence du Numérique en Santé</a>.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Pour en savoir plus, nous vous invitons à prendre connaissance de notre <Link to="/politique-de-confidentialite">politique de confidentialité</Link>.
      </Ui.BasicElement.P>
    </Ui.Layout.BlockContainer>
  },
  {
    title: 'Comment présenter un patient ?',
    content:
      <iframe width="560" height="315" src="https://www.youtube.com/embed/IuM3NCw3OeM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>,
  },
  {
    title:
      'Comment savoir si mon patient est bien partagé via une expertise, une RCP ou une cohorte ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Pour vérifier si les informations de votre patient ont bien été partagées dans le cadre d'une téléexpertise spécifique, il vous suffit de consulter la fiche du patient concerné et de sélectionner l'onglet « Téléexpertises ».
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-1.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment retrouver une expertise, unr RCP ou une cohorte ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Pour trouver une téléexpertise, que ce soit pour obtenir une expertise, participer à une RCP, ou consulter une cohorte, commencez par sélectionner l'option appropriée depuis le menu latéral. Ensuite, utilisez le moteur de recherche pour affiner et trouver spécifiquement ce que vous cherchez.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-2.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment charger un document ou examen radiologique ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Après avoir sélectionné un patient dans votre liste, dans l'onglet "Fiche patient", vous avez la possibilité de :
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        - Ajouter des images en sélectionnant "Ajouter une image". Les formats d'image acceptés incluent JPG, JPEG, PNG, et GIF.<br />
        - Intégrer un examen en coupe, tel qu'un scanner ou une IRM, au format DICOM. Pour ce faire, chargez le dossier exporté depuis votre système PACS ou un CD-ROM.<br />
        - Insérer un document, comme des fichiers PDF, Word, Excel, ou PowerPoint, dans les champs personnalisés prévus pour la téléexpertise.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-3.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment retrouver un patient ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Pour accéder à la liste des patients que vous avez ajoutés, cliquez sur l'option « Patient » située dans le menu latéral. Cela affichera tous les patients enregistrés.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Si vous souhaitez voir tous les patients qui ont été partagés avec vous dans le cadre d'une téléexpertise, cliquez sur l'option « Afficher : tous les patients ».
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Utilisez le moteur de recherche pour trouver rapidement un patient spécifique, en saisissant des critères tels que le nom, le diagnostic, ou le médecin responsable.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-4.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment se connecter à la visioconférence pour une RCP ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Pour rejoindre une visioconférence, commencez par localiser la Réunion de Concertation Pluridisciplinaire (RCP) concernée en naviguant via le menu latéral. Ensuite, cliquez sur « RCP ». Vous verrez alors apparaître le bouton « Visio », sur lequel vous devrez cliquer pour accéder à la réunion en ligne.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-5.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment télécharger l\'avis qui a été donné pour mon patient ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Après avoir choisi votre patient dans la liste, accédez à l'onglet « Commentaires » pour vérifier si un avis a été partagé. Normalement, vous auriez dû recevoir une notification par email à ce sujet. Pour télécharger ou imprimer le compte rendu associé à cet avis, repérez et cliquez sur l'icône « Compte-rendu » située à droite du commentaire.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Si vous ne trouvez aucun avis dans l'onglet « Commentaires », nous vous recommandons de prendre contact avec l’administrateur, mais aussi le responsable de la téléexpertise pour assistance.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Notez qu'il existe également des commentaires directement liés au patient qui ne peuvent pas être téléchargés. La principale différence réside dans le fait que ces commentaires sont émis directement depuis la fiche du patient, en dehors de toute téléexpertise.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-6.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment créer une expertise, unr RCP ou une cohorte ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Vous avez la possibilité de créer une expertise, une RCP, ou une cohorte sans frais supplémentaires. Pour ce faire, sélectionnez d'abord la catégorie souhaitée depuis le menu latéral. Ensuite, cliquez sur le bouton « Nouvel expertise/RCP/cohorte... » situé à droite de la page pour initier la création.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Il est important de noter qu'une expertise personnelle est automatiquement générée à votre nom lors de votre inscription sur la plateforme.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-7.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title: 'J\'ai atteint la limite de 10 patients, comment débloquer ma téléexpertise ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Si votre activité de téléexpertise implique le partage de plus de 10 patients, vous avez l'option d'augmenter les capacités de votre service en passant à « SKEMEET PRO ». Pour effectuer cette mise à niveau, il vous suffit de cliquer sur le lien « Mettre à niveau » situé en bas à gauche de l’écran.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Cette action vous redirigera vers nos offres SKEMEET, notamment les abonnements individuels PRO et PRO+, qui sont proposés selon un modèle de licence.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-8.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment modifier des données déjà rentrées sur un patient ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        À tout moment, vous avez la possibilité de mettre à jour les informations de votre patient. Pour ce faire, rendez-vous sur la fiche du patient concerné et cliquez simplement sur les champs que vous désirez modifier.
      </Ui.BasicElement.P>
      <FaqPicture src="/screen-new-FAQ-9.png" />
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Comment et quel justificatif professionnel dois-je télécharger ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Pour activer son compte et bénéficier pleinement des fonctionnalités offertes par la plateforme, l'utilisateur doit confirmer son statut professionnel à la suite de son inscription. Ceci se fait en téléchargeant un document justificatif, tel qu'une copie de la Carte de Professionnel de Santé (CPS), un certificat d'inscription universitaire, une licence de remplacement, une attestation d'inscription à l'Ordre des Médecins, ou un contrat de travail pour les personnels soignants.
      </Ui.BasicElement.P>
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },
  {
    title:
      'Dois-je informer mon patient que ses données sont partagées via Skemeet ?',
    content: <Ui.Layout.BlockContainer>
      <Ui.BasicElement.P>
        Les données de santé à caractère personnel sont couvertes par le secret professionnel dans les conditions prévues à l'article L 1110-4 du Code de la santé publique, dont la violation est réprimée par l'article 226-13 du Code pénal.
      </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        L'Utilisateur communique (à l'oral ou à l'écrit) au patient dont il enregistre les données sur la Plateforme toutes les informations relatives au traitement de ses données
      </Ui.BasicElement.P>
    </Ui.Layout.BlockContainer>,
    isPrivate: true
  },]

const FAQContent = ({ isPublicSite }) => {

  return (
    <FAQContentContainer isPublicSite={isPublicSite}>
      {faqContent
        .filter(({ isPrivate }) => isPublicSite ? !isPrivate : isPrivate)
        .map(({ title, content }, index) => (
          <Ui.AccordionItem title={title} content={content} key={index} />
        ))}
    </FAQContentContainer>
  )
}

const FAQContentContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  margin-top: ${props => props.isPublicSite ? '2rem' : '0'};

  a {
    color: ${props => props.theme.patientGreen};
  }

  ${Ui.BasicElement.H1} {
    margin-top: 3rem;
  }

  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Ui.BasicElement.P} {
    margin: .5rem 0;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

export default FAQContent;
