import * as React from "react";

export const DocJpg = ({
  ...props
}) => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 384 384"
    style={{
      enableBackground: "new 0 0 384 384",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={-88.4651}
      y1={545.5741}
      x2={-35.6171}
      y2={492.7321}
      gradientTransform="matrix(8 0 0 -8 817.04 4441.9248)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#EFEEEE",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#DEDEDD",
        }}
      />
    </linearGradient>
    <polygon
      style={{
        fill: "url(#SVGID_1_)",
      }}
      points="64,0 64,384 288,384 384,288 384,0 "
    />
    <polygon
      style={{
        fill: "#ABABAB",
      }}
      points="288,288 288,384 384,288 "
    />
    <polygon
      style={{
        fill: "#DEDEDD",
      }}
      points="192,384 288,384 288,288 "
    />
    <path
      style={{
        fill: "#71A742",
      }}
      d="M0,96v112h256V96L0,96L0,96z"
    />
    <g>
      <path
        style={{
          fill: "#FFFFFF",
        }}
        d="M72.24,167.2c0,7.296-1.696,12.496-5.088,15.616c-3.392,3.088-8.576,4.656-15.552,4.656 c-3.664,0-6.64-0.496-8.992-1.504c-2.336-0.992-4.192-2.384-5.568-4.224s-2.32-3.952-2.816-6.384 c-0.528-2.416-0.784-4.992-0.784-7.68v-2.992h12.672v2.128c0,3.648,0.4,6.336,1.2,8.112c0.8,1.744,2.448,2.64,4.96,2.64 c2.48,0,4.144-0.896,4.944-2.64c0.8-1.76,1.2-4.464,1.2-8.112V117.84H72.24V167.2z"
      />
      <path
        style={{
          fill: "#FFFFFF",
        }}
        d="M115.04,117.84c3.52,0,6.464,0.608,8.88,1.792s4.336,2.736,5.808,4.656s2.528,4.112,3.168,6.576 c0.624,2.448,0.96,4.976,0.96,7.536c0,3.52-0.528,6.592-1.584,9.216c-1.056,2.624-2.528,4.768-4.416,6.416 c-1.888,1.664-4.176,2.928-6.864,3.744c-2.688,0.832-5.664,1.248-8.944,1.248h-7.376v27.36H90.848V117.84L115.04,117.84 L115.04,117.84z M110.816,148.896c2.752,0,4.992-0.848,6.672-2.512c1.696-1.664,2.544-4.256,2.544-7.776 c0-3.456-0.736-6.08-2.208-7.872c-1.472-1.776-3.936-2.688-7.392-2.688h-5.76v20.848L110.816,148.896L110.816,148.896z"
      />
      <path
        style={{
          fill: "#FFFFFF",
        }}
        d="M180.272,134.288c-0.288-1.344-0.752-2.576-1.392-3.696c-0.64-1.104-1.456-2.048-2.432-2.784 c-0.992-0.736-2.208-1.104-3.616-1.104c-3.328,0-5.712,1.856-7.2,5.584c-1.472,3.696-2.208,9.856-2.208,18.416 c0,4.112,0.128,7.808,0.384,11.136s0.72,6.16,1.392,8.496s1.632,4.128,2.88,5.376s2.864,1.872,4.848,1.872 c0.848,0,1.744-0.24,2.752-0.672c0.992-0.448,1.904-1.12,2.784-2.016c0.864-0.912,1.584-2.032,2.16-3.408s0.864-3.008,0.864-4.864 v-7.008h-9.104V149.44h22.352v36.96H184.56v-6.336h-0.192c-1.664,2.704-3.664,4.592-6,5.712s-5.136,1.68-8.4,1.68 c-4.224,0-7.664-0.752-10.336-2.224c-2.672-1.472-4.736-3.728-6.24-6.816s-2.512-6.864-3.008-11.376 c-0.512-4.512-0.784-9.744-0.784-15.696c0-5.744,0.368-10.816,1.104-15.152c0.736-4.352,2-7.984,3.792-10.912 c1.776-2.912,4.16-5.088,7.088-6.576c2.96-1.472,6.624-2.208,11.04-2.208c7.552,0,12.992,1.872,16.32,5.632 c3.328,3.728,4.992,9.088,4.992,16.08h-13.248C180.704,136.944,180.56,135.632,180.272,134.288z"
      />
    </g>
  </svg>
);
