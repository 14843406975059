import React, { useState } from 'react'
import { apiUrl } from '../../Util/Config'
import styled from 'styled-components'
import * as Button from './Button'
import * as IconUI from './Icon'
import { useAlert } from 'react-alert'
import { mimeTypeIcon } from '../../Util/MimeTypeIcons'

export const DocumentPreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;

  a {
    text-decoration: none;
    color: black;
  }
`

export const DocumentPreviewElement = styled.div`
  font-size: .8rem;
  margin-right: 1rem;
  display: flex;
  background-color: white;
  padding: .5rem;
  flex: 1;
  width: 14rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px 1px rgba(134,174,197,0.24);
  div:nth-child(2) {
    /* overflow-wrap: break-word;
    white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const DocumentThumbnail = styled.div`
  background: url(${props => props.background}) no-repeat center;
  background-size: cover;
  width: 3rem;
  height: 3rem;
  flex: initial;
  cursor: ${props => props.zoomable ? 'pointer' : 'default'};
`

const DocumentPreviewContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: .8rem;
`

const DocFileSize = styled.div`
  flex: 1;
`

const TrashButton = styled(props => <Button.Button {...props} />)`
  width: 2.3125rem;
  height: 2.3125rem;
  padding: 0;
  &:hover {
    color: white;
  }
`

export const ImagePreviewElement = styled.div`
  width: ${props => props.width || '6.25rem'};
  height: 6.25rem;
  position: relative;
  button {
    position: absolute;
    left: 50%;
    margin-left: -1.09375rem;
    bottom: -1rem;
    pointer-events: auto;
    &:hover {
      background-color: ${props => props.theme.darkGreen}
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  };
`

export const Placeholder = styled.div`
  width: 6.25rem;
  height: 6.25rem;
  position: relative;
  background: ${props => props.theme.mediumGrey};
  border: 1px dashed ${props => props.theme.grey};
`

export const ImagePreviewContainer = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
`

export const ImageThumbnail = styled.div`
  background: url(${props => props.background}) no-repeat center top;
  background-size: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: ${props => props.isRounded ? '5000px' : '0'};
`

const ZoomImageSlide = styled.div`
  z-index: 2;
  flex: 1;
  display: flex;
  justify-content: center;
`

const ZoomImage = styled.img`
    max-height: calc(100vh - 6rem);
    width: auto;
    height: 100vh;
    cursor: default;
`

const ZoomImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 500;
  align-items: center;
  animation: fadein .2s;
  box-sizing: border-box;
  top: 0;
  left: 0;
  cursor: pointer;
  @keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
  }
`

const ZoomImageBackground = styled.div`
  width: 100%;
  height: 100%;
  background: #0b0b0b;
  position: fixed;
  opacity: .8;
`

const DocActionButtons = styled.div`
  align-self: flex-end;
  ${IconUI.Icon} {
    font-size: 1.1rem;
    color: ${props => props.theme.grey2};
    margin-left: .4rem;
    &:hover {
      color: ${props => props.theme.mediumBlue};
    }
  };
    margin-right: .4rem;
`

const TrashDocButton = styled(props => <IconUI.IconTrash {...props} />)`
  cursor: pointer;
`

const SeeDocButton = styled(props => <IconUI.IconEye {...props} />)`
  cursor: pointer;
`

const SaveDocButton = styled(props => <IconUI.IconSave {...props} />)`
  align-self: flex-end;
`

const ZoomHeader = styled.div`
  height: 1rem;
  padding-top: 1.2rem;
  padding-bottom: .6em;
  color: white;
  align-self: flex-end;
  margin-right: 1rem;
  font-size: 1.3rem;
  cursor: pointer;
  z-index: 2;
`

const DocTitle = styled.div`
  font-weight: 600;
  margin-bottom: .4rem;
`

const ArrowLeft = styled(props => <IconUI.IconArrowLeft {...props} />)`
  color: white;
  z-index: 4;
  margin-left: 1.5rem;
  font-size: 2rem;
  cursor: pointer;
  width: 3rem;
  flex: initial;
`

const ArrowRight = styled(props => <IconUI.IconArrowRight {...props} />)`
  color: white;
  z-index: 4;
  margin-right: 1.5rem;
  font-size: 2rem;
  cursor: pointer;
  width: 3rem;
  flex: initial;
`

const ZoomFooter = styled.div`
  color: white;
  text-align: center;
  z-index: 2;
  font-size: .8rem;
  padding: 1rem 0;
  position: relative;
  padding: 1rem 0;
`

export const DocumentPreview = ({
  onRemove,
  name,
  iconSrc,
  fileName,
  apiPath = '/attachments/assets/',
  size,
  children,
  title,
  mimeType,
  showSeeAndDownloadButtons = true,
  ...props
}) => {
  const alert = useAlert()
  const onRemoveDocument = e => {
    alert.show(`Confirmez-vous la suppression de ce document ?`, {
      onConfirm: onRemove
    })
  }

  function convertFileSize(size) {
    const aSize = Math.abs(parseInt(size, 10));
    const def = [[1, 'octets'], [1024, 'Ko'], [1024 * 1024, 'Mo']];
    for (var i = 0; i < def.length; i++) {
      if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1];
    }
  }

  const [zoomIndex, setZoomIndex] = useState(null)

  return (
    <DocumentPreviewContainer>
      <DocumentPreviewElement>
        {zoomIndex !== null &&
          <ZoomImageContainer onClick={() => setZoomIndex(null)}>
            <ZoomImageBackground />
            <ZoomImageContent>
              <ZoomHeader>
                <CloseBtn
                  onClick={() => setZoomIndex(null)}
                />
              </ZoomHeader>
              <ZoomImageSlider>
                <ZoomImageSlide>
                  <ZoomImage
                    onClick={e => e.stopPropagation()}
                    src={`${apiUrl}${apiPath}${fileName}`}
                    alt="Zoom"
                  />
                </ZoomImageSlide>
              </ZoomImageSlider>
            </ZoomImageContent>
          </ZoomImageContainer>
        }
        {(
          showSeeAndDownloadButtons &&
          mimeType &&
          /(jpe?g|png)$/.test(mimeType)
        )
          ? <DocumentThumbnail zoomable background={`${apiUrl}${apiPath}${fileName}`} onClick={() => setZoomIndex(1)} />
          : <span className='h-[3rem] w-[3rem]'>{mimeTypeIcon(mimeType)}</span>
        }
        <DocumentPreviewContent>
          <DocTitle>{name}</DocTitle>
          <DocFileSize>{convertFileSize(size)}</DocFileSize>
        <DocActionButtons>
          {(
            showSeeAndDownloadButtons &&
            mimeType &&
            /(jpe?g|png)$/.test(mimeType)
          ) &&
            <span
              onClick={() => setZoomIndex(1)}
              title={`Visionner ${name}`}
            >
              <SeeDocButton />
            </span>
          }
          {(
            showSeeAndDownloadButtons &&
            mimeType &&
            /(plain|pdf|gif|ms-excel|openxmlformats-officedocument.spreadsheetml.sheet|oasis.opendocument.presentation|ms-powerpoint|openxmlformats-officedocument.presentationml.presentation|oasis.opendocument.text|msword|openxmlformats-officedocument.wordprocessingml.document)$/.test(mimeType)
          ) &&
            <a
              href={`${apiUrl}${apiPath}${fileName}/${name.split('.')[0]}.pdf`}
              alt={`Visionner ${name}`}
              title={`Visionner ${name}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <SeeDocButton />
            </a>
          }
          {showSeeAndDownloadButtons &&
            <a
              href={`${apiUrl}${apiPath}${fileName}/download/${name}`}
              download={name}
              alt={`Télécharger ${name}`}
              title={`Télécharger ${name}`}
              rel="noopener noreferrer"
            >
              <SaveDocButton />
            </a>
          }
          {onRemove &&
            <TrashDocButton
              title={`Supprimer ${name}`}
              onClick={onRemoveDocument}
            />
          }
        </DocActionButtons>
        </DocumentPreviewContent>

      </DocumentPreviewElement>
    </DocumentPreviewContainer >
  )
}


export const ImagePreview = ({
  zoom,
  image,
  onClick,
  onRemove,
  background,
  canEdit,
  loading,
  isRounded,
  children,
  width,
  ...props
}) => {
  const alert = useAlert()
  const [actionButtons, setActionButtons] = useState(false)
  const onRemoveImage = e => {
    e.preventDefault();
    alert.show(`Confirmez-vous la suppression de cette image ?`, {
      onConfirm: onRemove
    })
  }

  return (
    <ImagePreviewContainer onMouseEnter={() => setActionButtons(true)} onMouseLeave={() => setActionButtons(false)}>
      <ImagePreviewElement width={width}>
        {canEdit && actionButtons &&
          <TrashButton
            $isFilled
            $isGreen
            onClick={onRemoveImage}
          >
            <IconUI.IconTrash />
          </TrashButton>
        }
        <ImageThumbnail
          onClick={onClick}
          background={background}
          alt="Agrandir l'image"
          isRounded={isRounded}
        />
      </ImagePreviewElement>
    </ImagePreviewContainer>
  )
}

const ZoomImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  `

const ZoomImageSlider = styled.div`
  display: flex;
  /* margin: auto; */
  flex-direction: row;
  align-items: center;
`

const CloseBtn = styled(props => <IconUI.IconClose {...props} />)`
  align-self: flex-end;
`

export const Zoom = ({
  src,
  onClose,
  children,
  zoomImage,
  setZoomImage,
  images,
  previewUri = "/patients/imageries",
  ...props
}) => {
  const previous = zoomImage === 0
    ? images.length - 1
    : zoomImage - 1
  const next = zoomImage === (images.length - 1)
    ? 0
    : zoomImage + 1

  return (
    <ZoomImageContainer onClick={onClose}>
      <ZoomImageBackground />
      <ZoomImageContent>
        <ZoomHeader>
          <CloseBtn
            onClick={onClose}
          />
        </ZoomHeader>
        <ZoomImageSlider>
          <ArrowLeft onClick={e => { e.stopPropagation(); setZoomImage(previous) }} />
          <ZoomImageSlide>
            <ZoomImage
              onClick={e => e.stopPropagation()}
              src={`${apiUrl}${previewUri}/${images[zoomImage].fileName}`}
              alt="Zoom"
            />
          </ZoomImageSlide>
          <ArrowRight onClick={e => { e.stopPropagation(); setZoomImage(next) }} />
        </ZoomImageSlider>
        <ZoomFooter>{zoomImage + 1} sur {images.length}</ZoomFooter>
      </ZoomImageContent>
    </ZoomImageContainer>
  )
}

