import * as React from "react";

export const SkemeetLogo = ({
    color = '#3BC3A8',
    ...props
}) => (
    <svg
        id="SKEMEET"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1349.27 353.66"
        {...props}
    >
        <defs>
        <style>
            {
            "\n      .cls-1 {\n        fill: #3bc3a8;\n      }\n\n      .cls-1, .cls-2, .cls-3 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #fff;\n      }\n\n      .cls-3 {\n        fill: #214152;\n      }\n    "
            }
        </style>
        </defs>
        <g>
        <path
            className="cls-3"
            d="m626.39,200.14l-31.1-123.59v133.91c0,7.41-1.65,12.97-4.96,16.67-3.31,3.71-7.7,5.56-13.17,5.56s-9.62-1.83-12.97-5.49c-3.35-3.66-5.03-9.24-5.03-16.74V56.97c0-8.47,2.2-14.18,6.62-17.14,4.41-2.95,10.36-4.43,17.86-4.43h12.17c7.32,0,12.64.66,15.95,1.99,3.31,1.32,5.76,3.71,7.34,7.14,1.59,3.44,3.39,9.04,5.43,16.81l28.18,106.26,28.18-106.26c2.03-7.76,3.84-13.36,5.43-16.81,1.59-3.44,4.04-5.82,7.34-7.14,3.31-1.32,8.62-1.99,15.95-1.99h12.17c7.5,0,13.45,1.48,17.86,4.43,4.41,2.96,6.62,8.67,6.62,17.14v153.5c0,7.41-1.65,12.97-4.96,16.67-3.31,3.71-7.74,5.56-13.3,5.56-5.21,0-9.48-1.85-12.83-5.56-3.35-3.71-5.03-9.26-5.03-16.67V76.55l-31.1,123.59c-2.03,8.03-3.68,13.92-4.96,17.67-1.28,3.75-3.64,7.17-7.08,10.25s-8.2,4.63-14.29,4.63c-4.59,0-8.47-.99-11.64-2.98-3.18-1.99-5.65-4.52-7.41-7.61-1.77-3.09-3.16-6.5-4.17-10.26-1.02-3.75-2.05-7.65-3.11-11.71Z"
        />
        <path
            className="cls-3"
            d="m914.46,65.7h-88.52v47.64h81.51c6,0,10.47,1.35,13.43,4.04,2.95,2.69,4.43,6.24,4.43,10.65s-1.46,8.01-4.37,10.78c-2.91,2.78-7.41,4.17-13.5,4.17h-81.51v55.18h91.57c6.17,0,10.83,1.43,13.96,4.3,3.13,2.87,4.7,6.68,4.7,11.45s-1.57,8.32-4.7,11.18c-3.13,2.87-7.79,4.3-13.96,4.3h-106.79c-8.56,0-14.71-1.9-18.46-5.69-3.75-3.79-5.62-9.92-5.62-18.39V59.48c0-5.64.84-10.25,2.51-13.83,1.67-3.57,4.3-6.17,7.87-7.81,3.57-1.63,8.14-2.45,13.7-2.45h103.74c6.26,0,10.92,1.39,13.96,4.17,3.04,2.78,4.57,6.42,4.57,10.92s-1.52,8.27-4.57,11.05c-3.04,2.78-7.7,4.17-13.96,4.17Z"
        />
        <path
            className="cls-1"
            d="m956.13,61.53c-3.04-2.78-4.57-6.46-4.57-11.05,0-4.5,1.52-8.14,4.57-10.92,3.04-2.78,7.7-4.17,13.96-4.17h103.74c5.56,0,10.12.82,13.7,2.45,3.57,1.63,6.2,4.23,7.87,7.81,1.68,3.57,2.51,8.18,2.51,13.83v145.82c0,8.47-1.87,14.6-5.62,18.39-3.75,3.79-9.9,5.69-18.46,5.69h-106.79c-6.17,0-10.83-1.43-13.96-4.3-3.13-2.87-4.7-6.59-4.7-11.18,0-4.76,1.57-8.58,4.7-11.45,3.13-2.87,7.79-4.3,13.96-4.3h91.57s0-55.18,0-55.18h-81.51c-6.09,0-10.59-1.39-13.5-4.17-2.91-2.78-4.37-6.37-4.37-10.78s1.48-7.96,4.43-10.65c2.96-2.69,7.43-4.04,13.43-4.04h81.51s0-47.64,0-47.64h-88.52c-6.26,0-10.92-1.39-13.96-4.17Z"
        />
        </g>
        <g>
        <path
            className="cls-3"
            d="m396.36,37h109.97c7.11,0,12.87,7.22,12.87,16.13h0c0,8.91-5.76,16.13-12.87,16.13h-109.97c-7.11,0-12.87-7.22-12.87-16.13h0c0-8.91,5.76-16.13,12.87-16.13Z"
        />
        <path
            className="cls-3"
            d="m396.36,197.12h109.97c7.11,0,12.87,7.22,12.87,16.13h0c0,8.91-5.76,16.13-12.87,16.13h-109.97c-7.11,0-12.87-7.22-12.87-16.13h0c0-8.91,5.76-16.13,12.87-16.13Z"
        />
        <path
            className="cls-3"
            d="m396.36,116.55h109.97c7.11,0,12.87,7.22,12.87,16.13h0c0,8.91-5.76,16.13-12.87,16.13h-109.97c-7.11,0-12.87-7.22-12.87-16.13h0c0-8.91,5.76-16.13,12.87-16.13Z"
        />
        </g>
        <g>
        <path
            className="cls-3"
            d="m164.85,170.84c0,11.47-2.96,21.78-8.86,30.93-5.91,9.14-14.56,16.3-25.94,21.48s-24.89,7.76-40.5,7.76c-18.72,0-34.16-3.54-46.32-10.61-8.63-5.09-15.64-11.88-21.03-20.38-5.39-8.5-8.09-16.76-8.09-24.78,0-4.66,1.62-8.65,4.85-11.97,3.24-3.32,7.35-4.98,12.36-4.98,4.05,0,7.48,1.29,10.29,3.88,2.8,2.59,5.2,6.43,7.18,11.52,2.41,6.04,5.02,11.09,7.83,15.14,2.8,4.06,6.75,7.4,11.84,10.03,5.09,2.63,11.78,3.95,20.06,3.95,11.39,0,20.64-2.65,27.75-7.96,7.12-5.31,10.67-11.92,10.67-19.86,0-6.3-1.92-11.41-5.76-15.33-3.84-3.92-8.8-6.92-14.88-8.99-6.08-2.07-14.21-4.27-24.39-6.6-13.63-3.19-25.04-6.92-34.22-11.19-9.19-4.27-16.48-10.09-21.87-17.47-5.39-7.38-8.09-16.54-8.09-27.5s2.85-19.71,8.54-27.82c5.69-8.11,13.93-14.34,24.71-18.7,10.78-4.36,23.46-6.54,38.04-6.54,11.65,0,21.72,1.45,30.21,4.33,8.5,2.89,15.55,6.73,21.16,11.52,5.61,4.79,9.71,9.81,12.29,15.07,2.59,5.26,3.88,10.4,3.88,15.4,0,4.57-1.62,8.69-4.85,12.36-3.23,3.67-7.27,5.5-12.1,5.5-4.4,0-7.74-1.1-10.03-3.3-2.29-2.2-4.77-5.8-7.44-10.8-3.45-7.16-7.59-12.75-12.42-16.76-4.83-4.01-12.6-6.02-23.29-6.02-9.92,0-17.92,2.18-24,6.53-6.08,4.36-9.12,9.6-9.12,15.72,0,3.8,1.04,7.07,3.11,9.83,2.07,2.76,4.92,5.13,8.54,7.12,3.62,1.99,7.29,3.54,11,4.66,3.71,1.12,9.83,2.76,18.37,4.92,10.7,2.5,20.38,5.26,29.05,8.28,8.67,3.02,16.04,6.69,22.13,11,6.08,4.31,10.83,9.77,14.23,16.37,3.41,6.6,5.11,14.69,5.11,24.26Z"
        />
        <path
            className="cls-3"
            d="m236.15,58.13v66.51l75.44-78.67c3.62-3.79,6.71-6.6,9.25-8.41,2.54-1.81,5.93-2.72,10.16-2.72,5.52,0,9.98,1.62,13.39,4.85,3.41,3.24,5.11,7.18,5.11,11.84,0,5.61-2.89,11.17-8.67,16.69l-46.71,44.25,53.83,77.12c3.79,5.35,6.64,10.03,8.54,14.04,1.9,4.01,2.85,7.92,2.85,11.71,0,4.23-1.66,7.89-4.98,11-3.32,3.11-7.83,4.66-13.52,4.66-5.35,0-9.71-1.14-13.07-3.43-3.37-2.28-6.21-5.33-8.54-9.12-2.33-3.79-4.4-7.25-6.21-10.35l-45.42-69.87-31.44,30.15v39.21c0,7.94-1.81,13.83-5.44,17.66-3.62,3.84-8.28,5.76-13.97,5.76-3.28,0-6.43-.86-9.45-2.59-3.02-1.72-5.35-4.05-6.99-6.99-1.21-2.41-1.94-5.22-2.2-8.41-.26-3.19-.39-7.81-.39-13.84V58.13c0-7.76,1.7-13.59,5.11-17.47,3.41-3.88,8.04-5.82,13.91-5.82s10.46,1.92,14.04,5.76c3.58,3.84,5.37,9.68,5.37,17.53Z"
        />
        </g>
        <path
        className="cls-3"
        d="m1237.19,35.4h-99.99c-6.44,0-11.36,1.46-14.75,4.37-3.4,2.91-5.09,6.88-5.09,11.91s1.63,8.76,4.9,11.71c3.26,2.96,8.25,4.43,14.95,4.43h42.87v140.93c0,8.03,1.83,14.03,5.49,18,3.66,3.97,8.4,5.96,14.23,5.96s10.41-1.96,14.03-5.89c3.62-3.92,5.43-9.95,5.43-18.06V67.82h17.95v-32.42Z"
        />
        <g>
        <path
            className="cls-3"
            d="m924.27,334.71c.18-2.9.35-7.22.35-11v-51.48h7.66v26.75h.18c2.73-4.75,7.66-7.83,14.52-7.83,10.56,0,18.04,8.8,17.95,21.74,0,15.22-9.59,22.79-19.1,22.79-6.16,0-11.09-2.38-14.25-8.01h-.26l-.35,7.04h-6.69Zm8.01-17.07c0,.97.18,1.94.35,2.82,1.5,5.37,5.98,9.06,11.62,9.06,8.1,0,12.94-6.6,12.94-16.37,0-8.54-4.4-15.84-12.67-15.84-5.28,0-10.21,3.61-11.79,9.5-.18.88-.44,1.94-.44,3.17v7.66Z"
        />
        <path
            className="cls-3"
            d="m976.9,292.12l9.33,25.17c.97,2.82,2.02,6.16,2.73,8.71h.18c.79-2.55,1.67-5.81,2.73-8.89l8.45-24.99h8.18l-11.62,30.36c-5.54,14.61-9.33,22.09-14.61,26.66-3.78,3.34-7.57,4.66-9.5,5.02l-1.94-6.51c1.94-.62,4.49-1.85,6.78-3.78,2.11-1.67,4.75-4.66,6.51-8.62.35-.79.62-1.41.62-1.85s-.18-1.06-.53-2.02l-15.75-39.25h8.45Z"
        />
        <path
            className="cls-3"
            d="m1033.39,325.38c3.43,2.11,8.45,3.87,13.73,3.87,7.83,0,12.41-4.14,12.41-10.12,0-5.54-3.17-8.71-11.17-11.79-9.68-3.43-15.66-8.45-15.66-16.81,0-9.24,7.66-16.1,19.18-16.1,6.07,0,10.47,1.41,13.11,2.9l-2.11,6.25c-1.94-1.06-5.9-2.82-11.26-2.82-8.1,0-11.18,4.84-11.18,8.89,0,5.54,3.61,8.27,11.79,11.44,10.03,3.87,15.14,8.71,15.14,17.42,0,9.15-6.78,17.07-20.77,17.07-5.72,0-11.97-1.67-15.13-3.78l1.94-6.42Z"
        />
        <path
            className="cls-3"
            d="m1077.83,275.4h7.66v28.6h.26c1.58-2.29,3.17-4.4,4.66-6.34l18.13-22.26h9.5l-21.47,25.17,23.14,34.14h-9.06l-19.54-29.13-5.63,6.51v22.62h-7.66v-59.31Z"
        />
        <path
            className="cls-3"
            d="m1156.24,306.91h-23.05v21.38h25.7v6.42h-33.35v-59.31h32.03v6.42h-24.38v18.74h23.05v6.33Z"
        />
        <path
            className="cls-3"
            d="m1164.77,330.22l32.91-48.13v-.26h-30.09v-6.42h40.04v4.66l-32.74,47.96v.26h33.17v6.42h-43.29v-4.49Z"
        />
        <path className="cls-3" d="m1225.14,275.4v59.31h-7.66v-59.31h7.66Z" />
        </g>
        <g>
        <path
            className="cls-1"
            d="m1285.59,29.9c1.02-10.86,10.55-13.57,15.84-13.32l22.47.12c8.13,0,15.32,5.34,15.32,13.2l-.09,25.01c0,7.89-7,12.85-15.17,12.85h0l-3.38.15s0-.02,0,0v2.22s-.13,9.75-.13,9.75c0,.04.03.03,0,0l-8.17-8.14-3.7-3.95s.01,0,0,0l-1.65-.03-6.64.22c-3.31.11-6.64-.81-9.19-2.85-4.2-3.36-5.94-8.91-5.76-15.11l.24-20.12Z"
        />
        <path
            className="cls-3"
            d="m1298.44,67.94c-.67,0-1.27-.11-1.8-.31-.61-.09-1.2-.28-1.73-.56-.88-.35-1.57-.94-2.05-1.66-.28-.35-.52-.74-.69-1.16-1.35-1.8-1.36-4.54-.02-6.35-.25-4.27.67-8.33.87-12.67.14-2.94.54-6.09.34-9.02-.02-.36-.07-.73-.13-1.08-.74-.4-1.41-1.05-1.91-2.01-.11-.21-.21-.43-.32-.65.03-2.16.11-4.32.32-6.47.03-.27.07-.52.13-.77-.46-.1-.92-.17-1.37-.24.03-.06.04-.12.07-.17-1.15-.01-2.36.01-3.59.04l-5.32.03s0,0,0,.01l-19.14.11c-8.13,0-15.32,5.34-15.32,13.2l.09,25.01c0,7.89,7,12.85,15.17,12.85l3.38.15v2.22l.13,9.75,8.17-8.14,3.7-3.95,1.65-.03,9.33-.09c5.28-1.09,8.66-4.02,10.51-8.03-.03,0-.05-.02-.08-.03-.13,0-.25.02-.39.02Z"
        />
        <path
            className="cls-2"
            d="m1304.74,39.99h-8.97v-9.12c0-3.3-2.63-5.97-5.87-5.97s-5.87,2.67-5.87,5.97v9.12h-8.97c-3.24,0-5.87,2.67-5.87,5.97s2.63,5.97,5.87,5.97h8.97v9.12c0,3.3,2.63,5.97,5.87,5.97s5.87-2.67,5.87-5.97v-9.12h8.97c3.24,0,5.87-2.67,5.87-5.97s-2.63-5.97-5.87-5.97Z"
        />
        </g>
    </svg>
);
