import { getCookie, deleteCookie } from './Storage'
import { privateSitePrefix } from './Config'

export const redirectToAuth = (
    defaultRedirectUrl = `${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/patients`,
    visioConference = {},
    skeziPage = 'login'
) => {
    const patientCookie = getCookie('patient')
    const teleExpertiseCookie = getCookie('teleExpertise')
    const patient = patientCookie && Object.values(patientCookie).length > 0
      ? patientCookie
      : null;
    const expertise = teleExpertiseCookie && Object.values(teleExpertiseCookie).length > 0
      ? teleExpertiseCookie
      : null;

    const redirectUrl = 
      patient && expertise
        ? `${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/creer-patient?${expertise.type}=${expertise.id}&ref=home&firstname=${patient.firstname}&lastname=${patient.lastname}&birthdate=${patient.birthdate}&sex=${patient.sex}`
        : visioConference.id
          ? `${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/videoconference/${visioConference.type}s/${visioConference.id}`
          : window.location.href.includes(`${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/`)
            ? window.location.href
            : defaultRedirectUrl
    ;

    deleteCookie('patient');
    deleteCookie('teleExpertise');

    window.location.href = `${process.env.REACT_APP_SKEZI_AUTH_FRONT_URL}/${skeziPage}?redirectUrl=${encodeURIComponent(redirectUrl)}`
}
