import React from 'react';
import styled, { css, createGlobalStyle, keyframes } from 'styled-components';

export const mobileOnly = css`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    display: none;
  }
`

export const theme = {
  primary: '#3bc3a8',
  primaryLight: '#C5EDE4',
  darkBlue: '#214152',
  lightBlue: '#b6cefd',
  mediumBlue: '#42708a',
  patientGreen: '#3bc3a8',
  lightGreen: '#C5EDE4',
  darkGreen: '#209981',
  staffPurple: '#9376e5',
  lightPurple: '#F6F4FF',
  expertiseBlue: '#3c75e4',
  cohorteRed: '#d94965',
  grey: '#86aec5',
  grey2: '#bad1de',
  blueGrey: '#758994',
  blueGreyLight: '#90AAB9',
  lightGrey: '#f2f6f9',
  lightGrey2: '#e6eef3',
  lightGrey3: '#f7f9fa',
  lightGrey4: '#E5EDF3',
  mediumGrey: '#D4E1EA',
  greyLight: '#DAE6ED',
  white: '#FFF',
  notification: '#86f9c1',
  black: '#000',
  bg: '#3bc3a8',
  fg: '#FFF',
  boxShadow: {
    thick: '0 0 15px 1px rgba(134, 174, 197, 0.4)',
    thin: '0 2px 5px 1px rgba(134, 174, 197, 0.4)',
    medium: '2px 5px 1px rgba(134, 174, 197, 0.24)',
    right: '3px 0 7px -2px   rgba(134, 174, 197, 0.4)',
  },
  tagColors: {
    green: '#009e7e',
    gray: '#bad1de',
    black: 'black',
    blue: '#2e4166',
    purple: '#7726FC',
    orange: '#FC5826',
    red: '#ff5959',
    yellow: '#f2d600',
  },
  borderLine: {
    light: '#EEE'
  },
  device: {
    mobile: '576px',
    tablet: '768px',
    desktop: '992px',
    largeDesktop: '1200px',
    extraLargeDesktop: '1600px'
  },
};

export const themeStaff = {
  ...theme,
  primary: '#9376e5',
  primaryLight: '#F6F4FF',
};

export const themeCohorte = {
  ...theme,
  primary: '#d94965',
  primaryLight: '#FBEBEE',
};

export const themeExpertise = {
  ...theme,
  primary: '#3c75e4',
  primaryLight: '#EAF0FC',
};

export const themeHome = {
  ...theme,
  primary: '#3bc3a8',
  primaryLight: '#EAF0FC',
};

export const GreenText = styled.span`
  color: ${props => props.theme.patientGreen};
  font-style: normal;
  font-weight: 600;
`

export const BlueText = styled.span`
  color: ${props => props.theme.darkBlue};
  font-style: normal;
`

export const invertTheme = ({ fg, bg }) => ({
  fg: bg,
  bg: fg
});

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    //font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  html, body {
    height: 100%;
    width: 100vw;
    margin: 0;
    scroll-behavior: smooth;
  }
  #root {
    width: 100vw;
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    background: #FFF;
    p {
      font-size: 0.875em;
      line-height: 1.4rem;
    }
    h1,h2,h3,h4,h5,h6,hgroup,ul,ol,dd,p,figure,pre,table,fieldset,hr {
      margin-bottom: 1.4rem;
    }
    h2 {
      padding-top: .5rem;
    }
    h3 {
      padding-top: .3rem;
    }
    i {
      font-style: normal;
    }
  }
  .logo {
    width: 200px;
  }
  #__react-alert__ {
    > div {
      pointer-events: all;
    }
  }
`;

const rotate = keyframes`
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1);
  }
`

export const Spinner = styled.div`
  width: ${props => props.isSmall ? '15px' : '20px'};
  height: ${props => props.isSmall ? '15px' : '20px'};
  position: relative;
  margin: 0 auto;
  & div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  & div:before {
    content: '';
    display: block;
    width: 15%;
    height: 15%;
    background-color: ${props => props.isWhite
    ? 'white'
    : props.isGrey
      ? props.theme.grey2
      : props.theme.grey
  };
    border-radius: 100%;
    animation: ${rotate} 1.2s infinite ease-in-out both;
  }
  & div:nth-child(1) { transform: rotate(30deg); }
  & div:nth-child(2) { transform: rotate(60deg); }
  & div:nth-child(3) { transform: rotate(90deg); }
  & div:nth-child(4) { transform: rotate(120deg); }
  & div:nth-child(5) { transform: rotate(150deg); }
  & div:ild(6) { transform: rotate(180deg); }
  & div:nth-child(7) { transform: rotate(210deg); }
  & div:nth-child(8) { transform: rotate(240deg); }
  & div:nth-child(9) { transform: rotate(270deg); }
  & div:nth-child(10) { transform: rotate(300deg); }
  & div:nth-child(11) { transform: rotate(330deg); }
  & div:nth-child(1):before { animation-delay: -1.1s; }
  & div:nth-child(2):before { animation-delay: -1s; }
  & div:nth-child(3):before { animation-delay: -0.9s; }
  & div:nth-child(4):before { animation-delay: -0.8s; }
  & div:nth-child(5):before { animation-delay: -0.7s; }
  & div:nth-child(6):before { animation-delay: -0.6s; }
  & div:nth-child(7):before { animation-delay: -0.5s; }
  & div:nth-child(8):before { animation-delay: -0.4s; }
  & div:nth-child(9):before { animation-delay: -0.3s; }
  & div:nth-child(10):before { animation-delay: -0.2s; }
  & div:nth-child(11):before { animation-delay: -0.1s; }
`

export const Loader = ({
  ...props
}) =>
  <Spinner {...props}>
    {[...Array(12)].map((e, i) => <div key={`spinner_${i}`} />)}
  </Spinner>

export const TriangleRight = styled.div`
  display: inline-block;
  line-height: 1.125rem;
  width: 0;
  height: 0;
  padding-right: .8rem;
  margin-left: -2rem;
  border-top: .6rem solid transparent;
  border-left: 1.2rem solid ${props => props.theme.primary};
  border-bottom: .6rem solid transparent;
`
