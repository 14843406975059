import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import * as Login from '../State/Login'
import { redirectToAuth } from '../Util/Auth'
import {
  privateSitePrefix,
  publicHostsTeleExpertisePortal,
  publicHostsCohortPatient,
  establishmentsPortalPro,
  authUrl,
  privateUrl,
  serverEnv,
} from '../Util/Config'
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Loader } from './Ui/Global'
import { FlexBox } from '../components/common/Layout'

const { href, hostname, origin, host } = window.location
const subdomain = host.split('.')[0]

const FirewallComponent = ({ children }) => {
  const { trackPageView } = useMatomo()
  useEffect(() => {
    document.title = 'Skemeet';

    if (serverEnv === 'prod') {
      trackPageView()
    }

    // Sécurité ajoutée le temps que les mails des utilisateurs ne contiennent plus des /avis ou /staff
    // Prévoir de le supprimer
    if (window.location.href.includes('avis')) {
      window.location.href =`${window.location.href.replace('avis', 'expertise')}`
    }
    if (window.location.href.includes('staff')) {
      window.location.href =`${window.location.href.replace('staff', 'rcp')}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatch = useDispatch()
  const currentUserLoading = useSelector(Login.selectCurrentUserLoading)
  const failure = useSelector(Login.selectFailure)
  const currentUser = useSelector(Login.selectUser)

  useEffect(() => {
    if (!currentUser) {
      dispatch(Login.fetchCurrentUser({launchIsLoggedIf404: false}))
    }
  }, [])

  useEffect(() => {
    if (failure) {
      redirectToAuth(window.location.href)
    }
  }, [failure])

  return currentUserLoading || failure
    ? <FullSpaceContainer><Loader /></FullSpaceContainer>
    : <>{children}</>
}

export const FullSpaceContainer = styled(props => <FlexBox {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

export const FirewallPublic = ({ children }) => {
  const { trackPageView } = useMatomo()
  useEffect(() => {
    document.title = 'Skemeet';

    if (serverEnv === 'prod') {
      trackPageView()
    }

    // Sécurité ajoutée pour les anciens liens de visio de RCP public
    // Prévoir de le supprimer
    if (window.location.href.includes('videoconference/staff')) {
      window.location.href =`${window.location.href.replace('videoconference/staff', 'videoconference/rcp')}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return href.match('close-tab')
    ? window.close()
    : establishmentsPortalPro[href]
      ? window.location = `${href}portail/${subdomain}`
      : [...publicHostsTeleExpertisePortal, ...publicHostsCohortPatient].includes(hostname) ||
        establishmentsPortalPro[`${origin}/`] ||
        authUrl.includes(hostname)
        ? <>{children}</>
        : window.location = `${privateUrl}${privateSitePrefix}/patients`
}

export default FirewallComponent;
