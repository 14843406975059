import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import * as Dicom from '../../State/Dicom'
import * as IconUI from './Icon'
import * as GlobalUi from './Global'
import * as Icon from '../../components/icons';
import { apiSymfony6Url } from '../../Util/Config'

const DicomPreviewContainer = styled.div`
  box-sizing: border-box;
  cursor: ${props => props.cursor};
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;

  a {
    text-decoration: none;
    color: black;
  }
`

const DicomPreviewElement = styled.div`
  font-size: .8rem;
  box-sizing: border-box;
  margin-right: 1rem;
  display: flex;
  height: 6.25rem;
  background-color: white;
  padding: .5rem;
  flex: 1;
  width: 17rem;
  border-radius: 5px;
  box-shadow: 0 2px 5px 1px rgba(134,174,197,0.24);
  div:nth-child(2) {
    overflow-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    flex: 1;
    padding-left: 1rem;
  }
`

const DicomPreviewContent = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: .8rem;
`

const ActionButtonGroup = styled.div`
  display: flex;
  align-self: flex-end;
  margin-right: .4rem;
  ${IconUI.Icon} {
    font-size: 1.1rem;
    color: ${props => props.theme.grey2};
    margin-left: .4rem;
    &:hover {
      color: ${props => props.theme.mediumBlue};
    }
  };
`

const TrashDocButton = styled(props => <IconUI.IconTrash {...props} />)`
  cursor: pointer;
`

const SaveDocButton = styled(props => <IconUI.IconSave {...props} />)`
  align-self: flex-end;
  cursor: pointer;
`

const DocTitle = styled.div`
  font-weight: 600;
`

export const DicomS3Preview = ({
  onRemoveDicom,
  patientId,
  fileKey,
  description,
  date,
  canEdit,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const waitingViewers = useSelector(Dicom.selectWaitingViewers)
  const errorMessage = useSelector(Dicom.selectedErrorMessage)
  const [actionButtons, setActionButtons] = useState(false)

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [errorMessage])

  return (
    <DicomPreviewContainer
      onMouseEnter={() => setActionButtons(true)}
      onMouseLeave={() => setActionButtons(false)}
      cursor={waitingViewers.includes(fileKey) ? 'wait' : 'pointer'}
    >
      <DicomPreviewElement>
        {waitingViewers.includes(fileKey)
          ? <div className='flex justify-center items-center w-full'><GlobalUi.Loader /></div>
          : <>
            <Icon.DocDicom className='w-[3rem] h-[3rem]' onClick={() => dispatch(Dicom.goToDicomViewer({ patientId, fileKey }))} />
            <DicomPreviewContent onClick={() => dispatch(Dicom.goToDicomViewer({ patientId, fileKey }))} title={`Voir ${description}`}>
              <DocTitle>{date}</DocTitle>
              <div>{description}</div>
            </DicomPreviewContent>
            <ActionButtonGroup>
              {canEdit
                ? <SaveDocButton
                  title={`Télécharger ${description}`}
                  onClick={e => window.open(`${apiSymfony6Url}/patients/${patientId}/download-dicom/${fileKey}`, '_blank')}
                />
                : null
              }
              {canEdit && onRemoveDicom && actionButtons &&
                <TrashDocButton
                  title={`Supprimer ${description}`}
                  onClick={onRemoveDicom}
                />
              }
            </ActionButtonGroup>
          </>
        }
      </DicomPreviewElement>
    </DicomPreviewContainer >
  )
}
