import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as Menu from '../State/Menu'
import FAQContent from './Ui/FAQContent';
// import BeRecalled from './Ui/BeRecalled';
import qs from 'qs'
import * as Ui from './Ui'
import styled from 'styled-components'
import LoadingBox from './Ui/LoadingBox'
import PatientInformationContent from './Ui/PatientInformationContent';

const HelpComponent = (props) => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.login.currentUser)
  const [currentSection, setCurrentSection] = useState(1)
  const params = props.location.search
  const { tab } = qs.parse(params, { ignoreQueryPrefix: true })

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.HELP))
  }, [dispatch])

  useEffect(() => {
    if (tab) {
      setCurrentSection(Number(tab))
    }
  }, [tab])

  return (
    <>
      <LoadingBox />
      {currentUser
        ? <Ui.Layout.PageLayout currentUser={currentUser}>
          <ContactUsContainer>
            <Ui.Layout.PageContentWrapper>
              <Ui.Layout.PanelContainer>
                <Ui.Layout.Panel>
                  <Ui.Layout.TelexpertiseThumbnailContainer>
                    <Ui.Layout.TelexpertiseLogo>
                      <Ui.Icon.IconHelpNoBorder />
                    </Ui.Layout.TelexpertiseLogo>
                    <Ui.BasicElement.H3 noMargin>Aide</Ui.BasicElement.H3>
                  </Ui.Layout.TelexpertiseThumbnailContainer>
                </Ui.Layout.Panel>
              </Ui.Layout.PanelContainer>
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.NavContainer>
                  <nav>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrentSection(1)}
                      on={currentSection === 1}
                    >
                      <Ui.Icon.IconHelp />
                      Questions fréquentes
                    </Ui.Layout.NavLink>
                    {/* <Ui.Layout.NavLink
                      onClick={() => setCurrentSection(3)}
                      on={currentSection === 3}
                    >
                      <Ui.Icon.IconPhoneIncoming />
                      Etre rappelé
                    </Ui.Layout.NavLink> */}
                    <Ui.Layout.NavLink
                      onClick={() => setCurrentSection(4)}
                      on={currentSection === 4}
                    >
                      <Ui.Icon.IconInfos />
                      Information patient
                    </Ui.Layout.NavLink>
                  </nav>
                </Ui.Layout.NavContainer>
                <Ui.Layout.PaddedContent>
                  {(currentSection === 1) &&
                    <>
                      <FAQContent />
                    </>
                  }
                  {/* {(currentSection === 3) &&
                    <>
                      <BeRecalled isLoggedUser={currentUser} />
                    </>
                  } */}
                  {(currentSection === 4) &&
                    <Ui.Layout.BlockContainer>
                      <PatientInformationContent isLoggedIn />
                    </Ui.Layout.BlockContainer>
                  }
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
          </ContactUsContainer>
        </Ui.Layout.PageLayout>

        : <Ui.PortailLayout.PageLayout isHelpPage>
          <ContactUsContainer isPublicSite>
            <Ui.PortailLayout.Content hasPanel noPadding>
              <Ui.Layout.NavContainer isPublicSite>
                <nav>
                  <Ui.Layout.NavLink
                    onClick={() => setCurrentSection(1)}
                    on={currentSection === 1}
                  >
                    <Ui.Icon.IconHelp />
                    Questions fréquentes
                  </Ui.Layout.NavLink>
                  {/* <Ui.Layout.NavLink
                    onClick={() => setCurrentSection(3)}
                    on={currentSection === 3}
                  >
                    <Ui.Icon.IconPhoneIncoming />
                    Etre rappelé
                  </Ui.Layout.NavLink> */}
                </nav>
              </Ui.Layout.NavContainer>
              {(currentSection === 1) &&
                <>
                  <FAQContent isPublicSite />
                </>
              }
              {/* {(currentSection === 3) &&
                <>
                  <BeRecalled />
                </>
              } */}
              {(currentSection === 4) &&
                <Ui.Layout.BlockContainer>
                  <PatientInformationContent />
                </Ui.Layout.BlockContainer>
              }
            </Ui.PortailLayout.Content>
          </ContactUsContainer>
        </Ui.PortailLayout.PageLayout>
      }
    </>
  )
}

const ContactUsContainer = styled.div`
 ${Ui.BasicElement.H1} {
   margin-top: 2rem;
 }
 ${Ui.BasicElement.H2} {
   margin-top: ${props => props.isPublicSite ? '2rem' : '0'};
 }
 ${Ui.Layout.Content} {
   background-color: ${props => props.isPublicSite ? 'white' : props.theme.lightGrey};
 }
 ${Ui.PortailLayout.Content} {
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-left: 0;
    }
 }
 ${Ui.PortailLayout.Figure} {
   @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-left: 2rem;
      height: 600px !important;
    }
  }
 ${Ui.Layout.TelexpertiseThumbnailContainer} {
   margin-top: 1.5rem;
   ${Ui.BasicElement.H3} {
     margin-top: 0;
   }
 }
 ${Ui.Layout.NavLinkItem} {
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-right: .5rem;
  }
 }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    margin: 1rem;
  }
  ${Ui.SearchBar.SearchBarContainer} {
    margin-bottom: 0;
    width: 100%;
  }
  ${Ui.Form.Input} {
    &:read-only {
      border-color: transparent;
      opacity: 1;
    }
  }
  ${Ui.Search.FiltersAndSorting} {
    justify-content: flex-end;
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  ${Ui.Layout.Content} {
    padding-bottom: 2rem;
    ${Ui.Layout.ButtonGroup} {
      margin-top: .7rem;
    }
    ${Ui.Message.Body} {
      ${Ui.BasicElement.P} {
        margin: 0;
      }
    }
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding:0;
    }
  }
  ${Ui.BasicElement.H3} {
    margin-top: 2rem;
    line-height: 2rem;
  }
  ${Ui.Select.SelectContainer} {
    width: 21rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      width: 85%;
    }
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
  ${Ui.Form.Label} {
    ${Ui.SearchBar.SearchBarContainer} {
      margin-top: .6rem;
    }
  }
  ${Ui.Layout.TelexpertiseLogo} {
    background-color: ${props => props.theme.patientGreen};
  }
  a {
    flex: 0;
    text-decoration: none;
  }
  nav {
    margin: ${props => props.isPublicSite ? '0' : '0 2rem'};
  }
  ${Ui.Button.Button} {
    margin-top: 1rem;
  }
  ${Ui.Icon.IconHelpNoBorder} {
    font-weight: 600;
  }
`

export default HelpComponent;
