import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Login from '../State/Login'
import * as Ui from './Ui'
import * as Styled from './Login.styled'
import SEOTags from './SEOTags';
import { redirectToAuth } from '../Util/Auth'
import { privateSitePrefix } from '../Util/Config'

const LoginComponent = () => {
  const loading = useSelector(Login.selectLoading);
  const visioConference = useSelector(Login.selectedVisioconference);

  useEffect(() => {
    redirectToAuth(
      `${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/patients`,
      visioConference
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEOTags
        title="Connectez-vous - Skemeet"
      />
      <Styled.LoginTeleExpertiseContainer>
        <Ui.PortailLayout.Wrapper>
          <Ui.PortailHeader noButtons />
          <Ui.PortailLayout.PortalMain $loading={loading}>
            <Ui.Global.Loader />
          </Ui.PortailLayout.PortalMain>
        </Ui.PortailLayout.Wrapper>
      </Styled.LoginTeleExpertiseContainer>
    </>
  )
}

export default LoginComponent;
