import * as React from "react";

export const CheckboxUncheck = ({
    widthAndHeight = 20,
    color='white',
    ...props
}) => (
    <svg
        width={widthAndHeight}
        height={widthAndHeight}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <mask
            id="path-1-inside-1_4601_11188"
            fill={color}
        >
        <path d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z" />
        </mask>
        <path
            d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
            fill={color}
        />
        <path
            d="M2 1.5H18V-1.5H2V1.5ZM18.5 2V18H21.5V2H18.5ZM18 18.5H2V21.5H18V18.5ZM1.5 18V2H-1.5V18H1.5ZM2 18.5C1.72386 18.5 1.5 18.2761 1.5 18H-1.5C-1.5 19.933 0.0670043 21.5 2 21.5V18.5ZM18.5 18C18.5 18.2761 18.2761 18.5 18 18.5V21.5C19.933 21.5 21.5 19.933 21.5 18H18.5ZM18 1.5C18.2761 1.5 18.5 1.72386 18.5 2H21.5C21.5 0.067003 19.933 -1.5 18 -1.5V1.5ZM2 -1.5C0.067003 -1.5 -1.5 0.0670043 -1.5 2H1.5C1.5 1.72386 1.72386 1.5 2 1.5V-1.5Z"
            fill="#E6EEF3"
            mask="url(#path-1-inside-1_4601_11188)"
        />
    </svg>
);
