import React, { useRef } from 'react'
import * as TeleExpertise from '../../../State/Staff';
import { privateSitePrefix } from '../../../Util/Config'
import * as Ui from '../../../Component/Ui'
import * as Styled from '../Staff.styled'
import Editable from '../../../Util/Editable'
import Invitation from '../../../Component/Invitation';
import * as InvitationState from '../../../State/Invitation';
import { fr } from '../../../Util/i18n';

const ColleaguesTab = ({
    teleExpertiseName,
    current,
    data,
    teleExpertiseId,
    sessionIdParam,
    sessionId,
    dispatch,
    organizations: organizationsResource,
    members,
    search,
    resourceLoading,
    nextSessionDate,
    isEditMode,
    onEnterFieldsBlock,
    onLeaveFieldsBlock,
    currentUser,
    isInviting,
    membersCount,
  }) => {
    const {
      colleagues,
      owner,
      managers,
      participants,
      organizations,
      organizationMembers,
      endDate,
    } = data;
  
    const canEdit = data?.permissions?.value?._canBeModified?.members
    const areAllParticipantsChecked = (participants.value.length > 0) && (membersCount === participants.value.length)
  
    const onAllParticipantsToggle = (areAllParticipantsChecked) => () =>
      dispatch(TeleExpertise.toggleAllParticipants({ areAllParticipantsChecked }))
  
    const onToggleParticipant = (id, toggled) =>
      dispatch(TeleExpertise.toggleParticipant({ id, toggled }))
  
    const teleExpertiseOrganizations = organizationsResource
      .filter(organization => organizations.value.includes(organization.id))
  
    const uniqueColleagues = members => members
      .filter(colleague =>
        [...organizationMembers.value,].includes(colleague.id))
      .filter(colleague =>
        ![...owner.value, ...managers.value, ...colleagues.value].includes(colleague.id))
  
    const membersByOrganization = Object
      .values(organizationsResource
        .filter(organization => organizations.value.includes(Number(organization.id))))
      .reduce((acc, { id, name, members, managers }) => ({
        ...acc,
        [id]: { name, members: [...members, ...managers] }
      }), {})
  
    const MembersByOrganizationList = () => Object
      .entries(membersByOrganization)
      .map(([_, { name = 'Médecins', members }], i) =>
        members.length > 0 &&
        uniqueColleagues(members).length > 0 &&
        <Ui.Layout.BlockContainer key={`organization_${i}`}>
          <Ui.BasicElement.H3>Médecins liés à {name}</Ui.BasicElement.H3>
          <Ui.Layout.ColleagueCardsContainer>
            {uniqueColleagues(members)
              .map((colleague, i) =>
                <Ui.Colleague.Colleague
                  key={`colleague_${i}`}
                  to={`${privateSitePrefix}/medecins/${colleague.id}`}
                  colleague={colleague}
                  endDate={endDate.value}
                  closedSession={sessionIdParam}
                  isParticipant={participants.value.includes(colleague.id)}
                  onToggle={onToggleParticipant}
                  currenUser={currentUser}
                  canEdit={canEdit}
                />
              )}
          </Ui.Layout.ColleagueCardsContainer>
        </Ui.Layout.BlockContainer>
      )
  
    const onToggleOrganization = (id, toggled) =>
      toggled
        ? dispatch(TeleExpertise.addOrganization({ id, teleExpertiseId }))
        : dispatch(TeleExpertise.removeOrganization({ id, teleExpertiseId }))
  
    const onToggleColleague = (id, toggled) => {
      if (toggled) {
        dispatch(TeleExpertise.addColleague({ id }))
      } else {
        dispatch(TeleExpertise.removeColleague({ id }))
      }
      dispatch(TeleExpertise.setQuery({ name: 'colleagues', value: '' }))
      dispatch(TeleExpertise.setEditMode(!isEditMode))
    }
  
    const onSearchOrganization = (organizations, criteria) => organizations
      .filter(({ name }) => RegExp(criteria, 'i').test(name))
      .slice(0, 5)
  
    const searchColleaguesRef = useRef();
    const searchOrganizationsRef = useRef();
  
    return (
      current === 2
        ? isInviting
          ? <Invitation
            type={teleExpertiseName}
            teleExpertiseId={teleExpertiseId}
            teleExpertiseName={teleExpertiseName}
            data={data}
          />
          : <>
            <Ui.Layout.BlockContainer>
              <Editable
                content={
                  teleExpertiseOrganizations.length > 0 &&
                  <Ui.Layout.FlexBox flow="column wrap">
                    <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                    <Ui.Layout.ColleagueCardsContainer>
                      <Ui.Layout.FlexBox flow="row wrap">
                        {teleExpertiseOrganizations.map((organization, i) =>
                          <Ui.Card.OrganizationCard
                            key={i}
                            id={organization.id}
                            name={organization.name}
                            logo={organization.logo}
                            currentUserManagerOrMember={organization.currentUserManagerOrMember}
                          />
                        )}</Ui.Layout.FlexBox>
                    </Ui.Layout.ColleagueCardsContainer>
                  </Ui.Layout.FlexBox>
                }
                description=""
                isEditMode={isEditMode.organizations}
                onClick={onEnterFieldsBlock('organizations', canEdit)}
                onBlur={onLeaveFieldsBlock('organizations', canEdit)}
                altContent={
                  <>
                    <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                    {canEdit ? `Associer une organisation ` : `Aucune organisation n'est associée `}
                    à {fr[teleExpertiseName].thisExpertise}
                  </>
                }
                childRef={searchOrganizationsRef}
                canEdit={canEdit}
              >
                <Ui.Layout.FocusDiv ref={searchOrganizationsRef} tabIndex="166554" >
                  <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                  <Ui.Form.InputContainer>
                    <Ui.SearchBar.SearchAndCheck
                      name="organisations"
                      placeholder="Rechercher une organisation..."
                      query={search.organizations.value}
                      TeleExpertise={TeleExpertise}
                      resultToLabel={organization => organization.name}
                      resultToValue={organization => organization.id}
                      resultSet={organizationsResource}
                      list={organizations.value}
                      loading={resourceLoading.organizations}
                      onQueryChange={(e) => dispatch(TeleExpertise.setQuery({
                        name: 'organizations',
                        value: e.target.value
                      }))}
                      onToggle={onToggleOrganization}
                      onSearch={onSearchOrganization}
                      CheckboxIconComponent={Ui.BasicElement.OrganizationPicture}
                    />
                  </Ui.Form.InputContainer>
                </Ui.Layout.FocusDiv>
              </Editable>
            </Ui.Layout.BlockContainer>
            <Styled.ColleaguesBlockHeader>
              <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
              {canEdit && endDate.value &&
                <Ui.Layout.FlexBox>
                  <Ui.Form.Checkbox
                    checked={areAllParticipantsChecked}
                    onChange={onAllParticipantsToggle(areAllParticipantsChecked)}
                  />
                  <div>Sélectionner tout</div>
                </Ui.Layout.FlexBox>
              }
            </Styled.ColleaguesBlockHeader>
            <Ui.Layout.BlockContainer>
              <Editable
                content={
                  members.length > 0 &&
                  <Ui.Layout.FlexBox flow="column wrap">
                    {canEdit &&
                      <Ui.BasicElement.P>
                        Cliquez pour ajouter ou supprimer un médecin
                      </Ui.BasicElement.P>
                    }
                    <Ui.Layout.ColleagueCardsContainer>
                      {members
                        .filter(colleague =>
                          [...owner.value, ...managers.value, ...colleagues.value].includes(colleague.id))
                        .map((colleague, i) =>
                          <Ui.Colleague.Colleague
                            key={`colleague_${i}`}
                            to={`${privateSitePrefix}/medecins/${colleague.id}`}
                            colleague={colleague}
                            nextMeeting={nextSessionDate}
                            endDate={endDate.value}
                            closedSession={sessionIdParam}
                            sessionId={sessionId}
                            isParticipant={participants.value.includes(colleague.id)}
                            onToggle={onToggleParticipant}
                            currentUser={currentUser}
                            canEdit={canEdit}
                          />
                        )}
                    </Ui.Layout.ColleagueCardsContainer>
                  </Ui.Layout.FlexBox>
                }
                description=""
                isEditMode={isEditMode.colleagues}
                onClick={onEnterFieldsBlock('colleagues', canEdit)}
                onBlur={onLeaveFieldsBlock('colleagues', canEdit)}
                altContent={
                  <>
                    <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                    {canEdit
                      ? 'Cliquez ici pour Ajouter ou supprimer un médecin'
                      : `Aucun médecin n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                    }
                  </>
                }
                childRef={searchColleaguesRef}
                canEdit={canEdit}
              >
                <Ui.Layout.FocusDiv ref={searchColleaguesRef} tabIndex="166554" >
                  <Ui.Form.InputContainer>
                    <Ui.SearchBar.SearchAndCheck
                      name="confreres"
                      query={search.colleagues.value}
                      TeleExpertise={TeleExpertise}
                      teleExpertiseName={teleExpertiseName}
                      checkboxDisabled={colleague => [
                        ...owner.value,
                        ...managers.value,
                        ...organizationMembers.value
                      ].includes(colleague.id)}
                      resultSet={members}
                      list={[
                        ...owner.value,
                        ...managers.value,
                        ...organizationMembers.value,
                        ...colleagues.value
                      ].filter((value, index, self) =>
                        self.indexOf(value) === index)
                      }
                      onToggle={onToggleColleague}
                      loading={resourceLoading.managers}
                      hasInviteColleagueButton
                      onClickExtraButton={e => dispatch(InvitationState.setIsInviting(true))}
                    />
                  </Ui.Form.InputContainer>
                </Ui.Layout.FocusDiv>
              </Editable>
            </Ui.Layout.BlockContainer>
            <MembersByOrganizationList />
          </>
        : null
    )
}

export default ColleaguesTab;
