import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import {apiUrl } from '../../Util/Config'
import * as Ui from '../Ui'
import styled from 'styled-components/macro'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as WorkList from '../../State/WorkList'
import * as Resources from '../../State/Resources'
import WithPermissions from '../WithPermissions'
import DatePicker from '../Ui/DatePicker';
import { dateToString } from '../../Util/Format';
import Editable from '../../Util/Editable'

const FreeWorklist = ({
  data,
  permissions,
  pending,
  updateField,
  teleExpertiseId,
  allPatientsVisible,
  programingMode,
  dispatch,
  workListPatients,
  patients,
  onSeeMorePatients,
  onPatientTab,
  alert,
}) => {
  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }
    if (type === 'worklists') {
      const newWorklistIds = Array.from(data.allIds)
      newWorklistIds.splice(source.index, 1)
      newWorklistIds.splice(destination.index, 0, draggableId.replace('worklist_', ''))

      const newWorklists = newWorklistIds.map(Number)

      dispatch(WorkList.moveWorkList({ teleExpertiseId, newWorklists }))

      return
    }
    if (type === 'patient') {
      if (source.droppableId === destination.droppableId) {
        const workListId = source.droppableId.replace('worklist_', '')
        const worklist = data.byId[workListId]
        const newPatientlistIds = Array.from(worklist.patients.value)
        newPatientlistIds.splice(source.index, 1)
        newPatientlistIds.splice(destination.index, 0, draggableId)

        const newWorklist = {
          ...worklist,
          patients: {
            ...worklist.patients,
            value: newPatientlistIds.map(Number)
          }
        }

        dispatch(WorkList.movePatient({ teleExpertiseId, worklistId: worklist.id, newWorklist, patientId: draggableId }))
      } else {
        const sourceWorkListId = source.droppableId.replace('worklist_', '')
        const destWorkListId = destination.droppableId.replace('worklist_', '')
        const [sourceWorkList, destWorkList] = [data.byId[sourceWorkListId], data.byId[destWorkListId]]
        const newSourcePatientlistIds = Array.from(sourceWorkList.patients.value)
        const newDestPatientlistIds = Array.from(destWorkList.patients.value)

        newSourcePatientlistIds.splice(source.index, 1)
        newDestPatientlistIds.splice(destination.index, 0, draggableId)
        dispatch(WorkList.movePatientBetweenLists({
          teleExpertiseId,
          source: {
            sourceId: sourceWorkListId,
            sourceList: newSourcePatientlistIds.map(Number),
          },
          dest: {
            destId: destWorkListId,
            destList: newDestPatientlistIds.map(Number),
          },
          patientId: draggableId
        }
        ))
      }

      return
    }
  }

  const checkedPatients = data.allIds
    .reduce((acc, id) => [
      ...acc,
      ...data.byId[id].patients.value
    ], [])

  const [isDragging, setIsDragging] = useState(false)

  const onRemoveWorkList = ({ teleExpertiseId, worklistId }) => e => {
    alert.show(`Confirmez-vous la suppression de cette liste de travail ?`, {
      onConfirm: () => dispatch(WorkList.removeWorkList({ teleExpertiseId, worklistId }))
    })
  }

  const onClearWorkList = ({ teleExpertiseId, worklistId }) => e => {
    alert.show(`Confirmez-vous la suppression de tous les patients de cette liste de travail ?`, {
      onConfirm: () => dispatch(WorkList.clearWorkList({ teleExpertiseId, worklistId }))
    })
  }

  const updateWorkList = ({ teleExpertiseId, worklistId }) => e => {
    e.preventDefault()
    dispatch(WorkList.updateWorkList({ teleExpertiseId, worklistId }))
    dispatch(WorkList.setEditWorkList({ worklistId, isEditing: false }))
  }

  const onRemovePatient = ({ teleExpertiseId, worklistId, patientId }) => e =>
    dispatch(WorkList.removePatient({ teleExpertiseId, worklistId, patientId }))

  const onAddPatient = ({ teleExpertiseId, worklistId, patientId }) => {
    dispatch(WorkList.addPatient({ teleExpertiseId, worklistId, patientId }))
  }

  const isPatientChecked = patientId => data.allIds.some(worklistId =>
    data.byId[worklistId].patients.value.includes(patientId)
  )

  const updateIsVisibleField = ({ worklistId, name, value }) => e =>
    dispatch(WorkList.updateIsVisibleField({ worklistId, name, value }))

  const onAddWorkList = e => {
    e.preventDefault()
    dispatch(WorkList.addWorkList({ teleExpertiseId }))
  }

  const onClosePatientList = worklistId => e => {
    dispatch(WorkList.updateField({ worklistId, name: 'isPatientListVisible', value: false }))
  }

  const setEditField = (worklistId, value) =>
    dispatch(WorkList.setEditWorkList({ worklistId, isEditing: value }))

  const onEnterField = (worklistId) => () => {
    return canEdit
      ? setEditField(worklistId, true)
      : null
  }

  const onRestoreValue = (worklistId, value) => e => {
    dispatch(WorkList.restoreInitialValue({ worklistId, value }))
    if (!data.byId[worklistId].title.errors.length > 0)
      dispatch(WorkList.setEditWorkList({ worklistId, isEditing: false }))
  }

  const onLeaveField = worklistId => e => {
    if (data.byId[worklistId].title.isFieldEdit && !e.currentTarget.contains(e.relatedTarget))
      if (data.byId[worklistId].title.value !== data.byId[worklistId].title.initialValue) {
        updateWorkList({ teleExpertiseId, worklistId })(e)
      }
      else {
        dispatch(WorkList.setEditWorkList({ worklistId, isEditing: false }))
      }
  }

  const onKeyPress = worklistId => e => {
    if (e.key === 'Enter' && data.byId[worklistId].title.value !== data.byId[worklistId].title.initialValue) {
      updateWorkList({ teleExpertiseId, worklistId })(e)
    }
    if (e.key === 'Escape') {
      onRestoreValue(worklistId, data.byId[worklistId].title.initialValue)(e)
    }
  }

  const refs = useRef({});

  const canEdit = permissions?._canBeModified?.default || isDragging

  return (
    <>
      {data.allIds.length > 0
        ? <>
          <WithPermissions domains={['default']} permissions={permissions}>
            {data.allIds.length > 1 && programingMode === WorkList.FREE_MODE &&
              <Ui.Layout.BlockContainer>
                <Ui.Layout.FlexBox justify="flex-end">
                  <Ui.Layout.ActionButtonsContainer>
                    <Ui.Button.ActionButton
                      onClick={() => setIsDragging(!isDragging)}
                      $isGreen={isDragging}
                      $isFilled={isDragging}
                      title="Déplacer les sections"
                    >
                      <Ui.Icon.IconMoveSplit />
                    </Ui.Button.ActionButton>
                  </Ui.Layout.ActionButtonsContainer>
                </Ui.Layout.FlexBox>
              </Ui.Layout.BlockContainer>
            }
          </WithPermissions>
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            <WorkListContainer>
              <Droppable
                droppableId="worklists"
                type="worklists"
              >
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {data
                      .allIds
                      .filter(id => id)
                      .map((worklistId, idx) =>
                        !(pending && ~String(data.byId[worklistId].id).indexOf('WL_'))
                          ? <Draggable
                            draggableId={`worklist_${worklistId}`}
                            index={idx}
                            key={worklistId}
                          >
                            {provided => (
                              <WorkListSection
                                ref={provided.innerRef}
                                isDragging={isDragging}
                                {...provided.draggableProps}
                              >
                                <Ui.Layout.BlockContainer>
                                  <Ui.Layout.FlexBox>
                                    <Editable
                                      text={data.byId[worklistId].title.value}
                                      isEditMode={data.byId[worklistId].title.isFieldEdit}
                                      onClick={onEnterField(worklistId)}
                                      onBlur={onLeaveField(worklistId)}
                                      childRef={refs}
                                      altText="Ajouter un titre à cette liste de travail"
                                      canEdit={canEdit}
                                    >
                                      <Ui.Form.TextInput
                                        name={worklistId}
                                        placeholder="Intitulé de la liste de travail (optionnel)"
                                        value={data.byId[worklistId].title.value}
                                        onChange={updateField({ worklistId, name: 'title' })}
                                        onBlur={onLeaveField(worklistId)}
                                        onRestoreValue={onRestoreValue(worklistId, data.byId[worklistId].title.initialValue)}
                                        onKeyPress={onKeyPress(worklistId)}
                                        refs={refs}
                                        tabIndex={idx * 30}
                                      />

                                    </Editable>
                                    <WithPermissions domains={['default']} permissions={permissions}>
                                      <Ui.Layout.ActionButtonsContainer>
                                        {data.byId[worklistId].patients.value.length > 0 &&
                                          <Ui.Button.ActionButton
                                            type="button"
                                            title="Vider la liste de travail"
                                            onClick={onClearWorkList({ teleExpertiseId, worklistId })}
                                          >
                                            <Ui.Icon.IconPatientMinus />
                                          </Ui.Button.ActionButton>
                                        }
                                        <Ui.Button.ActionButton
                                          type="button"
                                          title="Supprimer la liste de travail"
                                          onClick={onRemoveWorkList({ teleExpertiseId, worklistId })}
                                        >
                                          <Ui.Icon.IconTrash />
                                        </Ui.Button.ActionButton>
                                        {data.allIds.length > 1 &&
                                          !pending &&
                                          <MoveIcon
                                            visible={
                                              data.allIds.every(worklistId => !data.byId[worklistId].isPatientListVisible.value) ||
                                              isDragging
                                            }
                                            {...provided.dragHandleProps}
                                          />
                                        }
                                      </Ui.Layout.ActionButtonsContainer>
                                    </WithPermissions>
                                  </Ui.Layout.FlexBox>
                                </Ui.Layout.BlockContainer>
                                <Droppable
                                  droppableId={`worklist_${worklistId}`}
                                  type="patient">
                                  {provided => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {data.byId[worklistId].patients.value.length > 0
                                        ? <Ui.List.List isVisible={!isDragging}>
                                          {data.byId[worklistId].patients.value
                                            .filter(patientId => data.byId[worklistId].patients.value.includes(patientId))
                                            .map(patientId => workListPatients
                                              .reduce((acc, { id, ...rest }) =>
                                                ({ ...acc, [id]: { id, ...rest } }), {}
                                              )[patientId])
                                            .map((patient, idx) =>
                                              <Draggable
                                                draggableId={`${patient.id}`}
                                                index={idx}
                                                key={`${patient.id}`}
                                              >
                                                {provided => (
                                                  <div
                                                    key={`${patient.id}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                  >
                                                    <Ui.List.Item
                                                      patient={patient}
                                                      onRemovePatient={onRemovePatient({ teleExpertiseId, worklistId, patientId: patient.id })}
                                                      isVisible={data.allIds.every(worklistId => !data.byId[worklistId].isPatientListVisible.value)}
                                                      provided={provided}
                                                      pending={pending}
                                                    />
                                                  </div>
                                                )}
                                              </Draggable>
                                            )}
                                        </Ui.List.List>
                                        : null
                                      }
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                                {patients.length > 0
                                  ? <>
                                    <PatientList
                                      visible={data.byId[worklistId].isPatientListVisible.value}
                                    >
                                      {patients
                                        .slice()
                                        .sort((a, b) => checkedPatients.includes(b.id) - checkedPatients.includes(a.id))
                                        .slice(0, allPatientsVisible ? undefined : 10)
                                        .sort((a, b) => checkedPatients.includes(a.id) - checkedPatients.includes(b.id))
                                        .filter(patient => !patient.pendingPatient)
                                        .map((patient, index) =>
                                          <Ui.Form.CheckboxLarge
                                            key={`checkbox_checked_${index}`}
                                            name="patients"
                                            label={patient.fullName}
                                            onChange={e => onAddPatient({ teleExpertiseId, worklistId, patientId: patient.id })}
                                            checked={isPatientChecked(patient.id)}
                                            disabled={isPatientChecked(patient.id)}
                                            title="Ce patient a déjà été ajouté dans une de vos listes de travail"
                                          >
                                            <Ui.BasicElement.PatientIcon
                                              gender={patient.sex}
                                              disabled={isPatientChecked(patient.id)}
                                            />
                                          </Ui.Form.CheckboxLarge>
                                        )}
                                      {!allPatientsVisible &&
                                        patients.length > 10 &&
                                        <Ui.Button.BasicButton
                                          $isFilled
                                          $isGreen
                                          onClick={onSeeMorePatients}
                                        >
                                          Voir plus
                                        </Ui.Button.BasicButton>
                                      }
                                    </PatientList>
                                    {!data.allIds.some(id => data.byId[id].isPatientListVisible.value) &&
                                      !isDragging &&
                                      <Ui.Button.FormButton
                                        onClick={updateIsVisibleField({ worklistId, value: true })}
                                        disabled={pending}
                                      >
                                        {!pending
                                          ? <Ui.Icon.IconPatientSup />
                                          : <Ui.Global.Loader />
                                        }

                                        Ajouter un patient
                                      </Ui.Button.FormButton>
                                    }
                                    {data.byId[worklistId].isPatientListVisible.value &&
                                      <Ui.Button.FormButton
                                        onClick={onClosePatientList(worklistId)}
                                      >
                                        <Ui.Icon.IconPatientSup />
                                        Fermer
                                      </Ui.Button.FormButton>
                                    }
                                  </>
                                  : <WithPermissions domains={['default']} permissions={permissions}>
                                    <Ui.BasicElement.P>
                                      Aucun patient n'est associé à cette expertise. <GreenLink onClick={onPatientTab}>Cliquez ici</GreenLink> pour ajouter des patients.
                                    </Ui.BasicElement.P>
                                  </WithPermissions>
                                }
                              </WorkListSection>
                            )
                            }
                          </Draggable>
                          : <React.Fragment key="loader"><Ui.Global.Loader /></React.Fragment>
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </WorkListContainer>
          </DragDropContext>
        </>
        : <Ui.BasicElement.P>Créez des listes de travail pour organiser vos demandes d’expertises</Ui.BasicElement.P>}
      < WithPermissions domains={['default']} permissions={permissions} >
        <AddSectionContainer>
          <Ui.BasicElement.H3>
            Ajouter une liste de travail
          </Ui.BasicElement.H3>
          <Ui.Button.FormButton
            onClick={onAddWorkList}
            disabled={pending}
          >
            {pending
              ? <Ui.Global.Loader />
              : <Ui.Icon.IconLignes />
            }
            Liste de travail
          </Ui.Button.FormButton>
        </AddSectionContainer>
      </WithPermissions >
    </>
  )
}

const PlanningWorklist = ({
  data,
  dispatch,
  permissions,
  teleExpertiseId,
  allPatientsVisible,
  workListPatients,
  patients,
  onSeeMorePatients,
  onPatientTab,
  scheduledPatients,
  planningDate,
  pending,
}) => {

  const selectedPatientId = useSelector(WorkList.selectSelectedPatientId)
  const selectedPatient = workListPatients.find(({ id }) => String(id) === String(selectedPatientId)) || ''
  const planningHour = useSelector(WorkList.selectPlanningHour)
  const timeSlots = ['07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30']

  const scheduledUsersByHour = scheduledPatients
    .reduce((acc, user) => {
      const hour = user.dateScheduled.split(' ')[1]
      return ({
        ...acc,
        [hour]:
          acc[hour] ?
            [...acc[hour],
              user
            ]
            : [user]
      })
    }, {})

  const planningPatientListVisible = useSelector(WorkList.selectPlanningPatientListVisible)

  const planningPatientList = patients
    .slice()
    .slice(0, allPatientsVisible ? undefined : 10)
    .filter(patient =>
      !scheduledPatients
        .some(scheduledPatient => scheduledPatient.patientId === patient.id
        ))
    .filter(patient => !patient.pendingPatient)

  const isPatientScheduled = patientId => data.allIds.some(worklistId =>
    data.byId[worklistId].patients.value.includes(patientId)
  )

  const onRemovePlanningPatient = ({ patientPlanningId }) => e =>
    dispatch(WorkList.removePlanningPatient({ teleExpertiseId, patientPlanningId }))

  const onSendMail = ({ patientPlanningId }) => e =>
    dispatch(WorkList.sendMail({ teleExpertiseId, patientPlanningId }))

  const onSelectPatient = (e, { selectedPatientId, planningDate }) => {
    e.preventDefault()
    dispatch(WorkList.selectPatient({ selectedPatientId, planningDate }))
  }

  const onEditPatient = ({ selectedPatientId, patientPlanningId }) => e => {
    window.scrollTo(0, 0)
    dispatch(WorkList.removePlanningPatient({ teleExpertiseId, patientPlanningId }))
    dispatch(WorkList.selectPatient({ selectedPatientId }))
  }
  return (
    <>
      <Ui.BasicElement.H2>
        Programmation du {dateToString(planningDate)}
      </Ui.BasicElement.H2>
      {selectedPatientId
        ? <ProgramingLine>
          <div>
            Vous souhaitez programmer <strong>{selectedPatient.fullName}</strong> à
          </div>
          <Ui.Select.Select
            htmlFor="hour"
            isFilled
            items={timeSlots
              .map(item => ({
                label: item,
                value: item,
                selected: item === planningHour,
              }))
            }
            onChange={e => dispatch(WorkList.updateHour(e.target.value))}
          />
          <Ui.Layout.ButtonGroup>
            <Ui.Button.BasicButton
              onClick={e => {
                dispatch(WorkList.schedulePatient({ patient: selectedPatient, planningDate, planningHour, teleExpertiseId }))
              }}
            >
              Valider
            </Ui.Button.BasicButton>
          </Ui.Layout.ButtonGroup>
        </ProgramingLine>
        : planningPatientListVisible && planningPatientList.length > 0
          ? <PatientList visible>
            {planningPatientList
              .filter(patient => !patient.pendingPatient)
              .map((patient, index) =>
                <Ui.Form.CheckboxLarge
                  key={`checkbox_checked_${index}`}
                  name="patients"
                  label={patient.fullName}
                  onChange={e => {
                    onSelectPatient(e, { selectedPatientId: patient.id, planningDate })
                  }}
                >
                  <Ui.BasicElement.PatientIcon
                    gender={patient.sex}
                    disabled={isPatientScheduled(patient.id)}
                  />
                </Ui.Form.CheckboxLarge>
              )
            }
            {!allPatientsVisible &&
              planningPatientList.filter(patient => !patient.pendingPatient).length > 10 &&
              <Ui.Button.BasicButton
                $isFilled
                $isGreen
                onClick={onSeeMorePatients}
              >
                Voir plus
              </Ui.Button.BasicButton>
            }
          </PatientList>
          : planningPatientList.length > 0
            ? <WithPermissions domains={['default']} permissions={permissions}>
              <SelectPatientButton
                onClick={() => dispatch(WorkList.setPlanningPatientListVisible(true))}
              >
                Sélectionnez un patient
              </SelectPatientButton>
            </WithPermissions>
            : <Ui.BasicElement.P>Vous n'avez pas de patient à programmer</Ui.BasicElement.P>
      }
      {patients.length > 0
        ? <>
          {Object.entries(scheduledUsersByHour)
            .filter(([_, patients]) =>
              patients.some(({ dateScheduled }) => dateToString(planningDate) === dateScheduled.split(' ')[0]))
            .sort((a, b) => a[0].split(':').join('') - b[0].split(':').join(''))
            .map(([timeSlot, patients]) =>
              <WorkListPlanningSection key={timeSlot}>
                <Ui.Layout.FlexBox>
                  <Hour>
                    {timeSlot}
                  </Hour>
                  {scheduledPatients.length > 0
                    ? <Ui.List.TimeSlotList>
                      {patients
                        .filter(({ dateScheduled }) =>
                          dateToString(planningDate) === dateScheduled.split(' ')[0])
                        .map(({ patientId, ...resto }) => workListPatients
                          .reduce((acc, { id, ...rest }) =>
                            ({ ...acc, [id]: { id, ...rest, ...resto } }), {}
                          )[patientId])
                        .map(patient =>
                          <div key={`scheduled_patient_${patient.patientPlanningId}`}>
                            <Ui.List.TimeSlotListItem
                              onRemovePatient={onRemovePlanningPatient({
                                patientPlanningId: patient.patientPlanningId
                              })}
                              onEditPatient={onEditPatient({
                                selectedPatientId: patient.id,
                                patientPlanningId: patient.patientPlanningId
                              })}
                              onSendMail={onSendMail({
                                patientPlanningId: patient.patientPlanningId
                              })}
                              patient={patient}
                              canEdit={permissions._canBeModified.default}
                              pending={pending}
                              teleExpertiseId={teleExpertiseId}
                            />
                          </div>
                        )}
                    </Ui.List.TimeSlotList>
                    : <Ui.BasicElement.P>Aucun patient dans cette liste de travail</Ui.BasicElement.P>
                  }
                </Ui.Layout.FlexBox>
              </WorkListPlanningSection>
            )}
        </>
        : <WithPermissions domains={['default']} permissions={permissions}>
          <Ui.BasicElement.P>
            Aucun patient n'est associé à cette expertise. <GreenLink onClick={onPatientTab}>Cliquez ici</GreenLink> pour ajouter des patients.
          </Ui.BasicElement.P>
        </WithPermissions>
      }
    </>
  )
}

const WorkListComponent = ({
  patients,
  data,
  scheduledPatients,
  teleExpertiseId,
  permissions = { _canBe: { edited: false } },
  onPatientTab,
  selectedMode,
}) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const allPatientsVisible = useSelector(WorkList.selectAllPatientsVisible)
  const pending = useSelector(WorkList.selectPending)
  const mailSent = useSelector(WorkList.selectMailSent)
  const { [Resources.RESOURCE_PATIENTS]: workListPatients } = useSelector(Resources.selectResources)
  const planningDate = useSelector(WorkList.selectPlanningDate)

  const updateField = ({ worklistId, name, value }) => e =>
    dispatch(WorkList.updateField({ worklistId, name, value: value || e.target.value }))

  const onSeeMorePatients = () =>
    dispatch(WorkList.setAllPatientsVisible({ worklistType: programingMode, isVisible: true }))

  const [programingMode, setProgramingMode] = useState(selectedMode || localStorage.getItem('programingMode') || WorkList.PLANNING_MODE);

  useEffect(() => {
    localStorage.setItem('programingMode', programingMode);
  }, [programingMode]);

  useEffect(() => {
    if (mailSent) {
      alert.info('Le médecin responsable a été notifié de la programmation par mail', { timeout: 5000 })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailSent]);

  const onChangeDate = date => {
    dispatch(WorkList.updatePlanningDate(date))
  }

  const onPrintList = (planningDate) => {
    window.open(`${apiUrl}/opinions/${teleExpertiseId}/generatePatientPlannedPDF/Planning_${dateToString(planningDate, {separator: '-'})}`)
  }

  return (
    <>
      <Ui.Layout.BlockContainer>
        <ModeChoice alignItems="center">
          <div>
            Mode de programmation :
          </div>
          <Ui.Layout.FlexBox>
            {[WorkList.FREE_MODE, WorkList.PLANNING_MODE]
              .map((mode, idx) =>
                <Choice key={idx}>
                  <Ui.Form.Radio
                    name="programing_mode"
                    defaultChecked={mode === programingMode}
                    value={mode}
                    label={mode}
                    colorized
                    onClick={() => setProgramingMode(mode)}
                  />{mode}
                </Choice>
              )}
          </Ui.Layout.FlexBox>
          <WithPermissions domains={['default']} permissions={permissions}>
            {programingMode === WorkList.PLANNING_MODE &&
              <Ui.BasicElement.WhiteBox>
                <DatePicker
                  value={planningDate}
                  clearIcon={null}
                  calendarIcon="&#xe907;"
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  locale="fr-FR"
                  showLeadingZeros
                  onChange={onChangeDate}
                />
              </Ui.BasicElement.WhiteBox>
            }
          </WithPermissions>
          {programingMode === WorkList.PLANNING_MODE && scheduledPatients.length > 0 &&
            <PrintButton>
              <Ui.Button.ActionButton
                type="button"
                onClick={() => onPrintList(planningDate)}
              >
                <Ui.Icon.IconPrint />
              </Ui.Button.ActionButton>
            </PrintButton>
          }
        </ModeChoice>
      </Ui.Layout.BlockContainer>
      {programingMode === WorkList.FREE_MODE
        ? <FreeWorklist
          data={data}
          permissions={permissions}
          pending={pending}
          updateField={updateField}
          teleExpertiseId={teleExpertiseId}
          allPatientsVisible={allPatientsVisible[WorkList.FREE_MODE]}
          programingMode={programingMode}
          dispatch={dispatch}
          workListPatients={workListPatients}
          patients={patients}
          onSeeMorePatients={onSeeMorePatients}
          onPatientTab={onPatientTab}
          alert={alert}
        />
        : <PlanningWorklist
          data={data}
          permissions={permissions}
          pending={pending}
          updateField={updateField}
          teleExpertiseId={teleExpertiseId}
          allPatientsVisible={allPatientsVisible[WorkList.PLANNING_MODE]}
          programingMode={programingMode}
          dispatch={dispatch}
          workListPatients={workListPatients}
          patients={patients}
          onSeeMorePatients={onSeeMorePatients}
          onPatientTab={onPatientTab}
          scheduledPatients={scheduledPatients}
          planningDate={planningDate}
          onChangeDate={onChangeDate}
        />
      }
    </>
  )
}

const ProgramingLine = styled(props => <Ui.Layout.FlexBox {...props} />)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.4rem;
  & div:first-of-type {
    font-size: .875rem;
  }
  ${Ui.Select.SelectContainer} {
    margin-left: .5rem;
    margin-right: 1.5rem;
    width: 8rem;
  }
  ${Ui.Select.SelectList} {
    height: 10rem;
    overflow: scroll;
  }
  ${Ui.Button.Button} {
    max-height: 2.85rem;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
    align-items: center;
  }
  ${Ui.Layout.ButtonGroup} {
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      margin-top: 1rem;
      flex-direction: row;
      a:not(:last-child), button:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
`

const GreenLink = styled.span`
  color: ${props => props.theme.patientGreen};
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
`

const PatientList = styled(props => <Ui.Layout.RadioGroupContainer {...props} />)`
  display: ${props => props.visible ? 'flex' : 'none'};
  margin-bottom: 1rem;
  ${Ui.Button.Button} {
    height: 3rem;
    align-self: flex-end;
    margin-bottom: .8rem;
  }
  ${Ui.Form.CheckboxAddTrashInput} {
    &:checked + i {
      display: none;
    }
  }
`

const MoveIcon = styled(props => <Ui.Icon.MoveIcon {...props} />)`
  font-size: 1.125rem;
  color: ${props => props.theme.grey};
  margin-left: .3rem;
  display: ${props => props.visible ? 'block' : 'none'};
  &:hover {
    cursor: grab;
  }
`

const WorkListSection = styled(props => <Ui.Layout.Section {...props} />)`
  margin-bottom: 1.4rem;
  background: white;
  padding: 1.8rem;
  box-shadow: ${props => props.theme.boxShadow.medium};
  border-radius: 5px;
  box-sizing: border-box;
  height: ${props => props.isDragging ? '7.5rem' : 'auto'};
  overflow: ${props => props.isDragging ? 'hidden' : 'auto'};
  ${Ui.Form.InputContainer} {
   width: 100%;
  }
  ${Ui.Form.InputContainer} {
    margin-top: 0 !important;
  }
`

const Hour = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`

const WorkListPlanningSection = styled(props => <WorkListSection {...props} />)`
  padding: .5rem 1.8rem;
  ${Ui.Layout.ActionButtonsContainer} {
    margin-left: 1.5rem;
  }
  ${Ui.Layout.ActionButtonsContainer} {
    ${Ui.BasicElement.P} {
      margin: 1rem 1rem;
    }
  }
}
`

const WorkListContainer = styled.div`
  ${Ui.Layout.ActionButtonsContainer} {
    margin-left: 1.5rem;
    align-items: center;
  }
  ${Ui.Button.FormButton} {
    margin-right: 1rem;
  }
`

const AddSectionContainer = styled.div`
  margin-bottom: 2rem;
  ${Ui.BasicElement.H3} {
    margin-bottom: 1rem;
  }
`

const Choice = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.darkBlue};
  font-size: .875rem;
  margin-right: 1.1rem;
  label {
    margin-right: .4rem;
  }
  label > span {
    margin-left: .4rem;
    font-weight: normal;
  };
`

const ModeChoice = styled(props => <Ui.Layout.FlexBox {...props} />)`
  flex-direction: column;
  align-items: flex-start;
  min-height: 3rem;
  > div {
    margin-bottom: .8rem;
  }
  ${Ui.Layout.FlexBox} {
    width: auto;
  }
  & > div:first-of-type {
    font-size: .875rem;
    font-weight: 700;
    margin-right: 1rem;
  }
  ${Ui.Layout.RadioGroupContainer} {
    flex: initial;
    margin-left: 1.1rem;
  }
  ${Ui.BasicElement.WhiteBox} {
    > div {
      min-width: 12rem;
      padding: 0 1rem;
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    flex-direction: row;
    align-items: center;
    > div {
      margin-bottom: 0;
    }
  }
`

const PrintButton = styled.div`
  position: absolute;
  right: 1rem;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    position: static;
    margin-left: 1rem;
  }
`

const SelectPatientButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-bottom: 1.4rem;
`

export default WorkListComponent;
