import styled from 'styled-components/macro'
import * as Ui from '../Component/Ui'
import ClickOutside from '../Util/ClickOutside'

export const ColleagueContainer = styled.div`
  ${Ui.Card.BottomLink} {
    color: white;
  }
  ${Ui.BasicElement.H1} {
    color: black;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  ${Ui.Layout.Content} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      & > :not(:first-child) {
        margin-top: 5rem;
      }
    }
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      padding: 0;
    }
    ${Ui.Form.TextArea} {
      margin-bottom: 1rem;
    }
  }
`

export const SubTitlesContainer = styled.div`
  margin-top: .3rem;
  p {
    margin: 0;
  }
`

export const ProfilePicture = styled.div`
  background-image: url(${props => props.picture});
  background-size: cover;
  width: 8rem;
  height: 8rem;
`

export const CardsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 32% 0;
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
  ${Ui.Card.Card} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex: auto;
    margin-right: 0;
  }
  ${Ui.Card.BottomLink} {
    color: white;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-wrap: wrap;
    flex-direction: column;
  }
  ${Ui.Card.CardContainer} {
    padding: 0;
  }
  ${Ui.Card.Card} {
    margin-bottom: 1rem;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-right: 1rem;
    }
  }
`

export const TeleExpertiseCardsContainer = styled(props => <CardsContainer {...props} />)`
  flex-wrap: nowrap;
  ${Ui.Card.Card} {
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      margin-right: 0;
    }
  }
`

export const CardsSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: .8rem;
  font-size: 0.875rem;
  & p {
    color: ${props => props.theme.darkBlue};
    margin: 0;
    font-weight: bold;
  }
  & p:first-child {
    color: ${props => props.theme.grey};
    line-height: 1.3rem;
    font-weight: normal;
  }
`

export const CardsSectionIcon = styled(props => <Ui.Icon.ButtonIcon {...props} />)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.lightGrey};
  border: 0;
  border-radius: 50px;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: .5rem;
  flex-shrink: 0;
  color: white;
  cursor: auto;
  + span {
    padding-left: 1rem;
  }
  font-size: 1.4rem;
  background: ${props => props.expertise === 'staffs'
    ? props.theme.staffPurple
    : props.expertise === 'opinions'
      ? props.theme.expertiseBlue
      : props.theme.cohorteRed
  };
  &:hover {
    color: white;
  }
`

export const CardsSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  margin-bottom: 1.2rem;
`

export const CardsSectionBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${Ui.Card.CardContainer} {
    margin-top: 0;
    width: 100%;
    padding: 0;
  };
  ${Ui.Card.Card} {
    margin-bottom: 1rem;
    box-shadow: 0 2px 5px 1px rgba(134, 174, 197, 0.24);
  };
`

export const CardsSearchButton = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color : #E2EBF0;
  border: 1px dashed ${props => props.theme.grey};
  height: 2.5rem;
  font-size: 0.75rem;
  align-items: center;
  padding: 0 1rem;
  color: ${props => props.theme.grey};
  justify-content: center;
  flex: initial;
  margin-bottom: 1rem;
  font-weight: bold;
  display: ${props => props.visible ? 'flex' : 'none'};
  cursor: pointer;
  span {
    flex: 1;
  };
  &:hover {
    color: ${props => props.theme.patientGreen};
    border-color: ${props => props.theme.patientGreen};
  }
`

export const SearchBarContainer = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  position: relative;
  ${Ui.SearchBar.SearchBarContainer} {
    z-index: 4;
    margin-bottom: 0;
  };
  ${Ui.Form.DropdownItemLabel} {
    max-width: none;
    white-space: normal;
    overflow: auto;
  };
  ${Ui.Form.DropdownItemSubLabel} {
    white-space: normal;
  };
  p {
      text-align: center;
    }
`

export const SearchBar = ({
  onClickOutside = () => null,
  children,
  ...props
}) =>
  <ClickOutside
    Component={SearchBarContainer}
    onClickOutside={onClickOutside}
    {...props}
  >
    <>
      {children}
    </>
  </ClickOutside>

export const ToggleSearchBar = styled.div`
  height: 3.8rem;
`

export const SummarySection = styled(props => <Ui.Layout.Section {...props} />)`
  ${Ui.Layout.Row} {
    ${Ui.BasicElement.P} {
      margin-bottom: 0;
    }
  }
`
