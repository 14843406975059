import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import styled from "styled-components";
import { privateSitePrefix, maxPatientsNumber } from '../../Util/Config'
import * as Profile from '../../State/Profile';


export const WarningMessageContainer = styled.div`
  font-size: .875rem;
  margin-top: 3.8rem !important;
  margin-bottom: 1rem;
  background-color: ${props => props.theme.darkBlue};
  box-sizing: border-box;
  display: block;
  text-align: center;
  padding: 1.1rem;
  color: white;
  line-height: 1.2rem;
  a {
    color: white;
    margin-left: .3rem;
  }
`

const WarningBannerComponent = ({
  data,
  currentUser,
  teleExpertiseId,
  teleExpertiseType,
  slice = {},
}) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const alert = useAlert();
  const [warning, setWarning] = useState({
    freeTeleExpertiseAlert: '',
    subscriptionMessage: ''
  })
  const successUnlockTeleExpertise = useSelector(Profile.selectSuccessUnlockTeleExpertise)

  const upgradeTeleExpertise = e => {
    e.preventDefault()

    if (
      currentUser.activeSubscription &&
      currentUser.activeSubscription.unlockTeleExpertiseRemaining > 0
    ) {
      dispatch(Profile.toggle({
        value: {
          id: teleExpertiseId,
          name: data.name.value,
          ownerFullname: data.ownerFullname.value,
          ownerTitle: data.ownerTitle.value,
          type: teleExpertiseType
        },
        toggled: true,
        name: 'subscriptionExpertises',
        slice
      }))
    } else {
      if (
        currentUser.activeSubscription &&
        currentUser.activeSubscription.unlockTeleExpertiseRemaining === 0
      ) {
        alert.show('Vous abonnement ne vous permet pas de mettre à niveau cette expertise.')
      }

      history.push(`${privateSitePrefix}/profil?tab=5`)
    }
  }

  useEffect(() => {
    if (!successUnlockTeleExpertise) return

    const singularType = {
      staffs: 'staff',
      cohorts: 'cohorte',
      opinions: 'expertise'
    }

    alert.show(`Votre ${singularType[teleExpertiseType]} a bien été débloqué et ajouté à votre abonnement.`)

    dispatch(slice.updateData({
      name: 'patientLimitReached',
      value: false,
    }))

    dispatch(slice.updateData({
      name: 'patientLimitLocked',
      value: false,
    }))

    dispatch(slice.updateData({
      name: 'patientsLocked',
      value: [],
    }))

    dispatch(Profile.setSuccessUnlockTeleExpertise({
      successUnlockTeleExpertise: false
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUnlockTeleExpertise])

  useEffect(() => {
    if (
      data.currentUserAdmin.value &&
      data.patientLimitLocked.value
    ) {
      const addActionNumber = data.numberAddPatientAction.value
      if (addActionNumber >= 8) {
        setWarning(state => ({
          ...state,
          freeTeleExpertiseAlert: addActionNumber >= maxPatientsNumber
            ? `Vous avez déjà ajouté ${maxPatientsNumber} patients à cette téléexpertise gratuite. `
            : `Cette téléexpertise gratuite atteindra bientôt la limite de ${maxPatientsNumber} patients ajoutés.`
        }))
        setWarning(state => ({
          ...state,
          subscriptionMessage: currentUser.activeSubscription
            ? ' Débloquez-la avec Skemeet PRO'
            : ' Passez à Skemeet PRO'
        }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.numberAddPatientAction.value])

  return data.currentUserAdmin.value &&
    data.patientLimitLocked.value &&
    data.numberAddPatientAction.value >= 8
    ? <WarningMessageContainer>
      {warning.freeTeleExpertiseAlert} <Link onClick={upgradeTeleExpertise}>{warning.subscriptionMessage}</Link>
    </WarningMessageContainer>
    : null
}

export default WarningBannerComponent;
