import * as React from "react";

export const DocPpt = ({
  ...props
}) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{
        enableBackground: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <path
        style={{
            fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
        />
        <path
        style={{
            fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
        />
        <polygon
        style={{
            fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
        />
        <path
        style={{
            fill: "#F15642",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
        />
        <g>
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M105.456,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.568,8.816-8.176,8.816c-4.224,0-8.688-3.184-8.688-8.816v-72.032 H105.456z M122.336,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H122.336z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M191.616,303.152c0-4.224,3.328-8.832,8.704-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816V303.152z  M208.496,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H208.496z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M301.68,311.472h-22.368c-11.136,0-11.136-16.368,0-16.368h60.496c11.392,0,11.392,16.368,0,16.368 h-21.232v64.608c0,11.12-16.896,11.392-16.896,0V311.472z"
        />
        </g>
        <path
        style={{
            fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
        />
    </svg>
);
