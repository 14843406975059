import * as React from "react";

export const DocTiff = ({
  ...props
}) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 384 384"
        style={{
        enableBackground: "new 0 0 384 384",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1={-86.1031}
        y1={548.9446}
        x2={-33.257}
        y2={496.0986}
        gradientTransform="matrix(8 0 0 -8 798.1568 4468.8887)"
        >
        <stop
            offset={0}
            style={{
            stopColor: "#EFEEEE",
            }}
        />
        <stop
            offset={1}
            style={{
            stopColor: "#DEDEDD",
            }}
        />
        </linearGradient>
        <polygon
        style={{
            fill: "url(#SVGID_1_)",
        }}
        points="64,0 64,384 288,384 384,288 384,0 "
        />
        <polygon
        style={{
            fill: "#ABABAB",
        }}
        points="288,288 288,384 384,288 "
        />
        <polygon
        style={{
            fill: "#DEDEDD",
        }}
        points="192,384 288,384 288,288 "
        />
        <path
        style={{
            fill: "#2B5529",
        }}
        d="M0,96v112h256V96L0,96L0,96z"
        />
        <g>
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M76.928,129.184H61.952V186.4H48.128v-57.216H33.152v-11.328h43.776L76.928,129.184L76.928,129.184z "
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M89.312,117.856h13.824V186.4H89.312V117.856z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M157.472,117.856v11.328h-24.464v16.128h23.024v11.328h-23.024v29.76h-13.84v-68.544H157.472z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M207.68,117.856v11.328H183.2v16.128h23.04v11.328H183.2v29.76h-13.824v-68.544H207.68z"
        />
        </g>
    </svg>
);
