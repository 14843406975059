import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as CustomField from '../../State/CustomField';
import { useAlert } from 'react-alert'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as FormUi from './Form'
import * as BasicElementUi from './BasicElement'
import * as EditButtonsUi from './EditButtons'
import * as LayoutUi from '../../components/common/Layout'
import * as IconUi from './Icon'
import * as ButtonUi from './Button'
import styled from 'styled-components/macro'
import { isUniqId } from '../../Util/Format'
import Toggle from '../Ui/Toggle'

const CustomFieldBuilderComponent = ({
  form: {
    name,
  },
  errorMessage,
  isBuilderFieldEdit,
  isBuildMode = false,
  teleExpertiseId,
  teleExpertiseType,
}) => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const form = useSelector(CustomField.selectFormFields)
  const sections = useSelector(CustomField.selectSections)
  const fields = useSelector(CustomField.selectFields)
  const choices = useSelector(CustomField.selectChoices)
  const locked = useSelector(CustomField.selectLocked)
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    if (type === 'section') {
      const formField = form[0]
      const newSectionIds = Array.from(formField.sections.allIds)
      newSectionIds.splice(source.index, 1)
      newSectionIds.splice(destination.index, 0, draggableId.replace('section_', ''))

      const newCustomFields = {
        ...formField,
        sections: newSectionIds
      }

      dispatch(CustomField.moveSection({ newCustomFields, teleExpertiseType, teleExpertiseId }))
      return
    }

    if (type === 'field') {
      const section = sections.byId[source.droppableId.replace('section_', '')]
      const newFieldIds = Array.from(section.fields)
      newFieldIds.splice(source.index, 1)
      newFieldIds.splice(destination.index, 0, draggableId)

      const newSection = {
        ...section,
        fields: newFieldIds
      }

      dispatch(CustomField.moveField({ newSection, teleExpertiseType, teleExpertiseId }))
      return

    }

    if (type === 'choice') {
      const customField = fields.byId[source.droppableId]
      const newChoiceIds = Array.from(customField.choices)
      newChoiceIds.splice(source.index, 1)
      newChoiceIds.splice(destination.index, 0, draggableId)

      const newField = {
        ...customField,
        choices: newChoiceIds
      }

      dispatch(CustomField.moveChoice({ newField, teleExpertiseType, teleExpertiseId }))
    }
  }

  const addSection = e => {
    e.preventDefault()
    dispatch(CustomField.addSection({ teleExpertiseType, teleExpertiseId }))
  }

  const removeSection = (sectionId, teleExpertiseType, teleExpertiseId) => {
    for (const fieldId of sections.byId[sectionId].fields) {
      dispatch(CustomField.removeField({
        sectionId,
        fieldId,
        teleExpertiseType,
        teleExpertiseId
      }))
    }
    dispatch(CustomField.removeSection({
      sectionId,
      teleExpertiseType,
      teleExpertiseId
    }))
  }

  const addField = (sectionId, typeOfField) => e => {
    e.preventDefault()
    dispatch(CustomField.addField({ sectionId, typeOfField }))
  }

  const updateBuilderFieldLabel = (id, type) => e => {
    dispatch(CustomField.updateBuilderFieldLabel({ id, type, value: e.target.value }))
  }

  const onSubmitField = ({ type, fieldId, sectionId, typeOfField, noClose }) => e => {
    dispatch(CustomField.submitField({ type, fieldId, sectionId, teleExpertiseType, teleExpertiseId, typeOfField, noClose }))
  }
  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <TitleContainer>
          {isBuildMode
            ? <BasicElementUi.H2>Informations complémentaires{name && ` - ${name}`}</BasicElementUi.H2>
            : <BasicElementUi.P>
              Ajouter des champs personnalisés pour demander des
              informations complémentaires à vos correspondants ou
              créer des auto-questionnaires à remplir par les patients.
            </BasicElementUi.P>
          }
          {sections.allIds.length > 1 &&
            <LayoutUi.ActionButtonsContainer>
              <ButtonUi.ActionButton
                onClick={() => setIsDragging(!isDragging)}
                $isGreen={isDragging}
                $isFilled={isDragging}
                title="Déplacer les sections"
              >
                <IconUi.IconMoveSplit />
              </ButtonUi.ActionButton>
            </LayoutUi.ActionButtonsContainer>
          }
        </TitleContainer>
        <Droppable
          droppableId={`${form[0].id}`}
          type="section"
        >
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sections.allIds.length > 0 &&
                sections.allIds.map((sectionId, idx) =>
                  <Draggable
                    draggableId={`section_${sectionId}`}
                    index={idx}
                    key={`${sectionId}`}
                  >
                    {provided => (
                      <CustomFieldSection
                        key={`${sectionId}`}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        isDragging={isDragging}
                      >
                        <LayoutUi.FlexBox>
                          <FormUi.TextInput
                            placeholder="Intitulé de la section (optionnel)"
                            onInputBlur={onSubmitField({
                              type: 'sections',
                              typeOfField: 'sections',
                              fieldId: sectionId,
                            })}
                            value={sections.byId[sectionId].label}
                            onChange={updateBuilderFieldLabel(sectionId, 'sections')}
                            readOnly={isDragging}
                            noSubmit
                          />
                          <LayoutUi.ActionButtonsContainer>
                            {/* {isBuilderFieldEdit[sectionId] && */}
                            <ButtonUi.ActionButton
                              type="button"
                              onClick={(e) =>
                                !isUniqId(sectionId) || (isUniqId && sections.byId[sectionId].fields.length > 0)
                                  ? alert.show('Attention, la suppression de la section effacera tous les champs qu\'elle contient. Confirmez-vous la suppression de cette section ?', {
                                    onConfirm: () => removeSection(sectionId, teleExpertiseType, teleExpertiseId)
                                  })
                                  : dispatch(CustomField.removeSection({
                                    sectionId,
                                    teleExpertiseType,
                                    teleExpertiseId
                                  }))
                              }
                            >
                              <IconUi.IconTrash />
                            </ButtonUi.ActionButton>
                            <IconUi.MoveIcon
                              {...provided.dragHandleProps}
                              hidden={isBuildMode && (isUniqId(sectionId) || !isDragging)}
                            />
                          </LayoutUi.ActionButtonsContainer>
                        </LayoutUi.FlexBox>
                        {teleExpertiseType === 'cohorts' &&
                          <SectionType alignItems="center" isDragging={isDragging}>
                            <IconUi.IconDoctorCircle
                              disabled={sections.byId[sectionId].isPatientSection}
                            />
                            <Toggle
                              id={`header_image_${sectionId}`}
                              name={`header_image_${sectionId}`}
                              checked={sections.byId[sectionId].isPatientSection}
                              isOnOn
                              onChange={e => {
                                dispatch(CustomField.setIsPatientSection({
                                  sectionId,
                                  value: !sections.byId[sectionId].isPatientSection
                                }))
                                dispatch(CustomField.submitField({
                                  type: 'sections',
                                  fieldId: sectionId,
                                  teleExpertiseType,
                                  teleExpertiseId,
                                  typeOfField: 'sections',
                                }))
                              }}
                            />
                            <IconUi.IconPatientCircle
                              disabled={!sections.byId[sectionId].isPatientSection}
                            />
                            <BasicElementUi.ContentBlock>
                              Cette section est à remplir par le {sections.byId[sectionId].isPatientSection ? 'patient' : 'médecin'}
                            </BasicElementUi.ContentBlock>
                          </SectionType>
                        }
                        {sections.byId[sectionId].fields.length > 0 &&
                          <SectionContainer>
                            <BasicElementUi.H3>
                              Champs personnalisés :
                            </BasicElementUi.H3>
                            <Droppable
                              droppableId={`section_${sectionId}`}
                              type="field">
                              {provided => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {sections.byId[sectionId].fields.map((fieldId, idx) =>
                                    <Draggable
                                      draggableId={`${fieldId}`}
                                      index={idx}
                                      key={`${fieldId}`}
                                    >
                                      {provided => (
                                        <LayoutUi.CustomFieldContainer
                                          key={`${sectionId}_${fieldId}`}
                                          {...provided.draggableProps}
                                          ref={provided.innerRef}
                                        >
                                          <header>
                                            <div>
                                              <IconRoundThemed default>
                                                {fields.byId[fieldId].typeOfField === 'text'
                                                  ? <IconUi.IconText />
                                                  : fields.byId[fieldId].typeOfField === 'multicheckbox'
                                                    ? <IconUi.IconCheck />
                                                    : fields.byId[fieldId].typeOfField === 'selectbox'
                                                      ? <IconUi.IconChoixUnique />
                                                      : <IconUi.IconJoin />
                                                }
                                              </IconRoundThemed>
                                              {isBuilderFieldEdit[fieldId]
                                                ? <FormUi.TextAreaInputResizable
                                                  autoFocus={isBuildMode && fields.byId[fieldId].choices.length === 0}
                                                  placeholder="Intitulé du champ"
                                                  value={fields.byId[fieldId].label}
                                                  onChange={updateBuilderFieldLabel(fieldId, 'fields')}
                                                  onBlur={onSubmitField({
                                                    type: 'fields',
                                                    fieldId,
                                                    sectionId,
                                                    typeOfField: fields.byId[fieldId].typeOfField
                                                  })}
                                                  error={fields.byId[fieldId].error}
                                                  noSubmit
                                                />

                                                : <BasicElementUi.H2>
                                                  {`${fields.byId[fieldId].label}${fields.byId[fieldId].mandatory ? ' *' : ''}`}
                                                </BasicElementUi.H2>
                                              }
                                            </div>
                                            <LayoutUi.ActionButtonsContainer>
                                              {(!isBuilderFieldEdit[fieldId] || !isBuildMode) &&
                                                <ButtonUi.ActionButton
                                                  type="button"
                                                  title="Champ obligatoire"
                                                  $isGreen={fields.byId[fieldId].mandatory}
                                                  onClick={e => {
                                                    dispatch(CustomField.setMandatory({
                                                      fieldId,
                                                      value: !fields.byId[fieldId].mandatory
                                                    }))
                                                    dispatch(CustomField.submitField({
                                                      type: 'fields',
                                                      fieldId,
                                                      sectionId,
                                                      teleExpertiseType,
                                                      teleExpertiseId,
                                                      typeOfField: fields.byId[fieldId].typeOfField,
                                                    }))
                                                  }
                                                  }
                                                >
                                                  <IconUi.IconMandatory />
                                                </ButtonUi.ActionButton>
                                              }
                                              {!isBuilderFieldEdit[fieldId] && isBuildMode && !isUniqId(fieldId) &&
                                                <ButtonUi.ActionButton
                                                  type="button"
                                                  title="Supprimer ce champ"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    alert.show('Confirmez-vous la suppression de ce champ ?', {
                                                      onConfirm: () => dispatch(CustomField.removeField({
                                                        sectionId,
                                                        fieldId,
                                                        teleExpertiseType,
                                                        teleExpertiseId
                                                      }))
                                                    })
                                                  }}
                                                >
                                                  <IconUi.IconTrash />
                                                </ButtonUi.ActionButton>
                                              }
                                              {isBuilderFieldEdit[fieldId] && isUniqId(fieldId) &&
                                                <ButtonUi.ActionButton
                                                  type="button"
                                                  title="Annuler la création de ce champ"
                                                  onMouseDown={(e) =>
                                                    dispatch(CustomField.removeField({
                                                      sectionId,
                                                      fieldId,
                                                      teleExpertiseType,
                                                      teleExpertiseId
                                                    }))
                                                  }
                                                >
                                                  <IconUi.IconClose />
                                                </ButtonUi.ActionButton>
                                              }
                                              {isBuildMode &&
                                                <EditButtonsUi.EditButtons
                                                  isEditMode={isBuilderFieldEdit[fieldId]}
                                                  title="Valider"
                                                  isBuildMode={isBuildMode}
                                                  not-is-uniqueidfield={!isUniqId(fieldId)}
                                                  fields-byId-fieldId={fields.byId[fieldId].choices.length > 0}
                                                  onSubmit={isBuildMode && (!isUniqId(fieldId) || (fields.byId[fieldId].choices.length > 0))
                                                    ? onSubmitField({
                                                      type: 'fields',
                                                      fieldId,
                                                      sectionId,
                                                      typeOfField: fields.byId[fieldId].typeOfField
                                                    })
                                                    : () => { }
                                                  }
                                                  onEdit={() => {
                                                    if (isBuildMode) {
                                                      dispatch(CustomField.closeAllBuilderFieldEdit(sectionId))
                                                      onSubmitField({
                                                        type: 'fields',
                                                        fieldId,
                                                        sectionId,
                                                        typeOfField: fields.byId[fieldId].typeOfField
                                                      })
                                                    }
                                                    dispatch(CustomField.setBuilderEditFields({
                                                      type: 'fields',
                                                      fieldId,
                                                      editMode: isBuilderFieldEdit[fieldId] ? false : true
                                                    }))
                                                  }}
                                                />
                                              }
                                              <IconUi.MoveIcon
                                                {...provided.dragHandleProps}
                                                hidden={isBuildMode && isUniqId(fieldId)}
                                              />
                                            </LayoutUi.ActionButtonsContainer>
                                          </header>
                                          {fields.byId[fieldId].choices.length > 0 &&
                                            <Droppable
                                              droppableId={`${fieldId}`}
                                              type="choice"
                                            >
                                              {provided => (
                                                <LayoutUi.CustomFieldElementContainer
                                                  ref={provided.innerRef}
                                                  {...provided.droppableProps}
                                                >
                                                  {fields.byId[fieldId].choices.map((choiceId, idx) =>
                                                    <div key={choiceId}>
                                                      <Draggable
                                                        draggableId={`${choiceId}`}
                                                        index={idx}
                                                        key={`${choiceId}`}
                                                      >
                                                        {provided => (
                                                          <LayoutUi.CustomFieldElement
                                                            {...provided.draggableProps}
                                                            ref={provided.innerRef}
                                                          >
                                                            {fields.byId[fieldId].typeOfField === 'selectbox'
                                                              ? <IconUi.IconUncheckRound />
                                                              : <IconUi.IconUncheck />
                                                            }
                                                            {isBuilderFieldEdit[fieldId]
                                                              ? <FormUi.TextInput
                                                                placeholder="Intitulé du choix"
                                                                autoFocus={
                                                                  !choices.byId[choiceId].content &&
                                                                  !isUniqId(fieldId)
                                                                }
                                                                tabIndex={idx + 1}
                                                                value={choices.byId[choiceId].content}
                                                                onChange={updateBuilderFieldLabel(choiceId, 'choices')}
                                                                error={choices.byId[choiceId].error}
                                                                onInputBlur={!isUniqId(fieldId) && !locked
                                                                  ? onSubmitField({
                                                                    type: 'choices',
                                                                    fieldId,
                                                                    sectionId,
                                                                    typeOfField: choices.byId[choiceId].typeOfField,
                                                                    noClose: true,
                                                                  })
                                                                  : () => { }
                                                                }
                                                                disabled={locked && !isUniqId(fieldId)}
                                                                noSubmit
                                                              />
                                                              : <span>{choices.byId[choiceId].content}</span>
                                                            }
                                                            {isBuilderFieldEdit[fieldId] &&
                                                              choices.byId[choiceId].content.length > 0 &&
                                                              fields.byId[fieldId].choices.length === idx + 1 &&
                                                              <IconUi.IconPlus
                                                                onClick={(e) => dispatch(CustomField.addChoice({
                                                                  fieldId,
                                                                  typeOfField: fields.byId[fieldId].typeOfField,
                                                                  teleExpertiseType,
                                                                  teleExpertiseId,
                                                                }))}
                                                              />
                                                            }
                                                            {((idx !== 0) || (!isBuildMode)) && !isBuilderFieldEdit[fieldId] &&
                                                              <IconUi.IconTrash
                                                                onClick={(e) =>
                                                                  alert.show('Confirmez-vous la suppression de ce choix ?', {
                                                                    onConfirm: e => dispatch(CustomField.removeChoice({
                                                                      fieldId,
                                                                      choiceId,
                                                                      teleExpertiseType,
                                                                      teleExpertiseId,
                                                                    }))
                                                                  })}
                                                              />
                                                            }
                                                            <IconUi.IconMoveSplit
                                                              {...provided.dragHandleProps}
                                                            />
                                                          </LayoutUi.CustomFieldElement>
                                                        )}
                                                      </Draggable>
                                                    </div>
                                                  )}
                                                  {provided.placeholder}
                                                </LayoutUi.CustomFieldElementContainer>
                                              )}
                                            </Droppable>
                                          }
                                        </LayoutUi.CustomFieldContainer>
                                      )}
                                    </Draggable>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </SectionContainer>
                        }
                        {(!isBuildMode || (isBuildMode && !isUniqId(sectionId) &&
                          Object.keys(fields.byId)
                            .every(key => !isBuilderFieldEdit[key]))) &&
                          <ActionsContainer>
                            <BasicElementUi.H3>
                              Ajouter un champ :
                            </BasicElementUi.H3>
                            <LayoutUi.ButtonGroup>
                              <FieldButtonsContainer>
                                <ButtonUi.FormButton onClick={addField(sectionId, 'text')}>
                                  <IconUi.IconText />
                                  Texte
                                </ButtonUi.FormButton>
                                <ButtonUi.FormButton onClick={addField(sectionId, 'multicheckbox')}>
                                  <IconUi.IconCheck />
                                  Choix multiple
                                </ButtonUi.FormButton>
                                <ButtonUi.FormButton onClick={addField(sectionId, 'selectbox')}>
                                  <IconUi.IconChoixUnique />
                                  Choix unique
                                </ButtonUi.FormButton>
                                <ButtonUi.FormButton onClick={addField(sectionId, 'document')}>
                                  <IconUi.IconJoin />
                                  Document
                                </ButtonUi.FormButton>
                              </FieldButtonsContainer>
                            </LayoutUi.ButtonGroup>
                          </ActionsContainer>
                        }
                      </CustomFieldSection>
                    )}
                  </Draggable>
                )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* TODO Add section authorized in create patient mode */}
      <AddSectionContainer>
        {!isDragging && (!isBuildMode || (isBuildMode &&
          Object
            .entries(isBuilderFieldEdit)
            .filter(([key, _]) => key.indexOf('CF') === -1)
            .every(([_, value]) => !value))) &&
          <>
            <BasicElementUi.H3>
              Ajouter une section
            </BasicElementUi.H3>
            <ButtonUi.FormButton
              onClick={addSection}
            >
              <IconUi.IconDocument />
              Section
            </ButtonUi.FormButton>
          </>
        }
      </AddSectionContainer>
    </>
  )
}

const SectionContainer = styled.div`
  padding-top: 1.5rem;
`

const ActionsContainer = styled.div`
  padding-top: 1.5rem;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  ${BasicElementUi.H2} {
    flex: 1;
  }
`

const AddSectionContainer = styled.div`
  margin-bottom: 2rem;
  ${BasicElementUi.H3} {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
  }
`

const CustomFieldSection = styled.section`
  background: white;
  padding: 1.8rem;
  box-shadow: ${props => props.theme.boxShadow.medium};
  border-radius: 5px;
  box-sizing: border-box;
  height: ${props => props.isDragging ? '5.5rem' : 'auto'};
  overflow: ${props => props.isDragging ? 'hidden' : 'auto'};
  margin-bottom: 1.4rem;
  ${LayoutUi.FlexBox}:not(:first-child) {
    display: ${props => props.isDragging ? 'none' : 'flex'};
  }
  ${LayoutUi.ButtonGroup} {
    div:first-child {
      flex: 1;
    }
  }
  ${LayoutUi.ActionButtonsContainer} {
    margin-left: 1.5rem;
    align-items: center;
  }
  ${LayoutUi.FlexBox} {
    &:first-child {
      p {
        flex: initial;
        margin-right: .3rem;
      }
    }
    ${FormUi.InputContainer} {
      align-items: flex-end;
      margin-top: 0;
      margin-bottom: .15rem;
    }
  };
  ${LayoutUi.ButtonGroup} {
    margin-top: 1rem;
  };
  ${LayoutUi.CustomFieldElementContainer} {
    margin-top: 2rem;
    ${FormUi.InputContainer} {
      align-items: flex-end;
      margin-top: 0;
      margin-bottom: .15rem;
    }
    ${LayoutUi.CustomFieldElement} {
      ${FormUi.InputContainer} {
        margin-left: 1rem;
      }
    }
  }
  ${IconUi.IconMoveSplit} {
    font-size: 1.125rem;
    color: ${props => props.theme.grey};
    margin-left: .3rem;
  }
  ${LayoutUi.CustomFieldContainer} {
    padding: 1.1rem;
    textarea:last-child:not(:first-child) {
      margin-top: 0;
    }
    header {
      display: flex;
      align-items: flex-start;
      @media only screen and (max-width: ${props => props.theme.device.tablet}) {
        flex-direction: column-reverse;
        > div:last-child {
          align-self: flex-end;
          margin-bottom: 1rem;
        }
      }
      > div:first-child {
        display: flex;
        flex: 1;
        > div {
          margin: 0;
          input {
            border-color: ${props => props.hasError && 'red'};
          }
        }
      }
      ${LayoutUi.ActionButtonsContainer} {
        align-items: center;
        margin-left: 1.5rem;
      }
      ${BasicElementUi.H2} {
        flex: 1;
        margin: 0;
      }
    }
  }
`

const FieldButtonsContainer = styled.div`
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-direction: column;
    ${ButtonUi.FormButton} {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 1rem;
    }
  }
`

const IconRoundThemed = styled(props => <IconUi.IconRound {...props} />).attrs({ as: 'span' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.default ? props.theme.patientGreen : props.theme.primary};
  background: ${props => props.default ? props.theme.lightGreen : '#e3ddff'};
  border: 0;
  font-size: 1.1rem;
  margin-right: 1rem;
  flex-shrink: 0;
  border-radius: 50px;
  width: 2.5rem;
  height: 2.5rem;
`

const SectionType = styled(props => <LayoutUi.FlexBox {...props} />)`
  margin-top: .5rem;
`

export default CustomFieldBuilderComponent;

