import * as React from "react";

export const Loupe = ({
    color = '#5F6368',
    ...props
}) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z"
        stroke="#3BC3A8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        />
        <path
        d="M19 18.9998L13.8 13.7998"
        stroke="#3BC3A8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </svg>
);
