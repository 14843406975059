import * as React from "react";

export const DocGif = ({
  ...props
}) => (
    <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 384 384"
        style={{
         enableBackground: "new 0 0 384 384",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1={-90.3426}
            y1={549.4554}
            x2={-37.4926}
            y2={496.6154}
            gradientTransform="matrix(8 0 0 -8 832.076 4473)"
        >
        <stop
            offset={0}
            style={{
            stopColor: "#EFEEEE",
            }}
        />
        <stop
            offset={1}
            style={{
            stopColor: "#DEDEDD",
            }}
        />
        </linearGradient>
        <polygon
        style={{
            fill: "url(#SVGID_1_)",
        }}
        points="64,0 64,384 288,384 384,288 384,0 "
        />
        <polygon
        style={{
            fill: "#ABABAB",
        }}
        points="288,288 288,384 384,288 "
        />
        <polygon
        style={{
            fill: "#DEDEDD",
        }}
        points="192,384 288,384 288,288 "
        />
        <path
        style={{
            fill: "#010101",
        }}
        d="M0,96v112h256V96H0z"
        />
        <g>
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M66.8,134.288c-0.288-1.344-0.752-2.576-1.392-3.696c-0.64-1.104-1.456-2.048-2.432-2.784 c-0.992-0.736-2.208-1.104-3.616-1.104c-3.328,0-5.712,1.856-7.2,5.584c-1.472,3.696-2.208,9.856-2.208,18.416 c0,4.112,0.128,7.808,0.384,11.136c0.256,3.328,0.72,6.16,1.392,8.496s1.632,4.128,2.88,5.376c1.248,1.248,2.864,1.872,4.848,1.872 c0.848,0,1.744-0.24,2.752-0.672c0.992-0.448,1.904-1.12,2.784-2.016c0.864-0.912,1.584-2.032,2.16-3.408S68,168.48,68,166.624 v-7.008h-9.104V149.44h22.352v36.96H71.072v-6.336h-0.176c-1.664,2.704-3.664,4.592-6,5.712s-5.136,1.68-8.4,1.68 c-4.224,0-7.664-0.752-10.336-2.224c-2.672-1.472-4.736-3.728-6.24-6.816c-1.504-3.088-2.512-6.864-3.008-11.376 c-0.512-4.512-0.784-9.744-0.784-15.696c0-5.744,0.368-10.816,1.104-15.152c0.736-4.352,2-7.984,3.792-10.912 c1.776-2.912,4.16-5.088,7.088-6.576c2.96-1.472,6.624-2.208,11.04-2.208c7.552,0,12.992,1.872,16.32,5.632 c3.328,3.728,4.992,9.088,4.992,16.08H67.232C67.232,136.944,67.088,135.632,66.8,134.288z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M100.448,117.856h13.824V186.4h-13.824V117.856z"
        />
        <path
            style={{
            fill: "#FFFFFF",
            }}
            d="M172.448,117.856v11.328h-24.48v16.128h23.04v11.328h-23.04v29.76h-13.824v-68.544L172.448,117.856z "
        />
        </g>
    </svg>
);
