import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as IconUi from './Icon'
import * as Ui from '../Ui'
import * as LayoutUi from '../../components/common/Layout'
import * as BasicElementUi from './BasicElement'
import { fr } from '../../Util/i18n';
import { apiUrl } from '../../Util/Config'
import { KeywordsNotFoundMessage } from '../../Util/Message'
import { normalizeName } from '../../Util/Format'
import { privateSitePrefix } from '../../Util/Config'

export const SearchBar = ({ loading, children, error, ...props }) =>
  <>
    <SearchBarContainer disabled={props.disabled}>
      <Input {...props} autoComplete="off" type="text" />
      {!props.hiddenSearchIcon &&
        <IconContainer>
          {props.searching || loading
            ? <Ui.Global.Loader />
            : <IconSearch />
          }
        </IconContainer>
      }
    </SearchBarContainer>
    {error &&
      <>
        <Ui.Form.FieldErrorMessage>
          {error}
        </Ui.Form.FieldErrorMessage>
      </>
    }
  </>

export const DefaultCheckboxIcon = ({
  list = null,
  isPurple = false,
  isGreen = false,
  isRed = false,
  isBlue = false,
}) =>
  <CheckboxIcon
    isPurple={isPurple}
    isGreen={isGreen}
    isRed={isRed}
    isBlue={isBlue}
  >
    {isPurple
      ? <IconUi.IconMessage />
      : isRed
        ? <IconUi.IconCohorte />
        : <IconUi.IconExpertise />
    }
  </CheckboxIcon>

export const SearchAndCheckEngine = ({
  TeleExpertise,
  teleExpertiseId,
  teleExpertiseName = '',
  data = {},
  search = {},
  isResultEqualValue = null,
  CheckboxIconComponent = DefaultCheckboxIcon,
  name = '',
  placeholder = '',
  resultSet = [],
  list = [],
  keywordsNotFound = [],
  query = '',
  disabled = false,
  loading = false,
  isPurple = false,
  isRed = false,
  isBlue = false,
  isGreen = false,
  searchIfEmptyQuery = false,
  inputRef,
  hasNewPatientButton,
  hasInviteColleagueButton,
  onClickExtraButton,
  onToggle = (list, selected) => null,
  onQueryChange = () => null,
  onSearch = (resultSet, query) => [],
  resultToValue = a => a.id,
  resultToLabel = a => a.name,
  subLabel = () => '',
  checkboxDisabled = () => false,
  onFocus = () => { },
  ...restProps
}) => {
  const [currentResultSet, setCurrentResultSet] = useState(resultSet)
  const [matchingItems, setMatchingItems] = useState([])
  const [currentValue, setCurrentValue] = useState(list)

  useEffect(() => {
    if (!query && !searchIfEmptyQuery) {
      setMatchingItems([])

      return;
    }

    setMatchingItems(onSearch(currentResultSet, query))
  }, [query, currentResultSet, onSearch, searchIfEmptyQuery])

  useEffect(() => {
    setCurrentResultSet(resultSet)
  }, [resultSet, loading])

  useEffect(() => {
    if (resultSet.length === 0) {
      return;
    }

    setCurrentValue(list)
  }, [list, resultSet])

  const getCurrentResultSet = v =>
    currentResultSet.find(r => isResultEqualValue
      ? isResultEqualValue(r, v)
      : String(resultToValue(r)) === String(v)
    )

  const AddExtraButton = () =>
    (data?.patientLimitLocked?.value && data?.numberAddPatientAction?.value >= 10) || (!hasNewPatientButton && !hasInviteColleagueButton)
      ? null
      : <Ui.Layout.FlexBox alignItems="center" justify="center" width="auto">
        <Or>ou</Or>
        {hasNewPatientButton
          ? <Link to={`${privateSitePrefix}/nouveau/patient?${teleExpertiseName}s=${teleExpertiseId}`}>
            <ExtraButton
              isGreen
              isFilled
            >
              <Ui.Icon.IconPatientSup />
              Nouveau patient
            </ExtraButton>
          </Link>
          : <>
            <ExtraButton
              isGreen
              isFilled
              onClick={onClickExtraButton}
            >
              <Ui.Icon.IconPatientSup />
              Inviter un médecin
            </ExtraButton>
          </>
        }

      </Ui.Layout.FlexBox>

  // if (currentResultSet.length === 0) {
  //   return (
  //     <Ui.Layout.FlexBox alignItems="center" justify="center">
  //       <SearchBarContainer>
  //         <Input
  //           type="text"
  //           value={query}
  //           onChange={onQueryChange}
  //           onFocus={onFocus}
  //           placeholder={placeholder}
  //           {...restProps}
  //         />
  //         <IconContainer>
  //           {loading
  //             ? <Ui.Global.Loader />
  //             : <IconSearch />
  //           }
  //         </IconContainer>
  //       </SearchBarContainer>
  //       <AddExtraButton />
  //     </Ui.Layout.FlexBox>
  //   )
  // }

  return (
    <>
      <Ui.Layout.FlexBox>
        <SearchBarContainer>
          <Input
            type="text"
            value={query}
            onChange={onQueryChange}
            placeholder={placeholder}
            ref={inputRef}
            {...restProps}
          />
          <IconContainer>
            {loading
              ? <Ui.Global.Loader />
              : <IconSearch />
            }
          </IconContainer>
        </SearchBarContainer>
        <AddExtraButton />
      </Ui.Layout.FlexBox>
      {!loading && <KeywordsNotFoundMessage keywords={keywordsNotFound} />}
      {matchingItems.length !== 0 && (
        <>
          <LayoutUi.RadioGroupContainer>
            {matchingItems
              .filter(el => {
                if (isResultEqualValue) {
                  const result = currentValue.find(v => isResultEqualValue(el, v))

                  return !result
                }

                return !currentValue.includes(resultToValue(el))
              })
              .map((result, index) => (
                <Ui.Form.CheckboxLarge
                  key={`checkbox_unchecked_${index}`}
                  value={resultToValue(result)}
                  name={name}
                  subLabel={subLabel(result)}
                  label={resultToLabel(result)}
                  onChange={() => {
                    onToggle(resultToValue(result), true)
                    setMatchingItems([])
                  }}
                  checked={false}
                >
                  <CheckboxIconComponent
                    isPurple={isPurple}
                    isGreen={isGreen}
                    isRed={isRed}
                    isBlue={isBlue}
                    value={result}
                  />
                </Ui.Form.CheckboxLarge>
              )
              )}
          </LayoutUi.RadioGroupContainer>
        </>
      )}
      {currentValue.length !== 0 && (
        <>
          {matchingItems.length > 0 && list.length > 0 &&
            <Ui.BasicElement.Hr />
          }
          <LayoutUi.RadioGroupContainer>
            {list.length > 0 && list.map((v, index) => {

              return (
                <Ui.Form.CheckboxLarge
                  key={`checkbox_checked_${index}`}
                  value={v}
                  name={name}
                  subLabel={v && getCurrentResultSet(v) && subLabel(getCurrentResultSet(v))}
                  label={v && getCurrentResultSet(v) && resultToLabel(getCurrentResultSet(v))}
                  onChange={() => onToggle(v, false)}
                  checked={true}
                  disabled={getCurrentResultSet(v) && checkboxDisabled(getCurrentResultSet(v))}
                >
                  <CheckboxIconComponent
                    isPurple={isPurple}
                    isGreen={isGreen}
                    isRed={isRed}
                    isBlue={isBlue}
                    value={getCurrentResultSet(v)}
                  />
                </Ui.Form.CheckboxLarge>
              )
            })}
          </LayoutUi.RadioGroupContainer>
        </>
      )}
    </>
  )
}

export const ExpertiseCheckboxIcon = ({
  value = {},
  ...props
}) => {
  const publicPath = props.isHome ? 'public/' : ''

  if (value.type?.includes('opinion')) {
    const owner = value.ownerAndManagers &&
      Object.values(value.ownerAndManagers).length > 1 &&
      Object.values(value.ownerAndManagers)
        .find(user => user.type === 'owner')
    const ownerProfilPicture = owner && owner.profilePicture

    return value.pictureFileName || (value.thePersonal && value.thePersonal !== 'false')
      ? <BasicElementUi.AvatarPicture alt="Avatar"
        bgSrc={
          value.pictureFileName
            ? `${apiUrl}/${publicPath}${value.type}/picture/${value.pictureFileName}`
            : ownerProfilPicture
              ? `${apiUrl}/${publicPath}users/profile-picture/${ownerProfilPicture}`
              : value.profilePicture
                ? `${apiUrl}/${publicPath}users/profile-picture/${value.profilePicture}`
                : value.ownerProfilePicture
                  ? `${apiUrl}/${publicPath}users/profile-picture/${value.ownerProfilePicture}`
                  : '/Avatar_default.png'
        } />
      : <Ui.SearchBar.CheckboxIcon
        isBlue={true}
      >
        <Ui.Icon.IconExpertise />
      </Ui.SearchBar.CheckboxIcon>
  }

  return value.pictureFileName
    ? <BasicElementUi.AvatarPicture alt="Avatar"
      bgSrc={`${apiUrl}/${publicPath}${value.type}/picture/${value.pictureFileName}`} />
    : value.type.includes('staff')
      ? <Ui.SearchBar.CheckboxIcon
        isPurple={true}
      >
        <Ui.Icon.IconMessage />
      </Ui.SearchBar.CheckboxIcon>
      : <Ui.SearchBar.CheckboxIcon
        isRed={true}
      >
        <Ui.Icon.IconCohorte />
      </Ui.SearchBar.CheckboxIcon>
}

export const SearchAndCheck = ({
  name = '',
  TeleExpertise,
  teleExpertiseName = '',
  teleExpertiseId,
  sessionId = null,
  resultSet = [],
  data = {},
  search = {},
  list = [],
  keywordsNotFound = [],
  onToggle,
  onQueryChange,
  onSearch,
  searchIfEmptyQuery = false,
  resultToLabel,
  resultToValue,
  subLabel,
  checkboxDisabled = () => null,
  placeholder = '',
  isResultEqualValue = null,
  CheckboxIconComponent,
  query = '',
  disabled = false,
  loading = false,
  isPurple = false,
  isRed = false,
  isBlue = false,
  isGreen = false,
  onFocus,
  inputRef,
  hasNewPatientButton,
  hasInviteColleagueButton,
  onClickExtraButton,
  ...props
}) => {
  const dispatch = useDispatch()
  switch (name) {

    case 'managers':
      resultToLabel = resultToLabel ? resultToLabel : manager => manager.username
      resultToValue = resultToValue ? resultToValue : manager => manager.id
      subLabel = subLabel ? subLabel : member => member.speciality
      disabled = disabled ? disabled : result => result.id === data.owner.value[0]
      CheckboxIconComponent = CheckboxIconComponent ? CheckboxIconComponent : Ui.Colleague.Avatar
      onSearch = onSearch
        ? onSearch
        : (managers, criteria) => managers
          .filter(({ username }) =>
            RegExp(normalizeName(criteria), 'i').test(normalizeName(username)))
          .slice(0, 10)
      onToggle = onToggle ? onToggle : (id, toggled, name = 'managers') =>
        dispatch(TeleExpertise.toggle({ id, toggled, name }))
      onQueryChange = onQueryChange ? onQueryChange : (e) =>
        dispatch(TeleExpertise.setQuery({
          name: 'managers',
          value: e.target.value
        }))
      placeholder = placeholder
        ? placeholder
        : teleExpertiseName
          ? `Ajouter d'autres médecins administrateurs de ${fr[teleExpertiseName].thisExpertise}`
          : 'Ajouter des médecins administrateurs'

      break

    case 'confreres':
    case 'patientManagers':
    case 'members':
    case 'recipients':
    case 'defaultOpinions':
    case 'organisations':
      resultToLabel = resultToLabel ? resultToLabel : colleague =>
        [colleague.title, `${colleague.firstname} ${colleague.lastname}`].filter(e => e).join(' ')
      resultToValue = resultToValue ? resultToValue : colleague => colleague.id
      subLabel = subLabel ? subLabel : member => member.speciality
      disabled = disabled ? disabled : result => result.id === data.owner.value[0]
      CheckboxIconComponent = CheckboxIconComponent ? CheckboxIconComponent : Ui.Colleague.Avatar
      onSearch = onSearch
        ? onSearch
        : (managers, criteria) => managers
          .filter(({ firstname, lastname }) =>
            RegExp(normalizeName(criteria), 'i')
              .test(normalizeName(`${firstname} ${lastname}`)))
          .slice(0, 10)
      onToggle = onToggle ? onToggle : (id, toggled, name = 'colleagues') =>
        dispatch(TeleExpertise.toggle({ id, toggled, name }))
      onQueryChange = onQueryChange
        ? onQueryChange
        : (e) => dispatch(TeleExpertise.setQuery({
          name: 'colleagues',
          value: e.target.value
        }))
      placeholder = placeholder ? placeholder : 'Rechercher sur Skemeet...'

      break

    case 'disciplines':
      resultToLabel = disciplines => disciplines
      resultToValue = disciplines => disciplines
      CheckboxIconComponent = CheckboxIconComponent ? CheckboxIconComponent : DefaultCheckboxIcon
      onSearch = onSearch
        ? onSearch
        : (discipline, criteria) => discipline
          .filter(d => RegExp(normalizeName(criteria), 'i')
            .test(normalizeName(d)))

      onToggle = onToggle
        ? onToggle
        : (id, toggled, name = 'disciplines') =>
          dispatch(TeleExpertise.toggle({ id, toggled, name }))

      onQueryChange = onQueryChange
        ? onQueryChange
        : (e) => dispatch(TeleExpertise.setQuery({
          name: 'disciplines',
          value: e.target.value
        }))

      placeholder = placeholder || 'Rechercher une discipline...'

      break

    case 'expertises':
      subLabel = subLabel ? subLabel : expertise => expertise.thePersonal ? [expertise.ownerSpeciality, expertise.ownerCity].filter(e => e).join(', ') : expertise.ownerFullname
      resultToLabel = resultToLabel ? resultToLabel : expertise => expertise.name
      resultToValue = resultToValue ? resultToValue : expertise => ({ type: expertise.type, id: expertise.id, })
      isResultEqualValue = (result, value) => Number(result.id) === Number(value.id) && result.type === value.type
      CheckboxIconComponent = CheckboxIconComponent ? CheckboxIconComponent : DefaultCheckboxIcon

      onSearch = onSearch
        ? onSearch
        : (expertises, criteria) => expertises
          .slice(0, 30)

      onToggle = onToggle
        ? onToggle
        : (value, toggled, name = 'expertises') =>
          dispatch(TeleExpertise.toggle({ value, toggled, name }))

      onQueryChange = onQueryChange
        ? onQueryChange
        : (e) => dispatch(TeleExpertise.setQuery({
          name: 'expertises',
          value: e.target.value
        }))
      placeholder = placeholder ? placeholder : 'Rechercher une expertise, un médecin, une discipline, une ville, une organisation...'

      break;

    case 'patients':
      resultToLabel = resultToLabel ? resultToLabel : patient => patient.fullName
      resultToValue = resultToValue ? resultToValue : patient => patient.id
      CheckboxIconComponent = Ui.Colleague.DefaultAvatar

      onSearch = onSearch
        ? onSearch
        : (patientSelection, criteria) => patientSelection
          .filter(({ fullName, name, ownerFullname, disciplines = [] }) =>
            RegExp(normalizeName(criteria), 'i')
              .test(normalizeName(`${fullName}${name}${ownerFullname}${disciplines.join('')}`)))
          .slice(0, 10)

      onToggle = onToggle
        ? onToggle
        : (id, toggled) => {
          if (toggled) {
            dispatch(TeleExpertise.addPatient({ id, teleExpertiseId, sessionId }))
          } else {
            dispatch(TeleExpertise.removePatient({ id, teleExpertiseId, sessionId }))
          }
        }

      onQueryChange = onQueryChange
        ? onQueryChange
        : (e) => dispatch(TeleExpertise.setQuery({
          name: 'patients',
          value: e.target.value
        }))
      placeholder = placeholder ? placeholder : 'Rechercher parmi vos patients...'
      break;

    default:
  }

  return <SearchAndCheckEngine
    name={name}
    TeleExpertise={TeleExpertise}
    teleExpertiseName={teleExpertiseName}
    teleExpertiseId={teleExpertiseId}
    resultSet={resultSet}
    data={data}
    search={search}
    list={list}
    keywordsNotFound={keywordsNotFound}
    onToggle={onToggle}
    onQueryChange={onQueryChange}
    onSearch={onSearch}
    searchIfEmptyQuery={searchIfEmptyQuery}
    resultToValue={resultToValue}
    resultToLabel={resultToLabel}
    subLabel={subLabel}
    checkboxDisabled={checkboxDisabled}
    placeholder={placeholder}
    isResultEqualValue={isResultEqualValue}
    CheckboxIconComponent={CheckboxIconComponent}
    query={query}
    disabled={disabled}
    loading={loading}
    isPurple={isPurple}
    isRed={isRed}
    isBlue={isBlue}
    isGreen={isGreen}
    onFocus={onFocus}
    inputRef={inputRef}
    hasNewPatientButton={hasNewPatientButton}
    hasInviteColleagueButton={hasInviteColleagueButton}
    onClickExtraButton={onClickExtraButton}
  />
}

export const SearchBarContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.disabled ? '.7' : '1'};
  span {
    width: 3rem;
  }
`

export const Input = styled.input`
  background-color: #FFF;
  border: 1px solid;
  border-color: transparent;
  height: 2.8125rem;
  width: 100%;
  font-size: .875rem;
  border-radius: 50px;
  box-shadow: ${props => props.theme.boxShadow.thin};
  padding-left: ${props => props.hasIconLeft
    ? '3rem'
    : '1.5rem'
  };
  padding-right: ${props => props.hasIconLeft
    ? '1.5rem'
    : '3rem'
  };
  outline: 0;
  &::placeholder {
    color: ${props => props.theme.grey};
    letter-spacing: .03rem;
    font-style: italic;
  }
  &:focus {
    border-color: ${props => props.theme.primary};
  }
`

export const IconSearch = styled(props => <IconUi.IconSearch {...props} />)`
  font-size: 1.25rem;
  color: ${props => props.theme.grey};
  background: white;
  border: 0;
`

const IconContainer = styled.div`
  position: absolute;
  right: 1.2rem;

  i {
    font-size: 1.25rem;
    color: ${props => props.theme.grey};
    background: white;
    border: 0;
  }
`

export const PlainRoundIcon = styled(props => <IconUi.ButtonIcon {...props} />)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.grey};
  background: ${props => props.theme.lightGrey};
  border: 0;
  border-radius: 5000px;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: .5rem;
  flex-shrink: 0;

  + span {
    padding-left: 1rem;
  }
`

export const CheckboxIcon = styled(props => <PlainRoundIcon {...props} />)`
  color: white;
  cursor: auto;
  font-size: 1.4rem;
  background: ${props => props.isPurple
    ? props.theme.staffPurple
    : props.isRed
      ? props.theme.cohorteRed
      : props.isBlue
        ? props.theme.expertiseBlue
        : props.theme.patientGreen
  };
  &:hover {
    color: white;
  }
`
const ExtraButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-left: 1rem;
  height: 3rem;
`

const Or = styled.span`
  margin-left: 1rem
`
