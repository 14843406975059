import styled from 'styled-components';
import * as Button from './Button'
import { ButtonGroup } from '../../components/common/Layout'

export const AlertContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: .5rem;
  display: flex;
  background-color: #214152;
  pointer-events: auto;
  color: white;
  a {
    color: white;
  }
  ${ButtonGroup} {
    margin-top: .8rem;
    @media only screen and (max-width: ${props => props.theme.device.desktop}) {
      a:not(:last-child), button:not(:last-child) {
        margin-right: 0;
      }
    }
  }
  & > button {
    font-size: 1rem;
    color: #3bc3a8;
    margin-top: .8rem;
    margin-right: .2rem;
    align-self: flex-start;
    justify-self: flex-end;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 25rem;
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 1rem;
  line-height: 1.2em;
`

export const Message = styled.div`
  font-size: 0.875rem;
  align-self: center;
  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    ${Button.Button} {
      margin-bottom: 1rem;
    }
  }
`

export const Icon = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
`

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.1rem;
  width: 2.1875rem;
  height: 2.1875rem;
  border: 1px solid white;
  border-radius: 50px;
  flex-grow: 0;
  flex-shrink: 0;
`
