import React from 'react';
import styled from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import * as Ui from '.'
import * as Homepage from '../../State/Homepage'
import * as Login from '../../State/Login'
import { apiUrl } from '../../Util/Config'
import { JJMMAAAAToDate } from '../../Util/Format';
import SkemeetVisuelMedecins from '../../Asset/Image/Skemeet-visuel-medecins-detouree.png';

const HomeSideBarComponent = ({
  teleExpertiseOpened,
  portalProLogo,
  selectedExpertise = {},
  fromHome,
  fromLoginPage,
  fromRegistrationPage,
  fromPortalProHome,
  homeType = Homepage,
  description,
}) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const onSelectVisioconference = () => dispatch(Login.selectVisioconference({
    teleExpertiseId: selectedExpertise.id,
    teleExpertiseName: selectedExpertise.name,
    teleExpertiseType: selectedExpertise.type,
  }))

  return (
    <SideBarContainer>
      <Ui.PortailLayout.PortalSideBar homeType={homeType}>
        {(fromRegistrationPage || fromLoginPage) &&
          <>
            {!selectedExpertise.id &&
              <Ui.BasicElement.H1>
                {fromRegistrationPage
                  ? 'S\'inscrire'
                  : fromLoginPage
                    ? 'Se connecter'
                    : null
                }
              </Ui.BasicElement.H1>
            }
          </>
        }
        {fromHome
          ? selectedExpertise.id
            ? <>
              <Ui.PortailLayout.TeleExpertiseBlock>
                <SelectedExpertise>
                  <Ui.BasicElement.TeleExpertisePicture
                    value={selectedExpertise}
                    isPublic
                  />
                  <LeftSide>
                    {selectedExpertise.description &&
                      <Description>
                        {selectedExpertise.description}
                      </Description>
                    }
                    {selectedExpertise.staffNextSessionDate &&
                      <NextSessionDate primary bold veryBig>
                        Prochaine séance : {new Date(JJMMAAAAToDate(selectedExpertise.staffNextSessionDate.split(' ')[0])).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? "aujourd'hui" : selectedExpertise.staffNextSessionDate.split(' ')[0]}{(selectedExpertise.staffNextSessionDate.split(' ')[1] && selectedExpertise.staffNextSessionDate.split(' ')[1] !== '00:00') ? ` à ${selectedExpertise.staffNextSessionDate.split(' ')[1]}` : ''}
                      </NextSessionDate>
                    }
                    {selectedExpertise.isVideoconferencingToday &&
                      <VisioButton
                        isPurple
                        isFilled
                        onClick={() => {
                          onSelectVisioconference()
                          history.push('/connexion')
                        }}
                      >
                        Visioconférence
                      </VisioButton>
                    }
                    {Number(selectedExpertise.thePersonal)
                      ? <Ui.BasicElement.P>
                        {[selectedExpertise.ownerSpeciality, selectedExpertise.ownerCity].filter(e => e).join(', ')}
                      </Ui.BasicElement.P>
                      : null
                    }
                    {selectedExpertise.tags.length > 0 &&
                      <Ui.Layout.BlockContainer>
                        <Ui.BasicElement.H3>
                          Mots-clés
                        </Ui.BasicElement.H3>
                        <Ui.Badge>
                          {selectedExpertise.tags.split(',').map((tags, i) =>
                            <span key={`tags_${i}`}>{tags}</span>
                          )}
                        </Ui.Badge>
                      </Ui.Layout.BlockContainer>
                    }
                    {selectedExpertise.disciplines.length > 0 &&
                      <Ui.Layout.BlockContainer>
                        <Ui.BasicElement.H3>
                          Disciplines associées
                        </Ui.BasicElement.H3>
                        <Ui.Badge>
                          {selectedExpertise.disciplines.split(',').map((disciplines, i) =>
                            <span key={`disciplines_${i}`}>{disciplines}</span>
                          )}
                        </Ui.Badge>
                      </Ui.Layout.BlockContainer>
                    }
                    {selectedExpertise.owner &&
                      <TeleExpertiseOwner>Responsable : {selectedExpertise.owner}</TeleExpertiseOwner>
                    }
                  </LeftSide>
                </SelectedExpertise>
              </Ui.PortailLayout.TeleExpertiseBlock>
            </>
            : <Figure src={SkemeetVisuelMedecins} />
          : <SelectedExpertise>
            {portalProLogo
              ? <Ui.BasicElement.AvatarPicture
                alt="Avatar"
                isBig
                isRed
                bgSrc={`${apiUrl}/public/portal-pro/logo/${portalProLogo}`} />
              : <Ui.BasicElement.TeleExpertisePicture
                value={selectedExpertise}
                isPublic
              />
            }
            <LeftSide>
              {description &&
                <Description>
                  {description}
                </Description>
              }
              <Ui.BasicElement.H2 teleExpertiseOpened={teleExpertiseOpened}>
                Vous souhaitez...
              </Ui.BasicElement.H2>
              <Ui.PortailLayout.TeleExpertiseBlock teleExpertiseOpened={teleExpertiseOpened}>
                <Ui.PortailLayout.TeleExpertiseChoice onClick={() => dispatch(homeType.chooseTeleExpertise(homeType.OPINION))} >
                  <Ui.Icon.IconExpertise />
                  <Ui.PortailLayout.WordsBlock>
                    <Ui.PortailLayout.ImportantIconWords>Demander</Ui.PortailLayout.ImportantIconWords>
                    <Ui.PortailLayout.IconWords>une expertise</Ui.PortailLayout.IconWords>
                  </Ui.PortailLayout.WordsBlock>
                  {teleExpertiseOpened === homeType.OPINION
                    ? <Ui.PortailLayout.CircleButtonSelected />
                    : <Ui.PortailLayout.CircleButton />
                  }
                </Ui.PortailLayout.TeleExpertiseChoice>
                <Ui.PortailLayout.TeleExpertiseChoice onClick={() => dispatch(homeType.chooseTeleExpertise(homeType.STAFF))} >
                  <Ui.Icon.IconMessage />
                  <Ui.PortailLayout.WordsBlock>
                    <Ui.PortailLayout.ImportantIconWords>Présenter</Ui.PortailLayout.ImportantIconWords>
                    <Ui.PortailLayout.IconWords>à une RCP</Ui.PortailLayout.IconWords>
                  </Ui.PortailLayout.WordsBlock>
                  {teleExpertiseOpened === homeType.STAFF
                    ? <Ui.PortailLayout.CircleButtonSelected />
                    : <Ui.PortailLayout.CircleButton />
                  }
                </Ui.PortailLayout.TeleExpertiseChoice>
                <Ui.PortailLayout.TeleExpertiseChoice onClick={() => dispatch(homeType.chooseTeleExpertise(homeType.COHORT))} >
                  <Ui.Icon.IconCohorte />
                  <Ui.PortailLayout.WordsBlock>
                    <Ui.PortailLayout.ImportantIconWords>Inclure</Ui.PortailLayout.ImportantIconWords>
                    <Ui.PortailLayout.IconWords>dans une cohorte</Ui.PortailLayout.IconWords>
                  </Ui.PortailLayout.WordsBlock>
                  {teleExpertiseOpened === homeType.COHORT
                    ? <Ui.PortailLayout.CircleButtonSelected />
                    : <Ui.PortailLayout.CircleButton />
                  }
                </Ui.PortailLayout.TeleExpertiseChoice>
              </Ui.PortailLayout.TeleExpertiseBlock>
            </LeftSide>
          </SelectedExpertise>
        }
      </Ui.PortailLayout.PortalSideBar>
    </SideBarContainer>
  )
}

const SelectedExpertise = styled.div`
  ${Ui.BasicElement.H3} {
    font-size: 1rem;
    color: ${props => props.theme.darkBlue};
  }
  ${Ui.BasicElement.ExpertisePicture}, ${Ui.BasicElement.AvatarPicture} {
    position: absolute;
    top: -8rem;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
  }
  ${Ui.BasicElement.AvatarPicture} {
    border: 10px solid #FFF;
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    ${Ui.BasicElement.H2} {
      margin-left: 0;
    }
  }
`

const VisioButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-bottom: 1rem;
`

const Figure = styled.img`
  width: 100%;
  margin-bottom: 1rem;
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin-top: 2rem;
  }`

const Description = styled(props => <Ui.BasicElement.P {...props} />)`
  font-size: 1rem;
  line-height: 1.5625rem;
  word-break: normal;
  overflow-wrap: anywhere;
`

const LeftSide = styled.div`
  margin-top: 4rem;
  ${Description} {
    padding-top: .5rem;
  }
`

const TeleExpertiseName = styled(props => <Ui.BasicElement.H2 {...props} />)``
const TeleExpertiseOwner = styled(props => <Ui.BasicElement.P {...props} />)``

const NextSessionDate = styled(props => <Ui.BasicElement.P {...props} />)`
  margin-top: 1rem;
`

export const SideBarContainer = styled.div`
  ${Ui.PortailLayout.PortalSideBar} {
    padding-left: 1.8rem;
    @media only screen and (min-width: ${props => props.theme.device.desktop}) {
      padding-left: 2.8rem;
    }
  }
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    ${Ui.Form.CheckboxLargeContainer} {
      max-width: 100%;
    }
    ${Ui.Form.Labels} {
      width: 80%;
      min-height: 3.3rem;
    }
    ${Ui.PortailLayout.TeleExpertiseBlock}:not(:first-child) {
      margin-top: 1.5rem;
    }
    ${Ui.BasicElement.H2}:first-of-type  {
      margin-bottom: .5rem;
      margin-top: 2rem;
    }
    ${Ui.BasicElement.H1} {
      font-size: ${props => props.fromPortalProHome ? '2rem' : '1.9rem'};
    }
    ${TeleExpertiseName} {
      line-height: 1.8rem;
    }
    ${TeleExpertiseOwner} {
      line-height: 1.8rem;
    }
  }
`

export default HomeSideBarComponent;
