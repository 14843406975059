export const apiUrl = process.env.REACT_APP_API_URL;
export const apiSymfony6Url = process.env.REACT_APP_API_SYMFONY6_URL;
export const opinionPublicUrl = process.env.REACT_APP_OPINION_PUBLIC_URL;
export const staffPublicUrl = process.env.REACT_APP_STAFF_PUBLIC_URL;
export const cohortPublicUrl = process.env.REACT_APP_COHORT_PUBLIC_URL;
export const cohortPublicPatientUrl = process.env.REACT_APP_COHORT_PATIENT_PUBLIC_URL;
export const privateUrl = process.env.REACT_APP_PRIVATE_URL;
export const dicomViewerS3Url = process.env.REACT_APP_DICOM_S3_VIEWER_URL;
export const privateSitePrefix = '/p';
export const authUrl = process.env.REACT_APP_AUTH_URL;
export const serverEnv = process.env.REACT_APP_SERVER_ENV;
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const descriptionMaxLength = 250;
export const projectsName = process.env.REACT_APP_GOOGLE_PROJECTS;
export const projectsLocations = process.env.REACT_APP_GOOGLE_LOCATIONS;
export const projectsDatasets = process.env.REACT_APP_GOOGLE_DATASETS;
export const projectsDicomStores = process.env.REACT_APP_GOOGLE_DICOM_STORES;
export const SearchTeleExpertise = {
  STATUS_CREATED: 'created',
  STATUS_JOINED: 'joined',
  STATUS_ARCHIVED: 'archived',
  DISPLAY_ALL: 'all',
  DISPLAY_MINE: 'mine',
  DISPLAY_CREATED: 'created',
  DISPLAY_JOINED: 'joined',
  DISPLAY_ARCHIVED: 'archived',
  ORDER_BY_LATEST: 'updatedAt',
  ORDER_BY_TITLE: 'name',
  MODE_RECEIVED_MORE: 'received more',
  MODE_RECEIVED_FIRST: 'received first',
}
export const ErrorMessage = {
  NOT_FOUND: 'Not found',
  CUSTOM_PAGE_NOT_FOUND: 'CustomPage not found'
}
export const publicHostsTeleExpertisePortal = [
  'rcp.skemeet.dev',
  'rcp.skemeet.io',
  'cohorte.skemeet.dev',
  'cohorte.skemeet.io',
  'expertise.skemeet.dev',
  'expertise.skemeet.io',
]
export const publicHostsCohortPatient = [
  'cohorte-patient.skemeet.dev',
  'cohorte-patient.skemeet.io',
]
export const establishmentsPortalPro = {
  //Production
  'https://neurosphinx.skemeet.io/': 'neurosphinx',
  'https://oscar.skemeet.io/': 'oscar',
  'https://tetecou.skemeet.io/': 'tetecou',
  'https://sensgene.skemeet.io/': 'sensgene',
  'https://neurochirurgie-bicetre.skemeet.io/': 'neurochirurgie-bicetre',
  'https://anddirares.skemeet.io/': 'anddirares',
  'https://fai2r.skemeet.io/': 'fai2r',
  'https://fimarad.skemeet.io/': 'fimarad',
  'https://favamulti.skemeet.io/': 'favamulti',
  'https://mcgre.skemeet.io/': 'mcgre',
  'https://g2m.skemeet.io/': 'g2m',
  'https://test-prod.skemeet.io/': 'test-prod',

  //Preproduction
  'https://portail.skemeet.dev/': 'portail',
  'https://bruno.skemeet.dev/': 'bruno',
  'https://free.skemeet.dev/': 'free',
}
export const expiredEstablishmentsPortalPro = {
  'https://tetecou.skemeet.io/portail/3846': {
    message: 'La nouvelle adresse de la filière TeteCou est : ',
    newUrl: 'https://tetecou.skemeet.io/portail/tetecou',
  },
}
export const maxPatientsNumber = 10;
export const defaultBannerBackground = '/banner-01.jpg'
