import React, { useEffect } from 'react';
import { privateSitePrefix, publicHostsTeleExpertisePortal, publicHostsCohortPatient } from '../Util/Config'
import { Matomo } from '../Util/Matomo'
import { Provider } from 'react-redux';
import Homepage from './Homepage'
import PortalProHome from './PortalProHome';
import Login from './Login'
import Portal from './Portal'
import CloseTab from './CloseTab';
import Totp from './Totp';
import CreatePatient from './CreatePatient';
import PatientList from './PatientList'
import Patient from './Patient'
import Colleague from './Colleague'
import Organization from './Organization'
import OpinionList from './OpinionList'
import Opinion from './Opinion'
import Cohort from './Cohort'
import CreateCohort from './CreateCohort'
import CreateOpinion from './CreateOpinion'
import Staff from '../pages/Staff/Staff'
import StaffList from './StaffList'
import CohortList from './CohortList'
import ColleagueList from './ColleagueList'
import MessageList from './MessageList'
import CreateMessage from './CreateMessage'
import Message from './Message'
import CreateStaff from './CreateStaff'
import Profile from './Profile'
import AccountValidation from './AccountValidation'
import CreateOrganization from './CreateOrganization'
import ContactUs from './ContactUs'
import Help from './Help'
import DashboardAdmin from './DashboardAdmin'
import Legal from './Legal'
import Disclaimer from './Disclaimer'
import PrivacyPolicy from './PrivacyPolicy'
import Cookies from './Cookies'
import Cgu from './Cgu'
import Questionnaire from './Questionnaire'
import VideoConference from './VideoConference'
import PageNotFound from './PageNotFound'
import CustomPageNotFound from './CustomPageNotFound'
import createStore from '../State';
import { BrowserRouter, MemoryRouter, Route, Switch, useLocation } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './Ui/AlertBox'
import GlobalStyle, {
  theme,
  themeStaff,
  themeExpertise,
  themeCohorte,
} from './Ui/Global';
import { ContactUsButton } from './Ui/Button';
import Firewall, { FirewallPublic } from './Firewall';
import CreatePatientFromParams from './CreatePatientFromParams';
import SEOTags from './SEOTags';

/**
 * This is the entire Skemeet application.
 *
 * You can pass a store, and a location in order to test
 * the application correctly
 *
 * @see `src/__tests__` folder for any examples
 */
const options = {
  position: positions.TOP_RIGHT,
  offset: '30px',
  transition: transitions.FADE
}

const publicRoutes = [
  {
    path: '/',
    component: Homepage,
  },
  {
    path: '/connexion/:teleexpertiseType?/:teleexpertiseId?/:teleexpertiseName?',
    component: Login,
  },
  {
    path: '/portail/:portalProHost',
    component: PortalProHome,
  },
  {
    path: '/close-tab',
    component: CloseTab,
  },
  {
    path: '/mentions-legales',
    component: Disclaimer,
  },
  {
    path: '/politique-de-confidentialite',
    component: PrivacyPolicy,
  },
  {
    path: '/cookies',
    component: Cookies,
  },
  {
    path: '/cgu',
    component: Cgu,
  },
  {
    path: '/:teleExpertiseType(cohorte|rcp|expertise)/:publicUri/:tab?',
    component: Portal,
  },
  {
    path: '/cohorte-patient/:publicUri/:patientCode?',
    component: Questionnaire,
  },
  {
    path: '/patientVideoconference/videoconference/:teleExpertiseType/:teleExpertiseId/:patientCode?',
    component: VideoConference,
  },
  {
    path: '/videoconference/rcp/:staffVideoconferenceId',
    component: VideoConference,
  },
  {
    path: '/CustomPageNotFound',
    component: CustomPageNotFound,
  },
  {
    path: '/aide',
    component: Help,
  },
  {
    path: '/contactez-nous',
    component: ContactUs,
  },
]

const isCustomHost = (type) => {
  const { hostname } = window.location;

  if (type === 'teleExpertise') {
    return publicHostsTeleExpertisePortal.includes(hostname)
  } else if (type === 'cohortPatient') {
    return publicHostsCohortPatient.includes(hostname)
  }

  return false
}

const AppComponent = ({
  location = '/inscription',
  store = createStore(),
}) => {

  return (
    <Router location={location}>
      <RestoreScroll />
      <Provider store={store}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Matomo.Wrapper>
            <ContactUsButton />
            <AlertProvider
              template={AlertTemplate}
              containerStyle={{
                pointerEvents: 'none',
                zIndex: 100
              }}
              {...options}
            >
              <Switch>
                {/* Route serveur privé */}
                <Route path={`${privateSitePrefix}`} render={() => (
                  <Firewall>
                    <SEOTags />
                    <Switch>
                      <Route
                        path={`${privateSitePrefix}/installation-totp/:routeToRedirect`}
                        exact={true}
                        component={Totp}
                      />
                      <Route
                        path={`${privateSitePrefix}/nouveau/patient`}
                        exact={true}
                        component={CreatePatient}
                      />
                      <Route
                        path={`${privateSitePrefix}/patients/:id`}
                        exact={true}
                        component={Patient}
                      />
                      <Route
                        path={`${privateSitePrefix}/patients/:id/rcp/:expertiseId/rcpSession/:sessionId`}
                        exact={true}
                        component={Patient}
                      />
                      <Route
                        path={`${privateSitePrefix}/patients/:id/expertise/:expertiseId`}
                        exact={true}
                        component={Patient}
                      />
                      <Route
                        path={`${privateSitePrefix}/patients/:id/cohorte/:expertiseId`}
                        exact={true}
                        component={Patient}
                      />
                      <Route
                        path={`${privateSitePrefix}/patients`}
                        exact={true}
                        component={PatientList}
                      />
                      <Route
                        path={`${privateSitePrefix}/medecins/:id/`}
                        exact={true}
                        component={Colleague}
                      />
                      <Route
                        path={`${privateSitePrefix}/medecins/`}
                        exact={true}
                        component={ColleagueList}
                      />
                      <Route
                        path={`${privateSitePrefix}/organisation/:id/`}
                        exact={true}
                        component={Organization}
                      />
                      <Route
                        path={`${privateSitePrefix}/profil/`}
                        exact={true}
                        component={Profile}
                      />
                      <Route
                        path={`${privateSitePrefix}/validation-compte/:userCode`}
                        exact={true}
                        component={AccountValidation}
                      />
                      <Route
                        path={`${privateSitePrefix}/messages/`}
                        exact={true}
                        component={MessageList}
                      />
                      <Route
                        path={`${privateSitePrefix}/messages/:id`}
                        exact={true}
                        component={Message}
                      />
                      <Route
                        path={`${privateSitePrefix}/nouveau/message/:id?`}
                        exact={true}
                        component={CreateMessage}
                      />
                      <Route
                        path={`${privateSitePrefix}/nouveau/organisation/`}
                        exact={true}
                        component={CreateOrganization}
                      />
                      <Route
                        path={`${privateSitePrefix}/dashboard-admin`}
                        component={DashboardAdmin}
                        exact={true}
                      />
                      <Route
                        path={`${privateSitePrefix}/profil/informations-legales`}
                        component={Legal}
                        exact={true}
                      />
                      <Route
                        path={`${privateSitePrefix}/aide`}
                        component={Help}
                        exact={true}
                      />
                      <Route
                        path={`${privateSitePrefix}/contactez-nous`}
                        component={ContactUs}
                        exact={true}
                      />
                      <Route
                        path={`${privateSitePrefix}/creer-patient`}
                        component={CreatePatientFromParams}
                        exact={true}
                      />
                      <Route
                        path={`${privateSitePrefix}/(rcps|nouveau/rcp)`}
                        render={() =>
                          <ThemeProvider theme={themeStaff}>
                            <Switch>
                              <Route
                                path={`${privateSitePrefix}/rcps`}
                                component={StaffList}
                                exact={true}
                              />
                              <Route
                                path={`${privateSitePrefix}/rcps/:id`}
                                exact={true}
                                component={Staff}
                              />
                              <Route
                                path={`${privateSitePrefix}/rcps/:id/session/:sessionId`}
                                exact={true}
                                component={Staff}
                              />
                              <Route
                                path={`${privateSitePrefix}/nouveau/rcp`}
                                component={CreateStaff}
                                exact={true}
                              />
                              <Route component={PageNotFound} />
                            </Switch>
                          </ThemeProvider>
                        }
                      />
                      <Route
                        path={`${privateSitePrefix}/(expertises|nouveau/expertise)`}
                        render={() =>
                          <ThemeProvider theme={themeExpertise}>
                            <Switch>
                              <Route
                                path={`${privateSitePrefix}/expertises`}
                                component={OpinionList}
                                exact={true}
                              />
                              <Route
                                path={`${privateSitePrefix}/expertises/:id`}
                                exact={true}
                                component={Opinion}
                              />
                              <Route
                                path={`${privateSitePrefix}/nouveau/expertise`}
                                component={CreateOpinion}
                                exact={true}
                              />
                              <Route component={PageNotFound} />
                            </Switch>
                          </ThemeProvider>
                        }
                      />
                      <Route
                        path={`${privateSitePrefix}/(cohortes|nouveau/cohorte|videoconference)`}
                        render={() =>
                          <ThemeProvider theme={themeCohorte}>
                            <Switch>
                              <Route
                                path={`${privateSitePrefix}/cohortes`}
                                component={CohortList}
                                exact={true}
                              />
                              <Route
                                path={`${privateSitePrefix}/cohortes/:id`}
                                exact={true}
                                component={Cohort}
                              />
                              <Route
                                path={`${privateSitePrefix}/nouveau/cohorte`}
                                component={CreateCohort}
                                exact={true}
                              />
                              <Route
                                path={`${privateSitePrefix}/videoconference/:teleExpertiseType/:teleExpertiseId`}
                                component={VideoConference}
                                exact={true}
                              />
                              <Route component={PageNotFound} />

                            </Switch>
                          </ThemeProvider>
                        }
                      />
                      <Route component={PageNotFound} />
                    </Switch>
                  </Firewall>
                )} />
                {/* Route serveur publique */}
                <Route path="/" render={() => (
                  <FirewallPublic>
                    <SEOTags />
                    <Switch>
                      {publicRoutes
                        .map(({ path, component }, i) =>
                          <Route
                            key={`route_${i}`}
                            path={path}
                            component={component}
                            exact={true}
                          />
                        )
                      }
                      {isCustomHost('teleExpertise') &&
                        <Route
                          path="/:publicUri/:tab?"
                          component={Portal}
                          exact={true}
                        />
                      }
                      {isCustomHost('cohortPatient') &&
                        <Route
                          path="/:publicUri/:patientCode?"
                          component={Questionnaire}
                          exact={true}
                        />
                      }
                      <Route component={PageNotFound} />
                    </Switch>
                  </FirewallPublic>
                )} />
                <Route component={PageNotFound} />
              </Switch>
            </AlertProvider>
          </Matomo.Wrapper>
        </ThemeProvider>
      </Provider>
    </Router>
  )
}

/**
 * This is the application router wich automatically switch
 * between Memory and Browser implementation depending
 * on the application environment.
 *
 * Note that the `location` property is defining a default
 * location and it's only working in `test` env.
 */
const Router = ({
  location = '/',
  env = process.env.REACT_APP_NODE_ENV,
  children,
  ...restProps
}) => 'test' === env
    ? <MemoryRouter initialEntries={[location]} {...restProps}>{children}</MemoryRouter>
    : <Matomo.Provider><BrowserRouter {...restProps}>{children}</BrowserRouter></Matomo.Provider>


/**
 * This component is a little helper in order to restore the scroll position
 * each time a location change
 *
 * @see https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
const RestoreScroll = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default AppComponent;
