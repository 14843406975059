import React, { useState, useEffect, useRef } from 'react'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { privateSitePrefix } from '../Util/Config'
import { fr } from '../Util/i18n';
import { uniqByIdAndType } from '../Util/Object';
import { normalizeName } from '../Util/Format'
import { descriptionMaxLength } from '../Util/Config'
import * as Menu from '../State/Menu'
import * as Login from '../State/Login';
import * as Organization from '../State/Organization';
import * as Profile from '../State/Profile';
import * as Resources from '../State/Resources';
import * as UploadFile from '../State/UploadFile';
import * as ContactUs from '../State/ContactUs';
import * as Ui from './Ui'
import * as Styled from './Profile.styled'
import pluralize from '../Util/Pluralize'
import LoadingBox from './Ui/LoadingBox'
import ImageTool from './Ui/ImageTool'
import Editable from '../Util/Editable'
import UniqueDigitId from '../Util/UniqueDigitId';
import DownloadableQrCode from './Ui/DowloadableQRCode';
import {
  enterField,
  leaveField,
  enterFieldsBlock,
  leaveFieldsBlock,
  restoreValue,
} from '../Util/InlineEditActions';
import './Css/Payment.css';
import * as Icon from '../components/icons';

const ProfileComponent = (props) => {
  const dispatch = useDispatch()
  const alert = useAlert()

  const params = props.location.search
  const { organisationId } = qs.parse(params, { ignoreQueryPrefix: true })

  const currentUser = useSelector(Login.selectUser)
  const activeSubscriptionName = currentUser.activeSubscription?.offerName
  const hasPortal = currentUser.portal && Object.keys(currentUser.portal).length > 0
  const {
    data,
    search,
    loading,
    userExpertises,
    availableExpertises,
    updateUrlMessageBoxOpen,
    updateUrlMessageSent,
    estimateRequestMessageBoxOpen,
    estimateRequestMessageSent,
    subscriptionExpertisesLoading,
    isEditMode,
    offerSelected,
    quantityTeleExpertiseSubscrib,
    loadingSubscription,
    successSubscription,
    teleExpertiseDowngradeNames,
    errorMessage,
    pending,
    error: hasError,
    keywordsNotFound,
  } = useSelector(state => state.profile)

  const organizationId = useSelector(Organization.selectId)
  const organizationsKeywordsNotFound = useSelector(Organization.selectKeywordsNotFound)
  const resourceLoading = useSelector(Resources.selectLoading)
  const [current, setCurrent] = useState(1)
  const {
    [Resources.RESOURCE_MEMBERS]: members,
    [Resources.RESOURCE_ORGANIZATIONS]: organizations,
  } = useSelector(Resources.selectResources)

  const defaultCanEdit = data.permissions?.value._canBeModified?.default

  const pageEndRef = useRef(null)

  const scrollToBottom = () => {
    setTimeout(() =>
      pageEndRef.current.scrollIntoView({ behavior: "smooth" })
      , 0)
  }

  useEffect(() => {
    dispatch(Profile.fetchAllRppsRegistration())
  }, [dispatch])

  useEffect(() => {
    if (successSubscription) {
      if (teleExpertiseDowngradeNames) {
        alert.show(`Votre abonnement a été mis à jour : vos téléexpertises ${teleExpertiseDowngradeNames} sont de nouveau limitées.`, { timeout: 10000 })
      } else {
        if (
          currentUser.activeSubscription &&
          currentUser.activeSubscription.unlockTeleExpertise > 0 &&
          Number(quantityTeleExpertiseSubscrib) === Number(currentUser.activeSubscription.limitUnlockTeleExpertise)
        ) {
          alert.show('Votre abonnement Skemeet PRO a bien été modifié.', { timeout: 5000 })
        }

        if (!currentUser.activeSubscription)
          alert.show('Félicitations, vous pouvez désormais activer vos téléexpertises PRO.', { timeout: 5000 })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert, successSubscription, teleExpertiseDowngradeNames, currentUser.activeSubscription])

  const {
    data: organizationData,
    availableExpertises: organizationAvailableExpertises,
    search: organizationSearch,
  } = useSelector(state => state.organization)

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    if (updateUrlMessageSent)
      alert.show('Nous avons bien reçu votre demande de changement d\'URL. Nous vous avertirons dès que le changement sera effectif.', { timeout: 5000 })
  }, [alert, updateUrlMessageSent])

  useEffect(() => {
    if (estimateRequestMessageSent)
      alert.show('Votre message a bien été envoyé. Nous vous recontactons dans les plus brefs délais.', { timeout: 5000 })
  }, [alert, estimateRequestMessageSent])

  useEffect(() => {
    dispatch(Menu.changeActive(null))

    return () => {
      dispatch(Profile.clean())
      dispatch(Organization.clean())
      dispatch(UploadFile.cleanAll())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(Profile.getUserData())
    if (hasPortal) {
      dispatch(Profile.fetchPortalPro(currentUser.id))
    }
    dispatch(Profile.fetchOrganizations(organisationId))
    dispatch(Profile.fetchUserExpertises({
      unlockStaffs: currentUser.activeSubscription ? currentUser.activeSubscription.unlockStaffs : [],
      unlockCohorts: currentUser.activeSubscription ? currentUser.activeSubscription.unlockCohorts : [],
      unlockOpinions: currentUser.activeSubscription ? currentUser.activeSubscription.unlockOpinions : []
    }))
    dispatch(Profile.fetchDisciplines())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasPortal, organisationId, currentUser.id])

  useEffect(() => {
    const { tab } = qs.parse(params, { ignoreQueryPrefix: true })

    if (tab) {
      setCurrent(Number(tab))
    }
  }, [dispatch, params])

  useEffect(() => {
    if (currentUser.activeSubscription)
      dispatch(Profile.updateQuantityTeleExpertiseSubscrib(currentUser.activeSubscription.limitUnlockTeleExpertise))
  }, [dispatch, currentUser.activeSubscription])

  const updateField = name => e => {
    if (
      data.newPasswordConfirmation.errors[0] === 'Les mots de passe ne correspondent pas' &&
      (
        name === 'newPassword' ||
        name === 'newPasswordConfirmation'
      )
    ) {
      dispatch(Profile.cleanPasswordErrors())
    }

    dispatch(Profile.updateField({ name, value: e.target.value }))
  }

  const goSubscription = () => {
    setCurrent(5)
    alert.show('Ces fonctionnalités sont réservées aux comptes PRO+', { timeout: 5000 })
  }

  const {
    [Resources.RESOURCE_DISCIPLINES]: disciplines,
  } = useSelector(Resources.selectResources)

  const setEditField = (fieldName, value) =>
    dispatch(Profile.setEditField({ field: fieldName, value }))

  const setEditMode = (blockName, value) =>
    dispatch(Profile.setEditMode({ target: blockName, value }))

  const restoreInitialValue = (fieldName, value) =>
    dispatch(Profile.restoreInitialValue({ fieldName, value }))

  const saveField = (fieldName) =>
    dispatch(Profile.saveField({ fieldName, scope: Profile.SCOPE.profile }))

  const onEnterField = (fieldName, canEdit = defaultCanEdit) =>
    enterField(fieldName, canEdit, setEditField)

  const onEnterFieldsBlock = (blockName, canEdit = defaultCanEdit) =>
    enterFieldsBlock(blockName, canEdit, setEditMode)

  const onRestoreValue = (fieldName, initialValue) =>
    restoreValue(fieldName, data[fieldName], initialValue, setEditField, restoreInitialValue)

  const onLeaveField = (fieldName) =>
    leaveField(fieldName, data[fieldName], setEditField, saveField)

  const onLeaveFieldsBlock = (blockName, doSaveField = false) =>
    leaveFieldsBlock(blockName, null, isEditMode, doSaveField, setEditMode, saveField)

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      dispatch(Profile.saveField({ fieldName: e.target.name, scope: Profile.SCOPE.profile }))
    }
    if (e.key === 'Escape') {
      onRestoreValue(e.target.name, data[e.target.name].initialValue)()
    }
  }
  const refs = useRef({});
  const canEdit = true

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.ProfileContainer>
          {!loading || hasError
            ? <Ui.Layout.PageContentWrapper>
              <Summary
                data={data}
                dispatch={dispatch}
                setCurrent={setCurrent}
                currentUser={currentUser}
              />
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.NavContainer>
                  <nav>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrent(1)}
                      on={current === 1}
                    >
                      <Ui.Icon.IconInfos />
                      Mon profil
                    </Ui.Layout.NavLink>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrent(2)}
                      on={current === 2}
                    >
                      <Ui.Icon.IconShare />
                      Téléexpertises
                    </Ui.Layout.NavLink>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrent(3)}
                      on={current === 3}
                    >
                      <Ui.Icon.IconHospitalBuilding />
                      Organisations
                    </Ui.Layout.NavLink>
                    <>
                      <Ui.Layout.NavLink
                        onClick={() => setCurrent(5)}
                        on={current === 5}
                      >
                        <Ui.Icon.IconPro />
                        Abonnement
                      </Ui.Layout.NavLink>
                      {activeSubscriptionName === 'Pro+'
                        ? <Ui.Layout.NavLink
                          onClick={() => setCurrent(4)}
                          on={current === 4}
                        >
                          <Ui.Icon.IconPlanet />
                          Portail Pro
                        </Ui.Layout.NavLink>
                        : <Ui.Layout.NavLink
                          onClick={goSubscription}
                          on={current === 4}
                        >
                          <Styled.LightGrey><Ui.Icon.IconPlanet /></Styled.LightGrey>
                          <Styled.LightGrey>Portail Pro</Styled.LightGrey>
                        </Ui.Layout.NavLink>
                      }
                    </>
                  </nav>
                </Ui.Layout.NavContainer>
                <Ui.Layout.PaddedContent>
                  <MyProfile
                    data={data}
                    disciplines={disciplines}
                    updateField={updateField}
                    dispatch={dispatch}
                    current={current}
                    isEditMode={isEditMode}
                    pending={pending}
                    onEnterField={onEnterField}
                    onRestoreValue={onRestoreValue}
                    onLeaveField={onLeaveField}
                    onEnterFieldsBlock={onEnterFieldsBlock}
                    onLeaveFieldsBlock={onLeaveFieldsBlock}
                    onKeyPress={onKeyPress}
                    refs={refs}
                    canEdit={canEdit}
                  />
                  <TeleExpertises
                    data={data}
                    userExpertises={userExpertises}
                    currentUser={currentUser}
                    current={current}
                  />
                  <Organizations
                    dispatch={dispatch}
                    alert={alert}
                    data={organizationData}
                    profileData={data}
                    organizations={organizations}
                    organizationId={organizationId}
                    current={current}
                    isEditMode={isEditMode}
                    currentUser={currentUser}
                    members={members}
                    resourceLoading={resourceLoading}
                    search={organizationSearch}
                    keywordsNotFound={organizationsKeywordsNotFound}
                    availableExpertises={organizationAvailableExpertises}
                    onEnterFieldsBlock={onEnterFieldsBlock}
                    onLeaveFieldsBlock={onLeaveFieldsBlock}
                    refs={refs}
                  />
                  <Portal
                    data={data}
                    availableExpertises={availableExpertises}
                    keywordsNotFound={keywordsNotFound}
                    search={search}
                    isEditMode={isEditMode}
                    updateField={updateField}
                    current={current}
                    dispatch={dispatch}
                    updateUrlMessageBoxOpen={updateUrlMessageBoxOpen}
                    onEnterField={onEnterField}
                    onRestoreValue={onRestoreValue}
                    onEnterFieldsBlock={onEnterFieldsBlock}
                    onLeaveFieldsBlock={onLeaveFieldsBlock}
                    refs={refs}
                    canEdit={canEdit}
                  />
                  <Subscription
                    data={data}
                    current={current}
                    currentUser={currentUser}
                    updateField={updateField}
                    search={search}
                    offerSelected={offerSelected}
                    quantityTeleExpertiseSubscrib={quantityTeleExpertiseSubscrib}
                    loadingSubscription={loadingSubscription}
                    isEditMode={isEditMode}
                    availableExpertises={availableExpertises}
                    dispatch={dispatch}
                    pending={pending}
                    subscriptionExpertisesLoading={subscriptionExpertisesLoading}
                    estimateRequestMessageBoxOpen={estimateRequestMessageBoxOpen}
                    pageEndRef={pageEndRef}
                    scrollToBottom={scrollToBottom}
                  />
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
            : <Ui.Layout.FlexContainer><Ui.Global.Loader /></Ui.Layout.FlexContainer>
          }
        </Styled.ProfileContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const Summary = () => (
  <Ui.Layout.PanelContainer>
    <Ui.Layout.Panel>
      <>
        <Ui.Layout.TelexpertiseThumbnailContainer>
          <Ui.Layout.TelexpertiseLogo>
            <Ui.Icon.IconProfile />
          </Ui.Layout.TelexpertiseLogo>
          <Ui.BasicElement.H3 noMargin>
            Mon compte
          </Ui.BasicElement.H3>
        </Ui.Layout.TelexpertiseThumbnailContainer>
        <Ui.BasicElement.P light>
          Modifiez vos informations personnelles dans Mon profil.
          Créez des organisations pour collaborer plus efficacement.
          Utilisez votre portail personnalisé pour que vos correspondants
          vous adressent leurs patients en un clic. Souscrivez à un
          abonnement Skemeet PRO pour upgrader vos téléexpertises.
        </Ui.BasicElement.P>
        <Ui.BasicElement.P bold primary big> Soignons mieux, ensemble</Ui.BasicElement.P>
      </>
    </Ui.Layout.Panel>
  </Ui.Layout.PanelContainer>
)

const MyProfile = ({
  data,
  dispatch,
  updateField,
  current,
  isEditMode,
  disciplines,
  pending,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  onKeyPress,
  refs,
  canEdit,
}) => {
  const [seeExpertInformation, setSeeExpertInformation] = useState(false);
  const uploadFileData = useSelector(UploadFile.selectData)
  const uploadFileSuccess = useSelector(UploadFile.selectSuccess)

  useEffect(() => {
    if (uploadFileSuccess && uploadFileData.practitionerProof) {
      dispatch(Profile.successUploadPractitionerProof())
    }
  }, [dispatch, uploadFileSuccess, uploadFileData.practitionerProof])

  const onQueryChange = (value) => {
    dispatch(Profile.updateField({ name: 'speciality', value }))
  }

  const onLeaveSpecialityField = (payload) => e => {
    if (data.speciality.isFieldEdit && !e.currentTarget.contains(e.relatedTarget)) {
      dispatch(Profile.validateDiscipline())
    }
  }

  const onSpecialityKeyPress = e => {
    if (e.key === 'Enter') {
      dispatch(Profile.validateDiscipline())
    }
  }

  const onRemoveProfilePicture = () => {
    dispatch(Login.removeProfilePicture())
    dispatch(Profile.removeProfilePicture())
  }

  const specialityRef = useRef();
  const rppsRef = useRef();
  const titleRef = useRef();

  return (current === 1) && (
    <Ui.Layout.Section>
      <a href={`${process.env.REACT_APP_SKEZI_AUTH_FRONT_URL}/dashboard/authentication`} target="_blank" rel="noreferrer">
        <Ui.Button.BasicButton
          isFilled
          isGreen
        >
          Gérer mon compte SKEZI
        </Ui.Button.BasicButton>
      </a>
      <Ui.BasicElement.H2>
        Informations générales
      </Ui.BasicElement.H2>
      <Styled.MyProfileRow>
        <div>
          <Ui.UploadFile.ImagesUpload
            label=""
            name="profilePicture"
            id="profilePicture"
            htmlFor="profilePicture"
            multiple={false}
            endpoint="/users/current/profile-picture"
            value={data.profilePicture.value ? [{ fileName: data.profilePicture.value }] : []}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
            previewUri="/users/profile-picture"
            onRemove={onRemoveProfilePicture}
            isRounded
          />
        </div>
        <div>
          <Ui.Layout.Row>
            <Ui.Form.EditableTextInput
              name="lastname"
              label="Nom *"
              data={data}
              updateField={updateField}
              onEnterField={onEnterField}
              onLeaveField={onLeaveField}
              onRestoreValue={onRestoreValue}
              onKeyPress={onKeyPress}
              refs={refs}
              canEdit={canEdit}
            />
            <Ui.Form.EditableTextInput
              name="firstname"
              label="Prénom *"
              data={data}
              updateField={updateField}
              onEnterField={onEnterField}
              onLeaveField={onLeaveField}
              onRestoreValue={onRestoreValue}
              onKeyPress={onKeyPress}
              refs={refs}
              canEdit={canEdit}
            />
          </Ui.Layout.Row>
          <Ui.Layout.Row>
            <Editable
              title="Titre *"
              name="title"
              content={data.title.value}
              isEditMode={data.title.isFieldEdit}
              onClick={onEnterField('title', canEdit)}
              onBlur={onLeaveField('title')}
              childRef={titleRef}
              canEdit={canEdit}
            >
              <Ui.Layout.FocusDiv ref={titleRef} tabIndex="1654" >
                <Ui.Form.InputContainer tabIndex={UniqueDigitId()} ref={titleRef}>
                  <Ui.Form.Label>Titre *</Ui.Form.Label>
                  <Ui.Select.Select
                    htmlFor="title"
                    items={
                      ['Dr', 'Pr', 'M.', 'Mme']
                        .map(item => ({
                          label: item,
                          value: item,
                          selected: item === data.title.value
                        }))
                    }
                    onChange={(e) => {
                      dispatch(Profile.updateField({ name: 'title', value: e.target.value }))
                      dispatch(Profile.saveField({ fieldName: 'title', scope: Profile.SCOPE.profile }))
                    }}
                    pending={pending}
                  />
                </Ui.Form.InputContainer>
              </Ui.Layout.FocusDiv>
            </Editable>
            <Editable
              title="Discipline"
              name="speciality"
              text={data.speciality.value}
              isEditMode={data.speciality.isFieldEdit}
              onClick={onEnterField('speciality', canEdit)}
              onBlur={onLeaveSpecialityField()}
              altText="Ajouter une discipline"
              childRef={specialityRef}
              canEdit={canEdit}
            >
              <Styled.SearchSelectContainer
                inputRef={specialityRef}
                tabIndex="898098"
              >
                <Ui.SearchSelect
                  label="Discipline"
                  query={data.speciality.value}
                  loading={false}
                  datas={data.speciality.value && disciplines.filter(item => {
                    const re = new RegExp(normalizeName(data.speciality.value), 'i')
                    return re.test(normalizeName(item))
                  })}
                  itemLabel={discipline => discipline}
                  onQueryChange={onQueryChange}
                  onSelectData={query => onQueryChange(query)}
                  notFoundMessage="Aucune discipline n'a été trouvée."
                  placeholder="Rechercher votre discipline"
                  onBlur={onLeaveSpecialityField()}
                  onRestoreValue={onRestoreValue('speciality', data.speciality.initialValue)}
                  onKeyDown={onSpecialityKeyPress}
                  error={data.speciality.errors[0]}
                  pending={pending}
                  inputRef={specialityRef}
                />
              </Styled.SearchSelectContainer>
            </Editable>
          </Ui.Layout.Row>
          <Ui.Layout.Row>
            <Ui.Form.EditableTextInput
              name="city"
              label="Ville d'exercice"
              altText="Ajouter une ville d'exercice"
              data={data}
              updateField={updateField}
              onEnterField={onEnterField}
              onLeaveField={onLeaveField}
              onRestoreValue={onRestoreValue}
              onKeyPress={onKeyPress}
              refs={refs}
              canEdit={canEdit}
            />
            <Editable
              title="Numéro RPPS"
              text={!data.rpps.value && !data.rpps.isFieldEdit
                ? 'Ajouter votre numéro RPPS'
                : data.rpps.value || ''}
              isEditMode={data.rpps.isFieldEdit}
              onClick={onEnterField('rpps', canEdit)}
              onBlur={onLeaveField('rpps')}
              childRef={rppsRef}
              canEdit={canEdit}
            >
              <Ui.Form.TextInput
                name="rpps"
                label="Numéro RPPS"
                altContent="Le numéro RPPS n'a pas été renseigné"
                value={data.rpps.value}
                onChange={updateField('rpps')}
                onBlur={() => dispatch(Profile.validateRpps())}
                onRestoreValue={onRestoreValue('rpps', data.rpps.initialValue)}
                onKeyDown={onKeyPress}
                error={data.rpps.errors[0]}
                inputRef={rppsRef}
              />
            </Editable>
          </Ui.Layout.Row>
          <div className='flex items-center gap-[0.5rem] mb-[1.4rem] max-xl:w-[75%]'>
            <span
              className='cursor-pointer'
              onClick={() => dispatch(Profile.updateAndSaveField({ name: 'expert', value: !data.expert.value, scope: Profile.SCOPE.profile }))}
            >
              {data.expert.value ? <Icon.CheckboxCheck widthAndHeight='20' /> : <Icon.CheckboxUncheck widthAndHeight='20' />}
            </span>
            <span className='text-[0.875rem] leading-[1.5rem]'>Proposer votre expertise sur SKEMEET</span>
            <span className='relative'>
              <Icon.InfoWithBgColor
                onMouseEnter={() => setSeeExpertInformation(true)}
                onMouseLeave={() => setSeeExpertInformation(false)}
              />
              {seeExpertInformation && <span className='absolute top-[-2rem] left-[-16.5rem] w-[35rem] rounded-lg px-[0.5rem] text-[0.75rem] leading-[1.5rem] text-white bg-[#4A6574]'>Accepter d'être sollicité par d'autres médecins afin de les aider à diagnostiquer un patient.</span>}
            </span>
          </div>
          {data.expert.value &&
            <div className='flex items-center gap-[0.5rem]'>
              <Icon.Expert width='34' height='32' /><span className='text-blue-dark text-[0.75rem] font-[600]'>Ce badge Expert apparaîtra sur votre profil.</span>
            </div>
          }
          {data.status.value === 'NON_VERIFIED'
            ? true === data.uploadPractitionerProof.value
              ? <Styled.ValidationInProgress><Ui.Global.GreenText>Votre justificatif a bien été transmis, il est en cours de validation. Si besoin, <Styled.ContactUs href="mailto:contact@skezi.eu">contactez-nous</Styled.ContactUs>.</Ui.Global.GreenText></Styled.ValidationInProgress>
              : <>
                <Ui.UploadFile.DocumentsUpload
                  name="practitionerProof"
                  id="practitionerProof"
                  validationRules={["noLargeFiles", "noEmptyFiles"]}
                  label="Justificatif professionnel"
                  buttonLabel="Télécharger votre justificatif"
                  endpoint="/users/current/rpps-user/practitioner-proof"
                  isEditMode={true}
                >
                  Poids max : 30 Mo. <br />
                  Formats acceptés : PDF, Jpeg, Png
                </Ui.UploadFile.DocumentsUpload>
              </>
            : null
          }
        </div>
      </Styled.MyProfileRow>
    </Ui.Layout.Section >
  )
}

const TeleExpertises = ({
  current,
  data,
  userExpertises,
  currentUser,
  ...props
}) => {
  const expertisesByType = userExpertises.reduce((acc, expertises) => {
    acc[expertises.type] = [...acc[expertises.type] || [], expertises];
    return acc;
  }, {});

  return (current === 2) && (
    <Ui.Layout.Section>
      {userExpertises.length > 0 &&
        Object
          .entries(expertisesByType)
          .map(([type, teleExpertises]) =>
            <Ui.Layout.BlockContainer key={type}>
              <Ui.BasicElement.H2>
                {type === 'staffs'
                  ? 'RCPs'
                  : type === 'opinions'
                    ? 'Expertises'
                    : 'Cohortes'
                }
              </Ui.BasicElement.H2>
              <Ui.Layout.FlexBox flow="row wrap">
                {teleExpertises
                  .slice()
                  .sort(teleExpertise => teleExpertise.ownerFullname === currentUser.username ? -1 : 0)
                  .map((teleExpertise, index) =>
                    <Styled.ButtonLargeLink
                      key={index}
                      onClick={e => e.stopPropagation()}
                    >
                      <Ui.Form.ButtonLarge
                        to={`${privateSitePrefix}/${fr[teleExpertise.type === 'staffs'
                          ? 'staff'
                          : teleExpertise.type === 'opinions'
                            ? 'opinion'
                            : 'cohort'].expertises}/${teleExpertise.id}`}
                        teleExpertiseType={teleExpertise.type}
                        value={teleExpertise.name}
                        subLabel={`${teleExpertise.ownerTitle} ${teleExpertise.ownerFullname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}`}
                        label={teleExpertise.name}
                        badge={!teleExpertise.patientLimitLocked}
                        isClickable
                      >
                        <Ui.SearchBar.ExpertiseCheckboxIcon
                          value={teleExpertise}
                        />
                      </Ui.Form.ButtonLarge>
                    </Styled.ButtonLargeLink>
                  )}
              </Ui.Layout.FlexBox>
            </Ui.Layout.BlockContainer>
          )}
    </Ui.Layout.Section>
  )
}

const Organizations = ({
  data,
  profileData,
  dispatch,
  current,
  alert,
  organizationId,
  isEditMode,
  currentUser,
  resourceLoading,
  search,
  members,
  availableExpertises,
  organizations: organizationsSource,
  keywordsNotFound,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  refs,
}) => {

  const canEdit = data?.permissions?.value?._canBeModified?.default
  const canDelete = data?.permissions?.value?._canBe?.deleted
  const expertises = availableExpertises.filter(expertise =>
    data.expertises.value.some(exp => exp.type === expertise.type && Number(exp.id) === Number(expertise.id)))

  const organizations = organizationsSource.filter(organization =>
    profileData.organizations.value.includes(Number(organization.id))
  )

  const updateField = name => e => {
    dispatch(Organization.updateField({ name, value: e.target.value }))
  }

  const onTeleExpertiseToggle = ({ teleExpertiseId, teleExpertiseType }, toggled) => {
    dispatch(Organization.updateTeleExpertise({
      organizationId,
      resource: teleExpertiseType === 'staffs'
        ? 'staff'
        : teleExpertiseType === 'opinions'
          ? 'opinion'
          : 'cohort',
      resourceId: teleExpertiseId,
      toggled,
    }))
  }

  const setEditField = (fieldName, value) =>
    dispatch(Organization.setEditField({ field: fieldName, value }))

  const onEnterField = (fieldName, canEdit) => () =>
    canEdit ? setEditField(fieldName, true) : null

  const onRestoreValue = (fieldName, value) => (e) => {
    dispatch(Organization.restoreInitialValue({ fieldName, value }))
    if (!data[fieldName].errors.length > 0)
      setEditField(fieldName, false)
  }

  const onLeaveField = (fieldName) => e => {
    if (data[fieldName].isFieldEdit && !e.currentTarget.contains(e.relatedTarget)) {
      if (data[fieldName].value !== data[fieldName].initialValue)
        dispatch(Organization.saveField({ fieldName, scope: 'organization', organizationId: data.organizationId.value }))
      else
        dispatch(Organization.setEditField({ field: fieldName, value: false }))
    }
  }

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      dispatch(Organization.saveField({ fieldName: e.target.name, scope: 'organization', organizationId: data.organizationId.value }))
    }
    if (e.key === 'Escape') {
      onRestoreValue(e.target.name, data[e.target.name].initialValue)()
    }
  }

  const onRemoveOrganizationLogo = () => dispatch(Organization.removeLogo({ organizationId }))

  const searchManagersRef = useRef();
  const searchColleaguesRef = useRef();
  const searchTeleExpertisesRef = useRef();
  const organizationLogoRef = useRef();

  return (
    (current === 3)
      ? <Styled.OrganizationsContainer>
        <Ui.BasicElement.P>
          Créez une organisation pour regrouper les médecins de votre établissement,
          service ou réseau de soins. <br />Liez vos téléexpertises à cette organisation
          pour partager automatiquement les patients avec vos collaborateurs.
        </Ui.BasicElement.P>
        {!isEditMode.organization &&
          <Ui.Layout.BlockContainer>
            <Styled.HeaderButtons>
              <Link to={`${privateSitePrefix}/nouveau/organisation`}>
                <Ui.Button.BasicButton
                  isFilled
                  isGreen
                >
                  Créer une organisation
                </Ui.Button.BasicButton>
              </Link>
              {data.name.value && canDelete &&
                <Ui.Button.BasicButton
                  type="button"
                  title="Supprimer cette organisation"
                  onClick={(e) =>
                    alert.show('Confirmez-vous la suppression de cette organisation ?', {
                      onConfirm: () => dispatch(Profile.removeOrganization(organizationId))
                    })
                  }
                >
                  Supprimer cette organisation
                </Ui.Button.BasicButton>
              }
            </Styled.HeaderButtons>
          </Ui.Layout.BlockContainer>
        }
        {organizations.length > 0 &&
          <Ui.Layout.BlockContainer>
            <Ui.Layout.FlexBox flow="row wrap">
              {organizations
                .filter(organization => isEditMode.organization
                  ? organization.id === data.organizationId.value
                  : true
                )
                .map((organization, i) =>
                  <Ui.Card.OrganizationCard
                    key={i}
                    id={organization.id}
                    name={organization.name}
                    logo={organization.logo}
                    onClick={(e) => {
                      dispatch(Profile.fetchOrganization(organization.id))
                    }}
                    preventDefault
                    selected={organization.id === data.organizationId.value}
                  />
                )}
            </Ui.Layout.FlexBox>
          </Ui.Layout.BlockContainer>
        }
        {organizationId
          ? <>
            <Ui.Layout.Section>
              <Ui.BasicElement.H2>
                Informations générales
              </Ui.BasicElement.H2>
              <Ui.Layout.Row>
                <Ui.Form.EditableTextInput
                  name="name"
                  label="Nom *"
                  data={data}
                  updateField={updateField}
                  onEnterField={onEnterField}
                  onLeaveField={onLeaveField}
                  onRestoreValue={onRestoreValue}
                  onKeyPress={onKeyPress}
                  refs={refs}
                  canEdit={canEdit}
                />

              </Ui.Layout.Row>
              <Ui.BasicElement.ViewTitleContainer>
                <Ui.BasicElement.H3>Logo</Ui.BasicElement.H3>
              </Ui.BasicElement.ViewTitleContainer>
              <Ui.Layout.BlockContainer>
                <Ui.UploadFile.ImagesUpload
                  label=""
                  name={`logo_${data.organizationId.value}`}
                  id="logo"
                  htmlFor="logo"
                  multiple={false}
                  endpoint={`/organizations/${data.organizationId.value}/update-logo`}
                  onRemove={onRemoveOrganizationLogo}
                  value={data.logo.value ? [{ fileName: data.logo.value }] : []}
                  validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
                  previewUri="/organizations/logo"
                  inputRef={organizationLogoRef}
                  isEditMode={isEditMode.organizationLogo}
                  disabled={!canEdit}
                />
              </Ui.Layout.BlockContainer>
            </Ui.Layout.Section>
            <Ui.Layout.BlockContainer>
              <Editable
                content={
                  <Ui.Layout.FlexBox flow="column wrap">
                    <Ui.BasicElement.H2>Administrateurs</Ui.BasicElement.H2>
                    <Ui.Layout.ColleagueCardsContainer>
                      {members.length > 0 &&
                        members
                          .filter(user => data.managers.value.includes(Number(user.id)))
                          .map((colleague, i) =>
                            <Ui.Colleague.Colleague
                              key={`colleague_${i}`}
                              to={`${privateSitePrefix}/medecins/${colleague.id}`}
                              colleague={colleague}
                              currentUser={currentUser}
                            />
                          )}
                    </Ui.Layout.ColleagueCardsContainer>
                  </Ui.Layout.FlexBox>
                }
                description=""
                isEditMode={isEditMode.managers}
                onClick={onEnterFieldsBlock('managers', canEdit)}
                onBlur={onLeaveFieldsBlock('managers')}
                altContent={`Aucun administrateur n'est associé à cette organisation`}
                childRef={searchManagersRef}
                canEdit={canEdit}
              >
                <Ui.Layout.FocusDiv ref={searchManagersRef} tabIndex="1654" >
                  <Ui.BasicElement.H2>Administrateurs</Ui.BasicElement.H2>
                  <Ui.Form.InputContainer>
                    <Ui.SearchBar.SearchAndCheck
                      name="managers"
                      query={search.managers.value}
                      TeleExpertise={Organization}
                      resultSet={members}
                      list={[...data.managers.value]}
                      loading={resourceLoading.managers}
                      checkboxDisabled={manager => Number(manager.id) === Number(currentUser.id)}
                      onToggle={(id, toggled) => dispatch(Organization.updateResource({
                        organizationId,
                        resource: 'manager',
                        resourceId: id,
                        toggled
                      }))}
                    />
                    {data.members.errors[0] &&
                      <Ui.Form.FieldErrorMessage>{data.members.errors[0]}</Ui.Form.FieldErrorMessage>
                    }
                  </Ui.Form.InputContainer>
                </Ui.Layout.FocusDiv>
              </Editable>
            </Ui.Layout.BlockContainer>
            <Ui.Layout.BlockContainer>
              <Editable
                content={
                  <Ui.Layout.FlexBox flow="column wrap">
                    <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                    <Ui.Layout.ColleagueCardsContainer>
                      {members
                        .filter(user => data.members.value.includes(Number(user.id)))
                        .length > 0
                        ? members
                          .filter(user => data.members.value.includes(Number(user.id)))
                          .map((colleague, i) =>
                            <Ui.Colleague.Colleague
                              key={`colleague_${i}`}
                              to={`${privateSitePrefix}/medecins/${colleague.id}`}
                              colleague={colleague}
                              currentUser={currentUser}
                            />
                          )
                        : <Ui.BasicElement.P>Aucun médecin n'est rattaché à cette organisation</Ui.BasicElement.P>}
                    </Ui.Layout.ColleagueCardsContainer>
                  </Ui.Layout.FlexBox>
                }
                isEditMode={isEditMode.members}
                onClick={onEnterFieldsBlock('members', canEdit)}
                onBlur={onLeaveFieldsBlock('members')}
                childRef={searchColleaguesRef}
                canEdit={canEdit}
              >
                <Ui.Layout.FocusDiv ref={searchColleaguesRef} tabIndex="1655" >
                  <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                  <Ui.Form.InputContainer>
                    <Ui.SearchBar.SearchAndCheck
                      name="members"
                      query={search.members.value}
                      TeleExpertise={Organization}
                      resultSet={members}
                      list={[...data.managers.value, ...data.members.value]}
                      loading={resourceLoading.managers}
                      checkboxDisabled={member => [...data.managers.value].includes(member && member.id)}
                      onQueryChange={(e) => dispatch(Organization.setQuery({
                        name: 'members',
                        value: e.target.value
                      }))}
                      onToggle={(id, toggled) => dispatch(Organization.updateResource({
                        organizationId: data.organizationId.value,
                        resource: 'member',
                        resourceId: id,
                        toggled
                      }))}
                    />
                    {data.members.errors[0] &&
                      <Ui.Form.FieldErrorMessage>{data.members.errors[0]}</Ui.Form.FieldErrorMessage>
                    }
                  </Ui.Form.InputContainer>
                </Ui.Layout.FocusDiv>
              </Editable>

            </Ui.Layout.BlockContainer>
            <Ui.Layout.BlockContainer>
              <Editable
                content={
                  <Ui.Layout.FlexBox flow="column wrap">
                    <Ui.BasicElement.H2>Téléexpertises associées à l'organisation</Ui.BasicElement.H2>
                    <Ui.Layout.FlexBox flow="row wrap">
                      {expertises
                        .filter(uniqByIdAndType)
                        .map((teleExpertise, index) =>
                          <Styled.ButtonLargeLink
                            key={index}
                            onClick={e => e.stopPropagation()}>
                            <Ui.Form.ButtonLarge
                              to={`${privateSitePrefix}/${fr[teleExpertise.type === 'staffs'
                                ? 'staff'
                                : teleExpertise.type === 'opinions'
                                  ? 'opinion'
                                  : 'cohort'].expertises}/${teleExpertise.id}`}
                              teleExpertiseType={teleExpertise.type}
                              value={teleExpertise.name}
                              subLabel={teleExpertise.ownerFullname}
                              label={teleExpertise.name}
                              badge={!teleExpertise.patientLimitLocked}
                            >
                              <Ui.SearchBar.ExpertiseCheckboxIcon
                                value={teleExpertise}
                              />
                            </Ui.Form.ButtonLarge>
                          </Styled.ButtonLargeLink>
                        )}
                    </Ui.Layout.FlexBox>
                  </Ui.Layout.FlexBox>
                }
                isEditMode={isEditMode.teleExpertises}
                onClick={onEnterFieldsBlock('teleExpertises', canEdit)}
                onBlur={onLeaveFieldsBlock('teleExpertises')}
                altContent={`Aucune téléexpertise n'est associée à cette organisation`}
                childRef={searchTeleExpertisesRef}
                canEdit={canEdit}
              >
                <Ui.Layout.FocusDiv ref={searchTeleExpertisesRef} tabIndex="7978987" >
                  <Ui.BasicElement.H2>Téléexpertises associées à l'organisation</Ui.BasicElement.H2>
                  <Ui.Form.InputContainer>
                    <Ui.SearchBar.SearchAndCheck
                      name="expertises"
                      query={search.expertises.value}
                      TeleExpertise={Organization}
                      resultSet={availableExpertises.filter(uniqByIdAndType)}
                      list={[...data.expertises.value]}
                      loading={search.expertises.loading}
                      resultToLabel={expertise => expertise.name}
                      resultToValue={expertise => ({ type: expertise.type, id: expertise.id, })}
                      CheckboxIconComponent={Ui.SearchBar.ExpertiseCheckboxIcon}
                      onToggle={(teleExpertise, toggled) =>
                        onTeleExpertiseToggle({
                          teleExpertiseType: teleExpertise.type,
                          teleExpertiseId: teleExpertise.id
                        }, toggled)}
                      keywordsNotFound={keywordsNotFound}
                    />
                    {data.members.errors[0] &&
                      <Ui.Form.FieldErrorMessage>{data.members.errors[0]}</Ui.Form.FieldErrorMessage>
                    }
                  </Ui.Form.InputContainer>
                </Ui.Layout.FocusDiv>
              </Editable>
            </Ui.Layout.BlockContainer>
          </>
          : null
        }
      </Styled.OrganizationsContainer>
      : null
  )
}

const Portal = ({
  data,
  keywordsNotFound,
  isEditMode,
  dispatch,
  availableExpertises,
  current,
  updateField,
  search,
  updateUrlMessageBoxOpen,
  onEnterField,
  onRestoreValue,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  refs,
  canEdit,
}) => {
  const uploadData = useSelector(state => state.uploadFile.data)

  const uploadFilePortalHeaderImage = uploadData &&
    uploadData['portalProPicture'] &&
    uploadData['portalProPicture'].value
    ? Object.values(uploadData['portalProPicture'].value)[0]
    : {}
  const uploadFilePortalHeaderImageFileName = uploadFilePortalHeaderImage?.fileName

  const expertises = availableExpertises.filter(expertise =>
    data.expertises.value.some(exp => exp.type === expertise.type && Number(exp.id) === Number(expertise.id)))
  const [descriptionCharactersLeft, setDescriptionCharactersLeft] = useState(data.description.value && data.description.value.length);

  useEffect(() => {
    dispatch(Profile.updateField({
      name: 'message',
      value: `Bonjour,\nMerci de modifier notre url de connexion au portail : ${data.url.value}\nCordialement,\n${data.firstname.value} ${data.lastname.value}`
    }))
  }, [dispatch, data.firstname.value, data.lastname.value, data.url.value])

  useEffect(() => {
    setDescriptionCharactersLeft(data.description.value
      ? descriptionMaxLength - data.description.value.length
      : descriptionMaxLength)
  }, [data.description.value])

  const onLeaveField = (fieldName) => e => {
    if (data[fieldName].isFieldEdit && !e.currentTarget.contains(e.relatedTarget)) {
      if (data[fieldName].value === '' || data[fieldName].value !== data[fieldName].initialValue)
        dispatch(Profile.saveField({ fieldName, scope: Profile.SCOPE.portalPro }))
      else
        dispatch(Profile.setEditField({ field: fieldName, value: false }))
    }
  }

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      dispatch(Profile.saveField({ fieldName: e.target.name, scope: Profile.SCOPE.portalPro }))
    }
    if (e.key === 'Escape') {
      onRestoreValue(e.target.name, data[e.target.name].initialValue)()
    }
  }

  const onRemovePortalProPicture = ({ id }) => dispatch(Profile.removePortalProPicture())
  const onRemovePortalProLogo = ({ id }) => dispatch(Profile.removePortalProLogo())

  const messageRef = useRef();
  const expertisesRef = useRef();
  const informationRef = useRef();

  return (current === 4) && (
    <Ui.Layout.Section>
      <Ui.BasicElement.P>
        Réunissez l'ensemble de vos téléexpertises au sein
        d'un portail dédié et personnalisable, facilement
        accessible par tous vos correspondants.
      </Ui.BasicElement.P>
      <Ui.BasicElement.H2>
        Connexion
      </Ui.BasicElement.H2>
      <Ui.BasicElement.H3>
        URL du portail Pro
      </Ui.BasicElement.H3>
      <Editable
        text={data.url.value}
        altText="Aucune url n'est associée à ce portail Pro"
        isEditMode={isEditMode.message}
        onClick={onEnterFieldsBlock('message', canEdit)}
        onBlur={onLeaveFieldsBlock('message')}
        altContent="L/'URL n\'est pas renseignée"
        childRef={messageRef}
        canEdit={canEdit}
      >
        <Ui.Layout.FocusDiv ref={messageRef} tabIndex="909">
          {updateUrlMessageBoxOpen
            ? <Styled.UrlBlockContainer>
              <Ui.BasicElement.P>
                Demande de modification de votre URL de connexion:
              </Ui.BasicElement.P>
              <Ui.Layout.BlockContainer>
                <Ui.Form.TextAreaInputResizable
                  htmlFor="url"
                  label=""
                  value={data.message.value}
                  onChange={updateField('message')}
                  noSubmit
                  error={data.message.errors[0]}
                />
              </Ui.Layout.BlockContainer>
              <Ui.Button.BasicButton
                isGreen
                onClick={() => dispatch(Profile.sendMessage(data.message.value))}
              >
                Envoyer
              </Ui.Button.BasicButton>
            </Styled.UrlBlockContainer>
            : <>
              <Ui.BasicElement.P>
                Demande de modification de votre URL de connexion:
              </Ui.BasicElement.P>
              <Ui.Button.BasicButton
                isGreen
                isFilled
                onClick={() => dispatch(Profile.setUrlMessageBox(!updateUrlMessageBoxOpen))}
              >
                Changer d'URL
              </Ui.Button.BasicButton>
            </>
          }
        </Ui.Layout.FocusDiv>
      </Editable>
      {data.url.value &&
        <><Styled.ShowPortalButton>
          <a href={data.url.value} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer">
            <Ui.Button.BasicButton isSmall isFilled isGreen>
              Voir le portail
            </Ui.Button.BasicButton>
          </a>
        </Styled.ShowPortalButton>
          <Ui.Layout.BlockContainer>
            <a href={data.url.value} rel="noreferrer" target="_blank">
              <DownloadableQrCode
                teleExpertiseName={data.name.value}
              >
                <QRCode
                  value={data.url.value}
                  size={90}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  includeMargin={false}
                  id="qrCodeCanvas"
                />
              </DownloadableQrCode>
            </a>
          </Ui.Layout.BlockContainer>
        </>
      }
      <Ui.BasicElement.H2>
        Personnalisation
      </Ui.BasicElement.H2>
      <Ui.BasicElement.ViewTitleContainer>
        <Ui.BasicElement.H3>Logo</Ui.BasicElement.H3>
      </Ui.BasicElement.ViewTitleContainer>
      <Ui.Layout.BlockContainer>
        <Ui.UploadFile.ImagesUpload
          label=""
          name="portalProLogo"
          id="portalProLogo"
          htmlFor="portalProLogo"
          multiple={false}
          onRemove={onRemovePortalProLogo}
          endpoint="/portal/update-logo"
          value={data.portalProLogo.value ? [{ fileName: data.portalProLogo.value }] : []}
          validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
          previewUri="/portal/logo"
        />
      </Ui.Layout.BlockContainer>
      <Ui.Layout.FlexBox>
        <Ui.Layout.BlockContainer>
          <Ui.BasicElement.ViewTitleContainer>
            <Ui.BasicElement.H3>Image d'en-tête</Ui.BasicElement.H3>
          </Ui.BasicElement.ViewTitleContainer>
          <Ui.UploadFile.ImagesUpload
            label=""
            name="portalProPicture"
            id="portalProPicture"
            htmlFor="portalProPicture"
            multiple={false}
            onRemove={onRemovePortalProPicture}
            endpoint="/portal/update-picture"
            value={data.portalProPicture.value ? [{ fileName: data.portalProPicture.value }] : []}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
            previewUri="/portal/picture"
          />
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          {(uploadFilePortalHeaderImageFileName || data.portalProPicture.value) &&
            <>
              <ImageTool
                alignKey='portalProPictureAlign'
                stretchKey='portalProPictureStretch'
                data={data}
                slice={Profile}
                isStretched={data.portalProPictureStretch.value}
                alignment={data.portalProPictureAlign.value}
                image={uploadFilePortalHeaderImageFileName || data.portalProPicture.value}
                isEditMode={true}
                endpointPath=''
                canEdit={canEdit}
              />
            </>
          }
        </Ui.Layout.BlockContainer>
      </Ui.Layout.FlexBox>
      <Ui.BasicElement.H2>
        Contenus
      </Ui.BasicElement.H2>
      <Ui.Layout.Row>
        <Ui.Form.EditableTextInput
          name="name"
          label="Titre *"
          data={data}
          updateField={updateField}
          onEnterField={onEnterField}
          onLeaveField={onLeaveField}
          onRestoreValue={onRestoreValue}
          onKeyPress={onKeyPress}
          refs={refs}
          canEdit={canEdit}
        />
      </Ui.Layout.Row>
      <Ui.Layout.Row>
        <Editable
          title="Information aux correspondants *"
          text={data.description.value}
          isEditMode={data.description.isFieldEdit}
          onClick={onEnterField('description', canEdit)}
          onBlur={onLeaveField('description')}
          childRef={informationRef}
          canEdit={canEdit}
        >
          <Ui.Form.TextAreaInputResizable
            name="description"
            label="Information aux correspondants *"
            value={data.description.value}
            onChange={updateField('description')}
            onBlur={onLeaveField('description')}
            onRestoreValue={onRestoreValue('description', data.description.initialValue)}
            error={data.description.errors[0]}
            inputRef={informationRef}
            footer={descriptionCharactersLeft
              ? `${descriptionCharactersLeft} ${pluralize('caractère', descriptionCharactersLeft)} ${pluralize('restant', descriptionCharactersLeft)}`
              : 'Vous avez atteint le nombre de caractères autorisés par la description'
            }
          />
        </Editable>
      </Ui.Layout.Row>
      <Ui.Layout.BlockContainer>
        <Ui.BasicElement.H2>Téléexpertises</Ui.BasicElement.H2>
        <Editable
          content={expertises.filter(uniqByIdAndType).length > 0
            ?
            <Ui.Layout.FlexBox flow="column wrap">
              <Ui.BasicElement.P>Gérez les RCPs, cohortes et expertises des services liés à votre portail.</Ui.BasicElement.P>
              <Ui.Layout.FlexBox flow="row wrap">
                {expertises.filter(uniqByIdAndType).map((teleExpertise, index) =>
                  <Styled.ButtonLargeLink
                    key={index}
                    onClick={e => e.stopPropagation()}>
                    <Ui.Form.ButtonLarge
                      to={`${privateSitePrefix}/${fr[teleExpertise.type === 'staffs'
                        ? 'staff'
                        : teleExpertise.type === 'opinions'
                          ? 'opinion'
                          : 'cohort'].expertises}/${teleExpertise.id}`}
                      teleExpertiseType={teleExpertise.type}
                      value={teleExpertise.name}
                      subLabel={teleExpertise.ownerFullname}
                      label={teleExpertise.name}
                      badge={!teleExpertise.patientLimitLocked}
                    >
                      <Ui.SearchBar.ExpertiseCheckboxIcon
                        value={teleExpertise}
                      />
                    </Ui.Form.ButtonLarge>
                  </Styled.ButtonLargeLink>
                )}
              </Ui.Layout.FlexBox>
            </Ui.Layout.FlexBox>
            : <Ui.BasicElement.P italic>Aucune téléexpertise n'est associée à ce portail.</Ui.BasicElement.P>
          }
          isEditMode={isEditMode.expertises}
          onClick={onEnterFieldsBlock('expertises', canEdit)}
          onBlur={onLeaveFieldsBlock('expertises')}
          childRef={expertisesRef}
          canEdit={canEdit}
        >
          <Ui.Layout.FocusDiv ref={expertisesRef} tabIndex="809890" >
            <Ui.BasicElement.P>Gérez les RCPs, cohortes et expertises des services liés à votre portail.</Ui.BasicElement.P>
            <Ui.Form.InputContainer>
              <Ui.SearchBar.SearchAndCheck
                name="expertises"
                query={search.expertises.value}
                TeleExpertise={Profile}
                resultToLabel={expertise => expertise.name}
                resultToValue={expertise => ({ type: expertise.type, id: expertise.id, })}
                subLabel={expertise => expertise.ownerFullname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}
                resultSet={availableExpertises.filter(uniqByIdAndType)}
                list={[...data.expertises.value]}
                loading={search.expertises.loading}
                CheckboxIconComponent={Ui.SearchBar.ExpertiseCheckboxIcon}
                onToggle={({ type, id }, toggled) => {
                  if (toggled) {
                    dispatch(Profile.addExpertise({ type, id }))
                  } else {
                    dispatch(Profile.removeExpertise({ type, id }))
                  }
                }}
                keywordsNotFound={keywordsNotFound}
              />
            </Ui.Form.InputContainer>
          </Ui.Layout.FocusDiv>
        </Editable>
      </Ui.Layout.BlockContainer>
    </Ui.Layout.Section >
  )
}

const Subscription = ({
  data,
  dispatch,
  search,
  updateField,
  current,
  currentUser,
  availableExpertises,
  estimateRequestMessageBoxOpen,
  pending,
  offerSelected,
  quantityTeleExpertiseSubscrib,
  loadingSubscription,
  isEditMode,
  subscriptionExpertisesLoading,
  pageEndRef,
  scrollToBottom,
}) => {
  const onTeleExpertiseQueryChange = (e) => dispatch(Profile.setQuery({
    name: 'subscriptionExpertises',
    value: e.target.value,
    onlyLocked: 1
  }))
  const onTeleExpertiseToggle = (value, toggled, name = 'subscriptionExpertises') => {
    dispatch(Profile.toggle({ value, toggled, name }))
    dispatch(Profile.setEditMode({ target: 'subscription', value: false }))
  }

  const [offerName, setOfferName] = useState('pro')
  const onEstimateRequest = (offerName, type) => {
    dispatch(Profile.selectOffer(offerName))
    setOfferName(offerName)
    dispatch(Profile.updateField({
      name: 'estimateMessage',
      value: 'new' === type
        ? `Bonjour,\nJe suis intéressé par votre formule Skemeet ${offerName === 'pro' ? 'Pro' : 'Pro+'}.\nCordialement,\n${data.firstname.value} ${data.lastname.value}`
        : `Bonjour,\nJe souhaiterais augmenter le nombre de téléexpertise de mon abonnement ${offerName === 'pro' ? 'Pro' : 'Pro+'}.\nCordialement,\n${data.firstname.value} ${data.lastname.value}`
    }))
    dispatch(Profile.setEstimateRequestMessageBox(true))
  }

  const updateSubscription = (offerName) => {
    if ('Pro' === offerName) {
      dispatch(Profile.selectOffer('pro'))
    } else if ('Pro+' === offerName) {
      onEstimateRequest('pro+', 'update')
    }
  }

  const updateQuantityTeleExpertiseSubscrib = e => {
    dispatch(Profile.updateQuantityTeleExpertiseSubscrib(e.target.value))
  }

  return (current === 5) && (
    <>
      {currentUser.activeSubscription
        ?
        <>
          <Ui.BasicElement.P>
            Choisissez le nombre de téléexpertises que vous souhaitez débloquer.
            Vous pourrez ensuite les sélectionner parmi vos expertises,
            RCPs ou cohortes. Vos correspondants pourront alors y
            ajouter un nombre illimité de patients.
          </Ui.BasicElement.P>
          <Ui.BasicElement.H2>Votre abonnement</Ui.BasicElement.H2>
          {'ROLE_SUPER_ADMIN' === currentUser.role
            ? <Ui.BasicElement.P>Vous êtes administrateur du site, veuillez passer par le backoffice.</Ui.BasicElement.P>
            : <>
              <Styled.ActiveSubscriptionDetailContainer>
                <Ui.BasicElement.P>Nombre de téléexpertises {currentUser.activeSubscription.offerName} : <Ui.Global.GreenText>{currentUser.activeSubscription.unlockTeleExpertise} / {currentUser.activeSubscription.limitUnlockTeleExpertise}</Ui.Global.GreenText></Ui.BasicElement.P>
                {currentUser.activeSubscription.pricingPerMonth &&
                  <Ui.BasicElement.P>Abonnement mensuel : {currentUser.activeSubscription.pricingPerMonth}€ TTC</Ui.BasicElement.P>
                }
                {currentUser.activeSubscription.nextBillingDate &&
                  <Ui.BasicElement.P>Date de renouvellement : {currentUser.activeSubscription.nextBillingDate}</Ui.BasicElement.P>
                }
                {!offerSelected &&
                  <Styled.UpdateSubscription onClick={() => updateSubscription(currentUser.activeSubscription.offerName)}>
                    Modifier mon abonnement
                  </Styled.UpdateSubscription>
                }
                {'pro' === offerSelected &&
                  <Styled.PaymentCardContainer>
                    <Ui.Form.Label htmlFor="teleExpertiseNumber">
                      {`Choisissez le nombre de téléexpertises PRO de votre abonnement (${quantityTeleExpertiseSubscrib * 120}€/mois TTC)`}
                    </Ui.Form.Label>
                    <Styled.PaymentCardContainerSelect>
                      <Ui.Select.Select
                        id="teleExpertiseNumber"
                        items={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
                          .map(item => ({
                            label: item,
                            value: item,
                            selected: Number(item) === Number(quantityTeleExpertiseSubscrib),
                          }))
                        }
                        onChange={updateQuantityTeleExpertiseSubscrib}
                      />
                    </Styled.PaymentCardContainerSelect>
                    <Styled.UpdateSubscriptionButton
                      isGreen
                      isFilled
                      isSmall
                      disabled={loadingSubscription}
                      onClick={() => dispatch(Profile.saveSubscription({ quantityTeleExpertiseSubscrib: quantityTeleExpertiseSubscrib }))}
                    >
                      {loadingSubscription && <Ui.Global.Loader />}
                      Valider
                    </Styled.UpdateSubscriptionButton>
                    <Ui.Button.BasicButton
                      isGrey
                      isFilled
                      isSmall
                      disabled={loadingSubscription}
                      onClick={() => dispatch(Profile.selectOffer(null))}
                    >
                      Annuler
                    </Ui.Button.BasicButton>
                  </Styled.PaymentCardContainer>
                }
              </Styled.ActiveSubscriptionDetailContainer>
              <Ui.BasicElement.H2>Mes téléexpertises PRO</Ui.BasicElement.H2>
              {!isEditMode.subscription &&
                <>
                  <Styled.UpdateTeleExpertiseButton onClick={() => dispatch(Profile.setEditMode({ target: 'subscription', value: true }))}>
                    {subscriptionExpertisesLoading && <Ui.Global.Loader />}
                    Modifier mes téléexpertises PRO
                  </Styled.UpdateTeleExpertiseButton>
                  {!subscriptionExpertisesLoading &&
                    <Ui.Layout.FlexBox flow="row wrap">
                      {data.subscriptionExpertises.value.map((teleExpertise, index) =>
                        <Styled.ButtonLargeLink
                          key={index}
                          onClick={e => e.stopPropagation()}
                        >
                          <Ui.Form.ButtonLarge
                            to={`${privateSitePrefix}/${fr[teleExpertise.type === 'staffs'
                              ? 'staff'
                              : teleExpertise.type === 'opinions'
                                ? 'opinion'
                                : 'cohort'].expertises}/${teleExpertise.id}`}
                            teleExpertiseType={teleExpertise.type}
                            value={teleExpertise.name}
                            subLabel={`${teleExpertise.ownerTitle} ${teleExpertise.ownerFullname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}`}
                            label={teleExpertise.name}
                            badge={!teleExpertise.patientLimitLocked}
                            isClickable
                          >
                            <Ui.SearchBar.DefaultCheckboxIcon
                              isPurple={teleExpertise.type === 'staffs'}
                              isRed={teleExpertise.type === 'cohorts'}
                              isBlue={teleExpertise.type === 'opinions'}
                              value={teleExpertise}
                            />
                          </Ui.Form.ButtonLarge>
                        </Styled.ButtonLargeLink>
                      )}
                    </Ui.Layout.FlexBox>
                  }
                </>
              }
              {currentUser.activeSubscription && isEditMode.subscription &&
                <Ui.Layout.Section>
                  <Ui.SearchBar.SearchAndCheck
                    name="expertises"
                    query={search.subscriptionExpertises.value}
                    TeleExpertise={Profile}
                    resultToLabel={expertise => expertise.name}
                    resultToValue={expertise => ({ type: expertise.type, id: expertise.id, name: expertise.name, ownerFullname: expertise.ownerFullname, ownerTitle: expertise.ownerTitle })}
                    subLabel={expertise => expertise.ownerFullname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase())}
                    resultSet={availableExpertises.filter(expertise =>
                      (
                        expertise.type.includes('staff') &&
                        currentUser.activeSubscription.unlockStaffIds.includes(String(expertise.id))
                      ) ||
                      (
                        expertise.type.includes('cohort') &&
                        currentUser.activeSubscription.unlockCohortIds.includes(String(expertise.id))
                      ) ||
                      (
                        expertise.type.includes('opinion') &&
                        currentUser.activeSubscription.unlockOpinionIds.includes(String(expertise.id))
                      ) ||
                      expertise.patientLimitLocked
                    ).filter(uniqByIdAndType)}
                    list={[...data.subscriptionExpertises.value]}
                    loading={search.subscriptionExpertises.loading}
                    CheckboxIconComponent={Ui.SearchBar.ExpertiseCheckboxIcon}
                    onToggle={onTeleExpertiseToggle}
                    onQueryChange={onTeleExpertiseQueryChange}
                    placeholder="Rechercher une téléexpertise"
                  />
                </Ui.Layout.Section>
              }
            </>
          }
        </>
        : <Ui.Layout.Section>
          <Styled.Intro offerSelected={offerSelected ? true : false}>
            Vous n'avez pas encore d'abonnement à Skemeet :
            vos téléexpertises sont limitées à 10 patients partagés.
            Passez à <Ui.BasicElement.Span bold isPrimary>Skemeet PRO</Ui.BasicElement.Span>.
          </Styled.Intro>
          <Styled.SubscriptionContainer>
            <Styled.SubscriptionPackage offerSelected={'pro' === offerSelected} highlight>
              <Styled.SubscriptionPackageBody>
                <Ui.BasicElement.H2>
                  Skemeet{'\u00A0'}
                  <Ui.BasicElement.Span bold>PRO</Ui.BasicElement.Span>
                </Ui.BasicElement.H2>
                <Ui.BasicElement.P>
                  Upgradez vos téléexpertises pour partager
                  un nombre illimité de patients.
                </Ui.BasicElement.P>
                <Styled.SubscriptionPackageRow>
                  <Ui.Icon.IconH1>
                    <Ui.Icon.IconShare />
                  </Ui.Icon.IconH1>
                  <Ui.BasicElement.P big bold>Téléexpertises{'\u00A0'}
                    <Ui.BasicElement.Span isPrimary bold>
                      PRO
                    </Ui.BasicElement.Span>
                  </Ui.BasicElement.P>
                </Styled.SubscriptionPackageRow>
                <Styled.SubscriptionPackageRow>
                  <Ui.Icon.IconH1>
                    <Ui.Icon.IconPatientSup />
                  </Ui.Icon.IconH1>
                  <Ui.BasicElement.P big bold>Patients{'\u00A0'}
                    <Ui.BasicElement.Span isPrimary bold>
                      illimités
                    </Ui.BasicElement.Span>
                  </Ui.BasicElement.P>
                </Styled.SubscriptionPackageRow>
                {/* <Styled.Price>100€/mois (HT)</Styled.Price>
                <Styled.SubPrice>par téléexpertise</Styled.SubPrice> */}
              </Styled.SubscriptionPackageBody>
              <Styled.SubscriptionPackageFooter>
                <Ui.Button.BasicButton
                  isGreen
                  isFilled
                  onClick={() => {
                    dispatch(ContactUs.updateField({ name: 'subject', value: 'Demande de devis depuis Skemeet' }))
                    onEstimateRequest('pro', 'new')
                    scrollToBottom()
                  }}
                >
                  Demander un devis
                </Ui.Button.BasicButton>
              </Styled.SubscriptionPackageFooter>
            </Styled.SubscriptionPackage>
            <Styled.SubscriptionPackage offerSelected={'pro+' === offerSelected} highlight>
              <Styled.SubscriptionPackageBody>
                <Ui.BasicElement.H2>
                  Skemeet{'\u00A0'}
                  <Ui.BasicElement.Span bold>PRO+</Ui.BasicElement.Span>
                </Ui.BasicElement.H2>
                <Ui.BasicElement.P>
                  Un site internet personnalisé regroupant
                  les téléexpertises de votre organisation.
                </Ui.BasicElement.P>
                <Styled.SubscriptionPackageRow>
                  <Ui.Icon.IconH1>
                    <Ui.Icon.IconShare />
                  </Ui.Icon.IconH1>
                  <Ui.BasicElement.P big bold>Téléexpertises{'\u00A0'}
                    <Ui.BasicElement.Span isPrimary bold>
                      PRO
                    </Ui.BasicElement.Span>
                  </Ui.BasicElement.P>
                </Styled.SubscriptionPackageRow>
                <Styled.SubscriptionPackageRow>
                  <Ui.Icon.IconH1>
                    <Ui.Icon.IconPatientSup />
                  </Ui.Icon.IconH1>
                  <Ui.BasicElement.P big bold>Patients{'\u00A0'}
                    <Ui.BasicElement.Span isPrimary bold>
                      illimités
                    </Ui.BasicElement.Span>
                  </Ui.BasicElement.P>
                </Styled.SubscriptionPackageRow>

                <Styled.SubscriptionPackageRow>
                  <Ui.Icon.IconH1>
                    <Ui.Icon.IconHospitalBuilding />
                  </Ui.Icon.IconH1>
                  <Ui.BasicElement.P big bold>Votre{'\u00A0'}
                    <Ui.BasicElement.Span isPrimary bold>
                      organisation
                    </Ui.BasicElement.Span>
                  </Ui.BasicElement.P>
                </Styled.SubscriptionPackageRow>
                <Styled.SubscriptionPackageRow>
                  <Ui.Icon.IconH1>
                    <Ui.Icon.IconShare />
                  </Ui.Icon.IconH1>
                  <Ui.BasicElement.P big bold>Un site{'\u00A0'}
                    <Ui.BasicElement.Span isPrimary bold>
                      dédié
                    </Ui.BasicElement.Span>
                  </Ui.BasicElement.P>
                </Styled.SubscriptionPackageRow>
              </Styled.SubscriptionPackageBody>
              <Styled.SubscriptionPackageFooter>
                <Ui.Button.BasicButton
                  isGreen
                  isFilled
                  onClick={() => {
                    dispatch(Profile.selectOffer('pro+'))
                    onEstimateRequest('pro+', 'new')
                    scrollToBottom()
                  }}
                >
                  Demander un devis
                </Ui.Button.BasicButton>
              </Styled.SubscriptionPackageFooter>
            </Styled.SubscriptionPackage>
          </Styled.SubscriptionContainer>
        </Ui.Layout.Section>
      }
      {['pro', 'pro+'].includes(offerSelected) && estimateRequestMessageBoxOpen &&
        <>
          <Ui.Form.TextAreaInput
            htmlFor="estimateMessage"
            label={`Votre demande de devis Skemeet ${offerName === 'pro' ? 'Pro' : 'Pro+'} :`}
            value={data.estimateMessage.value}
            onChange={updateField('estimateMessage')}
            noSubmit
          />
          <Styled.GreenPlainButton
            $isGreen
            onClick={() => dispatch(Profile.sendEstimateRequest({
              offerName,
              message: data.estimateMessage.value
            }))}
          >
            {pending && <Ui.Global.Loader />}
            Envoyer
          </Styled.GreenPlainButton>
        </>
      }
      {/* On commente cette partie le temps de redévelopper un module de paiment CB francais */}
      {/* {offerSelected === 'pro' && !currentUser.activeSubscription && stripePromise !== null &&
        <Elements stripe={stripePromise}>
          <CheckoutForm
            dispatch={dispatch}
            quantityTeleExpertiseSubscrib={quantityTeleExpertiseSubscrib}
            updateQuantityTeleExpertiseSubscrib={updateQuantityTeleExpertiseSubscrib}
            loadingSubscription={loadingSubscription}
          />
        </Elements>
      } */}
      <div ref={pageEndRef} />
    </>
  )
}

// const CheckoutForm = ({
//   dispatch,
//   quantityTeleExpertiseSubscrib,
//   updateQuantityTeleExpertiseSubscrib,
//   loadingSubscription
// }) => {
//   const CARD_ELEMENT_OPTIONS = {
//     style: {
//       base: {
//         color: '#32325d',
//         fontFamily: '"Montserrat", sans-serif',
//         fontSmoothing: 'antialiased',
//         fontSize: '16px',
//         '::placeholder': {
//           color: '#aab7c4'
//         }
//       },
//       invalid: {
//         color: '#fa755a',
//         iconColor: '#fa755a'
//       }
//     },
//     hidePostalCode: true
//   };

//   const [error, setError] = useState(null)
//   const stripe = useStripe()
//   const elements = useElements()

//   // Handle real-time validation errors from the card Element.
//   const handleChange = (event) => {
//     if (event.error) {
//       setError(event.error.message)
//     } else {
//       setError(null)
//     }
//   }

//   // Handle form submission.
//   const handlePaymentSubmit = async (event) => {
//     event.preventDefault()
//     const card = elements.getElement(CardElement)
//     const result = await stripe.createToken(card)
//     if (result.error) {
//       // Inform the user if there was an error.
//       setError(result.error.message)
//     } else {
//       setError(null)
//       // Send the token to your server.
//       dispatch(Profile.receivedPaymentCardData({ quantityTeleExpertiseSubscrib: quantityTeleExpertiseSubscrib, paymentCardToken: result.token.id, paymentCardData: result.token.card }))
//     }
//   }

//   return (
//     <Styled.PaymentCardContainer>
//       <Ui.BasicElement.H2>Abonnement</Ui.BasicElement.H2>
//       <Ui.Form.Label htmlFor="teleExpertiseNumber">
//         {`Choisissez le nombre de téléexpertises PRO de votre abonnement (${quantityTeleExpertiseSubscrib * 120}€/mois TTC)`}
//       </Ui.Form.Label>
//       <Styled.PaymentCardContainerSelect>
//         <Ui.Select.Select
//           id="teleExpertiseNumber"
//           items={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
//             .map(item => ({
//               label: item,
//               value: item,
//               selected: item === quantityTeleExpertiseSubscrib,
//             }))
//           }
//           onChange={updateQuantityTeleExpertiseSubscrib}
//         />
//       </Styled.PaymentCardContainerSelect>
//       <form onSubmit={handlePaymentSubmit}>
//         <Styled.PaymentCardLabelContainer>
//           <Ui.Form.Label>Vos coordonnées de carte bancaire</Ui.Form.Label>
//         </Styled.PaymentCardLabelContainer>
//         <CardElement
//           id="card-element"
//           options={CARD_ELEMENT_OPTIONS}
//           onChange={handleChange}
//         />
//         <Styled.CardError>{error}</Styled.CardError>
//         <Styled.CardValidButton
//           isGreen
//           isFilled
//           type="submit"
//           disabled={loadingSubscription}
//         >
//           {loadingSubscription && <Ui.Global.Loader />}
//           S'abonner
//         </Styled.CardValidButton>
//       </form>
//     </Styled.PaymentCardContainer>
//   );
// }

export default ProfileComponent;
