import React, { useState, useEffect } from 'react'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useAlert } from 'react-alert'
import * as Styled from './Colleague.styled'
import * as Menu from '../State/Menu'
import * as Login from '../State/Login';
import * as OpinionList from '../State/OpinionList';
import * as Colleague from '../State/Colleague';
import * as Resources from '../State/Resources';
import * as Ui from './Ui'
import { privateSitePrefix } from '../Util/Config'
import { apiUrl } from '../Util/Config'
import { fr } from '../Util/i18n';
import pluralize from '../Util/Pluralize'
import LoadingBox from './Ui/LoadingBox'

const ColleagueComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentUser = useSelector(Login.selectUser)
  const {
    data,
    search,
    loading,
    expertisesData: expertises,
    availableExpertises,
    isEditMode,
    errorMessage,
    error: hasError,
  } = useSelector(state => state.colleague)

  const {
    [Resources.RESOURCE_USER_PATIENTS]: patients,
  } = useSelector(Resources.selectResources)

  const resourceLoading = useSelector(Resources.selectLoading)
  const { match: { params } } = props
  const [current, setCurrent] = useState(1)
  const colleagueId = params.id
  const myselfColleague = currentUser.id === Number(colleagueId)

  useEffect(() => {
    dispatch(Menu.changeActive(Menu.MEDECINS))

    return () => {
      dispatch(Colleague.clean())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(Colleague.fetch(params.id))

    return () => {
      dispatch(Colleague.clean())
    }
  }, [dispatch, params])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  useEffect(() => {
    const selectTab = props.location.search
    const activeTab = qs.parse(selectTab, { ignoreQueryPrefix: true }).tab
    if (activeTab !== undefined) {
      setCurrent(Number(activeTab))
    }
  }, [props.location.search])

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.ColleagueContainer>
          {!loading || hasError
            ? <Ui.Layout.PageContentWrapper>
              <Identity
                data={data}
                dispatch={dispatch}
                loading={loading}
                setCurrent={setCurrent}
                errorMessage={errorMessage}
                alert={alert}
                history={history}
              />
              <Ui.Layout.Content hasPanel noPadding>
                <Ui.Layout.NavContainer>
                  <nav>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrent(1)}
                      on={current === 1}
                    >
                      <Ui.Icon.IconInfos />
                      Informations générales
                    </Ui.Layout.NavLink>
                    <Ui.Layout.NavLink
                      onClick={() => setCurrent(2)}
                      on={current === 2}
                    >
                      <Ui.Icon.IconShare />
                      Téléexpertises
                    </Ui.Layout.NavLink>
                    {!myselfColleague &&
                      <>
                        <Ui.Layout.NavLink
                          onClick={() => setCurrent(3)}
                          on={current === 3}
                        >
                          <Ui.Icon.IconPatientSup />
                          Présenter un patient
                        </Ui.Layout.NavLink>
                        <Ui.Layout.NavLink
                          onClick={() => history.push(`${privateSitePrefix}/nouveau/message/${params.id}`)}
                          on={current === 4}
                        >
                          <Ui.Icon.IconMessage />
                          Envoyer un message
                        </Ui.Layout.NavLink>
                      </>
                    }
                  </nav>
                </Ui.Layout.NavContainer>
                <Ui.Layout.PaddedContent>
                  <Summary
                    data={data}
                    patients={patients}
                    search={search}
                    resourceLoading={resourceLoading}
                    dispatch={dispatch}
                    current={current}
                    currentUser={currentUser}
                    colleagueId={colleagueId}
                    isEditMode={isEditMode}
                    setCurrent={setCurrent}
                  />
                  <TeleExpertises
                    current={current}
                    data={data}
                    search={search}
                    dispatch={dispatch}
                    availableExpertises={availableExpertises}
                    expertises={expertises}
                    myselfColleague={myselfColleague}
                  />
                  <Opinion
                    expertises={expertises}
                    current={current}
                    setCurrent={setCurrent}
                    colleagueId={colleagueId}
                  />
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
            : <Ui.Layout.FlexContainer><Ui.Global.Loader /></Ui.Layout.FlexContainer>
          }
        </Styled.ColleagueContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const Identity = ({
  data,
  history,
}) => {
  const {
    pictureFileName,
    firstname,
    lastname,
    speciality,
    city,
    profilePicture,
  } = data;

  const profilePictureFile = pictureFileName?.value
    ? `${apiUrl}/users/profile-picture/${pictureFileName.value}`
    : data?.profilePicture?.value
      ? `${apiUrl}/users/profile-picture/${profilePicture.value}`
      : '/Avatar_default.png'

  return (
    <Ui.Layout.PanelContainer>
      <Ui.Layout.Panel>
        <>
          <header>
            <Ui.Button.BackButton onClick={history.goBack} />
          </header>
          <Ui.BasicElement.H1Container>
          </Ui.BasicElement.H1Container>
          <Styled.ProfilePicture
            picture={profilePictureFile}
          />
          <Ui.BasicElement.H1>
            {firstname.value} {lastname.value}
          </Ui.BasicElement.H1>
          <Styled.SubTitlesContainer>
            <Ui.BasicElement.P>
              {speciality.value}
            </Ui.BasicElement.P>
            <Ui.BasicElement.P>
              {city.value}
            </Ui.BasicElement.P>
          </Styled.SubTitlesContainer>
        </>
      </Ui.Layout.Panel>
    </Ui.Layout.PanelContainer>
  )
}

const Summary = ({
  data,
  colleagueId,
  current,
  dispatch,
  currentUser,
}) => {
  const {
    speciality,
    city,
    firstname,
    lastname,
    rppsNumber,
  } = data;

  return (current === 1) && (
    <Styled.SummarySection>
      <Ui.BasicElement.H2 id="summary">
        Informations générales
      </Ui.BasicElement.H2>
      <Ui.Layout.Row>
        <div>
          <Ui.BasicElement.H3>Nom</Ui.BasicElement.H3>
          <Ui.BasicElement.P>{lastname.value}</Ui.BasicElement.P>
        </div>
        <div>
          <Ui.BasicElement.H3>Prénom</Ui.BasicElement.H3>
          <Ui.BasicElement.P>{firstname.value}</Ui.BasicElement.P>
        </div>
      </Ui.Layout.Row>
      <Ui.BasicElement.H3>Numéro RPPS</Ui.BasicElement.H3>
      <Ui.BasicElement.P>{rppsNumber.value || 'A compléter'}</Ui.BasicElement.P>
      <Ui.BasicElement.H3>Discipline</Ui.BasicElement.H3>
      <Ui.BasicElement.P>{speciality.value || 'A définir'}</Ui.BasicElement.P>
      <Ui.BasicElement.H3>Ville d'exercice</Ui.BasicElement.H3>
      <Ui.BasicElement.P>{city.value || 'A définir'}</Ui.BasicElement.P>
    </Styled.SummarySection>
  )
}

const ExpertiseSection = ({
  expertises,
  dispatch,
  expertiseType,
  expertiseName,
  searchBarVisible,
  setSearchBarVisible,
  availableExpertises,
  search,
  intro,
  myselfColleague,
}) => {

  const numExpertises = Object
    .entries({ staffs: 0, opinions: 0, cohorts: 0 })
    .reduce((acc, [expertise, num]) => ({
      ...acc,
      [expertise]: expertises.filter(el => el.type === expertise).length
    }), {})

  const onCheck = (e, type, id) =>
    e.target.checked
      ? dispatch(Colleague.checkExpertiseFromList({
        type,
        id,
        teleExpertise: expertises.find(expertise => expertise.id === id)
      }))
      : dispatch(Colleague.uncheckExpertiseFromList({
        type,
        id,
        teleExpertise: expertises.find(expertise => expertise.id === id)
      }))

  const onQueryChange = (e) => dispatch(Colleague.setQuery({
    name: expertiseType,
    value: e.target.value
  }))

  const singularType = {
    staffs: 'staff',
    cohorts: 'cohort',
    opinions: 'opinion'
  }

  return (
    <Styled.CardsSectionContainer>
      <Styled.CardsSectionHeader>
        <Styled.CardsSectionIcon expertise={expertiseType}>
          {expertiseType === 'staffs'
            ? <Ui.Icon.IconMessage />
            : expertiseType === 'cohorts'
              ? <Ui.Icon.IconCohorte />
              : <Ui.Icon.IconExpertise />
          }
        </Styled.CardsSectionIcon>
        <Styled.CardsSectionTitle>
          <p>{intro}</p>
          <p>{`${numExpertises[expertiseType]} ${pluralize(expertiseName, numExpertises[expertiseType])}`}</p>
        </Styled.CardsSectionTitle>
      </Styled.CardsSectionHeader>
      {!myselfColleague &&
        <Styled.ToggleSearchBar>
          <Styled.SearchBar
            open={searchBarVisible[expertiseType]}
            onClickOutside={e => setSearchBarVisible({ [expertiseType]: false })}
          >
            <Ui.SearchBar.SearchBar
              placeholder={`Rechercher un ${expertiseName} par nom, discipline ou médecin...`}
              onChange={onQueryChange}
              loading={search.expertises.loading}
              value={search[expertiseType].value}
              id={`search_${expertiseType}`}
            />
            {search[expertiseType].value &&
              <Ui.Dropdown.SearchDropdown>
                {availableExpertises.filter(el => el.type === expertiseType).length > 0
                  ? availableExpertises
                    .filter(el => el.type === expertiseType)
                    .map((expertise, i) =>
                      <Ui.Form.CheckboxDropdown
                        icon={expertise.thePersonal ? <Ui.Icon.IconDoctorCircle /> : null}
                        key={`dropdown_${expertiseType}_${i}`}
                        label={expertise.name}
                        subLabel={expertises.disciplines ? expertise.disciplines.join(', ') : ''}
                        onChange={e => onCheck(e, expertiseType, expertise.id)}
                        checked={!!expertises.find(el => el.id === expertise.id && el.type === expertiseType)}
                      >
                      </Ui.Form.CheckboxDropdown>
                    )
                  : !search.expertises.loading &&
                  <p>Aucune {expertiseName} n'a été trouvé.</p>
                }
              </Ui.Dropdown.SearchDropdown>
            }
          </Styled.SearchBar>
          <Styled.CardsSearchButton
            onClick={e => setSearchBarVisible({ [expertiseType]: true })}
            visible={!searchBarVisible[expertiseType]}
          >
            <span>Inviter à une {expertiseName}</span>
            <Ui.Icon.IconArrowRight />
          </Styled.CardsSearchButton>
        </Styled.ToggleSearchBar>
      }
      <Styled.CardsSectionBody>
        {expertises
          .filter(el => el.type === expertiseType)
          .map(expertise =>
            <Ui.Card.TelexpertiseCard
              key={`${expertiseType}_card_${expertise.id}`}
              to={expertise.currentUserAdmin || expertise.currentUserMember
                ? `${privateSitePrefix}/${fr[singularType[expertise.type]].expertises}/${expertise.id}`
                : `${privateSitePrefix}/nouveau/patient?${expertise.type}=${expertise.id}`
              }
              showDropdown={false}
              teleExpertiseType={expertiseType.replace(/s$/, '')}
              teleExpertise={expertise}
              loggedIn
              {...expertise}
            />
          )}
      </Styled.CardsSectionBody>
    </Styled.CardsSectionContainer>
  )
}

const TeleExpertises = ({
  current,
  data,
  currentUser,
  search,
  myselfColleague,
  ...props
}) => {
  const {
    staffs,
    cohorts,
    opinions,
  } = data;
  const expertises = [...staffs.value, ...cohorts.value, ...opinions.value]
  const [searchBarVisible, setSearchBarVisible] = useState({
    staffs: false,
    cohorts: false,
    opinions: false
  })

  return (current === 2) && (
    <Ui.Layout.Section>
      <Styled.TeleExpertiseCardsContainer>
        <ExpertiseSection
          expertiseName="expertise"
          expertiseType="opinions"
          expertises={expertises}
          myselfColleague={myselfColleague}
          intro="participe à"
          search={search}
          searchBarVisible={searchBarVisible}
          setSearchBarVisible={setSearchBarVisible}
          {...props}
        />
        <ExpertiseSection
          expertiseName="RCP"
          expertiseType="staffs"
          expertises={expertises}
          myselfColleague={myselfColleague}
          intro="participe à"
          search={search}
          searchBarVisible={searchBarVisible}
          setSearchBarVisible={setSearchBarVisible}
          {...props}
        />
        <ExpertiseSection
          expertiseName="cohorte"
          expertiseType="cohorts"
          expertises={expertises}
          myselfColleague={myselfColleague}
          intro="participe à"
          search={search}
          searchBarVisible={searchBarVisible}
          setSearchBarVisible={setSearchBarVisible}
          {...props}
        />
      </Styled.TeleExpertiseCardsContainer>
    </Ui.Layout.Section>
  )
}

const Opinion = ({
  current,
  expertises,
  colleagueId,
}) => {

  const teleExpertiseName = 'opinion'

  return (
    (current === 3)
      ? <Ui.Layout.Section>
        {expertises.filter(expertise => expertise.thePersonal).length > 0
          ? <Ui.Search.CardContainer>
              {expertises
                .filter(expertise => expertise.thePersonal)
                .map((expertise, i) =>
                  <Ui.Card.TelexpertiseCard
                    key={`${teleExpertiseName}_card_${i}`}
                    to={expertise.currentUserAdmin || expertise.currentUserMember
                      ? `${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${expertise.id}`
                      : `${privateSitePrefix}/nouveau/patient?opinions=${expertise.id}&colleague=${colleagueId}`
                    }
                    teleExpertiseType={teleExpertiseName}
                    isArchived={expertise.archive}
                    currentUserAdmin={expertise.currentUserAdmin}
                    patientLimitLocked={expertise.patientLimitLocked}
                    numberAddPatientAction={expertise.numberAddPatientAction}
                    permissions={{ _canBe: expertise._canBe, _canBeModified: expertise._canBeModified }}
                    showDropdown={false}
                    loggedIn
                    teleExpertiseId={expertise.id}
                    teleExpertise={expertise}
                    {...expertise}
                  />
                )}
            </Ui.Search.CardContainer>
          : <div className='text-blue-dark'>Aucune expertise proposée</div>
        }
      </Ui.Layout.Section>
      : null
  )
}

export default ColleagueComponent;
