import React, { useState, useEffect, useRef } from 'react'
import qs from 'qs'
import QRCode from 'qrcode.react';
import { useHistory, Redirect } from "react-router-dom";
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux';
import { privateSitePrefix } from '../Util/Config'
import { apiUrl } from '../Util/Config'
import { fr } from '../Util/i18n';
import { normalizeUrl } from '../Util/Format';
import { uniq, _ } from '../Util/Object'
import { cohortPublicUrl, cohortPublicPatientUrl } from '../Util/Config'
import * as TeleExpertise from '../State/Cohort';
import * as CustomField from '../State/CustomField';
import * as Resources from '../State/Resources';
import * as Login from '../State/Login';
import * as Questionnaire from '../State/Questionnaire';
import * as UploadFile from '../State/UploadFile';
import * as Menu from '../State/Menu'
import * as InvitationState from '../State/Invitation';
import * as Ui from './Ui'
import * as Styled from './Cohort.styled'
import Invitation from './Invitation';
import Tags from './Ui/Tags';
import Editable from '../Util/Editable'
import WithPermissions from './WithPermissions'
import pluralize from '../Util/Pluralize'
import CustomFieldBuilder from './Ui/CustomFieldBuilder'
import CustomFieldViewer from './Ui/CustomFieldViewer'
import QuestionnaireBuilder from './Ui/QuestionnaireBuilder'
import ImageTool from './Ui/ImageTool'
import WarningBanner from './Ui/WarningBanner'
import LoadingBox from './Ui/LoadingBox'
import DownloadableQrCode from './Ui/DowloadableQRCode';
import { getManagersDisciplines } from '../Util/TeleExpertisesHelpers';
import {
  enterField,
  leaveField,
  enterFieldsBlock,
  leaveFieldsBlock,
  keyPress,
  restoreValue,
} from '../Util/InlineEditActions';

const teleExpertiseName = 'cohort'

const CohortComponent = (props) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const alert = useAlert()
  const { match: { params: { id: teleExpertiseIdParam } } } = props
  const [current, setCurrent] = useState(1)
  const currentUser = useSelector(Login.selectUser)
  const teleExpertiseId = useSelector(TeleExpertise.selectId)
  const resourceLoading = useSelector(Resources.selectLoading)
  const forms = useSelector(CustomField.selectFormFields)
  const isEditFields = useSelector(CustomField.selectIsViewerFieldEdit)
  const isBuilderFieldEdit = useSelector(CustomField.selectIsBuilderFieldEdit)
  const isInviting = useSelector(InvitationState.selectIsInviting)
  const {
    data: {
      permissions: { value: permissions },
      owner,
      managers,
      colleagues,
      patients: patientsIds,
      organizationMembers,
      publicUri,
    },
    data,
    search,
    expertisesWithComments,
    deleted,
    errored,
    errorMessage,
    infoMessage,
    loading,
    sending,
    isEditMode,
  } = useSelector(state => state.cohort)
  const uploadData = useSelector(state => state.uploadFile.data)
  const uploadFileQuestionnaireImage = uploadData &&
    uploadData.questionnaireImage &&
    uploadData.questionnaireImage.value
    ? Object.values(uploadData.questionnaireImage.value)[0]
    : {}
  const uploadFileQuestionnaireImageFileName = uploadFileQuestionnaireImage && uploadFileQuestionnaireImage.fileName
  const {
    [Resources.RESOURCE_MEMBERS]: members,
    [Resources.RESOURCE_DISCIPLINES]: disciplines,
    [Resources.RESOURCE_PATIENTS]: patients,
    [Resources.RESOURCE_ORGANIZATIONS]: organizations,
  } = useSelector(Resources.selectResources)
  const defaultCanEdit = permissions?._canBeModified?.default

  const {
    data: questionnaireData,
    errorMessage: questionnaireErrorMessage,
  } = useSelector(state => state.questionnaire)

  const managersDisciplines = getManagersDisciplines(members, managers)

  const setEditField = (fieldName, value) =>
    dispatch(TeleExpertise.setEditField({ field: fieldName, value }))

  const setEditMode = (blockName, value) =>
    dispatch(TeleExpertise.setEditMode({ target: blockName, value }))

  const restoreInitialValue = (fieldName, value) =>
    dispatch(TeleExpertise.restoreInitialValue({ fieldName, value }))

  const saveField = (fieldName) =>
    dispatch(TeleExpertise.saveField({ fieldName }))

  const onEnterField = (fieldName, canEdit = defaultCanEdit) =>
    enterField(fieldName, canEdit, setEditField)

  const onEnterFieldsBlock = (blockName, canEdit = defaultCanEdit) =>
    enterFieldsBlock(blockName, canEdit, setEditMode)

  const onRestoreValue = (fieldName, initialValue) =>
    restoreValue(fieldName, data[fieldName], initialValue, setEditField, restoreInitialValue)

  const onLeaveField = (fieldName) =>
    leaveField(fieldName, data[fieldName], setEditField, saveField)

  const onLeaveFieldsBlock = (blockName, doSaveField = false) =>
    leaveFieldsBlock(blockName, teleExpertiseId, isEditMode, doSaveField, setEditMode, saveField)

  const onKeyPress = e =>
    keyPress(e, data, saveField, setEditField, restoreInitialValue)

  const updateField = name => e => {
    if (name === 'publicUri') {
      dispatch(TeleExpertise.updateField({ name, value: normalizeUrl(e.target.value) }))
    } else {
      dispatch(TeleExpertise.updateField({ name, value: e.target.value }))
    }
    dispatch(TeleExpertise.validateData({ name }))
  }

  const send = (e, selectedField) => {
    e.preventDefault()
    if (!sending) {
      dispatch(TeleExpertise.update({ teleExpertiseId, teleExpertiseName, selectedField }))
    }
  }

  useEffect(() => {
    const selectTab = props.location.search
    const activeTab = qs.parse(selectTab, { ignoreQueryPrefix: true }).tab
    if (activeTab) {
      setCurrent(Number(activeTab))
    }
  }, [props.location.search])

  useEffect(() => {
    dispatch(TeleExpertise.fetch({ teleExpertiseIdParam }))
    dispatch(Menu.changeActive(Menu.COHORTES))

    return () => {
      dispatch(TeleExpertise.clean())
      dispatch(CustomField.clean())
      dispatch(Questionnaire.clean())
      dispatch(UploadFile.cleanAll())
    }
  }, [teleExpertiseIdParam, dispatch])

  useEffect(() => {
    if (errorMessage || questionnaireErrorMessage) {
      const message = errorMessage || questionnaireErrorMessage
      alert.error(message, { timeout: 5000 })
    }
    if (infoMessage)
      alert.info(infoMessage, { timeout: 5000 })

  }, [alert, errorMessage, questionnaireErrorMessage, infoMessage])

  const {
    sections,
    fields,
    choices,
    error,
    errorMessage: customFieldErrorMessage,
  } = useSelector(state => state.customField)

  const membersCount = [
    ...owner.value,
    ...colleagues.value,
    ...organizationMembers.value,
    ...managers.value,
  ].filter((item, pos, arr) => arr.indexOf(item) === pos).length

  const navItems = [
    [<Ui.Icon.IconPatientSup />, `${patientsIds.value.length} ${pluralize('Patient', patientsIds.value.length)}`],
    [<Ui.Icon.IconDoctor />, `${membersCount} ${pluralize('Médecin', membersCount)}`],
    [<Ui.Icon.IconCalendar />, 'Programmation'],
    [<Ui.Icon.IconDocument />, 'Fiche patient'],
    [<Ui.Icon.IconPlanet />, 'Portail'],
    [<Ui.Icon.IconInfos />, 'Paramètres'],
  ]

  const publicUrl = [cohortPublicUrl, publicUri?.value].join('/');
  const pageEndRef = useRef(null);
  const refs = useRef({});
  const canEdit = permissions?._canBeModified?.default;

  if (deleted) {
    return <Redirect to={`${privateSitePrefix}/cohortes`} />
  }

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.CohortContainer>
          {!loading || errored
            ? <Ui.Layout.PageContentWrapper>
              <TeleExpertiseSettings
                teleExpertiseId={teleExpertiseId}
                data={data}
                loading={loading}
                errored={errored}
                teleExpertiseName={teleExpertiseName}
                history={history}
                publicUrl={publicUrl}
              />
              <Ui.Layout.Content hasPanel noPadding>
                {!loading &&
                  <Ui.Layout.NavLinks
                    navItems={navItems}
                    current={current}
                    setCurrent={setCurrent}
                  />
                }
                {current === 1 &&
                  <WarningBanner
                    data={data}
                    currentUser={currentUser}
                    teleExpertiseId={teleExpertiseId}
                    teleExpertiseType='cohorts'
                    slice={TeleExpertise}
                  />
                }
                <Ui.Layout.PaddedContent>
                  {!loading || errored
                    ? <Patients
                      data={data}
                      current={current}
                      sending={sending}
                      teleExpertiseId={teleExpertiseId}
                      dispatch={dispatch}
                      permissions={permissions}
                      isEditMode={isEditMode}
                      patients={patients}
                      search={search}
                      resourceLoading={resourceLoading}
                      teleExpertiseName={teleExpertiseName}
                      expertisesWithComments={expertisesWithComments}
                      onEnterFieldsBlock={onEnterFieldsBlock}
                      onLeaveFieldsBlock={onLeaveFieldsBlock}
                    />
                    :
                    <Styled.FullSpaceContainer>
                      <Ui.Global.Loader />
                    </Styled.FullSpaceContainer>
                  }
                  <Colleagues
                    data={data}
                    dispatch={dispatch}
                    current={current}
                    members={members}
                    organizations={organizations}
                    teleExpertiseId={teleExpertiseId}
                    search={search}
                    resourceLoading={resourceLoading}
                    isEditMode={isEditMode}
                    permissions={permissions}
                    onEnterFieldsBlock={onEnterFieldsBlock}
                    onLeaveFieldsBlock={onLeaveFieldsBlock}
                    currentUser={currentUser}
                    isInviting={isInviting}
                  />
                  <PatientFile
                    dispatch={dispatch}
                    teleExpertiseId={teleExpertiseId}
                    current={current}
                    isEditMode={isEditMode}
                    sections={sections}
                    fields={fields}
                    choices={choices}
                    errorMessage={customFieldErrorMessage}
                    hasError={error}
                    data={data}
                    forms={forms}
                    isEditFields={isEditFields}
                    isBuilderFieldEdit={isBuilderFieldEdit}
                    send={send}
                    onEnterFieldsBlock={onEnterFieldsBlock}
                    onLeaveFieldsBlock={onLeaveFieldsBlock}
                    canEdit={canEdit}
                  />
                  <Scheduling
                    current={current}
                    data={questionnaireData}
                    teleExpertiseId={teleExpertiseId}
                    forms={forms}
                    permissions={permissions}
                    setCurrent={setCurrent}
                    canEdit={canEdit}
                  />
                  <Portal
                    data={data}
                    current={current}
                    dispatch={dispatch}
                    refs={refs}
                    onEnterField={onEnterField}
                    onRestoreValue={onRestoreValue}
                    setEditField={setEditField}
                    onLeaveField={onLeaveField}
                    onKeyPress={onKeyPress}
                    canEdit={canEdit}
                    updateField={updateField}
                    teleExpertiseId={teleExpertiseId}
                    send={send}
                    alert={alert}
                  />
                  <Settings
                    data={data}
                    questionnaireData={questionnaireData}
                    dispatch={dispatch}
                    current={current}
                    isEditMode={isEditMode}
                    updateField={updateField}
                    send={send}
                    disciplines={disciplines}
                    members={members}
                    permissions={permissions}
                    search={search}
                    resourceLoading={resourceLoading}
                    teleExpertiseId={teleExpertiseId}
                    teleExpertise={data}
                    pageEndRef={pageEndRef}
                    uploadFileQuestionnaireImageFileName={uploadFileQuestionnaireImageFileName}
                    onEnterField={onEnterField}
                    onRestoreValue={onRestoreValue}
                    onLeaveField={onLeaveField}
                    onEnterFieldsBlock={onEnterFieldsBlock}
                    onLeaveFieldsBlock={onLeaveFieldsBlock}
                    onKeyPress={onKeyPress}
                    refs={refs}
                    canEdit={canEdit}
                    managersDisciplines={managersDisciplines}
                    currentUser={currentUser}
                    publicUrl={publicUrl}
                  />
                </Ui.Layout.PaddedContent>
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
            : <Styled.FullSpaceContainer><Ui.Global.Loader /></Styled.FullSpaceContainer>
          }
        </Styled.CohortContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

const TeleExpertiseSettings = ({
  data,
  loading,
  teleExpertiseId,
  errored,
  history,
  publicUrl
}) => {
  const {
    name,
    description,
    pictureFileName,
    isVideoconferencingToday,
    tags,
    disciplines,
    ownerTitle,
    ownerFullname,
    patientLimitLocked,
  } = data;

  const onVisioButtonClick = () =>
    window.open(`${privateSitePrefix}/videoconference/${teleExpertiseName}s/${teleExpertiseId}`, 'visio')

  const tagList = tags.value.map((tags, i) =>
    <span key={`tags_${i}`}>{tags}</span>
  )

  const disciplineList = disciplines.value
    .filter(uniq)
    .map((discipline, i) =>
      <span key={`discipline_${i}`}>{discipline}</span>
    )

  return (
    <Styled.LeftPanelContainer>
      <Ui.Layout.Panel>
        {!loading || errored
          ? <>
            <header>
              <Ui.Button.BackButton onClick={history.goBack} />
            </header>
            <Ui.BasicElement.TeleExpertisePicture
              value={{
                teleExpertiseId,
                type: teleExpertiseName,
                pictureFileName: pictureFileName.value,
              }}
            />
            <Ui.BasicElement.H1>
              {name.value}
            </Ui.BasicElement.H1>
            <Styled.SubTitlesContainer>
              <Ui.BasicElement.P dark>{description.value}</Ui.BasicElement.P>
            </Styled.SubTitlesContainer>
            {isVideoconferencingToday.value &&
              <Styled.PanelButtons>
                <Ui.Button.BasicButton
                  onClick={onVisioButtonClick}
                  isFilled
                  isPrimary
                  isSmall
                >
                  Visioconférence
                </Ui.Button.BasicButton>
              </Styled.PanelButtons>
            }
            <Ui.Layout.Section>
              <Styled.TagsContainer>
                {tags.value.length > 0 &&
                  <>
                    <Ui.BasicElement.P bold medium light>Mots-clés</Ui.BasicElement.P>
                    <Ui.Badge>{tagList}</Ui.Badge>
                  </>
                }
                {disciplines.value.length > 0 &&
                  <>
                    <Ui.BasicElement.P bold medium light>Disciplines associées</Ui.BasicElement.P>
                    <Ui.Badge>{disciplineList}</Ui.Badge>
                  </>
                }
              </Styled.TagsContainer>
              <Styled.QrCodeTitle bold medium light>Portail</Styled.QrCodeTitle>
              <Ui.Layout.BlockContainer>
                <a href={publicUrl} rel="noreferrer" target="_blank">
                  <DownloadableQrCode
                    teleExpertiseName={name.value}
                  >
                    <QRCode
                      value={publicUrl}
                      size={90}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level={"L"}
                      includeMargin={false}
                      id="qrCodeCanvas"
                    />
                  </DownloadableQrCode>
                </a>
              </Ui.Layout.BlockContainer>
              <Ui.BasicElement.P small light>
                Responsable : {ownerTitle.value} {ownerFullname.value}
              </Ui.BasicElement.P>
            </Ui.Layout.Section>
          </>
          : <Styled.FullSpaceContainer>
            <Ui.Global.Loader />
          </Styled.FullSpaceContainer>
        }
      </Ui.Layout.Panel>
      <Ui.Layout.UpgradeMessage isTeleExpertiseLocked={patientLimitLocked.value} />
    </Styled.LeftPanelContainer>
  )
}

const Portal = ({
  data,
  send,
  current,
  dispatch,
  alert,
  refs,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onKeyPress,
  canEdit,
  updateField,
  teleExpertiseId,
}) => {
  const {
    publicUri,
    portalHeaderImage,
    portalHeaderImageStretch,
    portalHeaderImageAlign,
    portalAbout,
    portalDocuments,
  } = data;
  const publicUriRef = useRef();
  const portalPictureRef = useRef();
  const portalAboutRef = useRef();
  const uploadData = useSelector(state => state.uploadFile.data)

  const publicUrl = [
    cohortPublicUrl,
    data?.publicUri?.value,
  ].join('/')

  const uploadFilePortalHeaderImage = uploadData &&
    uploadData['portal-header-image'] &&
    uploadData['portal-header-image'].value
    ? Object.values(uploadData['portal-header-image'].value)[0]
    : {}
  const uploadFilePortalHeaderImageFileName = uploadFilePortalHeaderImage?.fileName

  const onRemovePortalHeaderImage = () => {
    dispatch(TeleExpertise.updateField({ name: 'portalHeaderImage', value: '' }))
    dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'portalHeaderImage' }))
  }

  const onDocumentRemove = ({ id }) =>
    dispatch(TeleExpertise.removePortalDocument(id))

  const onAlertBeforeUrlUpdate = () =>
    alert.show(`Vous vous apprêtez à modifier l'URL. Vos correspondants ne pourront plus se connecter sur l'ancienne adresse`, {
      onConfirm: onEnterField('publicUri', canEdit)
    })

  return (current === 5) && (
    <Ui.Layout.Section>
      <Ui.Layout.Form onSubmit={send} noValidate>
        <Ui.BasicElement.H2>
          Connexion
        </Ui.BasicElement.H2>
        <Ui.BasicElement.H3>
          Url du portail
        </Ui.BasicElement.H3>
        <Ui.BasicElement.P>
          Personnalisez puis partagez cette adresse internet pour que vos correspondants accèdent directement à votre téléexpertise.
        </Ui.BasicElement.P>
        <Editable
          text={publicUrl}
          isEditMode={publicUri.isFieldEdit}
          onClick={onAlertBeforeUrlUpdate}
          onBlur={onLeaveField('publicUri')}
          childRef={publicUriRef}
          canEdit={canEdit}
        >
          <Ui.Layout.PublicUriContainer flow="column wrap">
            <Ui.Layout.FlexBox flow="row wrap">
              <Ui.BasicElement.P>{`${cohortPublicUrl}/`}</Ui.BasicElement.P>
              <div>
                <Ui.Form.TextInput
                  name="publicUri"
                  value={normalizeUrl(publicUri.value)}
                  onChange={updateField('publicUri')}
                  onBlur={onLeaveField('publicUri')}
                  onRestoreValue={onRestoreValue('publicUri', publicUri.initialValue)}
                  onKeyPress={onKeyPress}
                  error={publicUri.errors[0]}
                  refs={refs}
                  tabIndex="4"
                />
              </div>
            </Ui.Layout.FlexBox>
          </Ui.Layout.PublicUriContainer>
        </Editable>
        <Styled.ShowPortalButton>
          <a href={publicUrl} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer">
            <Ui.Button.BasicButton isSmall isFilled isGreen>
              Voir le portail
            </Ui.Button.BasicButton>
          </a>
        </Styled.ShowPortalButton>
        <Ui.BasicElement.H2>
          Personnalisation
        </Ui.BasicElement.H2>
        <Ui.Layout.FlexBox>
          <Ui.Layout.BlockContainer>
            <Ui.BasicElement.ViewTitleContainer>
              <Ui.BasicElement.H3>Image d'en-tête</Ui.BasicElement.H3>
            </Ui.BasicElement.ViewTitleContainer>
            <Ui.UploadFile.ImagesUpload
              label=""
              name="portal-header-image"
              id="portal-header-image"
              htmlFor="portal-header-image"
              multiple={false}
              endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/add-portal-header-image`}
              removeEndpoint={`/${teleExpertiseName}s/${teleExpertiseId}/remove-portal-header-image`}
              onRemove={onRemovePortalHeaderImage}
              inputRef={portalPictureRef}
              value={portalHeaderImage.value
                ? [{ fileName: portalHeaderImage.value }]
                : []}
              validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
              previewUri={`/${teleExpertiseName}s/portal-header-image`}
              disabled={!canEdit}
            />
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            {(uploadFilePortalHeaderImageFileName || portalHeaderImage.value) &&
              <>
                <ImageTool
                  alignKey='portalHeaderImageAlign'
                  stretchKey='portalHeaderImageStretch'
                  data={data}
                  slice={TeleExpertise}
                  isStretched={portalHeaderImageStretch.value}
                  alignment={portalHeaderImageAlign.value}
                  image={uploadFilePortalHeaderImageFileName || portalHeaderImage.value}
                  isEditMode={true}
                  teleExpertiseName={teleExpertiseName}
                  teleExpertiseId={teleExpertiseId}
                  endpointPath='portal-header-image'
                  canEdit={canEdit}
                />
              </>
            }
          </Ui.Layout.BlockContainer>
        </Ui.Layout.FlexBox>
        <Ui.BasicElement.H2>
          Contenus
        </Ui.BasicElement.H2>
        <Ui.Layout.BlockContainer>
          <Editable
            title="A propos"
            text={portalAbout.value}
            isEditMode={portalAbout.isFieldEdit}
            onClick={onEnterField('portalAbout', canEdit)}
            onBlur={onLeaveField('portalAbout')}
            childRef={portalAboutRef}
            altText={canEdit
              ? `Ajouter un à-propos pour ${fr[teleExpertiseName].thisExpertise}`
              : `Aucun à-propos n'a été défini pour ${fr[teleExpertiseName].thisExpertise}`
            }
            canEdit={canEdit}
          >
            <Ui.Form.TextAreaInputResizable
              name="portalAbout"
              label="A propos"
              value={portalAbout.value}
              onChange={updateField('portalAbout')}
              onBlur={onLeaveField('portalAbout')}
              onRestoreValue={onRestoreValue('portalAbout', portalAbout.initialValue)}
              error={portalAbout.errors[0]}
              inputRef={portalAboutRef}
            />
          </Editable>
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Ui.BasicElement.H3>
            Documents
          </Ui.BasicElement.H3>
          <Ui.UploadFile.DocumentsUpload
            name={`portal-documents`}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedDocumentFormat"]}
            label=""
            endpoint={`/tele-expertises/portal/add/${teleExpertiseName}/${teleExpertiseId}`}
            apiPath="/tele-expertises/portal/assets/"
            value={portalDocuments.value}
            onRemove={onDocumentRemove}
            isEditMode={true}
            disabled={!canEdit}
          />
        </Ui.Layout.BlockContainer>
      </Ui.Layout.Form>
    </Ui.Layout.Section>
  )
}

const Settings = ({
  data,
  current,
  send,
  updateField,
  isEditMode,
  members,
  dispatch,
  disciplines: disciplinesResource,
  permissions,
  search,
  resourceLoading,
  teleExpertiseId,
  questionnaireData,
  pageEndRef,
  uploadFileQuestionnaireImageFileName,
  onEnterField,
  onRestoreValue,
  onLeaveField,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  onKeyPress,
  refs,
  canEdit,
  managersDisciplines,
  currentUser,
}) => {
  const {
    ownerId,
    owner,
    managers,
    pictureFileName,
    description,
    publicPatientUri,
    disciplines,
    archive,
  } = data;
  const alert = useAlert();

  const ownerData = members.find(member => member.id === Number(ownerId?.value))

  const managersData = members
    .filter(member =>
      managers.value.includes(member.id) &&
      member.id !== Number(ownerId?.value)
    )

  const onAddTag = () => {
    dispatch(TeleExpertise.addTag())
    tagRef?.current?.focus()
  }

  const onRemoveTag = idx => e => {
    dispatch(TeleExpertise.removeTag({ idx }))
    tagRef?.current?.focus()
  }

  const onUpdateOwner = (colleague) =>
    dispatch(TeleExpertise.updateOwner({
      formerOwner: ownerId.value,
      colleague: {
        colleagueId: colleague.id,
        colleagueTitle: colleague.title,
        colleagueFullName: `${colleague.firstname} ${colleague.lastname}`,
      },
    }))

  const updateTagContent = (name, idx) => e => {
    dispatch(TeleExpertise.updateTagContent({ name, value: e.target.value, idx }))
  }

  const removePicture = () => {
    dispatch(TeleExpertise.updateField({ name: 'pictureFileName', value: '' }))
    dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'pictureFileName' }))
  }

  const removeQuestionnaireImage = () => {
    dispatch(TeleExpertise.saveField({ teleExpertiseId, fieldName: 'patientStepsImageFileName' }))
    dispatch(Questionnaire.updateImage(''))
  }

  const onCloseTeleExpertise = (e, teleExpertiseId) => {
    alert.show(`Confirmez-vous l'archivage de ${fr[teleExpertiseName].thisExpertise} ?`, {
      onConfirm: (e) => dispatch(TeleExpertise.closeExpertise(teleExpertiseId))
    })
  }
  const onOpenTeleExpertise = (e, teleExpertiseId) => {
    alert.show(`Confirmez-vous la ré-ouverture de ${fr[teleExpertiseName].thisExpertise} ?`, {
      onConfirm: (e) => dispatch(TeleExpertise.openExpertise(teleExpertiseId))
    })
  }

  const publicPatientUrl = [
    cohortPublicPatientUrl,
    publicPatientUri.value,
  ].join('/')

  const onAlertBeforePatientUrlUpdate = () =>
    alert.show(`Voulez-vous vraiment modifier l'URL du site patient ? Vos patients ne pourront plus se connecter sur l'ancienne adresse`, {
      onConfirm: onEnterField('publicPatientUri', canEdit)
    })

  const descriptionRef = useRef();
  const tagRef = useRef();
  const publicPatientUriRef = useRef();
  const searchDisciplinesRef = useRef();
  const searchManagersRef = useRef();

  return (current === 6) && (
    <Ui.Layout.Section>
      <Ui.Layout.Form onSubmit={send} noValidate width="2">
        <Ui.Layout.Row>
          <Ui.Form.EditableTextInput
            name="name"
            label="Nom *"
            data={data}
            updateField={updateField}
            onEnterField={onEnterField}
            onLeaveField={onLeaveField}
            onRestoreValue={onRestoreValue}
            onKeyPress={onKeyPress}
            refs={refs}
            canEdit={canEdit}
            tabIndex="1"
          />
        </Ui.Layout.Row>
        <Ui.BasicElement.ViewTitleContainer>
          <Ui.BasicElement.H3>Illustration</Ui.BasicElement.H3>
        </Ui.BasicElement.ViewTitleContainer>
        <Ui.Layout.BlockContainer>
          <Ui.UploadFile.ImagesUpload
            label=""
            name="cohort-picture"
            id="cohort-picture"
            htmlFor="cohort-picture"
            multiple={false}
            endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/add-picture`}
            onRemove={removePicture}
            sliceToUpdate={TeleExpertise}
            value={pictureFileName.value ? [{ fileName: pictureFileName.value }] : []}
            validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
            previewUri={`/${teleExpertiseName}s/picture`}
            disabled={!canEdit}
          />
        </Ui.Layout.BlockContainer>
        <Ui.Layout.Row>
          <Editable
            title="Description"
            text={description.value}
            isEditMode={description.isFieldEdit}
            onClick={onEnterField('description', canEdit)}
            onBlur={onLeaveField('description')}
            childRef={descriptionRef}
            altText={canEdit
              ? `Ajouter une description pour ${fr[teleExpertiseName].thisExpertise}`
              : `Aucune description n'a été définie pour ${fr[teleExpertiseName].thisExpertise}`
            }
            canEdit={canEdit}
          >
            <Ui.Form.TextAreaInputResizable
              name="description"
              label="Description"
              value={description.value}
              onChange={updateField('description')}
              onBlur={onLeaveField('description')}
              onRestoreValue={onRestoreValue('description', description.initialValue)}
              error={description.errors[0]}
              inputRef={descriptionRef}
            />
          </Editable>
        </Ui.Layout.Row>
        <Ui.Layout.BlockContainer>
          <Tags
            TeleExpertise={TeleExpertise}
            teleExpertiseName={teleExpertiseName}
            data={data}
            type="tag"
            label="mot-clé"
            isEditMode={isEditMode.tags}
            onAddItem={onAddTag}
            onRemoveItem={(idx) => onRemoveTag(idx)}
            onUpdateItemContent={(type, idx) => updateTagContent(type, idx)}
            onEnterFieldsBlock={onEnterFieldsBlock('tags', canEdit)}
            onLeaveFieldsBlock={onLeaveFieldsBlock('tags', true)}
            childRef={tagRef}
            tabIndex="2"
            canEdit={canEdit}
          />
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Editable
            title=""
            content={
              [...managersDisciplines, ...disciplines.value].filter(uniq).length > 0 &&
              <Ui.Layout.FlexBox flow="column wrap">
                <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
                <Ui.Layout.ColleagueCardsContainer>
                  {[...managersDisciplines, ...disciplines.value]
                    .filter(uniq)
                    //.filter(user => data.managers.value.includes(Number(user.id)))
                    .map((discipline, i) =>
                      <Ui.Form.CheckboxLarge
                        key={`discipline_${i}`}
                        name="discipline"
                        label={discipline}
                      >
                        <Ui.SearchBar.DefaultCheckboxIcon
                        />
                      </Ui.Form.CheckboxLarge>
                    )}
                </Ui.Layout.ColleagueCardsContainer>
              </Ui.Layout.FlexBox>
            }
            isEditMode={isEditMode.disciplines}
            onClick={onEnterFieldsBlock('disciplines', canEdit)}
            onBlur={onLeaveFieldsBlock('disciplines')}
            altContent={
              <>
                <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
                {canEdit
                  ? 'Ajouter une discipline'
                  : `Aucune discipline n'est associée à ${fr[teleExpertiseName].thisExpertise}`
                }
              </>
            }
            childRef={searchDisciplinesRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={searchDisciplinesRef} tabIndex="1" >
              <Ui.BasicElement.H3>Disciplines</Ui.BasicElement.H3>
              <Ui.Form.InputContainer>
                <Ui.SearchBar.SearchAndCheck
                  name="disciplines"
                  query={search.disciplines.value}
                  TeleExpertise={TeleExpertise}
                  teleExpertiseName={teleExpertiseName}
                  resultSet={[...disciplinesResource, ...managersDisciplines]}
                  list={[...managersDisciplines, ...disciplines.value].filter(uniq)}
                  loading={resourceLoading.disciplines}
                  checkboxDisabled={discipline => managersDisciplines.includes(discipline)}
                  onToggle={(id, toggled, name = 'disciplines', teleExpertiseId) => {
                    dispatch(TeleExpertise.toggle({ id, toggled, name, teleExpertiseId }))
                  }}
                />
              </Ui.Form.InputContainer>
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Ui.BasicElement.H3>
            Responsable
          </Ui.BasicElement.H3>
          <Ui.Colleague.Colleague
            to={`${privateSitePrefix}/medecins/${data?.ownerId?.value}`}
            colleague={ownerData}
            currentUser={currentUser}
          />
        </Ui.Layout.BlockContainer>
        <Ui.Layout.BlockContainer>
          <Editable
            content={
              managersData.length > 0
                ? <Ui.Layout.FlexBox flow="column wrap">
                  <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
                  <Ui.Layout.ColleagueCardsContainer>
                    {managersData
                      .map((colleague, i) =>
                        <Ui.Colleague.Colleague
                          key={`colleague_${i}`}
                          to={`${privateSitePrefix}/medecins/${colleague.id}`}
                          colleague={colleague}
                          currentUser={currentUser}
                          onUpdateOwner={(colleague, ownerId) => onUpdateOwner(colleague, ownerId)}
                          teleExpertiseOwnerId={ownerId.value}
                          permissions={permissions}
                        />
                      )
                    }
                  </Ui.Layout.ColleagueCardsContainer>
                </Ui.Layout.FlexBox>
                : null
            }
            altContent={
              <>
                <Ui.BasicElement.H3>Administrateurs</Ui.BasicElement.H3>
                {canEdit
                  ? 'Ajouter un administrateur'
                  : `Aucun administrateur n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                }
              </>
            }
            isEditMode={isEditMode.managers}
            onClick={onEnterFieldsBlock('managers', canEdit)}
            onBlur={onLeaveFieldsBlock('managers')}
            childRef={searchManagersRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={searchManagersRef} tabIndex="4" >
              <Ui.BasicElement.H3>Administrateur</Ui.BasicElement.H3>
              <Ui.Form.InputContainer>
                <Ui.SearchBar.SearchAndCheck
                  name="managers"
                  query={search.managers.value}
                  TeleExpertise={TeleExpertise}
                  teleExpertiseName={teleExpertiseName}
                  resultSet={members}
                  list={[...owner.value, ...managers.value].filter(uniq)}
                  loading={resourceLoading.disciplines}
                  checkboxDisabled={manager => manager.id === owner.value[0]}
                  onToggle={(id, toggled, name = 'managers') => {
                    dispatch(TeleExpertise.toggle({ id, toggled, name }))
                    toggled
                      ? dispatch(TeleExpertise.addManager({ id }))
                      : dispatch(TeleExpertise.removeManager({ id }))
                  }}
                />
                <Ui.Layout.ButtonGroup>
                </Ui.Layout.ButtonGroup>
                {disciplines.errors[0] &&
                  <Ui.Form.FieldErrorMessage>{disciplines.errors[0]}</Ui.Form.FieldErrorMessage>
                }
              </Ui.Form.InputContainer>
              {managers.errors[0] &&
                <Ui.Form.FieldErrorMessage>{managers.errors[0]}</Ui.Form.FieldErrorMessage>
              }
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.BlockContainer>
        <Ui.BasicElement.H2>
          Site patient
        </Ui.BasicElement.H2>
        <Ui.BasicElement.H3>URL du site patient</Ui.BasicElement.H3>
        <Ui.BasicElement.P>Personnalisez adresse internet pour que vos patients accèdent directement aux programmes.</Ui.BasicElement.P>
        <Ui.Layout.BlockContainer>
          <Editable
            text={publicPatientUrl}
            isEditMode={publicPatientUri.isFieldEdit}
            onClick={onAlertBeforePatientUrlUpdate}
            onBlur={onLeaveField('publicPatientUri')}
            childRef={publicPatientUriRef}
            canEdit={canEdit}
          >
            <Ui.Layout.PublicUriContainer flow="column wrap">
              <Ui.BasicElement.H3>URL du site patient</Ui.BasicElement.H3>
              <Ui.Layout.FlexBox flow="row wrap">
                <Ui.BasicElement.P>{`${cohortPublicUrl}/`}</Ui.BasicElement.P>
                <Styled.Url>
                  <Ui.Form.TextInput
                    name="publicPatientUri"
                    value={normalizeUrl(publicPatientUri.value)}
                    onChange={updateField('publicPatientUri')}
                    onBlur={onLeaveField('publicPatientUri')}
                    onRestoreValue={onRestoreValue('publicPatientUri', publicPatientUri.initialValue)}
                    onKeyPress={onKeyPress}
                    error={publicPatientUri.errors[0]}
                    refs={refs}
                    tabIndex="6"
                  />
                </Styled.Url>
              </Ui.Layout.FlexBox>
            </Ui.Layout.PublicUriContainer>
          </Editable>
          <Styled.ShowPortalButton>
            <a href={publicPatientUrl} onClick={e => e.stopPropagation()} target="_blank" rel="noreferrer">
              <Ui.Button.BasicButton isSmall isFilled isGreen>
                Voir le site patient
              </Ui.Button.BasicButton>
            </a>
          </Styled.ShowPortalButton>
        </Ui.Layout.BlockContainer>
        <Ui.Layout.FlexBox>
          <Ui.Layout.BlockContainer>
            <Ui.BasicElement.ViewTitleContainer>
              <Ui.BasicElement.H3>Image d'en-tête</Ui.BasicElement.H3>
            </Ui.BasicElement.ViewTitleContainer>
            <Ui.UploadFile.ImagesUpload
              label=""
              subLabel=""
              name="questionnaireImage"
              id="heading-documents"
              htmlFor="heading-documents"
              endpoint={`/${teleExpertiseName}s/${teleExpertiseId}/add-image-patient-step`}
              onRemove={removeQuestionnaireImage}
              value={questionnaireData.questionnaireImage.value
                ? [{ fileName: questionnaireData.questionnaireImage.value }]
                : []}
              validationRules={["noLargeFiles", "noEmptyFiles", "acceptedImageFormat"]}
              previewUri={`/${teleExpertiseName}s/patientStepImage`}
              isEditMode={isEditMode}
              disabled={!canEdit}
            />
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            {(uploadFileQuestionnaireImageFileName || questionnaireData.questionnaireImage.value) &&
              <>
                <ImageTool
                  alignKey='patientStepsImageAlign'
                  stretchKey='patientStepsImageStretch'
                  data={questionnaireData}
                  slice={Questionnaire}
                  isStretched={questionnaireData.patientStepsImageStretch.value}
                  alignment={questionnaireData.patientStepsImageAlign.value}
                  image={uploadFileQuestionnaireImageFileName || questionnaireData.questionnaireImage.value}
                  isEditMode={true}
                  teleExpertiseName={teleExpertiseName}
                  teleExpertiseId={teleExpertiseId}
                  canEdit={canEdit}
                />
              </>
            }
          </Ui.Layout.BlockContainer>
        </Ui.Layout.FlexBox>
        <Ui.BasicElement.H2>
          Statut de {fr[teleExpertiseName].thisExpertise} :{'\u00A0'}
          {archive.value ? ' inactif' : 'actif'}
        </Ui.BasicElement.H2>
        <Ui.Layout.BlockContainer>
          <WithPermissions to={['archived']} permissions={permissions}>
            {archive.value
              ? <Ui.Button.BasicButton
                onClick={(e) => onOpenTeleExpertise(e, teleExpertiseId)}
              >
                Ré-ouvrir{'\u00A0'}{fr[teleExpertiseName].thisExpertise}
              </Ui.Button.BasicButton>
              : <Ui.Button.BasicButton
                onClick={(e) => onCloseTeleExpertise(e, teleExpertiseId)}
              >
                Archiver{'\u00A0'}{fr[teleExpertiseName].thisExpertise}
              </Ui.Button.BasicButton>
            }
          </WithPermissions>
        </Ui.Layout.BlockContainer>
        <div ref={pageEndRef} />
      </Ui.Layout.Form>
    </Ui.Layout.Section>
  )
}

const Patients = ({
  current,
  data,
  patients: patientsResource,
  isEditMode,
  search,
  resourceLoading,
  teleExpertiseId,
  expertisesWithComments,
  permissions,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
}) => {
  const {
    patients,
    patientsLocked,
  } = data;

  const canEdit = permissions?._canBeModified?.patients

  const patientSelection = patientsResource
    .filter(patient => patients.value.includes(patient.id) && !expertisesWithComments[patient.id])

  const searchPatientsRef = useRef();

  return (current === 1)
    ? <Ui.Layout.Section>
      <WithPermissions domains={['patients']} permissions={permissions}>
        <Styled.HeaderButtons>
          <a rel="noopener noreferrer" download href={`${apiUrl}/${teleExpertiseName}s/export-patients/${teleExpertiseId}`}>
            <Ui.Button.BasicButton
              isGreen
              disabled={patientSelection.length === 0}
            >
              <Ui.Icon.IconDocument />
              Export des patients
            </Ui.Button.BasicButton>
          </a>
        </Styled.HeaderButtons>
      </WithPermissions>
      <Ui.Layout.Section>
        <Editable
          content={
            patientSelection.length > 0 &&
            <Ui.Layout.FlexBox flow="column wrap">
              <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
              {canEdit &&
                <Ui.BasicElement.P>
                  Cliquez pour ajouter ou supprimer un patient
                </Ui.BasicElement.P>
              }
              <Ui.Layout.ColleagueCardsContainer>
                {patientSelection
                  .slice()
                  .sort((a, b) => a.pendingPatient - b.pendingPatient)
                  .map((patient, i) =>
                    <Ui.Card.PatientCard
                      key={`patient_card_${i}`}
                      to={`${privateSitePrefix}/patients/${patient.id}/${fr[teleExpertiseName].expertise}/${teleExpertiseId}`}
                      hasExpertiseComment={expertisesWithComments[patient.id]}
                      // TODO Add expertise counts
                      isLocked={patientsLocked.value.includes(patient.id)}
                      {...patient}
                    />
                  )}
              </Ui.Layout.ColleagueCardsContainer>
            </Ui.Layout.FlexBox>
          }
          description=""
          isEditMode={isEditMode.addPatient}
          onClick={onEnterFieldsBlock('addPatient', canEdit)}
          onBlur={onLeaveFieldsBlock('addPatient')}
          altContent={
            <>
              <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
              <Ui.BasicElement.P>
                {canEdit
                  ? 'Cliquez pour ajouter ou supprimer un patient'
                  : `Aucun de vos patients n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                }
              </Ui.BasicElement.P>
            </>
          }
          childRef={searchPatientsRef}
          canEdit={canEdit}
          isTitleCTA
        >
          <Ui.Layout.FocusDiv ref={searchPatientsRef} tabIndex="1654" >
            <Ui.BasicElement.H2>Patients</Ui.BasicElement.H2>
            <Ui.Form.InputContainer>
              <Ui.SearchBar.SearchAndCheck
                isPurple
                name="patients"
                data={data}
                query={search.patients.value}
                TeleExpertise={TeleExpertise}
                teleExpertiseName={teleExpertiseName}
                teleExpertiseId={teleExpertiseId}
                resultSet={patientsResource}
                list={patients.value}
                loading={resourceLoading.patients}
                hasNewPatientButton
              />
            </Ui.Form.InputContainer>
          </Ui.Layout.FocusDiv>
        </Editable>
      </Ui.Layout.Section>
    </Ui.Layout.Section >
    : null
}

const Colleagues = ({
  current,
  data,
  teleExpertiseId,
  dispatch,
  members,
  organizations: organizationsResource,
  search,
  resourceLoading,
  permissions,
  isEditMode,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  currentUser,
  isInviting,
}) => {
  const {
    colleagues,
    owner,
    managers,
    organizations,
    organizationMembers,
  } = data;

  const canEdit = permissions?._canBeModified?.members

  const teleExpertiseOrganizations = organizationsResource.filter(organization => organizations.value.includes(organization.id))

  const membersByOrganization = Object
    .values(organizationsResource
      .filter(organization => organizations.value.includes(Number(organization.id))))
    .reduce((acc, { id, name, members, managers }) => ({
      ...acc,
      [id]: { name, members: [...members, ...managers] }
    }), {})

  const uniqueColleagues = members => members
    .filter(colleague =>
      [
        ...organizationMembers.value,
      ].includes(colleague.id))
    .filter(colleague =>
      ![
        ...owner.value,
        ...managers.value,
        ...colleagues.value
      ].includes(colleague.id))

  const onToggleColleague = (id, toggled) => {
    if (toggled) {
      dispatch(TeleExpertise.addColleague({ id }))
    } else {
      dispatch(TeleExpertise.removeColleague({ id }))
    }
    dispatch(TeleExpertise.setQuery({ name: 'colleagues', value: '' }))
  }

  const onToggleOrganization = (id, toggled, name = 'organizations') => {
    if (toggled) {
      dispatch(TeleExpertise.addOrganization({ id, teleExpertiseId }))
    } else {
      dispatch(TeleExpertise.removeOrganization({ id, teleExpertiseId }))
    }
  }

  const searchOrganizationsRef = useRef();
  const searchColleaguesRef = useRef();

  return (
    (current === 2)
      ? isInviting
        ? <Invitation
          type={teleExpertiseName}
          teleExpertiseId={teleExpertiseId}
          teleExpertiseName={teleExpertiseName}
          data={data}
        />
        : <>
          <Ui.Layout.BlockContainer>
            <Editable
              content={teleExpertiseOrganizations.length > 0 &&
                <Ui.Layout.FlexBox flow="column wrap">
                  <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                  <Ui.Layout.ColleagueCardsContainer>
                    <Ui.Layout.FlexBox flow="row wrap">
                      {teleExpertiseOrganizations.map((organization, i) =>
                        <Ui.Card.OrganizationCard
                          key={i}
                          id={organization.id}
                          name={organization.name}
                          logo={organization.logo}
                          currentUserManagerOrMember={organization.currentUserManagerOrMember}
                        />
                      )}</Ui.Layout.FlexBox>
                  </Ui.Layout.ColleagueCardsContainer>
                </Ui.Layout.FlexBox>
              }
              description=""
              isEditMode={isEditMode.organizations}
              onClick={onEnterFieldsBlock('organizations', canEdit)}
              onBlur={onLeaveFieldsBlock('organizations')}
              altContent={
                <>
                  <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                  {canEdit
                    ? `Associer une organisation à ${fr[teleExpertiseName].thisExpertise}`
                    : `Aucune organisation n'est associée à ${fr[teleExpertiseName].thisExpertise}`
                  }
                </>
              }
              childRef={searchOrganizationsRef}
              canEdit={canEdit}
              isTitleCTA
            >
              <Ui.Layout.FocusDiv ref={searchOrganizationsRef} tabIndex="166554" >
                <Ui.BasicElement.H2>Organisations</Ui.BasicElement.H2>
                <Ui.Form.InputContainer>
                  <Ui.SearchBar.SearchAndCheck
                    name="organisations"
                    placeholder="Rechercher une organisation..."
                    query={search.organizations.value}
                    TeleExpertise={TeleExpertise}
                    resultToLabel={organization => organization.name}
                    resultToValue={organization => organization.id}
                    resultSet={organizationsResource}
                    list={organizations.value}
                    loading={resourceLoading.organizations}
                    onQueryChange={(e) => dispatch(TeleExpertise.setQuery({
                      name: 'organizations',
                      value: e.target.value
                    }))}
                    onToggle={(id, toggled) => onToggleOrganization(id, toggled)}
                    onSearch={(organizations, criteria) => organizations
                      .filter(({ name }) => RegExp(criteria, 'i').test(name))
                      .slice(0, 5)}
                    CheckboxIconComponent={Ui.BasicElement.OrganizationPicture}
                  />
                </Ui.Form.InputContainer>
              </Ui.Layout.FocusDiv>
            </Editable>
          </Ui.Layout.BlockContainer>
          <Ui.Layout.BlockContainer>
            <Editable
              content={
                <Ui.Layout.FlexBox flow="column wrap">
                  <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                  {canEdit &&
                    <Ui.BasicElement.P>
                      Cliquez pour ajouter ou supprimer un médecin
                    </Ui.BasicElement.P>
                  }
                  {members.length > 0 &&
                    <Ui.Layout.ColleagueCardsContainer>
                      {members
                        .filter(colleague =>
                          [
                            ...owner.value,
                            ...managers.value,
                            ...colleagues.value
                          ].includes(colleague.id))
                        .map((colleague, i) =>
                          <Ui.Colleague.Colleague
                            key={`colleague_${i}`}
                            to={`${privateSitePrefix}/medecins/${colleague.id}`}
                            colleague={colleague}
                            currentUser={currentUser}
                          />
                        )}
                    </Ui.Layout.ColleagueCardsContainer>
                  }
                </Ui.Layout.FlexBox>
              }
              description=""
              isEditMode={isEditMode.colleagues}
              onClick={onEnterFieldsBlock('colleagues', canEdit)}
              onBlur={onLeaveFieldsBlock('colleagues')}
              altContent={
                <>
                  <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                  {canEdit
                    ? 'Cliquez ici pour Ajouter ou supprimer un médecin'
                    : `Aucun médecin n'est associé à ${fr[teleExpertiseName].thisExpertise}`
                  }
                </>
              }
              childRef={searchColleaguesRef}
              canEdit={canEdit}
              isTitleCTA
            >
              <Ui.Layout.FocusDiv ref={searchColleaguesRef} tabIndex="166555" >
                <Ui.BasicElement.H2>Médecins</Ui.BasicElement.H2>
                <Ui.Form.InputContainer>
                  <Ui.SearchBar.SearchAndCheck
                    name="confreres"
                    query={search.colleagues.value}
                    TeleExpertise={TeleExpertise}
                    teleExpertiseName={teleExpertiseName}
                    checkboxDisabled={colleague => [
                      ...owner.value,
                      ...managers.value,
                      ...organizationMembers.value]
                      .includes(colleague.id)}
                    resultSet={members}
                    list={[
                      ...owner.value,
                      ...managers.value,
                      ...organizationMembers.value,
                      ...colleagues.value
                    ].filter((value, index, self) =>
                      self.indexOf(value) === index)
                    }
                    onToggle={(id, toggled) => onToggleColleague(id, toggled)}
                    loading={resourceLoading.managers}
                    hasInviteColleagueButton
                    onClickExtraButton={e => dispatch(InvitationState.setIsInviting(true))}
                  />
                </Ui.Form.InputContainer>
              </Ui.Layout.FocusDiv>
            </Editable>
          </Ui.Layout.BlockContainer>
          {Object
            .entries(membersByOrganization)
            .map(([_, { name = 'Médecins', members }], i) =>
              members.length > 0 &&
              uniqueColleagues(members).length > 0 &&
              <Ui.Layout.BlockContainer key={`organization_${i}`}>
                <Ui.BasicElement.H3>Médecins liés à {name}</Ui.BasicElement.H3>
                <Ui.Layout.ColleagueCardsContainer>
                  {uniqueColleagues(members)
                    .map((colleague, i) =>
                      <Ui.Colleague.Colleague
                        key={`colleague_${i}`}
                        to={`${privateSitePrefix}/medecins/${colleague.id}`}
                        colleague={colleague}
                        currentUser={currentUser}
                      />
                    )}
                </Ui.Layout.ColleagueCardsContainer>
              </Ui.Layout.BlockContainer>
            )}
        </>
      : null
  )
}

const PatientFile = ({
  dispatch,
  current,
  data,
  forms,
  isEditFields,
  isEditMode,
  isBuilderFieldEdit,
  sections,
  fields,
  choices,
  errorMessage,
  hasError,
  teleExpertiseId,
  send,
  onEnterFieldsBlock,
  onLeaveFieldsBlock,
  canEdit,
}) => {
  const {
    labels,
    currentUserAdmin: isCurrentUserAdmin,
  } = data;

  const labelColors = (label) => ['gray', 'black', 'green', 'blue', 'purple', 'orange', 'red', 'yellow']
    .map(item => ({
      label: fr.colors[item]
        .replace(/^./, m => m.toUpperCase()),
      value: item,
      selected: item === label.color,
      color: item
    }))

  const updateLabelContent = (name, idx) => e => {
    dispatch(TeleExpertise.updateLabelContent({ name, value: e.target.value, idx }))
  }

  const updateLabelColor = (name, idx) => e => {
    dispatch(TeleExpertise.updateLabelColor({ name, value: e.target.value, idx }))
  }

  const labelRef = useRef();

  return (current === 4) &&
    <Ui.Layout.Section>
      <Ui.Layout.Form onSubmit={e => send(e, 'labels')} noValidate>
        <Ui.Layout.BlockContainer>
          <Ui.Tag.LabelsHeader>
            <Ui.BasicElement.H2>
              Etiquettes
            </Ui.BasicElement.H2>
          </Ui.Tag.LabelsHeader>
          <Editable
            content={labels.value.length > 0
              ? <Ui.Tag.TagsContainer>
                {labels.value.map((label, i) =>
                  <Ui.Tag.Tag
                    key={`label_${i}`}
                    bgColor={label.color}
                  >
                    <span>{label.content}</span>
                  </Ui.Tag.Tag>
                )}
              </Ui.Tag.TagsContainer>
              : canEdit
                ? <Ui.Layout.FlexBox>Ajouter des étiquettes à {fr[teleExpertiseName].thisExpertise}</Ui.Layout.FlexBox>
                : <Ui.BasicElement.P>Aucune étiquette n'est associée à {fr[teleExpertiseName].thisExpertise}</Ui.BasicElement.P>
            }
            isEditMode={isEditMode.labels}
            onClick={onEnterFieldsBlock('labels', canEdit)}
            onBlur={labels.value.length > 0 ? onLeaveFieldsBlock('labels', true) : onLeaveFieldsBlock('labels')}
            childRef={labelRef}
            canEdit={canEdit}
          >
            <Ui.Layout.FocusDiv ref={labelRef} tabIndex="1785">
              <Styled.LabelsSection>
                {labels.value.map((label, idx) =>
                  <Ui.Form.LabelsContainer key={`label_${idx}`}>
                    <Ui.Form.LabelContainer>
                      <Ui.Form.TextInput
                        htmlFor={`label_${idx}`}
                        value={label.content}
                        onChange={updateLabelContent('label', idx)}
                        noSubmit
                      />
                      <Ui.Select.Select
                        label="Couleur"
                        htmlFor="Couleur"
                        items={labelColors(label)}
                        onChange={updateLabelColor('label', idx)}
                      />
                    </Ui.Form.LabelContainer>
                    <Ui.Layout.ActionButtonsContainer>
                      <Ui.Button.ActionButton
                        $isGreen
                        onClick={(e) => {
                          dispatch(TeleExpertise.removeLabel({ idx, id: label.id, fieldName: 'labels' }))
                          labelRef?.current?.focus()
                        }}
                      >
                        <Ui.Icon.IconTrash />
                      </Ui.Button.ActionButton>
                    </Ui.Layout.ActionButtonsContainer>
                  </Ui.Form.LabelsContainer>
                )}
                <Styled.LabelButtonContainer>
                  <Ui.Button.ActionButton
                    $isGreen
                    onClick={(e) => {
                      dispatch(TeleExpertise.addLabel({ content: '', color: 'gray' }))
                      labelRef?.current?.focus()
                    }}
                    disabled={
                      labels.value.length !== 0 &&
                      _.lastElement(labels.value).content.length === 0}
                  >
                    <Ui.Icon.IconPlus />
                  </Ui.Button.ActionButton>
                  {labels.value.length > 0 &&
                    <Ui.Button.BasicButton
                      isGreen
                      isFilled
                      onClick={onLeaveFieldsBlock('labels', true)}
                    >
                      Valider
                    </Ui.Button.BasicButton>
                  }
                </Styled.LabelButtonContainer>
              </Styled.LabelsSection>
            </Ui.Layout.FocusDiv>
          </Editable>
        </Ui.Layout.BlockContainer>

        {isCurrentUserAdmin.value
          ? <CustomFieldBuilder
            form={forms[0]}
            teleExpertiseType='cohorts'
            teleExpertiseId={teleExpertiseId}
            errorMessage={errorMessage}
            hasError={hasError}
            isBuildMode={true}
            isBuilderFieldEdit={isBuilderFieldEdit}
          />
          : <CustomFieldViewer
            data={{ sections, fields, choices }}
            forms={forms}
            isEditFields={isEditFields}
            isUseMode={true}
            isBuildMode={true}
          />
        }
      </Ui.Layout.Form>
    </Ui.Layout.Section>
}

const Scheduling = ({
  current,
  data,
  teleExpertiseId,
  forms,
  setCurrent,
  permissions,
  canEdit,
}) => {
  const {
    patientSteps,
  } = data;

  return (current === 3) && (
    <Ui.Layout.Section>
      <Ui.BasicElement.P>
        Organisez votre cohorte en créant des étapes :
        envoyez documents et questionnaires aux patients ou médecins
      </Ui.BasicElement.P>
      <Ui.BasicElement.H2>
        Etapes
      </Ui.BasicElement.H2>
      <QuestionnaireBuilder
        teleExpertiseId={teleExpertiseId}
        data={patientSteps.value}
        form={forms[0]}
        setCurrentStep={setCurrent}
        permissions={permissions}
        canEdit={canEdit}
      />
    </Ui.Layout.Section>
  )
}

export default CohortComponent;
