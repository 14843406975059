import * as React from "react";

export const DocDicom = ({
  ...props
}) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2,
        }}
        {...props}
    >
        <path
            d="M128,0C110.4,0 96,14.4 96,32L96,480C96,497.6 110.4,512 128,512L448,512C465.6,512 480,497.6 480,480L480,128L352,0L128,0Z"
            style={{
                fill: "rgb(226,229,231)",
                fillRule: "nonzero",
            }}
        />
        <path
            d="M384,128L480,128L352,0L352,96C352,113.6 366.4,128 384,128Z"
            style={{
                fill: "rgb(176,183,189)",
                fillRule: "nonzero",
            }}
        />
        <path
            d="M480,224L384,128L480,128L480,224Z"
            style={{
                fill: "rgb(202,209,216)",
                fillRule: "nonzero",
            }}
        />
        <path
            d="M416,416C416,424.8 408.8,432 400,432L48,432C39.2,432 32,424.8 32,416L32,256C32,247.2 39.2,240 48,240L400,240C408.8,240 416,247.2 416,256L416,416Z"
            style={{
                fill: "rgb(128,128,128)",
                fillRule: "nonzero",
            }}
        />
        <g transform="matrix(7.06446,0,0,10.3789,-1780.16,-2308.54)">
            <text
                x="263.319px"
                y="259.276px"
                style={{
                fontFamily:
                    "'ArialRoundedMTBold', 'Arial Rounded MT Bold', sans-serif",
                fontSize: 12,
                fill: "white",
                }}
            >
                {"DICOM"}
            </text>
        </g>
        <path
            d="M400,432L96,432L96,448L400,448C408.8,448 416,440.8 416,432L416,416C416,424.8 408.8,432 400,432Z"
            style={{
                fill: "rgb(202,209,216)",
                fillRule: "nonzero",
            }}
        />
    </svg>
);
