import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import effect from '../Effect';
import portalPro from './PortalPro';
import homepage from './Homepage';
import registration from './Registration';
import login from './Login'
import totp from './Totp'
import comment from './Comment'
import patient from './Patient'
import colleague from './Colleague'
import profile from './Profile'
import patientList from './PatientList'
import staffList from './StaffList'
import opinionList from './OpinionList'
import cohortList from './CohortList'
import colleagueList from './ColleagueList'
import messageList from './MessageList'
import message from './Message'
import staff from './Staff'
import opinion from './Opinion'
import cohort from './Cohort'
import customField from './CustomField'
import organization from './Organization'
import menu from './Menu'
import uploadFile from './UploadFile'
import resources from './Resources'
import videoConference from './VideoConference'
import contactUs from './ContactUs'
import questionnaire from './Questionnaire'
import accountValidation from './AccountValidation'
import workList from './WorkList'
import dicom from './Dicom'
import invitation from './Invitation'
import dashboardAdmin from './DashboardAdmin'

/**
 * This default function named `createStore` allows you to create
 * a store with and without effects depending on the environments.
 *
 * env === `test` => No Effects
 * *              => Effect
 */
const store = (env = process.env.REACT_APP_NODE_ENV) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: {
      homepage,
      portalPro,
      registration,
      login,
      totp,
      colleague,
      profile,
      comment,
      patient,
      patientList,
      staffList,
      opinionList,
      cohortList,
      colleagueList,
      messageList,
      message,
      staff,
      opinion,
      cohort,
      customField,
      organization,
      menu,
      uploadFile,
      resources,
      videoConference,
      contactUs,
      questionnaire,
      accountValidation,
      workList,
      dicom,
      invitation,
      dashboardAdmin,
    },
    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
    middleware: [ sagaMiddleware ],
  })

  if ('test' === env) {
    return mockStore(store)
  }

  sagaMiddleware.run(effect)

  return store
}

const mockStore = store => {
  let actions = []
  const dispatch = store.dispatch.bind(store)

  store.getActions = () => [ ...actions ]
  store.dispatch = action => {
    actions.push(action)

    return dispatch(action)
  }
  store.cleanActions = () => { actions = [] }

  return store
}

export default store;
