import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { privateSitePrefix } from '../Util/Config'
import * as Menu from '../State/Menu'
import * as Ui from './Ui'
import * as SearchTeleExpertise from '../State/CohortList'
import { fr } from '../Util/i18n';
import { KeywordsNotFoundMessage } from '../Util/Message'
import LoadingBox from './Ui/LoadingBox'
import TeleExpertiseIcon from './Ui/TeleExpertiseIcon'

import * as Sentry from "@sentry/react";

const CohortListComponent = () => {
  const teleExpertiseName = 'cohort'
  const dispatch = useDispatch()
  const alert = useAlert()
  const [filterOpen, setFilterOpen] = useState(false)
  const currentUser = useSelector(state => state.login.currentUser)
  const expertises = useSelector(SearchTeleExpertise.selectExpertises)
  const filters = useSelector(SearchTeleExpertise.selectFilters)
  const errorMessage = useSelector(state => state.cohortList.errorMessage)
  const hasError = useSelector(state => state.cohortList.error)
  const hasMore = useSelector(SearchTeleExpertise.selectHasMore)
  const loading = useSelector(SearchTeleExpertise.selectLoading)
  const fetching = useSelector(SearchTeleExpertise.selectFetching)
  const keywordsNotFound = useSelector(SearchTeleExpertise.selectKeywordsNotFound)
  const nextPage = Math.floor(expertises.length / process.env.REACT_APP_DEFAULT_RESULT_LIMIT) + 1

  useEffect(() => {
    dispatch(SearchTeleExpertise.fetch())
    dispatch(Menu.changeActive(Menu.COHORTES))
    dispatch(SearchTeleExpertise.count())

    return () => dispatch(SearchTeleExpertise.clean())
  }, [dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <SearchTeleExpertiseContainer>
          {!loading || hasError
            ?
            <Ui.Layout.PageContentWrapper>
              <Ui.Layout.PanelContainer>
                <Ui.Layout.Panel>
                  <Ui.Layout.TelexpertiseThumbnailContainer>
                    <Ui.Layout.TelexpertiseLogo>
                    <TeleExpertiseIcon teleExpertiseType={teleExpertiseName} />
                    </Ui.Layout.TelexpertiseLogo>
                    <Ui.BasicElement.H3 noMargin>
                      {fr[teleExpertiseName].expertises.charAt(0).toUpperCase()}
                      {fr[teleExpertiseName].expertises.slice(1)}
                    </Ui.BasicElement.H3>
                  </Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.BasicElement.P light>
                    Créez des cohortes, personnalisez votre eCRF, incluez les
                    patients, seul ou à plusieurs. Exportez vos données pour
                    évaluer vos pratiques et publier des études.
                  </Ui.BasicElement.P>
                  <Ui.Layout.PanelFilterContainer noPadding>
                    <Ui.Layout.PanelFilterMobileLiner>
                      {!filterOpen
                        ? (
                          <Ui.Button.SearchButton
                            onClick={e => setFilterOpen(!filterOpen)}
                          >
                            <span>Rechercher</span>
                            <Ui.Icon.IconSearch />
                          </Ui.Button.SearchButton>
                        )
                        : (
                          <Ui.Button.CloseButton
                            onClick={e => setFilterOpen(!filterOpen)}
                          >
                            <span>Fermer</span>
                            <Ui.Icon.IconClose />
                          </Ui.Button.CloseButton>
                        )
                      }
                    </Ui.Layout.PanelFilterMobileLiner>
                    <Ui.Layout.PanelFilterBox open={filterOpen}>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.Select.Select
                          label="Afficher"
                          items={[
                            {
                              label: `Mes ${teleExpertiseName}`,
                              value: SearchTeleExpertise.DISPLAY_MINE,
                              selected: SearchTeleExpertise.DISPLAY_MINE === filters.displayBy,
                            },
                            {
                              label: fr[teleExpertiseName].allExpertises,
                              value: SearchTeleExpertise.DISPLAY_ALL,
                              selected: SearchTeleExpertise.DISPLAY_ALL === filters.displayBy,
                            },
                            {
                              label: fr[teleExpertiseName].archivedExpertises,
                              value: SearchTeleExpertise.DISPLAY_ARCHIVED,
                              selected: SearchTeleExpertise.DISPLAY_ARCHIVED === filters.displayBy,
                            },
                          ]}
                          value={filters.displayBy}
                          onChange={e => dispatch(SearchTeleExpertise.changeDisplayBy(e.target.value))}
                        />
                      </Ui.Layout.PanelFilterRowMobile>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.Select.Select
                          label="Trier par"
                          items={[
                            { label: 'Mise à jour', value: SearchTeleExpertise.ORDER_BY_LATEST, selected: false },
                            { label: 'Nom', value: SearchTeleExpertise.ORDER_BY_TITLE, selected: false },
                          ]}
                          value={filters.orderBy}
                          onChange={e => dispatch(SearchTeleExpertise.changeOrderBy(e.target.value))}
                        />
                      </Ui.Layout.PanelFilterRowMobile>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.SearchBar.SearchBar
                          placeholder={`Rechercher un intitulé ${fr[teleExpertiseName].ofExpertises} ou une discipline`}
                          value={filters.search}
                          onChange={e => dispatch(SearchTeleExpertise.changeSearch(e.target.value))}
                          disabled={hasError}
                        />
                        <KeywordsNotFoundMessage
                          paddingTop
                          keywords={keywordsNotFound}
                          resourceType="cohort" />
                      </Ui.Layout.PanelFilterRowMobile>
                      <Ui.Layout.PanelFilterRowMobile>
                        <Ui.Icon.IconUp onClick={e => setFilterOpen(!filterOpen)} />
                      </Ui.Layout.PanelFilterRowMobile>
                    </Ui.Layout.PanelFilterBox>
                  </Ui.Layout.PanelFilterContainer>
                </Ui.Layout.Panel>
              </Ui.Layout.PanelContainer>
              <Ui.Layout.Content hasPanel>
                <Ui.Search.FilterContainer>
                  <Ui.Search.FiltersAndSorting>
                    <Ui.Search.FilterSection>
                      <Ui.Select.Select
                        label="Afficher"
                        items={[
                          {
                            label: `Mes ${fr[teleExpertiseName].expertises}`,
                            value: SearchTeleExpertise.DISPLAY_MINE,
                            selected: SearchTeleExpertise.DISPLAY_MINE === filters.displayBy,
                          },
                          {
                            label: fr[teleExpertiseName].allExpertises,
                            value: SearchTeleExpertise.DISPLAY_ALL,
                            selected: SearchTeleExpertise.DISPLAY_ALL === filters.displayBy,
                          },
                          {
                            label: fr[teleExpertiseName].archivedExpertises,
                            value: SearchTeleExpertise.DISPLAY_ARCHIVED,
                            selected: SearchTeleExpertise.DISPLAY_ARCHIVED === filters.displayBy,
                          },
                        ]}
                        onChange={e => dispatch(SearchTeleExpertise.changeDisplayBy(e.target.value))}
                      />
                      <Ui.Select.Select
                        label="Trier par"
                        items={[
                          { label: 'Mise à jour', value: SearchTeleExpertise.ORDER_BY_LATEST, selected: false },
                          { label: 'Nom', value: SearchTeleExpertise.ORDER_BY_TITLE, selected: false },
                        ]}
                        onChange={e => dispatch(SearchTeleExpertise.changeOrderBy(e.target.value))}
                      />
                    </Ui.Search.FilterSection>
                    <div>
                      <Ui.Button.BasicButton
                        isPrimary
                        isFilled
                        isLarge
                        as={Link}
                        to={`${privateSitePrefix}/nouveau/${fr[teleExpertiseName].expertise}`}
                      >
                        {fr[teleExpertiseName].newExpertise}
                      </Ui.Button.BasicButton>
                    </div>
                  </Ui.Search.FiltersAndSorting>
                  <Ui.Search.FiltersAndSorting>
                    <Ui.SearchBar.SearchBar
                      placeholder={`Rechercher un intitulé ${fr[teleExpertiseName].ofExpertises} ou une discipline`}
                      onChange={e => dispatch(SearchTeleExpertise.changeSearch(e.target.value))}
                    />
                  </Ui.Search.FiltersAndSorting>
                  <KeywordsNotFoundMessage
                    paddingTop
                    keywords={keywordsNotFound}
                    resourceType="cohort" />
                </Ui.Search.FilterContainer>
                <Ui.Card.Container>
                  <Ui.Search.MobileActionButtonGroup>
                    <Ui.Button.BasicButton
                      isPrimary
                      isFilled
                      isLarge
                      as={Link}
                      to={`${privateSitePrefix}/nouveau/${fr[teleExpertiseName].expertise}`}
                    >
                      {fr[teleExpertiseName].newExpertise}
                    </Ui.Button.BasicButton>
                  </Ui.Search.MobileActionButtonGroup>
                  {(expertises.length > 0
                    ? <Ui.Search.CardContainer>
                      {expertises.map((expertise, i) =>
                        <Ui.Card.TelexpertiseCard
                          key={`${teleExpertiseName}_card_${i}`}
                          to={`${privateSitePrefix}/${fr[teleExpertiseName].expertises}/${expertise.id}`}
                          teleExpertiseType={teleExpertiseName}
                          isArchived={expertise.archive}
                          currentUserAdmin={expertise.currentUserAdmin}
                          patientLimitLocked={expertise.patientLimitLocked}
                          numberAddPatientAction={expertise.numberAddPatientAction}
                          permissions={{ _canBe: expertise._canBe, _canBeModified: expertise._canBeModified }}
                          teleExpertise={expertise}
                          {...expertise}
                        />
                      )}
                    </Ui.Search.CardContainer>
                    : fetching
                      ? null
                      : <Ui.Search.NoResult>
                        Vous n'êtes pas encore membre d'une cohorte, <Ui.BasicElement.Link $isPrimary to={`${privateSitePrefix}/nouveau/cohorte`}>créer une nouvelle</Ui.BasicElement.Link> ou <Ui.BasicElement.Span isPrimary clickable bold onClick={() => dispatch(SearchTeleExpertise.changeDisplayBy(SearchTeleExpertise.DISPLAY_ALL))}>afficher toutes les cohortes</Ui.BasicElement.Span>
                      </Ui.Search.NoResult>
                  )
                  }
                </Ui.Card.Container>
                {hasMore && expertises.length > 0 && (
                  <Ui.Search.LoadMoreButtonContainer>
                    <Ui.Button.BasicButton
                      isFilled
                      isPrimary
                      isLarge
                      onClick={() => dispatch(SearchTeleExpertise.fetch({ page: nextPage }))}
                      disabled={fetching}
                    >
                      {fetching &&
                        <Ui.Global.Loader />
                      }
                      Voir plus de résultats
                    </Ui.Button.BasicButton>
                  </Ui.Search.LoadMoreButtonContainer>
                )}
              </Ui.Layout.Content>
            </Ui.Layout.PageContentWrapper>
            : <Ui.Layout.FlexContainer><Ui.Global.Loader /></Ui.Layout.FlexContainer>
          }
        </SearchTeleExpertiseContainer >
      </Ui.Layout.PageLayout>
    </>
  )
}

const SearchTeleExpertiseContainer = styled.div`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    ${Ui.Layout.PanelFilterContainer} {
      padding: .6rem 1.3rem;
    }
  }
  ${Ui.SearchBar.SearchBarContainer} {
      margin-bottom: 0;
      width: 100%;
    }
  ${Ui.Search.FiltersAndSorting}:first-child {
      margin-bottom: 1rem;
  }
`

export default CohortListComponent;
