import React, { useState } from 'react'
import {
  privateSitePrefix,
  staffPublicUrl,
  cohortPublicUrl,
  opinionPublicUrl
} from '../../Util/Config'
import { apiUrl } from '../../Util/Config'
import { useHistory } from 'react-router-dom'
import pluralize from '../../Util/Pluralize'
import { dateToString, JJMMAAAAToDate } from '../../Util/Format'
import {
  IconDown,
  IconWoman,
  IconMan,
  IconManWoman,
  IconArrowRight,
  IconUp,
  IconExpertise,
  IconCohorte,
  IconMessage,
  IconOptions,
  IconPro,
  IconPatientSup,
  IconCamera,
} from '../Ui/Icon'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { FlexBox } from '../../components/common/Layout'
import { P } from './BasicElement'
import * as Ui from '../Ui'
import ClickOutside from '../../Util/ClickOutside'
import { fr } from '../../Util/i18n';

export const PatientCard = ({
  currentUserId = 0,
  id = 0,
  sex = "",
  fullName = "",
  fullBirthdate = "",
  freeDiagnostic = "",
  hasExpertiseComment = false,
  addToCurrentSession = null,
  canAddToCurrentSession = false,
  disabled = false,
  doctor = {},
  labels = {},
  requests = {},
  nbCohorts = 0,
  nbStaffs = 0,
  nbOpinions = 0,
  isInvisible = false,
  isLocked,
  managers = [],
  to = "#"
}) => {
  const [tagsOpen, settagsOpen] = useState(false)
  const history = useHistory()

  let currentUserRequest = null

  for (var type in requests[currentUserId]) {
    for (var reference in requests[currentUserId][type]) {
      if (
        'new' === requests[currentUserId][type][reference].state ||
        'waiting' === requests[currentUserId][type][reference].state
      ) {
        currentUserRequest = requests[currentUserId][type][reference]
      }
    }
  }
  const hasRequests = requests && (requests.waiting || requests.new)
  const patientDoctors = [`${doctor.title} ${doctor.firstname} ${doctor.lastname}`,
  ...managers.map(({ title, firstname, lastname }) => `${title} ${firstname} ${lastname}`)]
    .filter(e => e)

  return (
    <CardContainer
      onClick={e => e.stopPropagation()}
      title={isLocked
        ? 'Cette téléexpertise est limitée à 10 patients. Passez à Skemeet PRO pour la débloquer.'
        : ''
      }>
      <Card
        hasNewRequest={!!currentUserRequest}
        isInvisible={isInvisible}
        onClick={!hasRequests && !isLocked
          ? e => history.push(to)
          : null}
        isLocked={isLocked}
        hasPointer={!isLocked}>
        <TopContainer>
          <Header>
            <PatientSexBox>
              <PatientSex sex={sex} />
            </PatientSexBox>
            <PatientCivilityBox>
              {/* TODO : find better truncating method css or js
              Try https://codepen.io/nilsynils/pen/KgaRBo?editors=1100
            */}
              <p><strong>{
                fullName.length > 30
                  ? `${fullName.slice(0, 30)}...`
                  : `${fullName}`
              }</strong></p>
              <p>{fullBirthdate}</p>
            </PatientCivilityBox>
            {/* TODO : add endpoint to export selected patients datas */}
            {/* <Checkbox onChange={onCheck} checked={checked} /> */}
          </Header>
          <Body>
            <p onClick={!isLocked ?
              e => history.push(to)
              : null
            }>{freeDiagnostic}</p>
            {hasExpertiseComment &&
              <P bold primary>Avis donné</P>
            }
            {Object.values(labels).length > 0 &&
              <Disciplines open={tagsOpen}>
                {Object.values(labels).map((label, i) =>
                  <DisciplineLabel
                    key={`patient_${id}_label_${i}`}
                    labelColor={label.color}
                    isPatient
                  >
                    {label.content}
                  </DisciplineLabel>
                )}
                {Object.values(labels).length > 1 && (
                  <MoreDisciplineButton
                    onClick={!isLocked
                      ? e => { e.stopPropagation(); settagsOpen(!tagsOpen) }
                      : null
                    }>
                    {tagsOpen
                      ? <><IconUp /></>
                      : Object.values(labels).length > 3 &&
                      <>
                        {Object.values(labels).length - 3} {pluralize('étiquette', Object.values(labels).length - 3)}&nbsp;<IconDown />
                      </>
                    }
                  </MoreDisciplineButton>
                )}
                {Object.values(labels).length < 1 && (
                  <DisciplineLabel invisible>-</DisciplineLabel>
                )}
              </Disciplines>
            }
          </Body>
        </TopContainer>
        {!!currentUserRequest
          ?
          <>
            <FooterRequests {...requestType(currentUserRequest)}>
              {'new' === currentUserRequest.state ? 'Nouvelle demande' : 'Demande en attente'}
            </FooterRequests>
            <Doctors>
              {`${pluralize('Médecin', patientDoctors.length)} : ${patientDoctors.join(', ')}`}
            </Doctors>
          </>
          : <>
            <Footer>
              <TelexpertiseContainer>
                <StaffBlock isFilled={nbStaffs > 0}>
                  <IconMessage />
                  <strong>{nbStaffs}</strong>
                </StaffBlock>
                <CohorteBlock isFilled={nbCohorts > 0}>
                  <IconCohorte />
                  <strong>{nbCohorts}</strong>
                </CohorteBlock>
                <ExpertiseBlock isFilled={nbOpinions > 0}>
                  <IconExpertise />
                  <strong>{nbOpinions}</strong>
                </ExpertiseBlock>
              </TelexpertiseContainer>
            </Footer>
            <Doctors>
              {`${pluralize('Médecin', patientDoctors.length)} : ${patientDoctors.join(', ')}`}
            </Doctors>
            {canAddToCurrentSession
              ? <FooterButtons type="staff">
                <SmallActionButton
                  $isSmall
                  onClick={e => {
                    e.stopPropagation()
                    !disabled && addToCurrentSession()
                  }}
                  disabled={disabled}
                >
                  <IconPatientSup />
                  Reporter sur la session en cours
                </SmallActionButton>
              </FooterButtons>
              : null}
          </>
        }
      </Card>
    </CardContainer >
  )
}

export const TelexpertiseCard = ({
  id = 0,
  name = "",
  description = "",
  isArchived = false,
  nextSessionStartDate = "",
  ownerFullname = "",
  ownerCity = "",
  ownerTitle = "",
  ownerSpeciality = "",
  updatedAt = "",
  disciplines = [],
  currentUserAdmin = null,
  currentUserMember = null,
  patientLimitLocked = null,
  numberAddPatientAction = null,
  permissions = {},
  isHome = false,
  requests = null,
  isInvisible = false,
  teleExpertise = {},
  teleExpertiseType = 'staff',
  onSelectTeleExpertise = () => null,
  onSelectVisioconference = () => null,
  changeHost = false,
  to = "#",
  loggedIn = false,
  pictureFileName = '',
  fromPatientPage = false,
  ...props
}) => {
  const [tagsOpen, settagsOpen] = useState(false)
  const history = useHistory()
  const nextSessionDateString = nextSessionStartDate && nextSessionStartDate.split(' ')[0]
  const nextSessionDate = JJMMAAAAToDate(nextSessionDateString)
  const nextSessionHour = nextSessionStartDate && nextSessionStartDate.split(' ')[1]
  const staffSessionDay = dateToString(new Date()) === nextSessionDateString
  const hasRequests = requests && (requests.waiting || requests.new)
  const isLocked = !(currentUserAdmin || currentUserMember)

  return (
    <>
      <CardContainer>
        <Card
          isLocked={isLocked && !isHome}
          isInvisible={isInvisible}
          type={teleExpertiseType}
          onClick={!isHome && !isLocked
            ? e => {
              onSelectTeleExpertise()
              changeHost
                ? window.location = to
                : history.push(to)
            }
            : null
          }
          hasPointer={!isHome && !isLocked}>
          <TopContainer isLocked={isLocked} isHome={isHome}>
            <Header>
              <Logo>
                {~teleExpertiseType.indexOf('opinion')
                  ? pictureFileName
                    ? <>
                      <PictureRounded isBlue bgSrc={`${apiUrl}${isHome ? '/public' : ''}/opinions/picture/${pictureFileName}`} />
                    </>
                    : props.thePersonal
                      ? <>
                        <PictureRounded
                          isBlue
                          bgSrc={!props.ownerProfilePicture
                            ? '/Avatar_default.png'
                            : loggedIn
                              ? `${apiUrl}/users/profile-picture/${props.ownerProfilePicture}`
                              : `${apiUrl}/public/users/profile-picture/${props.ownerProfilePicture}`
                          }
                          alt={ownerFullname}
                        />
                      </>
                      : <IconRounded isBlue><IconExpertise /></IconRounded>
                  : ~teleExpertiseType.indexOf('staff')
                    ? pictureFileName
                      ? <><PictureRounded isPurple bgSrc={`${apiUrl}${isHome ? '/public' : ''}/staffs/picture/${pictureFileName}`} /></>
                      : <IconRounded isPurple><IconMessage /></IconRounded>
                    : ~teleExpertiseType.indexOf('cohort')
                      ? pictureFileName
                        ? <><PictureRounded isRed bgSrc={`${apiUrl}${isHome ? '/public' : ''}/cohorts/picture/${pictureFileName}`} /></>
                        : <IconRounded isRed><IconCohorte /></IconRounded>
                      : pictureFileName
                        ? <><PictureRounded isBlue bgSrc={`${apiUrl}${isHome ? '/public' : ''}/opinions/picture/${pictureFileName}`} /></>
                        : <IconRounded isBlue><IconExpertise /></IconRounded>
                }
                <ColoredText
                  type={teleExpertiseType}
                  defaultOpinion={props.thePersonal}
                >
                  {~teleExpertiseType.indexOf('opinion')
                    ? 'Expertise'
                    : ~teleExpertiseType.indexOf('staff')
                      ? 'RCP'
                      : 'Cohorte'
                  }
                </ColoredText>
                {!patientLimitLocked
                  ? <IconPro />
                  : null
                }
              </Logo>
            </Header>
            <Body isInvisible={isInvisible}>
              <Title>
                {name}
              </Title>
              {!props.thePersonal &&
                <TimeBlock>
                  Responsable : {ownerTitle} {[ownerFullname, ownerSpeciality || '', ownerCity || '']
                    .filter(el => el)
                    .join(', ')}
                </TimeBlock>
              }
              {teleExpertise.organizations && teleExpertise.organizations.length > 0
                ? <SmallP>
                  Organisations : {teleExpertise.organizations
                    .map(organization => organization.name)
                    .join(', ')}
                </SmallP>
                : null
              }
              <SmallP>
                {(ownerSpeciality || ownerCity) && teleExpertise.thePersonal
                  ? [ownerSpeciality, ownerCity].filter(e => e).join(', ')
                  : ''
                }
              </SmallP>
              <Description>{description}</Description>
              {teleExpertise.tags && teleExpertise.tags.length > 0 &&
                <Disciplines open={tagsOpen}>
                  {Object.values(teleExpertise.tags).map((tags, i) =>
                    <DisciplineLabel key={`patient_${id}_tag_${i}`}>
                      {tags}
                    </DisciplineLabel>
                  )}
                  {Object.values(teleExpertise.tags).length > 1 && (
                    <MoreDisciplineButton onClick={e => { e.stopPropagation(); settagsOpen(!tagsOpen) }}>
                      {Object.values(teleExpertise.tags).length > 3
                        ? tagsOpen
                          ? <><IconUp /></>
                          : <>+{Object.values(teleExpertise.tags).length - 3} {pluralize('mot', Object.values(teleExpertise.tags).length - 3)}-{pluralize('clé', Object.values(teleExpertise.tags).length - 3)}&nbsp;<IconDown /></>
                        : null
                      }
                    </MoreDisciplineButton>
                  )}
                  {Object.values(teleExpertise.tags).length < 1 && (
                    <DisciplineLabel invisible>-</DisciplineLabel>
                  )}
                </Disciplines>
              }
            </Body>
          </TopContainer>
          {/* !!!TODO Restore next session date in home when available in endpoint */}
          {hasRequests && !(staffSessionDay && teleExpertiseType === 'staff') &&
            <FooterRequests
              hasRequests={true}
              teleExpertiseType={teleExpertiseType}
              onClick={() => history.push(`${to}`)}
            >
              {requests.waiting
                ? `${requests.waiting} ${pluralize('demande', requests.waiting)} en attente`
                : requests.new
                  ? `${requests.new}  ${pluralize('nouvelle', requests.new)} ${pluralize('demande', requests.new)}`
                  : null
              }
            </FooterRequests>
          }
          {teleExpertiseType === 'staff' &&
            <Footer>
              <SessionLabel>
                Prochaine séance : {
                  nextSessionDate && nextSessionDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
                    ? "aujourd'hui"
                    : nextSessionDateString || 'date à déterminer'
                }
                {nextSessionHour && nextSessionHour !== '00:00' ? ` à ${nextSessionHour}` : ''
                }
              </SessionLabel>
            </Footer>
          }
          {!isArchived && !fromPatientPage &&
            <>
              <FooterButtons type={teleExpertiseType} onClick={e => e.stopPropagation()}>
                <SmallActionButton
                  $isSmall
                  onClick={isHome
                    ? (e) => {
                      e.stopPropagation()
                      window.location = (
                        teleExpertiseType.match('staff')
                          ? [staffPublicUrl, teleExpertise.publicUri, 'nouveau-patient'].join('/')
                          : teleExpertiseType.match('cohort')
                            ? [cohortPublicUrl, teleExpertise.publicUri, 'nouveau-patient'].join('/')
                            : [opinionPublicUrl, teleExpertise.publicUri, 'nouveau-patient'].join('/')
                      )
                    }
                    : (e) => {
                      e.stopPropagation()
                      history.push(`${!window.location.href.includes(process.env.REACT_APP_PRIVATE_URL) ? process.env.REACT_APP_PRIVATE_URL : ''}${privateSitePrefix}/nouveau/patient?${fr[teleExpertiseType].expertises.toLowerCase()}=${teleExpertise.id}`)
                    }
                  }
                  type={teleExpertiseType}
                >
                  <IconPatientSup />
                  {teleExpertiseType.match('opinion') || teleExpertiseType.match('staff')
                      ? 'Présenter un patient'
                      : teleExpertiseType.match('cohort')
                        ? 'Inclure un patient'
                        : 'Demander un avis'
                  }
                </SmallActionButton>
                {staffSessionDay && teleExpertiseType === 'staff'
                  ? <SmallActionButton
                    onClick={
                      isHome
                        ? (e) => {
                          e.stopPropagation()
                          onSelectVisioconference()
                          window.open(`${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/videoconference/${fr[teleExpertiseType].expertises.toLowerCase()}/${teleExpertise.id}`)
                          // changeHost
                          //   ? window.location = `${to}&visio=1`
                          //   : window.open(`${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/videoconference/${fr[teleExpertiseType].expertises.toLowerCase()}/${teleExpertise.id}`)
                        }
                        : (e) => {
                          e.stopPropagation()
                          window.open(`${process.env.REACT_APP_PRIVATE_URL}${privateSitePrefix}/videoconference/${fr[teleExpertiseType].expertises.toLowerCase()}/${teleExpertise.id}`)
                        }
                    }
                    type={`${teleExpertiseType}`}
                  >
                    <IconCamera />
                    Visio
                  </SmallActionButton>
                  : null
                }
              </FooterButtons>
            </>
          }
        </Card>
      </CardContainer >
    </>
  )
}


const SmallActionButton = styled.button`
  text-align: left;
  font-family: 'Montserrat';
  font-size: .8rem;
  flex: initial;
  border: none;
  background: white;
  cursor: pointer;
  font-weight: 600;
`

const FooterButtons = styled(props => <FlexBox {...props} />)`
  background-color: white;
  padding: 0.6rem 0;
  border-top: 1px dotted ${props => props.theme.grey2};
  padding-top: .4rem;
  margin-top: .2rem;
  border-radius: 8px;
  flex-wrap: wrap;
  cursor: default;
  ${SmallActionButton} {
    padding: .2rem;
    padding-right: .4rem;
    background-color: white;
    color: ${props => props.type === 'staff'
    ? props.theme.staffPurple
    : props.type === 'opinion'
      ? props.theme.expertiseBlue
      : props.theme.cohorteRed
  };
    &:hover {
      opacity: .8;
    }
    ${IconPatientSup}, ${IconCamera}, ${IconExpertise}, ${IconCohorte}, ${IconMessage} {
      border: 1px solid ${props => props.type === 'staff'
    ? props.theme.staffPurple
    : props.type === 'opinion'
      ? props.theme.expertiseBlue
      : props.theme.cohorteRed
  };
      border-radius: 50px;
      margin: 0 .3rem;
      padding: .4rem;
    }
    ${IconPatientSup} {
      width: 1.8rem;
    }
  }
`

const requestType = ({ type }) =>
  type === 'staffSession'
    ? { hasNewStaffDemand: true }
    : type === 'cohort'
      ? { hasNewCohortDemand: true }
      : { hasNewExpertiseDemand: true }


export const Description = styled(props => <Ui.BasicElement.P {...props} />)`
  word-break: normal;
  overflow-wrap: anywhere;
`

export const Container = styled(props => <FlexBox {...props} />)`
  flex-flow: column nowrap;
  align-items: stretch;
  margin-top: 1rem;

  @media only screen and (max-width: ${props => props.theme.device.desktop}) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }
`

const IconRounded = styled.div`
  border: 1px solid ${props => props.isBlue
    ? props.theme.expertiseBlue
    : props.isPurple
      ? props.theme.staffPurple
      : props.theme.cohorteRed
  };
  align-items: center;
  border-radius: 5000px;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

`

const PictureRounded = styled.div`
  border-radius: 5000px;
  background: url(${props => props.bgSrc}) no-repeat center;
  background-size: cover;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  border: 1px solid ${props => props.isBlue
    ? props.theme.expertiseBlue
    : props.isPurple
      ? props.theme.staffPurple
      : props.theme.cohorteRed
  };`

export const BottomLink = styled.a`
  display: flex;
  width: 90%;
  margin-left: -.8rem;
  margin-bottom: -.6rem;
  margin-top: 1rem;
  background-color: ${props => props.theme.grey};
  color: ${props => props.theme.white};
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.8rem 1.1rem;
  border-top-right-radius: 2rem;
  cursor: pointer;

  ${IconArrowRight} {
    margin-left: auto;
    align-self: flex-end;
    padding-right: 0.9rem;
  }
`

export const CardContainer = styled.div`
  display: flex;
  padding: .5rem;
  box-sizing: border-box;
  width: 100%;
  min-height: 19rem;
  cursor: default;
  transition: .2s;
  opacity: 1;
  @media only screen and (min-width: ${props => props.theme.device.mobile}) {
    width: 50%;
  };
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    width: 33.33333%;
  };
`

export const Card = styled.div`
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  color: ${props => props.theme.darkBlue};
  background-color: ${props => props.isLocked ? props.theme.lightGrey : props.theme.white};
  border-radius: 6px;
  border: 1px solid ${props => props.theme.grey2};
  box-shadow: ${props => !props.isInvisible
    ? props.theme.boxShadow.thick
    : 'none'
  };
  display: flex;
  flex-flow: column nowrap;
  opacity: ${props => props.isInvisible
    ? '0'
    : '1'};
  //padding: .6rem .8rem;
  box-sizing: border-box;
  cursor: ${props => props.hasPointer ? 'pointer' : 'default'};
  ${props => props.hasNewRequest
    ? css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `
    : null
  }

  ${BottomLink}:hover {
    background-color: ${props =>
    props.type && ~props.type.indexOf('opinion')
      ? props.theme.expertiseBlue
      : props.type && ~props.type.indexOf('staff')
        ? props.theme.staffPurple
        : props.type && ~props.type.indexOf('cohort')
          ? props.theme.cohorteRed
          : props.theme.expertiseBlue
  }
  };

  @media only screen and (max-width: ${props => props.theme.device.tablet}) {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
`

export const TopContainer = styled.div`
  padding: 0.6rem 0.8rem;
  margin-bottom: .4rem;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 6;
  //opacity: ${props => props.isLocked && !props.isHome ? '.6' : '1'};
`

export const Header = styled(props => <FlexBox {...props} />)`
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1rem;
`

export const Logo = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${IconExpertise} {
    color: ${props => props.theme.expertiseBlue};
  }

  ${IconMessage} {
    color: ${props => props.theme.staffPurple};
  }

  ${IconCohorte} {
    color: ${props => props.theme.cohorteRed};
  }

  ${IconMessage} {
    color: ${props => props.theme.staffPurple};
    font-size: 1.1rem;
  }

  span:nth-child(3) {
    margin-right: .5rem;
  }

  ${IconOptions} {
    color: ${props => props.theme.grey2};
    font-size: 1.1rem;
    margin-left: .5rem;
    cursor: pointer;
  }
`

const ColoredText = styled.span`
  font-size: .8rem;
  margin-left: .6rem;
  flex-grow: 2;
  font-weight: 600;
  color: ${props =>
    ~props.type.indexOf('opinion')
      ? props.theme.expertiseBlue
      : ~props.type.indexOf('staff')
        ? props.theme.staffPurple
        : ~props.type.indexOf('cohort')
          ? props.theme.cohorteRed
          : props.theme.grey
  };
`

export const Title = styled.p`
  font-size: 1.1rem;
  font-weight: bold;
  word-break: normal;
  overflow-wrap: anywhere;
  cursor: ${props => props.hasPointer ? 'pointer' : null};
`

export const SessionLabel = styled.div`
  color: ${props => props.theme.darkBlue};
  font-size: .9rem;
  font-weight: bold;
  margin: .3rem 0;
  padding: 0;
`

export const Footer = styled.div`
  padding: 0.6rem 0.8rem;
  ${props => props.hasNewStaffDemand ||
    props.hasNewCohorteDemand ||
    props.hasNewExpertiseDemand ||
    props.hasRequests ||
    props.toCurrentSession
    ? css`
      background: ${props => props.disabled
        ? props.theme.grey2
        : props.hasNewStaffDemand || props.toCurrentSession
          ? props.theme.staffPurple
          : props.hasNewExpertiseDemand
            ? props.theme.expertiseBlue
            : props.theme.cohorteRed
      };
      border-top-right-radius: 35px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      color: ${props.theme.white};
      margin-bottom: -.6rem;
      margin-left: -.8rem;
      padding: .8rem 1.1rem;
      font-size: .875rem;

      div:last-child {
        align-self: flex-end
        a {
          font-size: .8rem;
          font-weight: bold;
          color: ${props.theme.white};
          text-decoration: none;
        }
      }
      p {
        padding: 0;
        margin: 0;
        small {
          font-style: italic;
        }
      }
      span {
        font-weight: 600;
      }
    `
    : null
  }
`

export const FooterRequests = styled.div`
  padding: 0 0.8rem;
  margin-bottom: .5rem;
  cursor: pointer;
  font-weight: bold;
  color: ${props => props.teleExpertiseType === 'staff' || props.hasNewStaffDemand
    ? props.theme.staffPurple
    : props.teleExpertiseType === 'opinion' || props.hasNewExpertiseDemand
      ? props.theme.expertiseBlue
      : props.theme.cohorteRed
  };
  flex-direction: column;
  //flex: 0 1 auto;
  align-items: flex-start;
  div:last-child {
    align-self: flex-start;
  }
`

export const PatientSexBox = styled.div`
  border-radius: 10000px;
  background-color: ${props => props.theme.mediumGrey};
  text-align: center;
  font-size: 1.8rem;
  color: ${props => props.theme.blueGrey};
  font-weight: bold;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  i {
    font-size: .9rem;
  }
`

export const PatientCivilityBox = styled.div`
  flex-grow: 2;
  margin-left: .6rem;

  p {
    margin: 0;
    font-size: .8rem;
    padding-right: 1rem;
    margin-bottom: .3rem;
    color: ${props => props.theme.blueGrey};
    line-height: 1.1rem;
  }

  p strong {
    font-size: 1rem;
    font-weight: bold;
    color: ${props => props.theme.black};
  }
`

export const DisciplineLabel = styled.span`
  display: block;
  border: 1px solid ${props => props.theme.tagColors[props.labelColor]};
  background-color:  ${props => props.theme.tagColors[props.labelColor]};
  padding: .2rem .5rem;
  border-radius: 15px;
  color: ${props => props.isPatient ? 'white' : props.theme.grey};
  margin-top: .6rem;
  opacity: .7;
  font-weight: bold;
  ${props => props.invisible && css`visibility: hidden;`}
  &:not(:first-child) {
    margin-left: .3rem;
  }
`


export const Disciplines = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  align-items: center;
  font-size: .7rem;
  line-height: .98rem;
  align-content: space-around;
  margin-bottom: 1rem;

  ${DisciplineLabel} {
    ${props => props.open
    ? null
    : css`
        &:nth-child(n+4) {
          display: none;
        }
      `
  }
  }
`

export const MoreDisciplineButton = styled.button`
  display: block;
  border: 0px;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${props => props.theme.grey};
  font-style: italic;
  margin-top: .6rem;

  &:hover, &:focus {
    outline: none;
  }

  i {
    font-size: .5rem;
  }
`

export const TelexpertiseContainer = styled.div`
   display: flex;
   align-items: center;
   flex-flow: row nowrap;
   min-height: 24px;
 `

export const TelexpertiseBlock = styled.div`
border-radius: 5px;
padding: .1rem .5rem;
margin-right: .6rem;
width: 50px;
height: 24px;
color: ${props => props.theme.white};
display: flex;
align-items: center;
font-size: .9rem;
flex-flow: row nowrap;
justify-content: space-around;

i, strong {
  display: block;
}
`

export const ExpertiseBlock = styled(props => <TelexpertiseBlock {...props} />)`
box-shadow: inset 0px 0px 0px 1px ${props => props.isFilled ? props.theme.expertiseBlue : props.theme.grey};
${IconExpertise} {
  color: ${props => props.isFilled
    ? props.theme.expertiseBlue
    : props.theme.grey
  }
}
strong {
  color: ${props => props.isFilled
    ? props.theme.expertiseBlue
    : props.theme.grey
  }
}
`

export const StaffBlock = styled(props => <TelexpertiseBlock {...props} />)`
box-shadow: inset 0px 0px 0px 1px ${props => props.isFilled ? props.theme.staffPurple : props.theme.grey};
${IconMessage} {
  color: ${props => props.isFilled
    ? props.theme.staffPurple
    : props.theme.grey
  }
}
strong {
  color: ${props => props.isFilled
    ? props.theme.staffPurple
    : props.theme.grey
  }
}
`

export const CohorteBlock = styled(props => <TelexpertiseBlock {...props} />)`
box-shadow: inset 0px 0px 0px 1px ${props => props.isFilled ? props.theme.cohorteRed : props.theme.grey};
${IconCohorte} {
  color: ${props => props.isFilled
    ? props.theme.cohorteRed
    : props.theme.grey
  }
}
strong {
  color: ${props => props.isFilled
    ? props.theme.cohorteRed
    : props.theme.grey
  }
}
`

export const TimeBlock = styled.p`
  font-size: .7rem;
  margin-bottom: .3rem;
  line-height: .8rem;
`

export const Doctors = styled.p`
  padding: 0.6rem 0.8rem;
  font-size: .7rem;
  margin-bottom: .3rem;
  line-height: .8rem;
`

export const SmallP = styled.p`
  font-size: .7rem;
  line-height: .98rem;
  margin-bottom: 1rem;
`

export const PatientSex = ({ sex = "female" }) =>
  sex === 'female'
    ? <IconWoman />
    : sex === 'male'
      ? <IconMan />
      : <IconManWoman />

export const Body = styled.div`
  flex: 1 1 auto;

  ${Ui.BasicElement.P} {
    line-height: 1.2rem;
    margin-bottom: .5rem;
  }
  p:first-child {
    margin-bottom: 0;
    min-height: 20px;
    margin-bottom: 1rem;
  }
`

export const DropdownContainer = styled.ul`
  list-style-type: none;
  display: ${props => props.open ? 'block' : 'none'};
  position: absolute;
  top: -9px;
  right: -12px;
  z-index: 10;
  box-shadow: ${props => props.theme.boxShadow.thin};
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
  padding: 0;
  margin: 0;
`

export const LinkNoDecoration = styled(props => <Link {...props} />)`
  text-decoration: none;
`

export const DropdownItem = styled.li`
  padding: 1rem .8rem;
  margin: 0;
  cursor: pointer;
  font-size: .9rem;
  text-decoration: none;
  color: black;
  display: block;
  a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: black;
  }
  &:hover {
    background-color: ${props => props.theme.greyLight};
  }
  border-bottom: 1px solid ${props => props.theme.lightGrey};
`

export const Dropdown = ({
  onClickOutside = () => null,
  children,
  ...props
}) =>
  <ClickOutside Component={DropdownContainer} onClickOutside={onClickOutside} {...props}>
    {children}
  </ClickOutside>

export const OrganizationCard = ({
  id = 0,
  name = "",
  logo = "",
  currentUserManagerOrMember = false,
  selected,
  onClick = e => e.stopPropagation(),
  preventDefault,
  ...props
}) => {
  const to = preventDefault
    ? void 0
    : currentUserManagerOrMember
      ? `${privateSitePrefix}/profil?tab=3&organisationId=${id}`
      : `${privateSitePrefix}/organisation/${id}`

  return (
    <OrganizationLink
      onClick={onClick}
      key={`organization_${id}`}>
      <Ui.Form.ButtonLarge
        value={name}
        label={name}
        selected={selected}
        to={to}
      >
        <Ui.BasicElement.OrganizationPicture value={{ logo }} />
      </Ui.Form.ButtonLarge>
    </OrganizationLink>
  )
}

const OrganizationLink = styled.div`
  display: flex;
  cursor: pointer;
`
