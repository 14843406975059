import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './Component/App';
import './Asset/output.css'
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SERVER_ENV,
    integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] }), Sentry.httpClientIntegration(), Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
    <App />,
    document.getElementById('root')
)

serviceWorker.unregister()
