import React, { useEffect, useState } from 'react';
import { privateSitePrefix } from '../Util/Config'
import { Link } from 'react-router-dom'
import { apiUrl } from '../Util/Config'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import * as Resources from '../State/Resources'
import * as MessageList from '../State/MessageList'
import * as Ui from './Ui'
import * as Icon from '../components/icons';
import { mimeTypeIcon } from '../Util/MimeTypeIcons'
import pluralize from '../Util/Pluralize'
import LoadingBox from './Ui/LoadingBox'
import * as Styled from './MessageList.styled'

const MessageListComponent = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const currentUser = useSelector(state => state.login.currentUser)
  const filters = useSelector(MessageList.selectFilters)
  const errorMessage = useSelector(state => state.messageList.errorMessage)
  const [filterOpen, setFilterOpen] = useState(false)

  useEffect(() => {
    dispatch(MessageList.fetch())

    return () => {
      dispatch(MessageList.clean())
      dispatch(Resources.clean())
    }
  }, [dispatch])

  useEffect(() => {
    if (errorMessage)
      alert.error(errorMessage, { timeout: 5000 })
  }, [alert, errorMessage])

  const {
    [Resources.RESOURCE_MESSAGES]: messagesResource,
  } = useSelector(Resources.selectResources)

  const readNum = messagesResource.filter(message => message.read && !message.isNotification).length
  const unreadNum = messagesResource.filter(message => !message.read && !message.isNotification).length
  const notifNum = messagesResource.filter(message => message.isNotification).length

  const messages = messagesResource
    .filter(message =>
      (filters[MessageList.DISPLAY_UNREAD] && !message.read && !message.isNotification) ||
      (filters[MessageList.DISPLAY_READ] && message.read && !message.isNotification) ||
      (filters[MessageList.DISPLAY_NOTIFICATIONS] && message.isNotification)
    )

  const messageLink = message => message.isNotification
    ? `${privateSitePrefix}${message.url}`
    : `${privateSitePrefix}/messages/${message.id}`

  const messageAvatar = message => message.isNotification
    ? Icon.SkemeetIcon
    : message.author.profilePicture
      ? `${apiUrl}/users/profile-picture/${message.author.profilePicture}`
      : '/Avatar_default.png'

  const MessageTitle = ({
    message: {
      author: {
        title, firstname, lastname,
      },
    }
  }) => {

    return (
      <Ui.BasicElement.P bold dark>
        {title} {firstname} {lastname}
      </Ui.BasicElement.P>
    )
  }

  const Attachments = (assets) => assets.length > 0 &&

    <Ui.UploadFile.PreviewContainer>
      {assets.map(({ fileName, name, size, mimeType, title }, idx) =>
        <Ui.DocumentPreview.DocumentPreview
          key={`document_${idx}`}
          name={name}
          mimeType={mimeType}
          apiPath={`/messages/assets/`}
          size={size}
          fileName={fileName}
          title={title}
          iconSrc={mimeTypeIcon(mimeType)}
        >
        </Ui.DocumentPreview.DocumentPreview>
      )}
    </Ui.UploadFile.PreviewContainer>

  return (
    <>
      <LoadingBox />
      <Ui.Layout.PageLayout currentUser={currentUser}>
        <Styled.MessageListContainer>
          <Ui.Layout.PageContentWrapper>
            <Ui.Layout.PanelContainer>
              <Ui.Layout.Panel>
                <Ui.Layout.TelexpertiseThumbnailContainer>
                  <Ui.Layout.TelexpertiseLogo>
                    <Ui.Icon.IconChat />
                  </Ui.Layout.TelexpertiseLogo>
                  <Ui.BasicElement.H3 noMargin>Mes messages</Ui.BasicElement.H3>
                </Ui.Layout.TelexpertiseThumbnailContainer>
                <Ui.Layout.PanelFilterContainer noPadding>
                  <Ui.Layout.PanelFilterMobileLiner>
                    {!filterOpen
                      ? (
                        <Ui.Button.SearchButton
                          onClick={e => setFilterOpen(!filterOpen)}
                        >
                          <span>Rechercher</span>
                          <Ui.Icon.IconSearch />
                        </Ui.Button.SearchButton>
                      )
                      : (
                        <Ui.Button.CloseButton
                          onClick={e => setFilterOpen(!filterOpen)}
                        >
                          <span>Fermer</span>
                          <Ui.Icon.IconClose />
                        </Ui.Button.CloseButton>
                      )
                    }
                  </Ui.Layout.PanelFilterMobileLiner>
                  <Ui.Layout.PanelFilterBox open={filterOpen}>
                    <Ui.BasicElement.P bold>
                      Afficher :
                    </Ui.BasicElement.P>
                    <Ui.Layout.PanelFilterRow>
                      <Ui.Layout.PanelFilterLabel>
                        <span>{unreadNum}</span> {`${pluralize('message', unreadNum)} non ${pluralize('lu', unreadNum)}`}
                      </Ui.Layout.PanelFilterLabel>
                      <Ui.Form.Checkbox
                        checked={filters[MessageList.DISPLAY_UNREAD]}
                        onChange={e => e.target.checked
                          ? dispatch(MessageList.addStatus(MessageList.DISPLAY_UNREAD))
                          : dispatch(MessageList.removeStatus(MessageList.DISPLAY_UNREAD))
                        }
                      ></Ui.Form.Checkbox>
                    </Ui.Layout.PanelFilterRow>
                    <Ui.Layout.PanelFilterRow>
                      <Ui.Layout.PanelFilterLabel>
                        <span>{readNum}</span> {`${pluralize('message', readNum)} ${pluralize('lu', readNum)}`}
                      </Ui.Layout.PanelFilterLabel>
                      <Ui.Form.Checkbox
                        checked={filters[MessageList.DISPLAY_READ]}
                        onChange={e => e.target.checked
                          ? dispatch(MessageList.addStatus(MessageList.DISPLAY_READ))
                          : dispatch(MessageList.removeStatus(MessageList.DISPLAY_READ))
                        }
                      />
                    </Ui.Layout.PanelFilterRow>
                    <Ui.Layout.PanelFilterRow>
                      <Ui.Layout.PanelFilterLabel>
                        <span>{notifNum}</span> {pluralize('notification', notifNum)}
                      </Ui.Layout.PanelFilterLabel>
                      <Ui.Form.Checkbox
                        checked={filters[MessageList.DISPLAY_NOTIFICATIONS]}
                        onChange={e => e.target.checked
                          ? dispatch(MessageList.addStatus(MessageList.DISPLAY_NOTIFICATIONS))
                          : dispatch(MessageList.removeStatus(MessageList.DISPLAY_NOTIFICATIONS))
                        }
                      />
                    </Ui.Layout.PanelFilterRow>
                    <Ui.Layout.PanelFilterRowMobile>
                      <Ui.SearchBar.SearchBar
                        placeholder="Rechercher un nom, un prénom ou un diagnostic ..."
                        value={filters.search}
                        onChange={e => dispatch(MessageList.changeSearch(e.target.value))}
                      />
                    </Ui.Layout.PanelFilterRowMobile>
                    <Ui.Layout.PanelFilterRowMobile>
                      <Ui.Icon.IconUp onClick={e => setFilterOpen(!filterOpen)} />
                    </Ui.Layout.PanelFilterRowMobile>
                  </Ui.Layout.PanelFilterBox>
                </Ui.Layout.PanelFilterContainer>
              </Ui.Layout.Panel>
            </Ui.Layout.PanelContainer>
            <Ui.Layout.Content hasPanel>
              <Ui.Search.FilterContainer>
                <Ui.Search.FiltersAndSorting>
                  <div>
                    <Ui.Button.BasicButton
                      isFilled
                      isGreen
                      isLarge
                      as={Link}
                      to={`${privateSitePrefix}/nouveau/message`}
                    >
                      Nouveau message
                    </Ui.Button.BasicButton>
                  </div>
                </Ui.Search.FiltersAndSorting>
              </Ui.Search.FilterContainer>
              <Ui.Card.Container>
                <Ui.Search.MobileActionButtonGroup>
                  <Ui.Button.BasicButton
                    isFilled
                    isGreen
                    isLarge
                    as={Link}
                    to={`${privateSitePrefix}/nouveau/message`}
                  >
                    Nouveau message
                  </Ui.Button.BasicButton>
                </Ui.Search.MobileActionButtonGroup>
                <Styled.MessageSection>
                  {messages.length > 0 &&
                    messages
                      .slice()
                      .sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp)
                      .map((message, idx) => (
                        <Ui.Layout.BlockContainer key={idx}>
                          <Link
                            to={() => messageLink(message)}
                          >
                            <Ui.Message.Container key={`message_${message.id}`} on={message.read ? 0 : 1}>
                              <Ui.Message.Header>
                                <div>
                                  <Ui.Message.Avatar src={messageAvatar(message)} />
                                  <Ui.Message.Title>
                                    {message.isNotification
                                      ? <Ui.BasicElement.P bold dark>
                                        Skemeet
                                      </Ui.BasicElement.P>
                                      : <MessageTitle message={message} />
                                    }
                                    <Ui.BasicElement.P>
                                      Le {message.dateString} à {message.hour}
                                    </Ui.BasicElement.P>
                                  </Ui.Message.Title>
                                </div>
                              </Ui.Message.Header>
                              <Ui.Message.Body>
                                <Ui.BasicElement.P bold>
                                  Objet {message.isNotification ? 'de la notification' : 'du message'}
                                </Ui.BasicElement.P>
                                <Ui.BasicElement.P>
                                  {message.subject}
                                </Ui.BasicElement.P>
                                {message.isNotification &&
                                  <span>{message.linkLabel}</span>
                                }
                              </Ui.Message.Body>
                              <Attachments assets={message.assets} />
                            </Ui.Message.Container>
                          </Link>
                        </Ui.Layout.BlockContainer>
                      ))
                  }
                </Styled.MessageSection>
              </Ui.Card.Container>
            </Ui.Layout.Content>
          </Ui.Layout.PageContentWrapper>
        </Styled.MessageListContainer>
      </Ui.Layout.PageLayout>
    </>
  )
}

export default MessageListComponent;
