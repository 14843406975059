import * as React from "react";

export const CheckboxCheck = ({
    widthAndHeight = 20,
    color='white',
    ...props
}) => (
    <svg
        width={widthAndHeight}
        height={widthAndHeight}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={20} height={20} rx={2} fill="#3BC3A8" />
        <path
        d="M14.6668 6.5L8.25016 12.9167L5.3335 10"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </svg>
);
