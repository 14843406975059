import * as Icon from '../components/icons';

export const mimeTypeIcon = (mimeType) => {
  const fileType = mimeType.split('/')[1]

  switch (fileType) {
    case 'pdf':
      return <Icon.DocPdf />
    case 'vnd.ms-excel':
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <Icon.DocXls />
    case 'msword':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      return <Icon.DocWord />
    case 'vnd.ms-powerpoint':
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'pptx':
      return <Icon.DocPpt />
    case 'zip':
    case 'x-7z-compressed':
      return <Icon.DocZip />
    case 'jpg':
    case 'jpeg':
      return <Icon.DocJpg />
    case 'plain':
      return <Icon.DocTxt />
    case 'gif':
      return <Icon.DocGif />
    case 'tiff':
      return <Icon.DocTiff />
    case 'mpeg':
    case 'x-msvideo':
      return <Icon.DocMpg />
    case 'mp4':
      return <Icon.DocMp4 />
    case 'png':
      return <Icon.DocPng />
    default:
      return <Icon.DocWord />
  }
}
