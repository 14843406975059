import React from 'react';
import styled from 'styled-components/macro'
import * as Ui from '../Ui'

const PrivacyPolicyComponent = ({ isLoggedIn }) => {

  return (
    <DisclaimerContentContainer>
      <Ui.BasicElement.P><i>Dernière mise à jour le 15/03/2023</i></Ui.BasicElement.P>
      <Ui.BasicElement.P bold big uppercase>Résumé de notre politique de confidentialité</Ui.BasicElement.P>
      <Ui.BasicElement.P>La plateforme SKEMEET est amenée à traiter des données personnelles vous concernant dans le cadre de son service de téléexpertise médicale permettant aux professionnels de santé utilisateurs de la plateforme de partager de manière sécurisée des données sur leurs patients dans le cadre de leur prise en charge médicale.</Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Nous collectons vos données de différentes façons.</Ui.BasicElement.Span> Elles peuvent nous être communiquées par vous-même, si vous êtes utilisateur de la plateforme, ou par un professionnel de santé assurant votre prise en charge médicale, si vous êtes patient. Elles peuvent également être recueillies par le biais d’outils informatiques tels que des traceurs (ou cookies) sur Internet (voir sur ce point notre <a href={isLoggedIn ? "?tab=4" : "/cookies"}>politique de cookies</a>). </Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Nous avons besoin de ces données pour</Ui.BasicElement.Span> fournir notre service de téléexpertise médicale aux professionnels de santé utilisateurs de la plateforme SKEMEET, faciliter la navigation et assurer la sécurité de notre plateforme, contrôler la qualité de la navigation sur notre plateforme, gérer vos droits rappelés ci-dessous. Nous utilisons également des traceurs (ou cookies) et autres dispositifs similaires sur Internet pour les finalités décrites dans notre <a href={isLoggedIn ? "?tab=4" : "/cookies"}>politique de cookies</a>. </Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Les fondements juridiques de ces traitements</Ui.BasicElement.Span> sont, en fonction des finalités, le contrat, les intérêts légitimes de nos utilisateurs (accès aux fonctionnalités de la plateforme), nos intérêts légitimes (amélioration de nos services et offres, gestion administrative et comptable, anticipation et gestion de contentieux, constatation, exercice ou défense d'un droit en justice). </Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Certaines données doivent être obligatoirement collectées.</Ui.BasicElement.Span> Les conséquences d’un défaut de collecte de certaines données personnelles dépendent des situations. Il peut s’agir de l’impossibilité pour nous de créer votre compte utilisateur ou de répondre à vos demandes sur le fondement de vos droits (l’exigence de fourniture des données personnelles est alors réglementaire).</Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Les destinataires auxquels vos données peuvent être communiquées par SKEMEET</Ui.BasicElement.Span> sont les professionnels de santé utilisateurs de la plateforme, les établissements de santé, nos sous-traitants et prestataires (hébergeur de notre plateforme (Hébergeur Agréé Données de Santé), fournisseur de la solution d’appels audio/vidéo), prestataire de services de paiement, avocats, huissiers) et autorités judiciaires et administratives. </Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Vous avez des droits.</Ui.BasicElement.Span> Il s’agit du droit d’accès, du droit de rectification, du droit d’effacement, du droit à la limitation des traitements, du droit à la portabilité des données, du droit d’opposition, ainsi que du droit de définir, modifier et révoquer des directives relatives au sort de vos données après votre mort. Selon les circonstances et selon les traitements et sous réserve de respecter les conditions posées par la réglementation, vous pourrez exercer ces droits en nous écrivant à l’adresse postale suivante : DPO – SKEZI, Les Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy ou par mail <a href="mailto:dpo@skezi.eu">dpo@skezi.eu</a>. Vous disposez également du droit d’introduire une réclamation auprès de l’autorité de contrôle compétente (ex. : en France, la CNIL).</Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>Les durées de conservation de vos données</Ui.BasicElement.Span> varient selon la nature des données traitées et la finalité poursuivie ou encore selon la durée de la prescription légale (qui est généralement de cinq ans à compter du moment où la personne souhaitant exercer une action a eu ou aurait dû avoir connaissance des faits lui permettant de l’exercer). </Ui.BasicElement.P>
      <Ui.BasicElement.P><Ui.BasicElement.Span bold>La sécurité des données</Ui.BasicElement.Span> est assurée par les mesures techniques et organisationnelles mises en œuvre par SKEMEET au regard de la nature des données et des risques présentés par le traitement.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>Pour en savoir plus, nous vous invitons à prendre connaissance de la politique de confidentialité complète ci-dessous.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold big uppercase>Politique de confidentialité détaillée – SKEMEET</Ui.BasicElement.P>
      <Ui.BasicElement.P>La présente politique de confidentialité définit les conditions dans lesquelles SKEMEET recueille et traite des données personnelles vous concernant en tant que professionnel de santé utilisateur de la plateforme, patient ou visiteur de son site Internet.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>La plateforme SKEMEET est exploitée par la société SAS SKEZI au Capital social de 10 225,00 € immatriculée au RCS de Annecy sous le numéro d’identification unique B 892 223 603 dont le siège social est au 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Conformément à la législation en vigueur en matière de protection des données personnelles, et notamment la loi n°78-17 du 6 janvier 1978 dite loi « Informatique et Libertés » (la « <Ui.BasicElement.Span bold>loi Informatique et Libertés</Ui.BasicElement.Span> ») et le règlement européen 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données dit règlement général sur la protection des données ou RGPD (le « <Ui.BasicElement.Span bold>RGPD</Ui.BasicElement.Span> »), la présente politique de confidentialité vous informe sur les modalités de recueil et de traitement de vos données personnelles par la plateforme SKEMEET.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Cette politique de confidentialité n’est pas de nature contractuelle et ne créée pas d’obligation au-delà de ce qui est déjà prévu par la réglementation précitée en matière de protection des données personnelles.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Nous pouvons être conduits à mettre à jour la présente politique de confidentialité. Toute mise à jour sera portée préalablement à votre connaissance, et, si cela est nécessaire, fera l’objet d’un recueil de votre consentement.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>1. Quelles sont les données que nous traitons et comment sont-elles collectées ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Nous pouvons être amenés à collecter vos données de différentes façons par le biais de notre plateforme. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Si vous êtes professionnel de santé, les données que nous collectons par ce biais peuvent être essentiellement votre civilité, votre nom, votre prénom, votre profession, votre discipline médicale, votre numéro RPPS, votre adresse postale professionnelle, votre adresse email professionnelle et votre numéro de téléphone professionnel, vos données de connexion et d’utilisation de la plateforme, matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé). Ces données peuvent nous être communiquées par vous-même lors de votre création de compte utilisateur ou lors de nos échanges.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Des données peuvent également être recueillies par le biais d’outils informatiques tels que des cookies. Pour plus d’informations sur l’utilisation de ces outils sur notre site internet, nous vous invitons à prendre connaissance de la <a href={isLoggedIn ? "?tab=4" : "/cookies"}>politique de cookies</a>. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Si vous êtes patient, les données que nous collectons par le biais de notre plateforme peuvent être essentiellement votre civilité, votre nom, votre prénom, votre date de naissance, votre profession, votre numéro de sécurité sociale, votre médecin traitant, des informations relatives à votre état de santé et à votre prise en charge médicale. Ces données peuvent nous être communiquées par un établissement ou un professionnel de santé ayant assuré votre prise en charge médical. Elles peuvent être partagées sur la plateforme par un professionnel de santé avec un autre professionnel de santé dans le cadre de votre prise en charge médicale. </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>2. Quelles sont les finalités et quels sont les fondements juridiques de nos traitements de données personnelles ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>En application de l’article 6 du RGPD, tout traitement de données à caractère personnel, pour être licite, doit reposer sur l’un des fondements juridiques énoncés à cet article. </Ui.BasicElement.P>
      <Ui.BasicElement.P>En application de l’article 9 du RGPD, les traitements de données concernant la santé ne sont autorisés que s’ils respectent l’une des conditions mentionnées audit article. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Le tableau ci-dessous expose les différentes finalités pouvant être poursuivies lors des traitements de vos données et les fondements juridiques sur lesquels repose la poursuite de chacune de ces finalités.</Ui.BasicElement.P>
      <Ui.BasicElement.Table>
        <thead>
          <tr>
            <td>
              <b>Finalités
					poursuivies</b>
            </td>
            <td>
              <b>Fondements
					juridiques</b>
            </td>
          </tr>
          <tr>
            <td>
              Gérer
              les comptes utilisateurs des professionnels de santé sur la
              plateforme
            </td>
            <td>
              Contrat
              entre l’utilisateur et la plateforme SKEMEET
            </td>
          </tr>
          <tr>
            <td>
              Mettre
              en relation des professionnels de santé dans le cadre de la
              prise en charge d’un patient
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              Permettre
              à des professionnels de santé de communiquer entre eux,
              présenter un patient, présenter une réunion, inclure des patients
              dans une cohorte
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              Gérer
              le paiement des abonnements
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              Permettre
              à des professionnels de santé de partager des documents sur un
              patient
            </td>
            <td>
              Traitement
              nécessaire à la prise en charge du patient au titre des
              activités de prévention, de diagnostic et de soins (article 9
              h) RGPD)
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Informer
              et/ou proposer des nouvelles fonctionnalités et produire
              des données statistiques anonymisées relatives à
              l’utilisation de la plateforme par les professionnels de santé
              afin de communiquer sur son outil et améliorer ses services
            </td>
            <td>
              Intérêts
              légitimes de la plateforme SKEMEET (amélioration des
              fonctionnalités de la plateforme)
            </td>
          </tr>
          <tr>
            <td>
              Réaliser
              des enquêtes facultatives sur ses services en vue de les
              améliorer
            </td>
            <td>
              Intérêts
              légitimes de la plateforme SKEMEET (amélioration des
              fonctionnalités de la plateforme)
            </td>
          </tr>
          <tr>
            <td>
              Gérer
              les relations presse
            </td>
            <td>
              Intérêts
              légitimes de la plateforme SKEMEET (communiquer sur nos
              contenus et produits et les promouvoir)
            </td>
          </tr>
          <tr>
            <td>
              Faciliter
              la navigation et assurer la sécurité de notre site Internet,
              contrôler la qualité de la navigation sur notre site Internet
              et son audience
            </td>
            <td>
              Intérêts
              légitimes de la plateforme SKEMEET (gestion de sa
              plateforme)
            </td>
          </tr>
          <tr>
            <td>
              Gérer
					vos droits rappelés au point <b>5.</b>
            </td>
            <td>
              Respect
              des obligations légales auxquelles la plateforme SKEMEET
              est soumise
            </td>
          </tr>
        </tbody>
      </Ui.BasicElement.Table>
      <Ui.BasicElement.P>Concernant les données collectées via des cookies et autres dispositifs similaires, nous vous invitons à consulter notre <a href={isLoggedIn ? "?tab=4" : "/cookies"}>politique de cookies</a>.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>3. La collecte et le traitement de vos données sont-ils obligatoires ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>L’accès à certaines fonctionnalités de notre plateforme est subordonné à la communication des données suivantes : civilité, nom, prénom, profession, discipline médicale, numéro RPPS, adresse email professionnelle, numéro de téléphone professionnel, adresse postale professionnelle, mot de passe choisi.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Si ces données ne nous sont pas communiquées ou ne peuvent pas être collectées, nous ne pourrons pas répondre à votre demande. Ces exigences de fourniture de données ont ici un caractère contractuel.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Pour pouvoir exercer vos droits rappelés au point 5, la communication d’un justificatif d’identité ou d’autres informations/documents permettant de nous assurer de votre identité pourront être requises dans certains cas. </Ui.BasicElement.P>
      <Ui.BasicElement.P>La non-communication de ces éléments peut nous empêcher de répondre à vos demandes. Cette exigence de fourniture de données revêt ici un caractère réglementaire.</Ui.BasicElement.P>
      <Ui.BasicElement.P>La collecte d’autres données peut être obligatoire. Toutes les données dont la collecte est obligatoire, la nature contractuelle ou règlementaire de cette obligation de fourniture, ainsi que les conséquences de l’absence de fourniture des données concernées seront portées à votre connaissance en temps utile.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>4. A qui sommes-nous susceptibles de transmettre vos données ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Nous pouvons être amenés à communiquer des données :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>aux établissements et professionnels de santé dans le cadre de la prise en charge du patient ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>à nos sous-traitants et prestataires (hébergeur de notre plateforme (Hébergeur Agréé Données de Santé), fournisseur de la solution d’appels audio/vidéo, prestataire de services de paiement), avocats, huissiers);</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>et aux autorités judiciaires et administratives.</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P bold>Les données recueillies via la plateforme ne seront pas transmises à des acteurs commerciaux ou publicitaires. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Afin de respecter les dispositions du Code de la santé publique concernant les données de santé, la plateforme SKEMEET a recours à un Hébergeur de Données de Santé certifié conforme à la norme NF ISO/CEI 27001.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Sous réserve de ce qui précède, nous nous engageons à ne jamais divulguer vos données à des tiers, sauf avec votre autorisation expresse ou dans des circonstances très particulières, par exemple :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>si nous sommes amenés, du fait de la loi, dans le cadre d’une procédure judiciaire, d’un litige et/ou d’une requête des autorités publiques, à divulguer vos données ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>si nous pensons qu’à des fins de sécurité nationale, d’application de la loi ou autre motif d’intérêt général, la divulgation de vos données est nécessaire ou appropriée.</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P bold>5. Quels sont vos droits ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Vous disposez dans les conditions définies aux articles 15 et suivants du RGPD, notamment des droits suivants, sauf exceptions :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>du droit d'obtenir de la plateforme SKEMEET la confirmation que des données personnelles vous concernant sont ou ne sont pas traitées et, lorsqu'elles le sont, l'accès auxdites données à caractère personnel ainsi qu’à plusieurs informations sur nos traitements (droit d’accès – article 15 du RGPD) ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>du droit d'obtenir de la plateforme SKEMEET la rectification des données personnelles vous concernant qui sont inexactes (droit de rectification – article 16 du RGPD) ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>du droit d'obtenir de la plateforme SKEMEET l'effacement de données personnelles vous concernant dans certains cas (droit d’effacement ou « à l’oubli » – article 17 du RGPD) ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>du droit d'obtenir de la plateforme SKEMEET la limitation des traitements dans certains cas (droit à la limitation du traitement – article 18 du RGPD) ;</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P>Vous disposez aussi, dans certains conditions et circonstances :</Ui.BasicElement.P>
      <Ui.BasicElement.Ul>
        <Ui.BasicElement.Li>du droit d’obtenir à tout moment de la plateforme SKEMEET pour des raisons tenant à votre situation particulière, que nous ne procédions plus aux traitements des données personnelles vous concernant dans certains cas (<Ui.BasicElement.Span bold>droit d’opposition</Ui.BasicElement.Span> – article 21.1. du RGPD) ;</Ui.BasicElement.Li>
        <Ui.BasicElement.Li>du droit de s'opposer à tout moment au traitement des données personnelles vous concernant à des fins de prospection (<Ui.BasicElement.Span bold>droit d’opposition à la prospection</Ui.BasicElement.Span> – article 21.2. du RGPD).</Ui.BasicElement.Li>
      </Ui.BasicElement.Ul>
      <Ui.BasicElement.P>Vous disposez également du droit de définir, modifier et révoquer à tout moment des directives relatives à la conservation, à l'effacement et à la communication de vos données personnelles après votre mort en application de l’article 40-1 de la loi Informatique et Libertés (qui deviendra le 1er juin 2019 l’article 85 de la même loi). Ces directives peuvent être générales ou particulières. Nous pouvons être uniquement dépositaire des directives particulières concernant les données que nous traitons. Les directives générales peuvent uniquement être recueillies et conservées par un tiers de confiance numérique certifié par la CNIL. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Vous avez notamment le droit de désigner un tiers auquel les données vous concernant pourront être communiquées après votre mort. Vous vous engagez alors à informer ce tiers de votre démarche et du fait que des données permettant de l’identifier sans ambiguïté nous seront transmises et à lui communiquer la présente politique de confidentialité. </Ui.BasicElement.P>
      <Ui.BasicElement.P>Sous réserve de respecter les conditions posées par la réglementation, vous pouvez exercer vos droits en nous écrivant à l’adresse postale suivante : DPO – SKEZI, Les Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy ou par mail <a href="mailto:dpo@skezi.eu">dpo@skezi.eu</a>.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Vous disposez également du droit de vous opposer à tout moment à l’utilisation de certains cookies ou dispositif similaires. Nous vous invitons à consulter notre <a href={isLoggedIn ? "?tab=4" : "/cookies"}>politique de cookies</a> pour connaître les moyens mis à votre disposition pour exercer ce droit.</Ui.BasicElement.P>
      <Ui.BasicElement.P>Enfin, vous disposez également du droit d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (la CNIL) ou de toute autre autorité de contrôle compétente.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>6. Combien de temps conservons-nous vos données ?</Ui.BasicElement.P>
      <Ui.BasicElement.P>Les données sont conservées pendant le temps strictement nécessaire aux finalités décrites ci-dessus au point 2.</Ui.BasicElement.P>
      <Ui.BasicElement.Table>
        <thead>
          <tr>
            <td>
              <b>Données concernées</b>
            </td>
            <td>
              <b>Durées de conservation </b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Données relatives à la gestion des comptes des Utilisateurs.
            </td>
            <td>
              Pendant la durée du compte, puis 5 ans à compter de la demande de
              clôture du compte
            </td>
          </tr>
          <tr>
            <td>
              Documents
              comptables (factures, bons de commande, etc.)
            </td>
            <td>
              10              ans à compter de la clôture de l’exercice au cours duquel
              ces documents ont été émis
            </td>
          </tr>
          <tr>
            <td>
              Données relatives à la gestion des précontentieux et contentieux
            </td>
            <td>
              <br />
                En cas de précontentieux, suppression dès le règlement amiable
					    	du litige ou dès la prescription de l’action en justice
                <br />
                En cas de contentieux, suppression lorsque les recours ne sont
                plus possibles contre la décision rendue pour la faire exécuter
                <br />
            </td>
          </tr>
          <tr>
            <td>
              Données liées aux directives concernant le sort de vos données après votre décès
            </td>
            <td>
              Aussi longtemps que les données concernées par les directives
              seront conservées
            </td>
          </tr>
          <tr>
            <td>
              Données relatives à l’exercice d’un droit d’accès,
              de rectification, d’effacement
            </td>
            <td>
              5 ans à compter de la date de fin de la procédure liée à
              votre demande
            </td>
          </tr>
          <tr>
            <td>
              Données relatives à l’exercice d’un droit d’opposition
            </td>
            <td>
              6 ans à compter de la date de fin de la procédure liée à
              votre demande
            </td>
          </tr>
          <tr>
            <td>
              Données relatives à l’exercice d’un droit à la limitation d’un
              traitement
            </td>
            <td>
              5 ans à compter de la fin de la limitation du traitement
            </td>
          </tr>
        </tbody>
      </Ui.BasicElement.Table>
      <Ui.BasicElement.P>
        A l’issue des durées précédemment listées,
        vos données feront l’objet soit d’une suppression,
        soit d’une anonymisation.
        </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Par exception aux paragraphes précédents, en cas de
        précontentieux ou contentieux, toutes ou certaines de vos données
        pourront faire l’objet d’une conservation prolongée si celles-ci s’avèrent utiles pour ledit précontentieux ou contentieux.</Ui.BasicElement.P>
      <Ui.BasicElement.P bold>7. La sécurité des données</Ui.BasicElement.P>
      <Ui.BasicElement.P>
        La plateforme SKEMEET met en œuvre
        toutes les mesures techniques et organisationnelles afin
        d’assurer la sécurité des traitements de données à caractère
        personnel et la confidentialité des données.
        </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        A ce titre, la plateforme SKEMEET
        prend toutes les précautions utiles, au regard de la
        nature des données et des risques présentés par le traitement,
        afin de préserver la sécurité des données et, notamment, d’empêcher
        qu’elles soient déformées, endommagées, ou que des tiers
        non autorisés y aient accès (procédés d’authentification
        avec accès personnel et sécurisé via des identifiants
        et mots de passe confidentiels, double authentification,
        journalisation des connexions, chiffrement de certaines données…).
        </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>8. Transferts en dehors de l’Espace économique européen</Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Vos données ne font l’objet d’aucun
        transfert en dehors de l’Union européenne.
        </Ui.BasicElement.P>
      <Ui.BasicElement.P bold>
        9. Qui est votre interlocuteur pour répondre à
        vos questions sur les données personnelles ?
        </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Compte tenu des activités de la plateforme SKEMEET,
        nous avons désigné un délégué à la protection
        des données au sens de la réglementation en vigueur.
        </Ui.BasicElement.P>
      <Ui.BasicElement.P>
        Celui-ci est en mesure de répondre à
        vos questions sur la protection des données personnelles.
        </Ui.BasicElement.P>
      <Ui.BasicElement.P>Pour toute question relative aux traitements de vos données par la plateforme SKEMEET, vous pouvez le contacter à l’adresse postale suivante : DPO – SKEZI, Les Papeteries, 1 Esplanade Augustin Aussedat, Cran Gevrier, 74960 Annecy ou par mail <a href="mailto:dpo@skezi.eu">dpo@skezi.eu</a>.</Ui.BasicElement.P>
    </DisclaimerContentContainer >
  )
}

const DisclaimerContentContainer = styled(props => <Ui.PortailLayout.PageContainer {...props} />)`
  @media only screen and (min-width: ${props => props.theme.device.desktop}) {
    margin: 0;
    margin-right: 4rem;
  }

  a {
    color: ${props => props.theme.patientGreen};
  }

  ${Ui.BasicElement.H1} {
    margin-top: 3rem;
  }

  ${Ui.BasicElement.H2} {
    margin-top: 2rem;
    font-size: 1rem;
  }

  ${Ui.BasicElement.P} {
    margin: .5rem 0;
  }

  ${Ui.BasicElement.Link} {
    font-size: .875rem;
    font-weight: 700;
  }
`

export default PrivacyPolicyComponent;
